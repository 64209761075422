import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import React, { useState } from 'react';
import { FieldStates } from '../../FormTypes';
import OnetimeRequestPay from './OnetimeRequestPay';
import JobFormPage from '../JobFormPage';
import RecurringRequestPay from './RecurringRequestPay';
import { JobFormFields } from 'parent-portal/forms/JobRequestTypes';
import JobPromoCodeInput from './JobPromoCodeInput';
import { client, track } from 'shared';
import { Text, Colors } from 'library';
import { Divider } from '@mui/material';
interface PayPageProps {
    fieldStates: FieldStates<JobFormFields>;
}

export default function PayPage({
    fieldStates: { pay, isQuickFill, requestType, paymentSchedule, code },
}: PayPageProps) {
    const { loading, submit } = useJobFormContext();
    const [promoCodeError, setPromoCodeError] = useState(false);
    const [showPromoCodeErrors, setShowPromoCodeErrors] = useState(false);

    async function submitJob() {
        track('Job Form Submitted');
        await submit();
    }

    function onNextClicked() {
        pageIsValid() && promoCodeIsValid();
    }

    function payIsValid() {
        const oneTimeValidState = isQuickFill.value ? isQuickFill.isValid && pay.isValid : pay.isValid;
        const recurringValidState = pay.isValid;
        return requestType.value === 'onetime' ? oneTimeValidState : recurringValidState;
    }

    function pageIsValid() {
        return payIsValid();
    }

    async function promoCodeIsValid() {
        const { value, setValid } = code;
        if (value) {
            track('PromoCode Validated', { code: value });
            setShowPromoCodeErrors(true);
            let res = await client('api/promo-code/validate/?name=' + value);
            if (!res.id) {
                setPromoCodeError(true);
                setValid(false);
            } else {
                setPromoCodeError(false);
                setValid(true);
                submitJob();
            }
        } else {
            setValid(true);
            submitJob();
        }
    }

    return (
        <JobFormPage
            onNextClicked={onNextClicked}
            nextText={'Finish'}
            nextDisabled={loading || !pageIsValid}
            id="pay-page"
        >
            <Text color={Colors.turquoise} bold textStyle={{ marginBottom: 20 }}>
                What are your pay preferences?
            </Text>
            {requestType.value === 'onetime' ? (
                <OnetimeRequestPay pay={pay} isQuickFill={isQuickFill.value} />
            ) : (
                <>
                    <RecurringRequestPay pay={pay} paymentSchedule={paymentSchedule} />
                    <Divider style={{ marginBottom: 30 }} />
                </>
            )}
            <JobPromoCodeInput code={code} error={promoCodeError} showErrors={showPromoCodeErrors} />
        </JobFormPage>
    );
}
