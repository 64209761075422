import React from 'react';
import { Grid } from '@material-ui/core';
import { Text, Colors } from 'library';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import { GeneralDocumentVerifyProps } from '../models';

export default function GeneralDocumentVerify({ setExpirationDate, expirationDate }: GeneralDocumentVerifyProps) {
    return (
        <Grid container item direction="column">
            <Text bold textStyle={{ margin: 5 }}>
                Before verifying, check the following...
            </Text>
            <Text textStyle={{ margin: 5 }}>✔ The document has correct providers name</Text>

            <Text bold textStyle={{ margin: 15 }}>
                Optional - Select Date Form Expires
            </Text>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    InputProps={{
                        inputProps: {
                            style: { textAlign: 'center', color: Colors.darkNavy },
                        },
                    }}
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    value={expirationDate}
                    onChange={(d: MaterialUiPickersDate | Date) => {
                        setExpirationDate(d as Date);
                    }}
                    minDate={new Date()}
                />
            </MuiPickersUtilsProvider>
        </Grid>
    );
}
