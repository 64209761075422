import React, { useCallback, useState } from 'react';
import { LoadingSpinner, SizeableRoundedDialog, Text, OutlineButton, Chip } from 'library';
import { consoleLogInDev } from 'shared';
import { IBusinessLocationWithNotes, ILocationStatusHistory } from 'models';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';
import * as fromApi from 'api/BusinessLocationStatusHistoryApi';

export default function ViewAllBusinessLocationStatusHistory({
    businessLocation,
}: {
    businessLocation: IBusinessLocationWithNotes;
}) {
    const [viewAllHistory, setViewAllHistory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [locationStatusHistory, setLocationStatusHistory] = useState<ILocationStatusHistory[]>();

    const updateLocationHistory = useCallback(() => {
        setLoading(true);
        fromApi
            .listBusinessLocationStatusHistory(businessLocation.id)
            .then(setLocationStatusHistory)
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }, [businessLocation]);

    const handleClick = () => {
        setViewAllHistory(true);
        updateLocationHistory();
    };

    const renderLocationStatusHistory = () => {
        if (loading) return <LoadingSpinner />;

        if (!locationStatusHistory?.length) {
            return (
                <Grid style={{ padding: 20 }}>
                    <Text>No Location History Found.</Text>
                </Grid>
            );
        }
        return locationStatusHistory.map((history) => (
            <>
                <Grid item key={history.business_location} style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Grid container item spacing={1} alignItems={'center'}>
                        <Grid item>
                            <Chip label={history.business_location_status.name} />
                        </Grid>
                        <Grid item>
                            <Text variant="caption">
                                {formatMonthDayYearWith12HourTime(history.business_location_status.added_at)}
                            </Text>
                        </Grid>
                    </Grid>
                    <Text>{history.business_location_status.description}</Text>
                </Grid>
                <Divider />
            </>
        ));
    };

    return (
        <>
            <Grid item style={{ marginRight: '2em', width: '40%' }}>
                <OutlineButton onClick={handleClick}>Status History</OutlineButton>
            </Grid>
            <SizeableRoundedDialog
                onClose={() => setViewAllHistory(false)}
                open={viewAllHistory}
                closeButton
                style={{ width: '70%', height: '93vh' }}
            >
                <Grid container item gap={1} direction="column" style={{ padding: 20 }}>
                    <Grid container direction="column">
                        <Grid style={{ marginBottom: '1em' }}>
                            <Text variant={'h1'}>Location Status History</Text>
                        </Grid>
                        {renderLocationStatusHistory()}
                    </Grid>
                </Grid>
            </SizeableRoundedDialog>
        </>
    );
}
