import { AdminProvider, OCCRRATrainings } from 'models';
import React from 'react';
import { Text } from 'library';
import { Cancel, CheckCircle } from '@mui/icons-material';
import Colors from 'constants/Colors';
import { Divider } from '@material-ui/core';
import {
    getCompletedDay0,
    getNinetyDaysAfterTandemStartDate,
    getSixtyDaysAfterTandemStartDate,
    getThirtyDaysAfterTandemStartDate,
    getCompletedDay30,
    getCompletedDay60,
} from 'internal/providers/OCCRRATrainingsStatus/OccrraTrainingsShared';

export default function TrainingsSection({ provider }: { provider: AdminProvider }) {
    let thirtyDaysAfterTandemStartDate = getThirtyDaysAfterTandemStartDate(provider);
    let sixtyDaysAfterTandemStartDate = getSixtyDaysAfterTandemStartDate(provider);
    let ninetyDaysAfterTandemStartDate = getNinetyDaysAfterTandemStartDate(provider);

    const completedDay0 = getCompletedDay0(provider);
    const completedDay30 = getCompletedDay30(provider);
    const completedDay60 = getCompletedDay60(provider);

    return (
        <div style={{ margin: 5, marginLeft: 10 }}>
            <Divider style={{ marginTop: 10 }} />
            <Text variant="body2" bold>
                OCCRRA Trainings (from OCCRRA):{' '}
            </Text>
            <Text variant="body2" bold textStyle={{ marginTop: 10 }}>
                {completedDay0 ? <CheckCircle htmlColor={Colors.turquoise} /> : <Cancel htmlColor={Colors.mediumRed} />}
                Day 0 Trainings
            </Text>
            {provider.occrra_info_collect?.occrra_trainings
                ?.filter((training: OCCRRATrainings) => training?.title?.includes('Child Care Center Orientation'))
                .map((training: OCCRRATrainings, index: number) => {
                    return (
                        <Text key={index} variant="caption">
                            {training.title}
                        </Text>
                    );
                })}
            <Text variant="body2" bold textStyle={{ marginTop: 10 }}>
                {completedDay30 ? (
                    <CheckCircle htmlColor={Colors.turquoise} />
                ) : (
                    <Cancel htmlColor={Colors.mediumRed} />
                )}
                Day 30 Training (Due: {thirtyDaysAfterTandemStartDate?.toLocaleDateString()})
            </Text>
            {!completedDay30 ? (
                <Text variant="caption" bold>
                    REQUIRED - Ohio's Approach to Quality, Overview of Child Development & Communicable Disease
                </Text>
            ) : null}
            {provider.occrra_info_collect?.occrra_trainings
                ?.filter(
                    (training: OCCRRATrainings) =>
                        training?.title?.includes('Approach to Quality') ||
                        training?.title?.includes('Overview of Child Development') ||
                        training?.title?.includes('Communicable Disease'),
                )
                .map((training: OCCRRATrainings, index: number) => {
                    return (
                        <Text key={index} variant="caption">
                            {training.title}
                        </Text>
                    );
                })}
            <Text variant="body2" bold textStyle={{ marginTop: 10 }}>
                {completedDay60 ? (
                    <CheckCircle htmlColor={Colors.turquoise} />
                ) : (
                    <Cancel htmlColor={Colors.mediumRed} />
                )}
                Day 60 Training (Due: {sixtyDaysAfterTandemStartDate?.toLocaleDateString()})
            </Text>
            {!completedDay60 ? (
                <Text variant="caption" bold>
                    {' '}
                    REQUIRED - Child Abuse One Hour
                </Text>
            ) : null}
            {provider.occrra_info_collect?.occrra_trainings
                ?.filter((training: OCCRRATrainings) => training?.title?.includes('Child Abuse'))
                .map((training: OCCRRATrainings, index: number) => {
                    return (
                        <Text key={index} variant="caption">
                            {training.title}
                        </Text>
                    );
                })}
            <Text variant="body2" textStyle={{ marginTop: 10 }}>
                To See Other Trainings - Download OCCRRA Training Summary!!
            </Text>
            <Text variant="body2" bold textStyle={{ marginTop: 10 }}>
                90 days at Tandem: {ninetyDaysAfterTandemStartDate?.toLocaleDateString()}
            </Text>
            <Divider style={{ marginTop: 10 }} />
        </div>
    );
}
