import { BusinessJobWithAppointments, SubJobAppointment } from 'models';
import React from 'react';
import { Colors, Text } from 'library';
import { Grid, styled } from '@mui/material';
import SubJobAppointmentEntry from 'internal/substitutes/SubJobAppointmentEntry';

export default function JobAppointments({
    job,
    refreshUpcomingShifts,
}: {
    job: BusinessJobWithAppointments;
    refreshUpcomingShifts: () => void;
}) {
    const appointments = job.slots.reduce((acc: SubJobAppointment[], slot) => [...acc, ...slot.appointments], []);
    return (
        <Container container item direction="column" gap={2}>
            <Grid container item direction="column">
                <Text bold>
                    {job.business[0].name} ({job.business[0].id})
                </Text>
                <Text>
                    {job.headline} ({job.id})
                </Text>
            </Grid>
            <Grid container item direction="row" gap={1}>
                {appointments.map((appt) => (
                    <Grid key={appt.id} container item style={{ width: 'unset' }}>
                        <SubJobAppointmentEntry appt={appt} refreshJobs={refreshUpcomingShifts} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

const Container = styled(Grid)({
    padding: 20,
    borderRadius: 18,
    backgroundColor: Colors.lightGrey,
});
