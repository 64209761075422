import { faPersonRunning } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@mui/material';
import AdminButton from 'internal/shared/AdminButton';
import { Colors, SizeableRoundedDialog } from 'library';
import { AdminListProvider, LeanAdminProvider } from 'models';
import React, { useState } from 'react';
import { StandByProvider } from '../StandbyList';
import theme from 'theme';
import ReverseMatchingTabs from './components/ReverseMatchingTabs';

export default function ReverseMatching({
    provider,
    updateProvider,
}: {
    provider: AdminListProvider | StandByProvider | LeanAdminProvider;
    updateProvider?: (providerId: number, fields: Partial<AdminListProvider>) => void;
}) {
    const [applicantsOpen, setApplicantsOpen] = useState(false);
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <AdminButton
                onClick={(e) => {
                    setApplicantsOpen(!applicantsOpen);
                }}
                FontAwesomeImg={faPersonRunning}
                color={Colors.lightBlue}
                borderColor={Colors.darkNavy}
                text="Matching"
            />
            <SizeableRoundedDialog
                open={applicantsOpen}
                style={{ padding: 10, backgroundColor: Colors.white, width: smDown ? '95%' : '60%' }}
                onClose={() => setApplicantsOpen(false)}
                closeButton
            >
                <ReverseMatchingTabs provider={provider} updateProvider={updateProvider} />
            </SizeableRoundedDialog>
        </>
    );
}
