import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { consoleLogInDev, track } from 'shared';
import { AppliedProvider, Job, ProfilePromptSubmission, Provider } from 'models';
import { ProviderJobType } from 'models/ProviderJobType';
import useQueryParams from 'shared/QueryParamsHook';
import { useUserContext } from 'UserContext';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';
import {
    emptyProvider,
    emptyQualifications,
    initialContext,
    ProviderProfileContextType,
    ProviderQualifications,
} from './providerProfileModels';
import {
    getApplicants,
    getChildcareCenterInformation,
    getExperience,
    getJob,
    getJobs,
    getProvider,
    getProviderQualifications,
    toggleHideApplicant,
    toggleLikeApplicant,
} from './providerProfileApi';

export const ProviderProfileContext = React.createContext<ProviderProfileContextType>(initialContext());

export function ProviderProfileContextProvider({ children }: { children: React.ReactNode }) {
    const { user, role } = useUserContext();
    const { id, jobId } = useParams<{ id: string; jobId: string }>();
    const query = useQueryParams();
    const [provider, setProvider] = useState<Provider>(emptyProvider);
    const [providerQualifications, setProviderQualifications] = useState<ProviderQualifications>(emptyQualifications);
    const [applicants, setApplicants] = useState<AppliedProvider[]>([]);
    const [visibleJobTypes, setVisibleJobTypes] = useState<ProviderJobType[]>([]);
    const [selectedProfile, setSelectedProfile] = useState<ProviderJobType>();
    const [jobs, setJobs] = useState<Job[]>([]);
    const [experiences, setExperiences] = useState([]);
    const [childcareCenterQualifications, setChildcareCenterQualifications] = useState([]);
    const [visibleProfilePrompts, setVisibleProfilePrompts] = useState<ProfilePromptSubmission[]>([]);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [relevanceFilter, setRelevanceFilter] = useState(false);
    const [orderByExperience, setOrderByExperience] = useState(false);
    const [orderByQualifications, setOrderByQualifications] = useState(false);
    const [job, setJob] = useState<Job>();

    const { trackSession } = usePublicSessionTracking('Provider Profile Context');

    const getVisibleProfilePrompts = () => {
        setVisibleProfilePrompts(
            provider.user.profile_prompt_submissions?.filter(
                (prompt) => prompt.text.trim() && prompt.profile_prompt.job_type === selectedProfile?.job_type.id,
            ) || [],
        );
    };

    const loadProvider = (providerUserId?: number) => {
        setInitialized(false);
        setLoading(true);
        let providerParam = providerUserId ?? id;
        let jobQueryParam = query.get('job');
        let jobParam = jobId || jobQueryParam ? `?job=${jobId ? jobId : jobQueryParam}` : '';
        getProvider(providerParam, jobParam)
            .then((response) => {
                response.hasBCIFBICheck =
                    response.user.background_checks?.some(
                        (check: any) => check.check_type === 'OCCRRA' && check.status === 'COMPLETED',
                    ) || false;
                setProvider(response);
                let childcare = response.job_type_interest.find(
                    (interest: ProviderJobType) => interest.job_type.name === 'Child Care',
                );
                setSelectedProfile(childcare ?? response.job_type_interest[0]);
                let jobTypes = !!childcare ? [childcare] : [];
                let other = response.job_type_interest.filter(
                    (interest: ProviderJobType) => interest.job_type.name !== 'Child Care',
                );
                setVisibleJobTypes(jobTypes.concat(other));
            })
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    };

    const loadUserData = () => {
        if (user) getJobs().then(setJobs).catch(consoleLogInDev);
    };

    const loadExperience = () => {
        if (initialized && user) getExperience(provider.id).then(setExperiences).catch(consoleLogInDev);
        if (initialized)
            getChildcareCenterInformation(provider.id).then(setChildcareCenterQualifications).catch(consoleLogInDev);
    };

    const loadApplicants = () => {
        if (!user || !jobId) {
            return;
        }
        setLoading(true);
        getApplicants(jobId, relevanceFilter, orderByExperience, orderByQualifications)
            .then(setApplicants)
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    };

    const loadJobData = () => {
        getJob(jobId).then(setJob).catch(consoleLogInDev);
    };

    const onProfileChanged = (profile?: ProviderJobType, location?: string) => {
        setSelectedProfile(profile);

        !user
            ? trackSession('CLICK', 'Provider Profile Job Type Selected', PublicSessionEventType.User, {
                  jobTypeProfileId: profile?.id,
                  location,
              })
            : track('Provider Profile Job Type Selected', { profile, location });
        window.scrollTo(0, 0);
    };

    function hideApplicant(provider: AppliedProvider) {
        if (job?.id) toggleHideApplicant(applicants, provider, job?.id).then(setApplicants).catch(consoleLogInDev);
    }

    function likeApplicant(provider: AppliedProvider) {
        if (job?.id) toggleLikeApplicant(applicants, provider, job?.id).then(setApplicants).catch(consoleLogInDev);
    }

    useEffect(() => {
        if (id) loadProvider();
        if (jobId) loadJobData();
        loadUserData();
    }, []);

    useEffect(() => {
        loadExperience();
    }, [initialized]);

    useEffect(() => {
        if (provider.id > 0) setInitialized(true);
    }, [provider]);
    useEffect(getVisibleProfilePrompts, [selectedProfile]);
    useEffect(() => {
        if (job) {
            let jobType = visibleJobTypes.find((type) => type.job_type.id === job.job_type);
            if (jobType) setSelectedProfile(jobType);
        }
    }, [visibleJobTypes]);

    useEffect(loadApplicants, [jobId, relevanceFilter, orderByExperience, orderByQualifications]);

    useEffect(() => {
        if (role === 'business_active' && provider.user.id > 0)
            getProviderQualifications(provider.user.id).then(setProviderQualifications).catch(consoleLogInDev);
    }, [role, provider]);

    const contextValue = {
        provider,
        providerQualifications,
        job,
        refreshJob: loadJobData,
        visibleJobTypes,
        refreshProvider: loadProvider,
        refreshApplicants: loadApplicants,
        jobs,
        selectedProfile,
        pastExperience: experiences,
        childcareCenterQualifications: childcareCenterQualifications,
        visibleProfilePrompts,
        loading,
        setSelectedProfile: onProfileChanged,
        applicants,
        relevanceFilter,
        setRelevanceFilter,
        orderByExperience,
        setOrderByExperience,
        orderByQualifications,
        setOrderByQualifications,
        setApplicants,
        likeApplicant,
        hideApplicant,
    };
    return <ProviderProfileContext.Provider value={contextValue}>{children}</ProviderProfileContext.Provider>;
}

export const useProviderProfileContext = () => {
    return useContext(ProviderProfileContext);
};
