import React, { useEffect, useState } from 'react';
import { IProviderReport, IProviderReports } from './models';
import { useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import ChangeModal from './ConfirmSubHours/ChangeModal';
import { IHourReport, IHourReportView } from 'internal/payments/HourReportModels';
import { buildNewReports, getHourReportViewStatus, buildNewReportFromHourReport } from './HourReportMethods';
import { ConfirmHoursSection } from './ConfirmHoursSection';
import { getHourReport } from 'api/HourReportApi';
import { consoleLogInDev } from 'shared';

export function ConfirmHoursContent({
    hourReports,
    updateHoursReported,
    includeTitles = true,
}: {
    hourReports: IHourReportView[];
    updateHoursReported: () => void;
    includeTitles?: boolean;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const [providerReports, setProviderReports] = useState<IProviderReports>({});
    const [actionReports, setActionReports] = useState<IProviderReports>({});
    const [changeReport, setChangeReport] = useState<IProviderReport>();

    useEffect(() => {
        buildProviderReports(hourReports);
    }, [hourReports]);

    function onSelectChangeReport(reportId?: number) {
        if (!reportId) {
            setChangeReport(undefined);
            return;
        }

        getHourReport(reportId)
            .then((response: IHourReport) => setChangeReport(buildNewReportFromHourReport(response)))
            .catch(consoleLogInDev);
    }

    function buildProviderReports(hourReports: IHourReportView[]) {
        if (!includeTitles) return setProviderReports(buildNewReports(hourReports));
        else {
            const needAction = buildNewReports(
                hourReports.filter((r) => getHourReportViewStatus(r) === 'Awaiting Business'),
            );
            setActionReports(needAction);
            setProviderReports(
                buildNewReports(hourReports.filter((r) => getHourReportViewStatus(r) !== 'Awaiting Business')),
            );
        }
    }

    return (
        <>
            {Object.keys(actionReports).length ? (
                <ConfirmHoursSection
                    reports={actionReports}
                    title="Action Needed:"
                    subtitle="These are the hours our providers have reported they worked for you today. Please confirm or dispute them by 8:00PM today. After that we will pay providers the reported hours."
                    setChangeReport={onSelectChangeReport}
                    updateHoursReported={updateHoursReported}
                    includeTitles={includeTitles}
                />
            ) : null}
            {Object.keys(providerReports).length ? (
                <ConfirmHoursSection
                    reports={providerReports}
                    title="History:"
                    subtitle="These reports have been acted on, you can see the status on each card."
                    setChangeReport={onSelectChangeReport}
                    updateHoursReported={updateHoursReported}
                    includeTitles={includeTitles}
                />
            ) : null}
            {changeReport ? (
                <ChangeModal
                    changeReport={changeReport}
                    setChangeReport={setChangeReport}
                    updateHoursReported={updateHoursReported}
                    fullScreen={small}
                />
            ) : null}
        </>
    );
}
