import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import OutlineDropdown from '../../home/components/shared/OutlineDropdown';
import moment from 'moment';
import PaySectionHeader from './PaySectionHeader';
import { PayBackgroundPaper } from './PayPapers';
import { track, client, consoleLogInDev } from 'shared';
import { Colors, Text } from 'library';
import LoadingButton from '../../../reusableComponents/loadingButton';

const MONTHS: object[] = [];
for (let i = 1; i < 15; i++) {
    let month = moment().subtract(i, 'months').format('MMMM YYYY');
    MONTHS.push({ key: month, value: month });
}

const SendReceipt = () => {
    const [selectedMonth, setSelectedMonth] = useState();
    const [loading, setLoading] = useState<boolean>(false);
    const [resultMessage, setResultMessage] = useState<string>('');

    const onMonthChange = (e: any) => {
        setSelectedMonth(e.target.value);
        setResultMessage('');
    };

    const sendReceipt = async () => {
        setLoading(true);
        setResultMessage('');
        let amount = 0;
        if (selectedMonth) {
            try {
                const res = await client('payment/api/invoice/receipt/', {
                    body: { amount: amount, month: selectedMonth },
                });

                setSelectedMonth(undefined);
                setResultMessage(`${selectedMonth} receipt sent`);
                track('Family requested monthly receipt', { receiptMonth: selectedMonth });
            } catch (consoleLogInDev) {
                setResultMessage('An error occurred');
            }
        }
        setLoading(false);
    };

    return (
        <PayBackgroundPaper>
            <Grid container item xs={12} direction="row" justify="center" alignItems="center">
                <Grid item xs={12}>
                    <PaySectionHeader>Send Monthly Receipt</PaySectionHeader>
                </Grid>
                <Grid item xs={12}>
                    <Text variant="body1" color={Colors.grey}>
                        Get an emailed receipt of total charges for a given month.
                    </Text>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                    <OutlineDropdown
                        value={selectedMonth || ''}
                        title={'Select Month'}
                        options={MONTHS}
                        onChange={onMonthChange}
                    />
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 20, marginBottom: 10 }} justify="flex-end">
                    <Grid item>
                        <LoadingButton
                            disabled={!selectedMonth}
                            loading={loading}
                            disableRipple
                            disableFocusRipple
                            onClick={() => sendReceipt()}
                        >
                            Send
                        </LoadingButton>{' '}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Text variant="body2">{resultMessage}</Text>
                </Grid>
            </Grid>
        </PayBackgroundPaper>
    );
};

export default SendReceipt;
