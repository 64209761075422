import React from 'react';
import { Route } from 'react-router-dom';
import TopNavigation from '../components/TopNavigation';
import JobRequestModal from 'parent-portal/forms/JobRequestModal';
import BusinessJobRequestModal from 'parent-portal/forms/BusinessJobRequestModal';
import PublicFooter from '../parent-portal/shared/PublicFooter';
import Footer from 'parent-portal/shared/Footer/Footer';
import SuperFooter from 'parent-portal/shared/SuperFooter/SuperFooter';
import { isLoggedIn } from 'api/UserApi';

export const Public = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <TopNavigation />
                {isLoggedIn() && <JobRequestModal />}
                {isLoggedIn() && <BusinessJobRequestModal />}
                <div style={{ display: 'flex', flexDireciton: 'column', flexGrow: 1 }}>
                    <Component {...props} />
                </div>
                <SuperFooter />
                <Footer />
                <div style={{ position: 'fixed', bottom: 0, right: 0 }}>
                    <PublicFooter />
                </div>
            </div>
        )}
    />
);
