import { PagingResult } from 'models';
import { ProviderSelection } from 'parent-portal/forms/JobRequestTypes';
import { client, queryStringifyObject } from 'shared';

export async function getPastWorkers(): Promise<PastWorkerDTO[]> {
    const sitterChoices = await client('get-past-sitters/');
    return sitterChoices.past_sitters;
}

export interface PastWorkerDTO {
    first_name: string;
    last_name: string;
    babysitter_id: number;
    id: number;
}

export function getPreferredWorkers(page = 1, addresses?: number[]): Promise<PagingResult<PreferredWorkerDTO>> {
    const addressString = addresses && addresses.length > 0 ? addresses.join(',') : undefined;
    const queryString = queryStringifyObject({ page, page_size: 5, addresses: addressString, distinct_workers: true });
    return client(`api/preferred-worker/?${queryString}`);
}

export interface PreferredWorkerDTO {
    id: number;
    user: {
        first_name: string;
        last_name: string;
        id: number;
    };
    business_location: {
        id: number;
        name: string;
    };
    business: {
        id: number;
        name: string;
    };
}

export interface PreferredJobSitterDTO {
    preferred_sitters: {
        sitter: number;
    }[];
}

export function convertPreferredSitterObject(
    preferredProviders: ProviderSelection[],
    pastProviders: ProviderSelection[],
): PreferredJobSitterDTO {
    const nonDupedPastProviders = pastProviders.filter(
        (past) => !preferredProviders.some((preferred) => preferred.id === past.id),
    );
    const allPreferred = [...preferredProviders, ...nonDupedPastProviders];
    return {
        preferred_sitters: allPreferred.map((preferredProvider: ProviderSelection) => ({
            sitter: preferredProvider.id,
        })),
    };
}
