import React, { useEffect } from 'react';
import { Input, MenuItem, FormControl, Select, Chip } from '@material-ui/core';
import FormField from './FormField';
import useDropdownStyles from '../../../reusableComponents/useDropdownStyles';
import { track } from 'shared/Tracking';
import { FormFieldProps } from './FormTypes';
import { JobType } from 'models';

const EXCLUDE_CATEGORIES = ['Child Care', 'House Cleaning', 'Pet Care'];

export default function JobCategoryInput({
    jobTypeId,
    jobTypes,
}: {
    jobTypeId: FormFieldProps<number>;
    jobTypes: JobType[];
}) {
    const { setValid, value, setValue } = jobTypeId;
    const selected = value === 0 || value === -2 ? '' : value;
    const selectedItem = jobTypes.find((x) => x.id === selected);
    const classes = useDropdownStyles();

    const excludedJobTypeIds = jobTypes.reduce((n: number[], old) => {
        if (EXCLUDE_CATEGORIES.includes(old.name)) {
            n.push(old.id);
        }
        return n;
    }, []);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    useEffect(() => {
        setValid(!!selected);
    }, [selected, setValid]);

    return value && !excludedJobTypeIds.includes(value) ? (
        <FormField title="Job Type*" style={{ marginTop: 30 }}>
            <FormControl className={classes.root}>
                <Select
                    className={classes.select}
                    labelId="label-id"
                    id="multi-select"
                    multiple={false}
                    value={selected}
                    onChange={(e: any) => {
                        setValue(e.target.value);
                        track('Job Form Job Type Change:', { jobTypeId: e.target.value });
                    }}
                    input={<Input />}
                    renderValue={() => <Chip label={selectedItem?.name} />}
                    MenuProps={MenuProps}
                    style={{ width: '100%' }}
                >
                    {jobTypes
                        .filter((j) => !EXCLUDE_CATEGORIES.includes(j.name))
                        .map((jobType) => (
                            <MenuItem key={jobType.id} value={jobType.id}>
                                {jobType.name}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </FormField>
    ) : null;
}
