import { useState, Dispatch, SetStateAction } from 'react';
import { useAdminAppContext } from 'internal/shared/AdminAppContext';

const useMatchingListLabels = (): [
    string[],
    string[],
    Dispatch<SetStateAction<string[]>>,
    string[],
    (labels: string[]) => string,
] => {
    const { getLabelByName } = useAdminAppContext();
    const defaultIncludedLabels = ['✅', '☑️'];
    const excludedLabels = ['Inactive Vetted', 'unavailable', 'Unreliable', 'Booked Permanently'];
    const [includedLabels, setIncludedLabels] = useState<string[]>(defaultIncludedLabels);

    const labelQueryString = (labels: string[]) => {
        return labels
            .map((label) => getLabelByName('admin-providers', label))
            .map((label) => label?.id)
            .filter((label) => label)
            .join(',');
    };

    return [includedLabels, excludedLabels, setIncludedLabels, defaultIncludedLabels, labelQueryString];
};

export default useMatchingListLabels;
