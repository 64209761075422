import React from 'react';
import { Colors } from 'library';
import SendIcon from '@material-ui/icons/Send';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Input } from '@mui/material';

export default function MessageInput({
    setMessageToSend,
    messageToSend,
    sendMessage,
    isSendingMessage,
    messagesEndRef,
    checkForPhone,
}: any) {
    return (
        <Input
            key="message-input"
            style={{ paddingBottom: 10, paddingLeft: 10, color: Colors.darkNavy }}
            disableUnderline={true}
            placeholder={'Message'}
            multiline
            maxRows={10}
            fullWidth={true}
            inputProps={{
                root: {
                    color: Colors.darkNavy,
                },
            }}
            endAdornment={
                <InputAdornment position="end">
                    <button
                        style={{ background: 'transparent', border: 'none', outline: 'none' }}
                        onClick={sendMessage}
                        disabled={isSendingMessage}
                    >
                        <SendIcon style={{ color: Colors.darkNavy, cursor: 'pointer' }} />
                    </button>
                </InputAdornment>
            }
            value={messageToSend}
            onChange={(e) => {
                setMessageToSend(e.target.value);
                checkForPhone(e.target.value);
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            }}
        />
    );
}
