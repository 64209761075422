import React, { useState } from 'react';
import { ModalHeader, PrimaryButton, Text, TextArea } from 'library';
import { Provider } from 'models';
import { client, consoleLogInDev, track } from 'shared';
import { Checkbox, Dialog, Grid } from '@material-ui/core';
import { useWidth } from 'reusableComponents/useWidth';
import { useUserContext } from 'UserContext';

interface IReportReason {
    id: number;
    display: string;
}

export default function ReportUser({ provider }: { provider: Provider }) {
    const breakpoint = useWidth();
    const { user } = useUserContext();
    const [reportModal, setReportModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [reasons, setReasons] = useState<IReportReason[]>([]);
    const [reason, setReason] = useState<IReportReason>();
    const [text, setText] = useState<string>('');

    function reportUser() {
        track('Report Provider clicked', { provider: provider.id });
        reportReasons();
        setReportModal(true);
    }
    function cancelReport() {
        if (!submitted) {
            track('Report Provider canceled', { provider: provider.id });
        }
        setReportModal(false);
        setReason(undefined);
        setSubmitted(false);
        setLoading(false);
        setText('');
    }
    function reportReasons() {
        client('api/report-reasons').then(setReasons);
    }

    function submitReport() {
        setLoading(true);
        let report = {
            reported: provider.user.id,
            reason: reason?.id,
            description: text,
        };
        track('Provider Reported', { report });
        client('api/report', {
            body: report,
        })
            .then((res) => {
                setSubmitted(true);
                setTimeout(() => {
                    cancelReport();
                }, 5000);
            })
            .catch(consoleLogInDev);
    }

    return user ? (
        <>
            <Text onClick={reportUser} variant="caption" textStyle={{ textDecoration: 'underline' }}>
                Report {provider.user.first_name}
            </Text>
            <Dialog
                maxWidth={'lg'}
                open={reportModal}
                onClose={cancelReport}
                fullScreen={breakpoint === 'xs'}
                PaperProps={{ style: { borderRadius: '18px' } }}
            >
                <Grid style={{ padding: 20 }}>
                    <ModalHeader
                        onClose={cancelReport}
                        title={`Report ${provider.user.first_name}`}
                        subtitle="Tandem will investigate any reports."
                    />
                    {submitted ? (
                        <Text textStyle={{ paddingTop: 20 }} variant="body2">
                            We will take it from here, thank you for reporting.
                        </Text>
                    ) : (
                        <>
                            <Grid xs={12} style={{ marginTop: 20, marginBottom: 10 }}>
                                {reasons.map((r: IReportReason) => (
                                    <Grid
                                        style={{ marginBottom: 5 }}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        wrap="wrap"
                                    >
                                        <Checkbox
                                            style={{ paddingLeft: 0 }}
                                            checked={r.id === reason?.id}
                                            onClick={() => setReason(r)}
                                        />
                                        <Text textStyle={{ maxWidth: '80%' }} variant="body2">
                                            {r.display}
                                        </Text>
                                    </Grid>
                                ))}
                            </Grid>
                            <Text variant="body1">Description:*</Text>
                            <TextArea
                                style={{ width: '100%' }}
                                onChange={(e) => setText(e.target.value)}
                                value={text}
                            />
                            <Grid xs={12} container justify="flex-end" direction="row">
                                <PrimaryButton
                                    loading={loading}
                                    disabled={!reason || !text.trim()}
                                    buttonStyle={{
                                        width: '75%',
                                        marginTop: 20,
                                        justifySelf: 'flex-end',
                                    }}
                                    onClick={submitReport}
                                >
                                    Submit Report
                                </PrimaryButton>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Dialog>
        </>
    ) : null;
}
