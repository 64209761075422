import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Checkbox } from 'library';

export function FilterCheckboxSection({
    options,
    selectedOptions,
    setSelectedOptions,
}: {
    options: string[];
    selectedOptions: string[];
    setSelectedOptions: (options: string[]) => void;
}) {
    return (
        <Grid item xs={12}>
            <Box style={{ marginTop: '3%' }}>
                {options.map((x) => (
                    <Checkbox
                        key={x}
                        label={x}
                        checked={selectedOptions.includes(x)}
                        onChange={() => {
                            if (selectedOptions.includes(x)) {
                                setSelectedOptions(selectedOptions.filter((y) => y !== x));
                            } else {
                                setSelectedOptions([...selectedOptions, x]);
                            }
                        }}
                    />
                ))}
            </Box>
        </Grid>
    );
}
