import React from 'react';
import TaskList from 'internal/TaskList/TaskList';
import { AdminBusinessLocationOption } from 'models/AdminBusinessLocation';

export default function BusinessLocationTasks({
    businessLocation,
    isOpen,
}: {
    businessLocation?: AdminBusinessLocationOption;
    isOpen: boolean;
}) {
    return (
        <div style={{ padding: 30 }}>
            <TaskList isOpen={isOpen} businessLocation={businessLocation} />
        </div>
    );
}
