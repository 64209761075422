import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import { TextButton, Text } from 'library';
import ProviderDetailsOutreachModal from 'internal/texting/outreach/ProviderDetailsOutreachModal';
import { AdminListProvider, BusinessJobWithAppointments } from 'models';
import AddProvider from '../AddProvider';
import { format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';
import SendMatch from './SendMatch';
import { ApiTimeslot } from '../substituteModels';

export default function SubAvailability({ sub_availability }: { sub_availability?: ApiTimeslot[] }) {
    return (
        <div>
            <Text variant="body2" bold>
                Provider-entered shift availability
            </Text>
            {sub_availability?.map((availability) => (
                <Text variant="body2">
                    {formatShortWeekdayMonthDay(availability.timeslot.lower)}{' '}
                    {format12HourTime(availability.timeslot.lower)}-{format12HourTime(availability.timeslot.upper)}
                </Text>
            ))}
        </div>
    );
}
