import React from 'react';
import { BasicDialog } from 'library';
import OutreachConversation from './OutreachConversation';
import { AdminListProvider, LeanAdminProvider } from 'models';

export default function OutreachConversationModal({
    externalNumber,
    tandemNumber,
    displayName,
    isOpen,
    onClose,
    provider,
}: {
    externalNumber: string;
    tandemNumber: string;
    displayName: string;
    isOpen: boolean;
    onClose: () => void;
    provider: LeanAdminProvider;
}) {
    return (
        <BasicDialog isOpen={isOpen} onClose={onClose} closeButton>
            <OutreachConversation
                externalNumber={externalNumber}
                tandemNumber={tandemNumber}
                displayName={displayName}
                onMarkUnread={onClose}
                provider={provider}
            />
        </BasicDialog>
    );
}
