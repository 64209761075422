import { Link } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import React from 'react';
import { track } from 'shared';

export default function MoreOptions() {
    const { toggleSimpleForm } = useJobFormContext();

    function goToLongForm() {
        track('More Options clicked on Job form');
        toggleSimpleForm();
    }

    return <Link onClick={goToLongForm}>More Options</Link>;
}
