import React, { useEffect, useState } from 'react';
import { Colors } from 'library';
import { client, track } from 'shared';
import AccountMenuItem from 'parent-portal/navigation/AccountMenu/AccountMenuItem';
import DifferenceIcon from '@mui/icons-material/Difference';
import { useModalsContext } from 'ModalsContext';
import { BusinessJobTypes, JobPaymentTypes, ProviderSelection } from 'parent-portal/forms/JobRequestTypes';
import moment, { Moment } from 'moment';
import { JobDetailValueWithOption, JobQualificationValue } from 'models';

interface IJobDetails {
    job_type_id: number;
    pay: number;
    rate_min: number;
    rate_max: number;
    pay_is_fixed: boolean;
    headline: string;
    family_comments: string;
    past_requested: ProviderSelection[];
    start_time: Moment | null;
    end_time: Moment | null;
    qualification_requirements: JobQualificationValue[];
    pay_type: JobPaymentTypes;
    business_job_type: BusinessJobTypes;
    details: JobDetailValueWithOption[];
    slots_available: number;
}

export const DuplicateJob = ({ jobId, onClose }: { jobId: number; onClose: () => void }) => {
    const { openJobForm } = useModalsContext();
    const [presets, setPresets] = useState<IJobDetails>({
        job_type_id: 1,
        pay: 0,
        rate_min: 0,
        rate_max: 0,
        pay_is_fixed: false,
        headline: '',
        family_comments: '',
        past_requested: [],
        start_time: null,
        end_time: null,
        pay_type: null,
        business_job_type: null,
        qualification_requirements: [],
        details: [],
        slots_available: 1,
    });
    useEffect(() => {
        client(`api/ongoing/${jobId}/duplicate/`).then((resp) => {
            setPresets(resp);
        });
    }, []);

    return (
        <AccountMenuItem
            icon={<DifferenceIcon style={{ color: Colors.darkNavy }} />}
            text="Duplicate Job"
            action={() => {
                track('Job card more options: Duplicate job clicked');
                onClose();
                openJobForm('Duplicate Job', {
                    frequency: 'onetime',
                    jobType: presets.job_type_id,
                    headline: presets.headline,
                    description: presets.family_comments,
                    pageNumber: 1,
                    pastProviders: presets.past_requested,
                    simple: false,
                    jobTimes: {
                        start: presets.start_time ? moment(presets.start_time) : null,
                        end: presets.end_time ? moment(presets.end_time) : null,
                        slots: presets.slots_available || 1,
                    },
                    pay: {
                        payMin: presets.rate_min,
                        payMax: presets.rate_max,
                        isFixedPay: presets.pay_is_fixed,
                        payFixed: presets.pay,
                    },
                    jobQualifications: presets.qualification_requirements,
                    businessJobType: presets.business_job_type,
                    payType: presets.pay_type,
                    jobDetails: presets.details,
                });
            }}
        />
    );
};
