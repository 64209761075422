import { Text } from 'library';
import { BusinessJobWithAppointments, LeanUserWithPhone } from 'models';
import React, { useEffect, useState } from 'react';
import MatchingMenuItem from './MatchingMenuItem';
import useLoading from 'library/hooks/useLoading';
import { refreshStandbyList } from 'api/StandbyApi';
import StandbyHeader from '../StandbyHeader';
import MatchingListActions from './MatchingListActions';
import useSelectedUsers from './useSelectedUsers';
import useNewMatches from './useNewMatches';
export interface StandByProvider {
    id: number;
    user: LeanUserWithPhone;
    standby: Standby;
}

interface Standby {
    added_at: string;
    date: string;
    end_time: string;
    id: number;
    last_updated: string;
    opted_out: boolean;
    start_time: string;
    user: number;
}

export default function StandbyList({
    job,
    open,
    refreshJobs,
}: {
    job: BusinessJobWithAppointments;
    open: boolean;
    refreshJobs: () => void;
}) {
    const [standbyList, setStandbyList] = useState<StandByProvider[]>([]);
    const [distance, setDistance] = useState(5);
    const { loading, setLoading, LoadingSpinner } = useLoading();
    const [date, setDate] = useState(new Date().toISOString());
    const [newMatches, setMoreMatches] = useNewMatches();
    const [selectedUsers, setSelectedUsers, selectUserOnChange] = useSelectedUsers();

    useEffect(() => {
        if (open) {
            setLoading(true);
            const queryParams = {
                available_for_job: job.id,
                distance: distance,
                distance_job: job.id,
                date: date,
            };
            refreshStandbyList(queryParams)
                .then((matchList) => {
                    setStandbyList((prevList) => {
                        setMoreMatches(prevList, matchList);
                        return matchList;
                    });
                })
                .catch(console.log)
                .finally(() => setLoading(false));
        }
    }, [open, job, distance, date]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StandbyHeader distance={distance} setDistance={setDistance} setDate={setDate} />
            <MatchingListActions
                job={job}
                refreshJobs={refreshJobs}
                matchingList={standbyList.map((list) => list.user)}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
            />

            {loading ? (
                <LoadingSpinner style={{ width: '50%' }} />
            ) : standbyList.length > 0 ? (
                standbyList.map((provider) => (
                    <MatchingMenuItem
                        key={provider.id}
                        provider={provider}
                        job={job}
                        refreshJobs={refreshJobs}
                        userSelected={selectedUsers.some((usr) => usr.id === provider.user.id)}
                        setSelectedUser={selectUserOnChange}
                        newMatches={newMatches}
                    />
                ))
            ) : (
                <Text textStyle={{ padding: 10 }}>No matching providers found</Text>
            )}
        </div>
    );
}
