import React from 'react';
import { Grid } from '@material-ui/core';
import theme from 'theme';
import { Text } from 'library';
import ProviderProfileJobTypeSelection from './ProviderProfileJobTypeSelection';
import { useProviderProfileContext } from './ProviderProfileContext';
import ProviderProfileMainAction from './actions/ProviderProfileMainAction';
import ReportUser from 'parent-portal/shared/ReportUser';
import { useMediaQuery } from '@mui/material';
import { useUserContext } from 'UserContext';

export default function ProviderProfileHeader({ mobile }: { mobile: boolean }) {
    const { provider } = useProviderProfileContext();
    const { role } = useUserContext();
    const isMd = useMediaQuery(theme.breakpoints.only('md'));

    return provider ? (
        <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: isMd ? 20 : 0, marginBottom: mobile ? 24 : 62 }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexGrow: 1,
                    flexWrap: 'wrap',
                    marginTop: mobile ? 10 : 0,
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text variant="display" textStyle={{ marginRight: 30 }}>
                        {provider?.user.first_name} {provider?.user.last_name}
                        <ReportUser provider={provider} />
                    </Text>
                    {role !== 'business_active' && <ProviderProfileJobTypeSelection />}
                </div>
                <ProviderProfileMainAction />
            </div>
        </Grid>
    ) : null;
}
