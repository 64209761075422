import React, { Component, useState, useEffect } from 'react';
import { Grid, Typography, withStyles, FormHelperText, RadioGroup, Radio } from '@material-ui/core';
import HalfLayout from '../../reusableComponents/halfLayout';
import BackgroundPaper from '../../reusableComponents/backgroundPaper';
import Label from '../../reusableComponents/checkboxLabel';
import { withRouter } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import LoadingButton from '../../reusableComponents/loadingButton';
import ContentPaper from '../../reusableComponents/contentPaper';
import Input from '../../reusableComponents/input';
import theme from '../../theme';
import { MobileView, BrowserView, isMobile } from 'react-device-detect';
import queryString from 'query-string';
import { client } from 'shared';
import { MixPanelKey } from 'environmentVariables';
const BACKGROUND = require('../../assets/images/contact-info.png');

var mixpanel = require('mixpanel-browser');
mixpanel.init(MixPanelKey);

const StyledRating = withStyles({
    root: {
        marginTop: 10,
        color: theme.palette.primary.main,
        margin: 'auto',
        width: 125,
        display: 'block',
    },
})(Rating);

class Reference extends Component {
    constructor(props) {
        super();
        this.state = {
            completed: false,
            confirmation: false,
            loading: false,
            extraComment: null,
            isComment: true,
            submitable: false,
            sitterName: null,
            cancelledComment: null,
            overall: 0,
            responsive: 0,
            cancelled: null,
            other: null,
            kids: null,
            rules: null,
            showForm: true,
        };
        this.id = queryString.parse(window.location.search).id || '';
        this.params = new URLSearchParams(window.location.search);
    }

    async componentDidMount() {
        const updated = this.params.has('v2') ? '&v2' : '';
        const json = await client('api/get-reference-info?id=' + this.id + updated);
        this.setState({ showForm: json.updated });
        if (json.completed) {
            this.setState({ completed: true });
        }
        this.setState({ sitterName: json.first_name + ' ' + json.last_name });
    }

    validate() {
        let cancelledWithComment = this.state.cancelled === '0' && this.state.cancelledComment !== null;
        if (
            this.state.overall &&
            this.state.responsive &&
            this.state.rules &&
            this.state.other &&
            (this.state.cancelled === '1' || cancelledWithComment)
        ) {
            this.setState({ submitable: true });
        }
    }

    async submitReference() {
        const {
            overall,
            responsive,
            engaged,
            kids,
            rules,
            cancelled,
            cancelledComment,
            other,
            extraComment,
        } = this.state;
        this.setState({ loading: true });

        const reference = {
            overall: overall === undefined || overall === null ? 0 : overall,
            responsive: responsive === undefined || responsive === null ? 0 : responsive,
            engaged: engaged === '0' ? 'yes' : 'no',
            kids: parseInt(kids) + 1,
            rules: rules === '0' ? 'yes' : 'no',
            cancelled: cancelled === '0' ? 'yes' : 'no',
            cancelledComment: cancelled === '0' ? cancelledComment : '',
            other: other === '0' ? 'yes' : 'no',
            extraComment: extraComment,
        };
        await client('api/references/patch-reference/?id=' + this.id, {
            method: 'PATCH',
            body: reference,
        });
        mixpanel.track('Reference: Patch', { body: reference });
        this.setState({ loading: false, confirmation: true });
    }

    render() {
        const {
            sitterName,
            confirmation,
            loading,
            cancelledComment,
            extraComment,
            cancelled,
            completed,
            showForm,
        } = this.state;
        return (
            <BrowserWrapper>
                {showForm ? (
                    <Grid container sm={12} xs={12} style={isMobile ? null : { marginTop: 30, marginBottom: 30 }}>
                        <BackgroundPaper>
                            <Grid container direction="column">
                                <Grid container direction="row" style={{ marginBottom: 20 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                            Reference for {sitterName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <ContentPaper>
                                    {completed ? (
                                        <Typography variant="subtitle1">
                                            This reference has already been completed.
                                        </Typography>
                                    ) : (
                                        <div>
                                            <Grid container spacing={4} direction="column">
                                                <Grid item>
                                                    <Typography variant="subtitle1">
                                                        You were provided as a reference by a Tandem service provider.
                                                        Please fill out this form so that they can be approved as a
                                                        provider. Thank you!
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                                                        Rate your overall experience with this provider
                                                    </Typography>
                                                    <RatingScale
                                                        disabled={confirmation}
                                                        onRatingChange={(rating) => {
                                                            this.setState({ overall: rating }, () => this.validate());
                                                        }}
                                                        low={"I wouldn't hire this person again"}
                                                        high={"I'd love to use them again!"}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                                                        How responsive was this person before, during, and after jobs
                                                    </Typography>
                                                    <RatingScale
                                                        disabled={confirmation}
                                                        onRatingChange={(rating) => {
                                                            this.setState({ responsive: rating }, () =>
                                                                this.validate(),
                                                            );
                                                        }}
                                                        low={'Really unresponsive'}
                                                        high={'Communication was great!'}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={{ fontWeight: 'bold', marginBottom: 10, marginTop: 10 }}
                                                    >
                                                        If the job involved children, would you consider her/him
                                                        experienced, engaged, and caring?
                                                    </Typography>
                                                    <RadioPicker
                                                        options={['Yes', 'No']}
                                                        onSelectionChange={(option) => {
                                                            this.setState({ engaged: option }, () => this.validate());
                                                        }}
                                                    ></RadioPicker>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={{ fontWeight: 'bold', marginBottom: 10, marginTop: 10 }}
                                                    >
                                                        if the job involved children, what is the number of kids watched
                                                        at a time
                                                    </Typography>
                                                    <RadioPicker
                                                        options={['1', '2', '3', '4', '5', '6+']}
                                                        onSelectionChange={(option) => {
                                                            this.setState({ kids: option }, () => this.validate());
                                                        }}
                                                    ></RadioPicker>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={{ fontWeight: 'bold', marginBottom: 10, marginTop: 10 }}
                                                    >
                                                        Does this person follow household rules and job requirements?
                                                    </Typography>
                                                    <RadioPicker
                                                        options={['Yes', 'No']}
                                                        onSelectionChange={(option) => {
                                                            this.setState({ rules: option }, () => this.validate());
                                                        }}
                                                    ></RadioPicker>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={{ fontWeight: 'bold', marginBottom: 10, marginTop: 10 }}
                                                    >
                                                        Has this person ever cancelled last minute or not shown up to a
                                                        job?
                                                    </Typography>
                                                    <RadioPicker
                                                        options={['Yes', 'No']}
                                                        onSelectionChange={(option) => {
                                                            this.setState({ cancelled: option }, () => this.validate());
                                                        }}
                                                    ></RadioPicker>
                                                </Grid>
                                                {cancelled === '0' ? (
                                                    <Grid item>
                                                        <Typography
                                                            variant="subtitle2"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                marginBottom: 10,
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            Please explain further.
                                                        </Typography>
                                                        <Input
                                                            error={!cancelledComment}
                                                            multiline
                                                            rows={3}
                                                            variant="outlined"
                                                            fullWidth
                                                            value={cancelledComment}
                                                            disabled={confirmation}
                                                            onChange={(event) =>
                                                                this.setState(
                                                                    { cancelledComment: event.target.value },
                                                                    () => this.validate(),
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                ) : null}
                                                <Grid item>
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={{ fontWeight: 'bold', marginBottom: 10, marginTop: 10 }}
                                                    >
                                                        Would you be willing to speak to other job posters about this
                                                        provider?
                                                    </Typography>
                                                    <RadioPicker
                                                        options={['Yes', 'No']}
                                                        onSelectionChange={(option) => {
                                                            this.setState({ other: option }, () => this.validate());
                                                        }}
                                                    ></RadioPicker>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={{ fontWeight: 'bold', marginBottom: 10, marginTop: 10 }}
                                                    >
                                                        Additional comments
                                                    </Typography>
                                                    <Input
                                                        multiline
                                                        rows={3}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={extraComment}
                                                        disabled={confirmation}
                                                        onChange={(event) =>
                                                            this.setState({ extraComment: event.target.value })
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            {!confirmation ? (
                                                <LoadingButton
                                                    loading={loading}
                                                    fullWidth
                                                    style={{ marginTop: 15 }}
                                                    disableFocusRipple
                                                    disableRipple
                                                    disabled={!this.state.submitable}
                                                    onClick={() => this.submitReference()}
                                                >
                                                    Submit Reference
                                                </LoadingButton>
                                            ) : (
                                                <LoadingButton
                                                    disabled
                                                    fullWidth
                                                    style={{ marginTop: 15 }}
                                                    disableFocusRipple
                                                    disableRipple
                                                >
                                                    Thank you for submitting a reference!
                                                </LoadingButton>
                                            )}
                                        </div>
                                    )}
                                </ContentPaper>
                            </Grid>
                        </BackgroundPaper>
                    </Grid>
                ) : (
                    <Grid container sm={12} xs={12} style={isMobile ? null : { marginTop: 30, marginBottom: 30 }}>
                        <BackgroundPaper>
                            <Grid container direction="column">
                                <Grid container direction="row" style={{ marginBottom: 20 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                            Reference Expired
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <ContentPaper>
                                <Typography variant="subtitle1">
                                    This link has expired. Please follow the most recent link to submit your reference.
                                </Typography>
                            </ContentPaper>
                        </BackgroundPaper>
                    </Grid>
                )}
            </BrowserWrapper>
        );
    }
}

function BrowserWrapper(props) {
    return isMobile ? (
        props.children
    ) : (
        <HalfLayout background={BACKGROUND} type="signup">
            {props.children}
        </HalfLayout>
    );
}

function RatingScale(props) {
    const { onRatingChange, low, high, disabled } = props;
    const [rating, setRating] = useState();

    useEffect(() => {
        onRatingChange(rating);
    }, [rating]);

    return (
        <div>
            <MobileView>
                <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                        <StyledRating value={rating} onChange={(e, rating) => setRating(rating)} disabled={disabled} />
                    </Grid>
                    <Grid container item alignItems="space-between">
                        <Grid item xs={5}>
                            <FormHelperText style={{ textAlign: 'center' }}>{low}</FormHelperText>
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={5}>
                            <FormHelperText style={{ textAlign: 'center' }}>{high}</FormHelperText>
                        </Grid>
                    </Grid>
                </Grid>
            </MobileView>
            <BrowserView>
                <Grid container item alignItems="center" justifyContent="center">
                    <Grid item xs={4}>
                        <FormHelperText style={{ textAlign: 'center' }}>{low}</FormHelperText>
                    </Grid>
                    <Grid item xs={4}>
                        <StyledRating value={rating} onChange={(e, rating) => setRating(rating)} disabled={disabled} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormHelperText style={{ textAlign: 'center' }}>{high}</FormHelperText>
                    </Grid>
                </Grid>
            </BrowserView>
        </div>
    );
}

function RadioPicker(props) {
    const { options, onSelectionChange } = props;
    const [option, setOption] = useState();

    useEffect(() => {
        onSelectionChange(option);
    }, [option]);

    return (
        <RadioGroup
            name="options"
            value={option + ''}
            onChange={(e) => {
                setOption(e.target.value);
            }}
            style={{ marginTop: 10 }}
        >
            {options.map((o, i) => (
                <Label
                    value={i + ''}
                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                    label={<Typography variant="subtitle1">{o}</Typography>}
                />
            ))}
        </RadioGroup>
    );
}

export default withRouter(Reference);
