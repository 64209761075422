import { useState, useEffect } from 'react';
import { client, consoleLogInDev, track } from 'shared';
import { UserFlowStep, UserFlowType, UserFlowRequestBody } from './UserFlowModels';

export default function useUserFlowManager(title: string) {
    const [success, setSuccess] = useState<boolean>(false);
    const [flowId, setFlowId] = useState<number>();
    const [steps, setSteps] = useState<UserFlowStep[]>([]);

    const apiUserFlow = (path: string = `api/user-flow/steps/?name=${title}`, data?: any, save: boolean = true) => {
        client(path, data)
            .then((response) => {
                setSuccess(response.success);
                if (save) {
                    setSteps(response.steps);
                    setFlowId(response.flow_id);
                }
            })
            .catch(consoleLogInDev);
    };

    useEffect(apiUserFlow, [title]);

    const trackUserFlowChoice = (stepName: string, option: string, altText?: string, otherInfo?: any) => {
        // Get step and choices
        const step = steps.find((obj: UserFlowStep) => obj.title === stepName);

        if (step && flowId) {
            // Get choice
            const toMatch = altText ? altText : option;
            const choice = step.user_flow_step_choices.find((obj: UserFlowType) => obj.title === toMatch);

            if (choice) {
                const requestBody: UserFlowRequestBody = {
                    step_id: step.id,
                    choice_ids: [choice.id],
                    other_info: otherInfo,
                };

                apiUserFlow(`api/user-flow/${flowId}/step/`, { body: requestBody, method: 'PATCH' }, false);
            } else {
                track('UserFlowManager Failure - no choice', {
                    stepName: stepName,
                    option: option,
                    altText: altText,
                    otherInfo: otherInfo,
                });
            }
        } else {
            track('UserFlowManager Failure - no step', {
                stepName: stepName,
                option: option,
                altText: altText,
                otherInfo: otherInfo,
            });
        }
    };

    return { trackUserFlowChoice };
}
