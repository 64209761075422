import { useState } from 'react';
import { AdminListProvider } from 'models';
import { StandByProvider } from './StandbyList';

const useNewMatches = (): [
    number[],
    (
        prevList: Array<AdminListProvider | StandByProvider> | undefined,
        matchList: Array<AdminListProvider | StandByProvider>,
    ) => void,
] => {
    const [newMatches, setNewMatches] = useState<number[]>([]);

    const setMoreMatches = (
        prevList: Array<AdminListProvider | StandByProvider> | undefined,
        matchList: Array<AdminListProvider | StandByProvider>,
    ) => {
        if (prevList?.length && matchList.length) {
            const newProviderMatches = matchList
                .filter((newMatch) => !prevList.some((prevMatch) => prevMatch.id === newMatch.id))
                .map((provider) => provider.id);

            setNewMatches(newProviderMatches);
        }
    };

    return [newMatches, setMoreMatches];
};

export default useNewMatches;
