import { CircularProgress } from '@mui/material';
import { RadioGroup, Text } from 'library';
import { AdminListProvider, BusinessJobWithAppointments } from 'models';
import React, { useEffect, useState } from 'react';
import MatchingMenuItem from './MatchingMenuItem';
import MatchingListActions from './MatchingListActions';
import useSelectedUsers from './useSelectedUsers';
import useMatchingListLabels from './useMatchingListLabels';
import useMatchingListQuery from './useMatchingListQuery';
import MatchingListSummary from './MatchingListSummary';
import useNewMatches from './useNewMatches';
import { getAdminProviders } from './matchingListApi';

const distanceValues = { '5': '5 miles', '10': '10 miles', '15': '15 miles', '25': '25 miles' };

export default function MatchingList({
    job,
    open,
    refreshJobs,
    week,
}: {
    job: BusinessJobWithAppointments;
    open: boolean;
    refreshJobs: () => void;
    week?: Date;
}) {
    const [matchingList, setMatchingList] = useState<AdminListProvider[]>([]);
    const [distance, setDistance] = useState(5);
    const [loading, setLoading] = useState(false);
    const [newMatches, setMoreMatches] = useNewMatches();
    const [includedLabels, excludedLabels] = useMatchingListLabels();
    const [selectedUsers, setSelectedUsers, selectUserOnChange] = useSelectedUsers();
    const [showMoreMatches, setShowMoreMatches, generateQueryParams] = useMatchingListQuery();
    const [excludeStandbyUsers, setExcludeStandbyUsers] = useState(true);

    useEffect(() => {
        if (open) {
            setLoading(true);
            const queryParams = generateQueryParams(job, distance, showMoreMatches, week, excludeStandbyUsers);

            getAdminProviders(queryParams)
                .then((matchList) => {
                    setMatchingList((prevList) => {
                        setMoreMatches(prevList, matchList.results);
                        return matchList.results;
                    });
                })
                .catch(console.log)
                .finally(() => setLoading(false));
        }
    }, [open, job, distance, showMoreMatches, excludeStandbyUsers]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <RadioGroup
                style={{ padding: 10 }}
                horizontal
                value={distance.toString()}
                setValue={(val) => {
                    setDistance(parseInt(val));
                }}
                values={distanceValues}
            />
            <MatchingListActions
                job={job}
                refreshJobs={refreshJobs}
                matchingList={matchingList.map((provider) => provider.user)}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                showMoreMatches={showMoreMatches}
                setShowMoreMatches={setShowMoreMatches}
                excludeStandbyUsers={excludeStandbyUsers}
                setExcludeStandbyUsers={setExcludeStandbyUsers}
            />
            <MatchingListSummary
                matchingList={matchingList}
                includedLabels={includedLabels}
                excludedLabels={excludedLabels}
            />
            {loading ? (
                <CircularProgress style={{ padding: 10 }} />
            ) : matchingList.length > 0 ? (
                matchingList.map((provider) => (
                    <MatchingMenuItem
                        key={provider.id}
                        provider={provider}
                        job={job}
                        refreshJobs={refreshJobs}
                        userSelected={selectedUsers.some((usr) => usr.id === provider.user.id)}
                        setSelectedUser={selectUserOnChange}
                        newMatches={newMatches}
                        week={week}
                    />
                ))
            ) : (
                <Text textStyle={{ padding: 10 }}>No matching providers found</Text>
            )}
        </div>
    );
}
