import { client, queryStringifyObject } from 'shared';

interface IQueryParams {
    available_for_job?: number;
    distance?: number;
    distance_job?: number;
    regions?: number[];
    date: string;
}

export async function refreshStandbyList(queryParams: IQueryParams) {
    return client(`api/standby/?${queryStringifyObject(queryParams)}`);
}
