import { consoleLogInDev, logRejectionsInDev } from 'shared/Utils';

import ApiClient from '../../shared/ApiClient';

import { track } from '../../shared/Tracking';
import moment from 'moment';
import { plusOneDay, timeWithDate } from './time-operations';
import { JobTypeIds } from '../../models/JobType';

export async function submitRequest(form, familyId, lastName, invitedProvider, jobId) {
    const formValues = form.toValues();
    const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const ongoingRequest = await createOngoingRequest(now, formValues, familyId);
    let newJob = null;

    const addresses = formValues.address;
    for (let i = 0; i < addresses.length; i++) {
        const onGoingToSubmit = {
            ...ongoingRequest,
            address: addresses[i].id,
            business_location: addresses[i].businessLocationId,
        };
        const submittedRequest = await submitOngoingRequest(onGoingToSubmit, i === 0 ? jobId : 0);

        if (submittedRequest) {
            if (i === 0) {
                newJob = submittedRequest;
            }
            if (newJob.id) {
                const createdJobId = submittedRequest.id;

                if (invitedProvider) {
                    await createInvitedPairing(createdJobId, invitedProvider.id);
                }
                if (formValues.code) {
                    submitPromoCode(formValues.code);
                }
                await Promise.all(
                    formValues.preferredWorkers.map(async (provider) => {
                        await createNonrequestedPairing(createdJobId, provider.providerId);
                        await inviteProvider(provider, submittedRequest);
                    }),
                );
            }
        }
    }

    const { startDay, endDay } = formatDates(formValues);
    newJob.start_date = startDay;
    newJob.end_date = endDay;
    return newJob;
}

function formatDates(formValues) {
    const { start, end } = formValues.jobTimes;

    let startDate = formValues.startDate || moment();
    let endDate = formValues.endDate || moment();

    let startDay;
    let endDay;
    if (!start || !end) {
        if (formValues.multiDay) {
            startDay = startDate.toISOString();
            endDay = endDate.toISOString();
        } else {
            startDay = startDate.startOf('day').toISOString();
            endDay = startDate.endOf('day').toISOString();
        }
    } else {
        const endBeforeStart = end?.isBefore(start);
        endDate = endBeforeStart ? plusOneDay(startDate) : startDate;
        startDay = timeWithDate(start, startDate || moment().startOf('day')).toISOString();
        endDay = timeWithDate(end, endDate || moment().startOf('day')).toISOString();
    }

    return { startDay, endDay };
}

async function submitPromoCode(code) {
    try {
        let body = { code: code };
        await ApiClient(`api/promo-code/apply/`, { body });
    } catch (error) {
        consoleLogInDev(error);
    }
}

async function submitOngoingRequest(request, jobId) {
    try {
        const res = await ApiClient(`api/ongoing/${jobId ? jobId : ''}`, {
            method: jobId ? 'PATCH' : 'POST',
            body: request,
        });
        return res;
    } catch (error) {
        consoleLogInDev(error);
    }
}

function getProviderTypesForRequest(providerType) {
    const mapping = {
        teacher: 'TEACHER',
        tutor: 'TUTOR',
        sitter: undefined,
    };

    return Object.entries(providerType)
        .map(([type, selected]) => (selected ? mapping[type] : undefined))
        .filter((item) => item !== undefined);
}

function getPayRangeValues(pay, isQuickFill) {
    const { payMin, payMax, isFixedPay } = pay;
    const payIsNotRange = isQuickFill || payMin === payMax || isFixedPay;

    return payIsNotRange ? nonRangePay(pay) : rangedPay(pay);
}

function nonRangePay(pay) {
    const { isFixedPay, payFixed, payMin } = pay;

    return {
        pay: isFixedPay ? payFixed : payMin,
        payMin: null,
        payMax: null,
    };
}

function rangedPay(pay) {
    return {
        pay: null,
        payMin: pay.payMin,
        payMax: pay.payMax,
    };
}

export async function createOngoingRequest(now, formValues, familyId, status = 'ACTIVE') {
    const { pay, payMin, payMax } = getPayRangeValues(formValues.pay, formValues.isQuickFill);
    const { start, end } = formValues.jobTimes;
    const startDate = formValues.startDate;
    const day = startDate.toDate().toLocaleString('en-us', { weekday: 'long' });

    let category = 'CHILD_CARE';
    if (formValues.jobTypeId === JobTypeIds.PetCare) {
        category = 'PET_CARE';
    } else if (formValues.jobTypeId > JobTypeIds.PetCare) {
        category = 'OTHER';
    }

    if (formValues.jobTypeId < 0) {
        ApiClient('api/job-types/', {
            body: { name: formValues.otherJobType },
        }).then((res) => {
            formValues.jobTypeId = res.id;
        });
    }
    let selectedWeekDays = [day];
    let ongoingTimesOfDay = [
        {
            [day]: {
                start: start.format('HH:mm:ss'),
                end: end.format('HH:mm:ss'),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                slots: formValues.slotsAvailable,
            },
        },
    ];

    let ongoing = {
        family: familyId,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: startDate.format('YYYY-MM-DD'),
        last_hire_offset_minutes: formValues.lastHireOffsetMinutes,
        pay: pay,
        pay_is_fixed: formValues.pay.isFixedPay,
        rate_min: payMin,
        rate_max: payMax,
        transportation: formValues.transportation || false,
        multiple_providers: false,
        added_at: now,
        preferred_education_level: getEducationLevel(formValues.education),
        status: status,
        quick_fill: formValues.isQuickFill || false,
        family_comments: formValues.comments,
        days_of_week: selectedWeekDays,
        preferred_sitters: getPreferredSittersObject(formValues.preferredWorkers),
        times_of_day: ongoingTimesOfDay,
        pay_window: 'DAILY',
        provider_types: getProviderTypesForRequest(formValues.providerType),
        ongoing: false,
        headline: formValues.headline,
        request_type: category,
        job_type: formValues.jobTypeId,
        pay_type: formValues.payType,
        business_job_type: formValues.businessJobType,
        qualification_requirements: formValues.jobQualifications,
        address: formValues.address?.length ? formValues.address[0].id : undefined,
        business_location: formValues.address?.length ? formValues.address[0].businessLocationId : undefined,
        job_times: { start, end },
        job_details: formValues.jobDetails.map((detail) => ({ ...detail, option: detail.option_id })),
        pay_scales: formValues.payScales.filter((x) => x.description),
        break_required: formValues.breakRequired,
        break_length: formValues.breakLength,
        slots_available: formValues.slotsAvailable,
        require_minimum_qualifications: formValues.requireMinimumQualifications,
        break_required_every_n_minutes: formValues.breakEveryNHours ? formValues.breakEveryNHours * 60 : undefined,
        trial_run_benefits: formValues.trial_run_benefits,
    };
    return ongoing;
}

function getEducationLevel(education) {
    return educationMapping[education] || 'NO_PREFERENCE';
}

const educationMapping = {
    ['Middle School']: 'MIDDLE_SCHOOL',
    ['High School']: 'HIGH_SCHOOL',
    ['College']: 'COLLEGE',
    ['Post College or Other']: 'POST_COLLEGE_OR_OTHER',
};

function getPreferredSittersObject(preferredProviders) {
    return {
        preferred_sitters: preferredProviders.map((preferredProvider) => ({
            sitter: preferredProvider.id,
        })),
    };
}

function createNonrequestedPairing(jobId, providerId) {
    return createProviderPairing(jobId, providerId, false).catch(consoleLogInDev);
}

const createInvitedPairing = logRejectionsInDev(async (jobId, providerId) => {
    await createProviderPairing(jobId, providerId, true);

    track('RT/P@P: Created Requested Pairing from One Time Form');
});

async function createProviderPairing(jobId, providerId, isRequested) {
    await ApiClient('api/pairings/create_from_job_request/', {
        body: {
            provider_id: providerId,
            ongoing_request_id: jobId,
            requested: isRequested,
        },
    });
}

function inviteProvider(provider, job) {
    const invitationMessage = `Hello, ${provider.firstName}! I think you could be a good fit for my job. Please review the details and apply if you're interested. Thanks, I look forward to hearing from you!`;

    return ApiClient(`api/invitation/`, {
        body: {
            invited_user: provider.id,
            body: invitationMessage,
            job_request: job.id,
        },
    });
}
