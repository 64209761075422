import React, { useState } from 'react';
import { BasicDialog, PrimaryButton } from 'library';
import { DCF_TRANSCRIPT, SIGNED_MED_FORM, verifyForm } from 'api/AdminDocumentApi';
import DocumentVerificationContent from '../texting/outreach/documents/DocumentVerificationContent';
import { DocumentToVerify, FormDetails } from './models';
import { FloridaTrainingFields } from 'internal/texting/outreach/documents/models';
import { IInfoModalDoc } from 'models';

export default function VerifyModal({
    verifyModalOpen,
    setVerifyModalOpen,
    form,
    refresh,
    formDetails,
    activeWorker,
}: {
    verifyModalOpen: boolean;
    setVerifyModalOpen: (x: boolean) => void;
    form: DocumentToVerify | IInfoModalDoc | undefined;
    refresh?: () => void;
    formDetails?: FormDetails;
    activeWorker: number;
}) {
    const formName = form?.name ?? form?.text ?? '';
    const [signedAt, setSignedAt] = useState<Date | undefined>(undefined);
    const [expirationDate, setExpirationDate] = useState<Date | undefined>(undefined);
    const [checkboxStates, setCheckboxStates] = useState<FloridaTrainingFields>({});

    function onVerify() {
        if (refresh) {
            setVerifyModalOpen(false);
            refresh();
        }
    }

    const selectedDocumentType = (form as DocumentToVerify)?.document_types?.find(
        (dt) => dt.document_type_id === formDetails?.document_type_id,
    );
    const showMedVersion = selectedDocumentType
        ? selectedDocumentType.friendly_name === 'Medical Statement'
        : formName.includes(SIGNED_MED_FORM);
    const formDetailsToSubmit = {
        ...formDetails,
        document_type: selectedDocumentType?.document_type,
    };

    return (
        <BasicDialog closeButton isOpen={verifyModalOpen} onClose={() => setVerifyModalOpen(false)}>
            <DocumentVerificationContent
                signedAt={signedAt}
                setSignedAt={setSignedAt}
                medicalRecord={showMedVersion}
                expirationDate={expirationDate}
                setExpirationDate={setExpirationDate}
                checkboxStates={checkboxStates}
                setCheckboxStates={setCheckboxStates}
                activeWorker={activeWorker}
                dcfDocument={!!formName.includes(DCF_TRANSCRIPT)}
            />
            <PrimaryButton
                buttonStyle={{ marginTop: 20, marginBottom: 20 }}
                disabled={showMedVersion && !signedAt}
                onClick={() => {
                    verifyForm(
                        form?.id,
                        form?.name,
                        signedAt,
                        expirationDate,
                        onVerify,
                        formDetailsToSubmit,
                        checkboxStates,
                    );
                }}
            >
                Verify
            </PrimaryButton>
        </BasicDialog>
    );
}
