import React, { useState } from 'react';
import { CardActionArea, Grid } from '@material-ui/core';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Text } from '../text';
import { Colors } from '../../../constants';
import { track } from 'shared';

interface IProps {
    header: string;
    expandedContent?: React.ReactNode;
    trackingData?: string;
    smallHeader?: boolean;
    contentPadding?: number;
}

export default function ExpandableCard({
    header,
    expandedContent,
    trackingData,
    smallHeader,
    contentPadding = 24,
}: IProps) {
    const [expanded, setExpanded] = useState<boolean>(false);

    function click() {
        setExpanded(!expanded);
        if (!!trackingData) {
            track(trackingData);
        }
    }

    return (
        <>
            <Grid style={styles.outerCard}>
                <CardActionArea style={{ outline: 'none' }} onClick={click}>
                    <Grid container item direction="row" style={{ padding: 24, alignContent: 'center' }}>
                        <Text variant={smallHeader ? 'body1' : 'h1'} bold>
                            {header}
                            <ArrowForwardIosIcon
                                style={{
                                    color: Colors.darkNavy,
                                    fontSize: 14,
                                    fontWeight: 'bolder',
                                    transform: !!expanded ? 'rotate(90deg)' : '',
                                    marginLeft: 8,
                                }}
                            />
                        </Text>
                    </Grid>
                </CardActionArea>
            </Grid>
            {!!expanded ? (
                <Grid container item style={{ padding: contentPadding }}>
                    <Grid container item style={{ paddingTop: 12, paddingBottom: 12 }}>
                        <Text variant="body1">{expandedContent}</Text>
                    </Grid>
                </Grid>
            ) : null}
        </>
    );
}

const styles = {
    outerCard: {
        backgroundColor: Colors.white,
        borderRadius: 20,
        boxShadow: '0px 3px 6px #00000029',
    },
};
