import React, { useState, CSSProperties } from 'react';
import { StickyNote2 } from '@mui/icons-material';
import { IconButton } from 'library';
import DraggableNotesDialog from './DraggableNotesDialog';

export default function Notes({
    type,
    name,
    refresh,
    style,
    objectId,
    tooltip,
}: {
    type:
        | 'business'
        | 'ongoing'
        | 'applicants'
        | 'interview'
        | 'admin-providers'
        | 'matching-inbox'
        | 'business-location';
    name: string;
    objectId: number;
    refresh?: () => void;
    style?: CSSProperties;
    tooltip?: string;
}) {
    const [open, setOpen] = useState(false);
    const ref = React.useRef<HTMLButtonElement>(null);

    return (
        <>
            <span style={{ marginLeft: 10, ...style }}>
                <IconButton tooltip={tooltip} ref={ref} icon={StickyNote2} onClick={() => setOpen(true)} />
            </span>
            <DraggableNotesDialog
                isOpen={open}
                onClose={() => setOpen(false)}
                name={name}
                objectId={objectId}
                type={type}
                refresh={refresh}
                anchorElement={ref.current}
            />
        </>
    );
}
