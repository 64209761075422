import React, { useContext, useEffect, useState } from 'react';
import { flatten, sortBy } from 'lodash';
import { IconButton, styled, useMediaQuery } from '@mui/material';
import { Qualification, QualificationOption } from 'models';
import { Colors, DropDown, Link, Text } from 'library';
import { uuid } from 'uuidv4';
import theme from 'theme';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IPayScaleQualification } from 'parent-portal/forms/JobRequestTypes';

import BusinessPayQualificationsModal from './BusinessPayQualificationsModal';
import { IManagedScale } from './BusinessPayInputs';
import { BusinessPayContext } from './utils/BusinessPayContext';
import {
    formatSelectionOption,
    getStartingDegree,
    getStartingExperience,
    listChosenQualifications,
    partitionQualifications,
    updateEducation,
    updateYearsOfExperience,
} from './utils/common';

import { DEGREE, ECE_DEGREE, HIGH_SCHOOL_CHOICE, CDA, YEARS_EXPERIENCE, NO_EXPERIENCE } from './utils/constants';
import {
    addOrUpdateSingleToHigherRate,
    addToHigherPayRates,
    removeFromLowerPayRates,
    removeSingleFromLowerRates,
} from './utils/managedScalesActions';
import PayWarning from './PayWarning';

export default function BusinessPayInputRow({
    singularRow,
    managedScale,
    hasSeenTrainingsModal,
    updateHasSeenTrainingsModal,
    isMinimumRate,
}: {
    singularRow: boolean;
    managedScale: IManagedScale;
    hasSeenTrainingsModal: boolean;
    updateHasSeenTrainingsModal: () => void;
    isMinimumRate: boolean;
}) {
    const small = useMediaQuery(theme.breakpoints.down('md'));
    const { qualifications, errorScales, managedScales, setManagedScales } = useContext(BusinessPayContext);

    const [degreeQualifications, otherQualifications] = partitionQualifications(qualifications, [DEGREE, ECE_DEGREE]);
    const [yearsExperienceQualifications, additionalQualifications] = partitionQualifications(otherQualifications, [
        YEARS_EXPERIENCE,
    ]);
    const yearsExperienceQualification = yearsExperienceQualifications[0];

    const degreeQualificationOptions = flatten(
        [[HIGH_SCHOOL_CHOICE]].concat(
            degreeQualifications
                // eslint-disable-next-line array-func/prefer-flat-map
                .map((qualification: Qualification) =>
                    qualification.options.map((option: QualificationOption) =>
                        formatSelectionOption(qualification, option),
                    ),
                ),
        ),
    );
    const sortedDegreeOptions = sortBy(degreeQualificationOptions, (option) => {
        if (option.value === HIGH_SCHOOL_CHOICE.value) {
            return 0;
        }

        return option.value === CDA ? 1 : 2;
    });

    const startingDegreeChoice = getStartingDegree(degreeQualifications, managedScale);
    const listedChosenQualifications = listChosenQualifications(
        managedScale.qualifications,
        degreeQualifications.concat(yearsExperienceQualifications),
    );

    const startingExperience = getStartingExperience(yearsExperienceQualification, managedScale);

    const [education, setEducation] = useState(startingDegreeChoice?.option_id ?? HIGH_SCHOOL_CHOICE.key);
    const [yearsExperience, setYearsExperience] = useState(startingExperience ?? NO_EXPERIENCE.key);
    const [open, setOpen] = useState(false);
    const showEducationWarning = isMinimumRate && education !== HIGH_SCHOOL_CHOICE.key;

    function addRow() {
        setManagedScales([
            ...managedScales,
            { id: uuid(), rate: managedScale.rate, description: managedScale.rate.toString(), qualifications: [] },
        ]);
    }

    function removeRow() {
        setManagedScales(managedScales.filter((x) => x.id !== managedScale.id));
    }

    function updateSelection(key: number, type: string) {
        if (type === DEGREE) {
            setEducation(key);
            const newQualificationOptions = updateEducation(qualifications, degreeQualifications, managedScale, key);

            updateManagedScales(managedScale, newQualificationOptions);
        } else if (type === YEARS_EXPERIENCE) {
            setYearsExperience(key);
            const newQualificationOptions = updateYearsOfExperience(yearsExperienceQualification, managedScale, key);

            updateManagedScales(managedScale, newQualificationOptions);
        }
    }

    function updateManagedScales(
        managedScale: IManagedScale,
        newQualifications: IPayScaleQualification[],
        addedQualification?: IPayScaleQualification,
        removedQualification?: IPayScaleQualification,
        addedSingleQualification?: IPayScaleQualification,
        singleValueRemoved?: number,
    ) {
        const newscales = managedScales.map((scale: IManagedScale) =>
            scale.id === managedScale.id ? { ...scale, qualifications: newQualifications } : scale,
        );

        if (addedQualification) {
            addToHigherPayRates(newscales, managedScale, addedQualification);
        }

        if (removedQualification) {
            removeFromLowerPayRates(newscales, managedScale, removedQualification);
        }

        if (addedSingleQualification) {
            addOrUpdateSingleToHigherRate(newscales, managedScale, addedSingleQualification);
        }

        if (singleValueRemoved) {
            removeSingleFromLowerRates(newscales, managedScale, singleValueRemoved);
        }

        setManagedScales(newscales);
    }

    useEffect(() => {
        setEducation(startingDegreeChoice?.option_id ?? HIGH_SCHOOL_CHOICE.key);
        setYearsExperience(startingExperience ?? NO_EXPERIENCE.key);
    }, [startingDegreeChoice, startingExperience]);

    return (
        <Row error={errorScales.includes(managedScale.id)}>
            <ContainerRow small={small}>
                <PayWarning
                    visible={showEducationWarning}
                    text="Is this level of education absolutely required for
            this job? If not, set to High School to increase the chance of a teacher getting booked."
                />
                <DropdownRow small={small}>
                    <StyledDropDown small={small} warning={showEducationWarning}>
                        <DropDown
                            selected={education}
                            fields={sortedDegreeOptions}
                            onChange={(e) => updateSelection(e.target.value, DEGREE)}
                            title="Education"
                            wrapText
                        />
                    </StyledDropDown>
                    <StyledDropDown small={small}>
                        <DropDown
                            selected={yearsExperience}
                            fields={[NO_EXPERIENCE].concat(
                                Array.from(Array(10).keys(), (x: number) => ({
                                    key: x + 1,
                                    value: x === 9 ? `${x + 1}+ Years` : `${x + 1} Year${x === 0 ? '' : 's'}`,
                                })),
                            )}
                            wrapText
                            onChange={(e) => updateSelection(e.target.value, YEARS_EXPERIENCE)}
                            title={'Min. Years Experience'}
                        />
                    </StyledDropDown>
                </DropdownRow>
                <AdditionalQualifications small={small}>
                    <Text variant="caption">
                        <b>Additional Qualifications:</b> {listedChosenQualifications}
                    </Text>
                    <LinkContainer>
                        <Link
                            color={Colors.darkNavy}
                            style={{ fontSize: 12, fontWeight: 'normal', width: 'max-content', marginLeft: 8 }}
                            onClick={() => setOpen(true)}
                        >
                            + Add Qualifications
                        </Link>
                    </LinkContainer>
                </AdditionalQualifications>
                <BusinessPayQualificationsModal
                    open={open}
                    onClose={() => setOpen(false)}
                    qualifications={additionalQualifications}
                    managedScale={managedScale}
                    updateManagedScales={updateManagedScales}
                    hasSeenTrainingsModal={hasSeenTrainingsModal}
                    updateHasSeenTrainingsModal={updateHasSeenTrainingsModal}
                    isMinimumRate={isMinimumRate}
                />
            </ContainerRow>
            <ButtonColumn>
                <CircleButton onClick={addRow}>
                    <AddIcon />
                </CircleButton>
                <CircleButton onClick={removeRow} disabled={singularRow}>
                    <RemoveIcon />
                </CircleButton>
            </ButtonColumn>
        </Row>
    );
}

const Row = styled('div')(({ error }: { error: boolean }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    backgroundColor: Colors.lightTurq,
    borderRadius: 18,
    padding: 5,
    border: error ? `1px ${Colors.error} solid` : 'inherit',
}));

const ContainerRow = styled('div')(({ small }: { small: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: small ? '80%' : '90%',
    maxWidth: small ? '80%' : '90%',
    minWidth: small ? '80%' : '90%',
}));

const DropdownRow = styled('div')(({ small }: { small: boolean }) => ({
    display: 'flex',
    flexDirection: small ? 'column' : 'row',
}));

const StyledDropDown = styled('div')(({ small, warning }: { small: boolean; warning?: boolean }) => ({
    marginLeft: 10,
    width: small ? '100%' : '48%',
    maxWidth: small ? '100%' : '48%',
    minWidth: small ? '100%' : '48%',
    border: warning ? `1px solid ${Colors.terraCotta}` : 'inherit',
    borderRadius: 16,
    padding: 8,
    marginBottom: 8,
}));

const ButtonColumn = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 10,
});

const CircleButton = styled(IconButton)({
    color: Colors.white,
    backgroundColor: Colors.darkNavy,
    width: 24,
    height: 24,
    margin: 8,
});

const AdditionalQualifications = styled('div')(({ small }: { small: boolean }) => ({
    display: 'flex',
    flexDirection: small ? 'column' : 'row',
    justifyContent: 'space-between',
    marginLeft: 8,
}));

const LinkContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
});
