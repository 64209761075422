import { Grid } from '@material-ui/core';
import React from 'react';
import { Text } from 'library';
import Report from './Report';
import { IHourReportView } from 'internal/payments/HourReportModels';

export default function ProviderReports({
    providerName,
    reports,
    setChangeReport,
    updateHoursReported,
}: {
    providerName: string;
    reports: IHourReportView[];
    setChangeReport: (reportId: number) => void;
    updateHoursReported: () => void;
}) {
    return (
        <Grid container item direction="column" xs={12}>
            <Text variant="h2" bold>
                {providerName}
            </Text>
            <Grid container item direction="row" style={{ gap: 20 }}>
                {reports.map((report) => (
                    <Grid key={report.id} container item xs={12} md={6} lg={3} direction="row">
                        <Report
                            report={report}
                            setChangeReport={setChangeReport}
                            updateHoursReported={updateHoursReported}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
