import React, { useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { BusinessJob, BusinessJobWithUser } from 'models';
import { BasicDialog, IconButton, Text, Chip, Checkbox } from 'library';
import Labels from 'internal/shared/tables/Labels';
import { isoWeekDays } from 'parent-portal/forms/JobTemplateModels';
import { to12HourTime, toTimeZoneAbbreviation } from 'shared/Dates';
import { client } from 'shared';

export default function JobDescription({ job }: { job: BusinessJob | BusinessJobWithUser }) {
    const [open, setOpen] = useState(false);
    const [minimumQualificationRequired, setMinimumQualificationRequired] = useState(
        job.require_minimum_qualifications,
    );

    const payScales = job.pay_scales;
    const hasPayScales = payScales && payScales.length > 0;

    const sortedPayScales = hasPayScales ? payScales.sort((a, b) => a.rate - b.rate) : [];
    const showDescription =
        sortedPayScales.every((scale) => !scale.qualifications?.length) && sortedPayScales.length !== 1;

    function toggleMinimumQualifiationRequirement() {
        client(`api/ongoing/${job.id}/`, {
            method: 'PATCH',
            body: { require_minimum_qualifications: !minimumQualificationRequired },
        }).then(() => {
            setMinimumQualificationRequired(!minimumQualificationRequired);
        });
    }

    return (
        <>
            <span style={{ marginLeft: 10 }}>
                <IconButton
                    tooltip="View job description and details"
                    icon={MoreHoriz}
                    onClick={() => setOpen(true)}
                ></IconButton>
            </span>
            <BasicDialog dialogTitle="Job Description" closeButton onClose={() => setOpen(false)} isOpen={open}>
                <Text variant="body2">{job.family_comments}</Text>
                <Text textStyle={{ marginTop: 20, marginBottom: 10 }} variant="body2" bold>
                    Break Required (minutes):{' '}
                    <Text inline variant="body2">
                        {job.break_length ? job.break_length : 'None'}
                    </Text>
                </Text>
                {job.daily_schedules && (
                    <Text textStyle={{ marginBottom: 4 }} variant="body2" bold>
                        Base Schedule:
                    </Text>
                )}
                {job.daily_schedules?.map((schedule) => (
                    <Text key={schedule.day} variant="body2">
                        {isoWeekDays[schedule.day]}: {to12HourTime(schedule.start_time)}-
                        {to12HourTime(schedule.end_time)} {toTimeZoneAbbreviation(schedule.timezone)} slots:{' '}
                        {schedule.slots}{' '}
                    </Text>
                ))}
                <Text variant="body2" textStyle={{ marginBottom: 20, marginTop: 20 }}>
                    {hasPayScales && (
                        <div>
                            <b>Pay Range Info:</b>
                            {sortedPayScales.map((scale) => (
                                <div key={scale.rate} style={{ marginTop: 20 }}>
                                    <Text variant="body2">
                                        <b>${scale.rate}/hr</b>
                                        {showDescription ? `: ${scale.description}` : ''}
                                    </Text>
                                    {scale.qualifications.map((qualification) => {
                                        return (
                                            <Chip
                                                key={`${qualification.name}:${qualification.option}`}
                                                style={{ height: 30, margin: 5 }}
                                                label={
                                                    (qualification.value && qualification.value !== 'true'
                                                        ? qualification.value + ' '
                                                        : '') +
                                                    qualification.name +
                                                    (qualification.option ? ': ' + qualification.option : '')
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                    )}
                </Text>
                <Checkbox
                    style={{ marginBottom: 20 }}
                    onChange={toggleMinimumQualifiationRequirement}
                    checked={minimumQualificationRequired}
                    label="Don't autobook past workers without minimum qualifications"
                />
                <Labels type="ongoing" object={job} />
            </BasicDialog>
        </>
    );
}
