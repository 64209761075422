import React, { useEffect, useState } from 'react';
import { BasicDialog, Colors, PrimaryButton, Text, Chip } from 'library';
import { AdminListProvider, BusinessJobWithUser } from 'models';
import { client } from 'shared';
import { ProviderMatch } from '../substituteModels';
import SentMatchesTable from './SentMatchesTable';
import JobDescription from 'internal/business/JobDescription';

interface GroupedMatch {
    job: BusinessJobWithUser;
    matches: ProviderMatch[];
}

export default function SentMatchesModal({
    provider,
    open,
    onClose,
}: {
    provider: AdminListProvider;
    open: boolean;
    onClose: () => void;
}) {
    const [matches, setMatches] = useState<GroupedMatch[]>([]);

    useEffect(getMatches, [open]);

    function getMatches() {
        if (open)
            client(`api/match/?provider_user=${provider.user.id}`).then((res) => {
                setMatches(
                    res.reduce((groupedMatches: GroupedMatch[], thisMatch: ProviderMatch) => {
                        const groupedMatch = groupedMatches?.find(
                            (match: GroupedMatch) => match.job.id === thisMatch.appointment.ongoing_request.id,
                        );
                        if (groupedMatch) groupedMatch.matches.push(thisMatch);
                        else groupedMatches.push({ job: thisMatch.appointment.ongoing_request, matches: [thisMatch] });
                        return groupedMatches;
                    }, []),
                );
            });
    }

    function Header({ job }: { job: BusinessJobWithUser }) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                    <Text variant="body2">
                        {job.user.first_name} {job.user.last_name} ({job.user.id}){' '}
                        <Chip
                            style={{ minHeight: 20, height: 20 }}
                            textColor={Colors.white}
                            backgroundColor={
                                job.business_job_type === 'TRIAL_RUN' ? Colors.mediumTerraCotta : Colors.turquoise
                            }
                            label={job.business_job_type === 'TRIAL_RUN' ? 'TRIAL' : 'SUB'}
                        />
                    </Text>
                    <Text variant="body2">
                        {job.business[0].name} ({job.business[0].id})
                    </Text>
                    <Text variant="body2">
                        {job.headline} ({job.id}) - {job.pay ? `$${job.pay}` : `$${job.rate_min}-${job.rate_max}`}
                    </Text>
                    <Text variant="body2">Posted: {new Date(job.added_at).toLocaleDateString('en-US')}</Text>
                </div>
                <JobDescription job={job} />
            </div>
        );
    }

    return (
        <BasicDialog closeButton isOpen={open} onClose={onClose} style={{ minWidth: '60%' }}>
            {matches.length > 0 ? (
                matches.map((match) => (
                    <SentMatchesTable
                        key={match.job.id}
                        matches={match.matches}
                        header={<Header job={match.job} />}
                        refresh={getMatches}
                    />
                ))
            ) : (
                <Text>No matches found.</Text>
            )}
            <PrimaryButton rightAlign onClick={onClose}>
                Done
            </PrimaryButton>
        </BasicDialog>
    );
}
