import React, { useState } from 'react';
import { SecondaryButton } from 'library';
import { Grid } from '@material-ui/core';
import { track } from 'shared';
import { BookProvider } from '../../BookProvider/BookProvider';
import { useUserContext } from 'UserContext';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface IProvider {
    user: {
        id: number;
    };
}

interface IProps {
    providerIdObject: IProvider;
    providerName: string;
}

export default function ActionSection({ providerIdObject, providerName }: IProps) {
    const { role, getDisplayText } = useUserContext();
    const [bookOpen, setBookOpen] = useState(false);

    function handleOnClick() {
        setBookOpen(!bookOpen);
        track(`Book Provider from Messaging`, { providerId: providerIdObject.user.id });
    }

    function ActionButtons() {
        return (
            <Grid item>
                <SecondaryButton onClick={handleOnClick}>
                    Book {bookOpen ? <ExpandLess /> : <ExpandMore />}
                </SecondaryButton>
            </Grid>
        );
    }

    function ActionForms() {
        return bookOpen ? (
            <Grid container item style={{ paddingTop: 20 }}>
                <BookProvider
                    providerUserId={providerIdObject.user.id}
                    providerName={providerName}
                    onClose={() => setBookOpen(false)}
                    showFixedPay={role !== 'business_active'}
                    showJobType={role !== 'business_active'}
                    title={
                        role === 'business_active'
                            ? `${getDisplayText('substitute').capitalize()} job request`
                            : undefined
                    }
                />
            </Grid>
        ) : null;
    }

    return (
        <>
            {ActionButtons()}
            {ActionForms()}
        </>
    );
}
