import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { client, consoleLogInDev } from 'shared';
import { Text } from 'library';
import { BusinessJobResponse } from './models';
import BusinessLocation from './BusinessLocation';
import BusinessJobHeader from './BusinessJobHeader';
import BusinessJobPay from './BusinessJobPay';
import useLoading from 'library/hooks/useLoading';
import CancelAllModal from './CancelAllModal';
import EditDescriptionModal from './EditDescriptionModal';
import EditPayModal from './EditPayModal';
import { useUserContext } from 'UserContext';
import Schedule from './components/Schedule';
import { Grid } from '@mui/material';
import { ShiftsSection, AdditionalDetails, TrialRunOverviewSection } from './components';
import { LightTurqContainer, WhiteContainer } from './components/StyledComponents';
import JobDetailsFaq from './components/JobDetailsFaq';

export default function BusinessJob() {
    const [businessJobResponse, setBusinessJobResponse] = useState<BusinessJobResponse>();
    const [cancelOpen, setCancelOpen] = useState(false);
    const [editDescriptionOpen, setEditDescriptionOpen] = useState(false);
    const [editPayOpen, setEditPayOpen] = useState(false);
    const [error, setError] = useState(false);
    const { loading, setLoading, LoadingSpinner } = useLoading();
    const { id } = useParams<{ id: string }>();
    const { user } = useUserContext();
    const business = user?.businesses?.[0];

    function getJob() {
        setLoading(true);
        if (id) {
            client(`api/business-job/${id}/job-details/`)
                .then((result) => {
                    setBusinessJobResponse(result);
                })
                .catch((e) => {
                    setError(true);
                    consoleLogInDev(e);
                })
                .finally(() => setLoading(false));
        }
    }

    useEffect(getJob, [id]);

    function onEditComplete() {
        getJob();
    }

    if (loading) return <LoadingSpinner />;
    if (error)
        return (
            <Grid
                item
                container
                style={{ padding: 20, height: '80vh', justifyContent: 'center', alignItems: 'center' }}
            >
                <Text>There was an error loading this job. Please go back and try again.</Text>
            </Grid>
        );
    if (!businessJobResponse) return <></>;

    const job = businessJobResponse.job_details;
    const isTrialRun = job.business_job_type === 'TRIAL_RUN';

    return (
        <Grid container direction="column" justifyContent="center" style={{ marginTop: 40, padding: '0px 10%' }}>
            <LightTurqContainer item container>
                <WhiteContainer item container style={{ gap: 20 }}>
                    <Grid item container>
                        <BusinessJobHeader
                            businessJobType={job.business_job_type}
                            headline={job.headline}
                            onEdit={() => setEditDescriptionOpen(true)}
                            openCancel={() => setCancelOpen(true)}
                        />
                        <BusinessLocation location={job.business_location} />
                    </Grid>
                    <Grid xs={12} item container>
                        <Text>{job.family_comments}</Text>
                    </Grid>
                    {job.business_job_type === 'TRIAL_RUN' && (
                        <Grid xs={12} item container direction="column">
                            <Grid item>
                                <Text bold>What benefits do you offer to permanent staff?</Text>
                            </Grid>
                            <Grid item>
                                <Text>{job.trial_run_benefits}</Text>
                            </Grid>
                        </Grid>
                    )}

                    <Grid xs={12} direction="row" container item style={{ gap: 10 }}>
                        <Schedule job={job} />
                        <BusinessJobPay
                            pay={job.pay}
                            rateMax={job.rate_max}
                            rateMin={job.rate_min}
                            onEdit={() => setEditPayOpen(true)}
                            payScales={job.pay_scales}
                        />
                        {business?.business_type === 'CHILD_CARE' && (
                            <AdditionalDetails
                                businessJobResponse={businessJobResponse}
                                onEditComplete={onEditComplete}
                            />
                        )}
                        <JobDetailsFaq businessJobResponse={businessJobResponse} onEditComplete={onEditComplete} />
                    </Grid>
                    {isTrialRun && businessJobResponse.provider_shifts.length > 0 && (
                        <TrialRunOverviewSection
                            providerShifts={businessJobResponse.provider_shifts}
                            job={job}
                            getJob={getJob}
                        />
                    )}
                    <ShiftsSection businessJobResponse={businessJobResponse} getJob={getJob} />

                    <CancelAllModal
                        open={cancelOpen}
                        onClose={() => setCancelOpen(false)}
                        businessJobResponse={businessJobResponse}
                        refresh={getJob}
                    />
                    <EditDescriptionModal
                        open={editDescriptionOpen}
                        onClose={() => setEditDescriptionOpen(false)}
                        businessJobResponse={businessJobResponse}
                        onSaveComplete={onEditComplete}
                    />

                    <EditPayModal
                        open={editPayOpen}
                        onClose={() => setEditPayOpen(false)}
                        businessJobResponse={businessJobResponse}
                        onSaveComplete={onEditComplete}
                    />
                </WhiteContainer>
            </LightTurqContainer>
        </Grid>
    );
}
