import React, { useEffect, useState } from 'react';
import { Text } from 'library';
import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ConfirmHoursContent } from './ConfirmHoursContent';
import { IHourReportView } from 'internal/payments/HourReportModels';
import { getHourReports } from 'api/HourReportApi';
import { PagingResult } from 'models';

export default function ConfirmSubHours() {
    const [hourReports, setHourReports] = useState<PagingResult<IHourReportView>>();
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        updateHoursReported(1);
    }, []);

    function updateHoursReported(page: number) {
        getHourReports(page).then(setHourReports);
    }

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: 20, paddingBottom: 100 }}>
            <Text variant="display">Confirm Job Hours</Text>
            <ConfirmHoursContent
                hourReports={hourReports?.results || []}
                updateHoursReported={() => updateHoursReported(pageNumber)}
            />
            <Pagination
                count={hourReports?.num_pages}
                page={pageNumber}
                onChange={(event, page) => {
                    setPageNumber(page);
                    updateHoursReported(page);
                }}
                siblingCount={2}
                boundaryCount={2}
            />
        </Grid>
    );
}
