import React, { useState } from 'react';
import { Grid, useMediaQuery, styled } from '@mui/material';
import { PrimaryButton, Text, SizeableRoundedDialog } from 'library';
import theme from 'theme';
import useLoading from 'library/hooks/useLoading';
import { format12HourTime, formatMonthDay } from 'shared/Dates';
import { IShift } from 'models';
import { ProviderSelection } from 'parent-portal/forms/JobRequestTypes';
import PreferredWorkersInput from 'parent-portal/forms/components/pages/ProviderPreferencesPage/PreferredWorkersInput';
import PastWorkersInput from 'parent-portal/forms/components/pages/ProviderPreferencesPage/PastWorkersInput';
import { updateBusinessAppointment } from './businessAppointmentApi';
import { convertPreferredSitterObject } from 'parent-portal/forms/components/pages/ProviderPreferencesPage/preferredWorkersApi';

export default function UpdatePreferredWorkerModal({
    open,
    onClose,
    shift,
    refresh,
}: {
    open: boolean;
    onClose: () => void;
    shift: IShift;
    refresh: () => void;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const matchedWorkerSelections = shift.matches?.map((match) => ({
        id: match.matched_worker.id,
        name: `${match.matched_worker.first_name} ${match.matched_worker.last_name}`,
    }));

    const [preferredWorkers, setPreferredWorkers] = useState<ProviderSelection[]>([]);
    const [pastWorkers, setPastWorkers] = useState<ProviderSelection[]>([]);

    const { loading, setLoading } = useLoading();
    function submit() {
        setLoading(true);
        updateBusinessAppointment(shift.id, {
            preferred_sitters: convertPreferredSitterObject(preferredWorkers, pastWorkers),
        })
            .then(() => {
                refresh();
                onClose();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">Request Specific Worker for Shift</Text>
                <Text variant="body1">
                    {shift.ongoing_request.headline}, {formatMonthDay(shift.start_date)}{' '}
                    {format12HourTime(shift.start_date)}-{format12HourTime(shift.end_date)} (
                    {shift.ongoing_request.business_job_type.replace('_', ' ').toLowerCase().capitalize()})
                </Text>
                <PreferredWorkersInput
                    selected={preferredWorkers}
                    onChange={setPreferredWorkers}
                    addresses={[shift.ongoing_request.address.id]}
                    existingMatches={matchedWorkerSelections}
                />
                <Spacer />
                <PastWorkersInput selected={pastWorkers} onChange={setPastWorkers} />
                <Spacer />
                <PrimaryButton
                    disabled={loading}
                    onClick={submit}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Submit
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}

const Spacer = styled('div')({
    marginTop: 30,
});
