import { AdminListProvider, AdminProvider, OCCRRATrainings } from 'models';

export function getTandemStartDate(provider: AdminProvider) {
    return provider?.tandem_start_date ? new Date(provider?.tandem_start_date) : undefined;
}
export function getCompletedDay0(provider: AdminProvider | AdminListProvider) {
    /* TODO: we really should be using the training summary for this. This is a temporary hack. Create a card for it to get it in the backlog
     * Not just day0 0m, but also day 30 and day 60
     */
    const forbiddenList = [
        'ODE Orientation Training for Preschool and School Age Child Care (SACC) Staff Members: Early Learning (AT119704)',
        'Family Child Care Home Orientation Training (AT123906)',
        'Health and Safety Orientation Training (AT122520)',
    ];

    /* eslint-disable no-comments/disallowComments */
    return provider.occrra_info_collect?.occrra_trainings?.some(
        (training: OCCRRATrainings) =>
            training?.title?.toLowerCase().includes('orientation') &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            training.hours == 6 &&
            !forbiddenList.includes(training.title),
    );
    /* eslint-enable no-comments/disallowComments */
}
export function getCompletedDay30(provider: AdminProvider) {
    return (
        provider.occrra_info_collect?.occrra_trainings?.some((training: OCCRRATrainings) =>
            training?.title?.includes('Approach to Quality'),
        ) &&
        provider.occrra_info_collect?.occrra_trainings?.some((training: OCCRRATrainings) =>
            training?.title?.includes('Overview of Child Development'),
        )
    );
}
export function getCompletedDay60(provider: AdminProvider) {
    return provider.occrra_info_collect?.occrra_trainings?.some((training: OCCRRATrainings) =>
        training?.title?.includes('Child Abuse'),
    );
}
export function getThirtyDaysAfterTandemStartDate(provider: AdminProvider) {
    let tandemStartDate = getTandemStartDate(provider);
    if (tandemStartDate) {
        let thirtyDaysAfterTandemStartDate = undefined;
        thirtyDaysAfterTandemStartDate = new Date(tandemStartDate);
        thirtyDaysAfterTandemStartDate.setDate(thirtyDaysAfterTandemStartDate.getDate() + 30);
        return thirtyDaysAfterTandemStartDate;
    }
}

export function getSixtyDaysAfterTandemStartDate(provider: AdminProvider) {
    let tandemStartDate = getTandemStartDate(provider);
    if (tandemStartDate) {
        let sixtyDaysAfterTandemStartDate = undefined;
        sixtyDaysAfterTandemStartDate = new Date(tandemStartDate);
        sixtyDaysAfterTandemStartDate.setDate(sixtyDaysAfterTandemStartDate.getDate() + 60);
        return sixtyDaysAfterTandemStartDate;
    }
}

export function getNinetyDaysAfterTandemStartDate(provider: AdminProvider) {
    let tandemStartDate = getTandemStartDate(provider);
    if (tandemStartDate) {
        let ninetyDaysAfterTandemStartDate = undefined;
        ninetyDaysAfterTandemStartDate = new Date(tandemStartDate);
        ninetyDaysAfterTandemStartDate.setDate(ninetyDaysAfterTandemStartDate.getDate() + 90);
        return ninetyDaysAfterTandemStartDate;
    }
}
