import React, { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Dialog,
    DialogTitle,
} from '@material-ui/core';
import theme from '../../../theme';
import { formatShortMonthDayYear } from '../../../shared/Dates';
import { client, consoleLogInDev } from 'shared';
import download from 'shared/Download';
import PaySectionHeader from './PaySectionHeader';
import { PayBackgroundPaper } from './PayPapers';
import { Colors, OutlinedDropdown, PrimaryButton, SecondaryButton, Text } from 'library';
import { useUserContext } from 'UserContext';

interface ILineItem {
    amount: number;
}
interface IInvoice {
    id: number;
    amount: number;
    paid_date: string;
    line_items: ILineItem[];
    bill_date: string;
}

const COMPONENT_STATES = {
    neutral: 'neutral',
    submittingPayment: 'submittingPayment',
    confirmSubmitPayment: 'confirmSubmitPayment',
    downloading: 'downloading',
};

const FailedPayments = () => {
    const [componentState, setComponentState] = useState(COMPONENT_STATES.neutral);
    const [failedPayments, setFailedPayments] = useState<IInvoice[]>([]);
    const [selectedFailedPayment, setSelectedFailedPayment] = useState<number>();
    const [selectedPaymentInfo, setSelectedPaymentInfo] = useState<number>();
    const [errorMessage, setErrorMessage] = useState('');
    const { user, role } = useUserContext();

    const isBusiness = role === 'business_active';

    useEffect(() => {
        getFailedPayments();
    }, []);

    const getFailedPayments = () => {
        client('payment/api/invoice/?status=overdue')
            .then((response) => {
                const mappedResults = (response.results as IInvoice[]).map((x) => {
                    return {
                        ...x,
                        amount: x.line_items.reduce((acc, currVal) => acc + currVal.amount, 0),
                    };
                });
                setFailedPayments(mappedResults);
            })
            .catch(consoleLogInDev);
    };

    const onSelectFailedPayment = (id: number) => {
        setSelectedFailedPayment(id);
        setComponentState(COMPONENT_STATES.confirmSubmitPayment);
    };

    const onRetryPayment = () => {
        setComponentState(COMPONENT_STATES.submittingPayment);
        setErrorMessage('');

        client(`payment/api/invoice/${selectedFailedPayment}/retry/`, {
            body: { payment_information_id: selectedPaymentInfo },
        })
            .then(() => {
                setFailedPayments(failedPayments.filter((x) => x.id !== selectedFailedPayment));
                setSelectedFailedPayment(undefined);
                setSelectedPaymentInfo(undefined);
            })
            .catch((error) => {
                setErrorMessage(error.message || 'There was an error retrying your payment');
            })
            .finally(() => setComponentState(COMPONENT_STATES.neutral));
    };

    const onDialogClose = () => {
        setComponentState(COMPONENT_STATES.neutral);
        setSelectedPaymentInfo(undefined);
    };

    const onDownloadClick = (invoiceId: number) => {
        setComponentState(COMPONENT_STATES.downloading);
        setErrorMessage('');
        download(`payment/api/invoice/${invoiceId}/download/`)
            .catch((error) => {
                consoleLogInDev(error);
                setErrorMessage('There was an error generating your invoice');
            })
            .finally(() => {
                setComponentState(COMPONENT_STATES.neutral);
            });
    };

    if (!failedPayments?.length) {
        return <></>;
    }

    const selectOptions = [
        { key: 0, value: 'Select One' },
        ...(user?.payment_information || []).map((info) => {
            const infoData = info.data;
            return {
                key: info.id,
                value: `Account ending in ${infoData?.last4 || info.last_four}`,
            };
        }),
    ];

    return (
        <Grid item xs={12}>
            <PayBackgroundPaper>
                <PaySectionHeader textStyle={{ color: Colors.error }}>Failed Payments</PaySectionHeader>
                <Text variant="body1">
                    It looks like there was an issue with your payment. Please update your default payment method, add
                    another payment method, or verify there are enough funds in your default account to cover the amount
                    before retrying the payment.
                </Text>
                <Grid style={{ marginBottom: 10 }}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Payment Date
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                            Amount
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {failedPayments.map((x) => (
                                    <TableRow key={x.id}>
                                        <TableCell>
                                            <Typography variant="subtitle1">
                                                {formatShortMonthDayYear(x.bill_date || x.paid_date)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">${x.amount.toFixed(2)}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {isBusiness ? (
                                                <SecondaryButton
                                                    disabled={componentState === COMPONENT_STATES.downloading}
                                                    loading={componentState === COMPONENT_STATES.downloading}
                                                    onClick={() => onDownloadClick(x.id)}
                                                    style={{ color: theme.palette.primary.main, padding: '1em' }}
                                                >
                                                    Download
                                                </SecondaryButton>
                                            ) : (
                                                <></>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <PrimaryButton
                                                onClick={() => onSelectFailedPayment(x.id)}
                                                buttonStyle={{ width: 150 }}
                                            >
                                                Retry
                                            </PrimaryButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {errorMessage && (
                    <Text variant="body1" textStyle={{ color: Colors.error }}>
                        {errorMessage}
                    </Text>
                )}
            </PayBackgroundPaper>
            <Dialog open={componentState === COMPONENT_STATES.confirmSubmitPayment} onClose={onDialogClose}>
                <DialogTitle id="simple-dialog-title">Pay Invoice</DialogTitle>
                <Grid container style={{ padding: 10 }}>
                    <Grid item style={{ padding: 10, marginBottom: 10 }}>
                        <Text variant="body1">Select payment method</Text>
                        <OutlinedDropdown
                            value={selectedPaymentInfo || 0}
                            fields={selectOptions}
                            onChange={(event) => setSelectedPaymentInfo(event.target.value)}
                        />
                    </Grid>
                    <Grid container item justify="space-evenly" style={{ marginBottom: 10 }}>
                        <Grid item xs={4}>
                            <SecondaryButton onClick={onDialogClose}>Cancel</SecondaryButton>
                        </Grid>
                        <Grid item xs={4}>
                            <PrimaryButton
                                disabled={!selectedPaymentInfo || componentState === COMPONENT_STATES.submittingPayment}
                                onClick={onRetryPayment}
                            >
                                OK
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
};

export default FailedPayments;
