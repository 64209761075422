export const BOOL = 'BOOLEAN';
export const SINGLE = 'SINGLE_SELECT';
export const MULTI = 'MULTI_SELECT';

export const DEGREE = 'Degree';
export const ECE_DEGREE = 'ECE-Related Degree';
export const HIGH_SCHOOL_CHOICE = { key: -1, value: 'High School' };
export const CDA = 'Child Development Associate (CDA) Credential';
export const YEARS_EXPERIENCE = 'Years experience in a childcare center';
export const NO_EXPERIENCE = { key: -1, value: 'No Experience' };
