import React from 'react';
import { Colors, Text } from 'library';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

type ChipWithIconProps = {
    text: string;
    key: string;
    icon?: IconDefinition;
};

export default function ChipWithIcon({text, key, icon}: ChipWithIconProps) {
    return (
        <div key={key} style={styles.chip}>
            { icon && <FontAwesomeIcon icon={icon} style={styles.icon} /> }
            <Text variant="body2" textStyle={styles.chipText}>{text}</Text>
        </div>
    );
}


const styles = {
    chip: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.terraCotta,
        padding: 5,
        paddingLeft: 14,
        paddingRight: 14,
        borderRadius: 13,
    },
    chipText: {
        color: Colors.white
    },
    icon: {
        color: Colors.white,
        fontSize: 12,
        marginRight: 6,
    }
};
