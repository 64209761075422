import { CircularProgress } from '@mui/material';
import { BusinessApplicantWithJob } from 'internal/applicants/ApplicantModels';
import { Text } from 'library';
import { AdminBusinessUser, LeanUser, LeanUserWithPhone } from 'models';
import React, { useEffect, useState } from 'react';
import { client } from 'shared';
import AppliedJobsMenuItem from './AppliedJobsMenuItem';

export default function AppliedJobsMenu({
    user,
    open,
}: {
    user: AdminBusinessUser | LeanUserWithPhone | LeanUser;
    open: boolean;
}) {
    const [appliedJobs, setAppliedJobs] = useState<BusinessApplicantWithJob[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setLoading(true);
            client(`api/business-applicant/?applied_user=${user.id}`)
                .then(setAppliedJobs)
                .catch(console.log)
                .finally(() => setLoading(false));
        }
    }, [open, user]);

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {loading ? (
                    <CircularProgress style={{ padding: 10 }} />
                ) : appliedJobs.length > 0 ? (
                    appliedJobs.map((job) => <AppliedJobsMenuItem application={job} user={user} />)
                ) : (
                    <Text textStyle={{ padding: 10 }}>No jobs found</Text>
                )}
            </div>
        </>
    );
}
