import React, { createContext, useContext, useState } from 'react';
import { track } from 'shared';
import useUserFlowManager from '../../userFlows/UserFlowManager';

interface OnboardingContextType {
    currentStep: OnboardingStep;
    goToStep: (nextStep: OnboardingStep, buttonText?: string) => void;
    submitAction: (
        action: () => void,
        optionName: string,
        buttonText?: string,
        otherInfo?: { [key: string]: string } | string,
    ) => void;
}

export enum OnboardingStep {
    BusinessNeeds = 'BusinessNeeds',
    ShortOrLongTerm = 'ShortOrLongTerm',
    DoYouKnowTheDates = 'DoYouKnowTheDates',
    Role = 'Role',
}

const defaultContext = {
    currentStep: OnboardingStep.BusinessNeeds,
    goToStep: () => {},
    submitAction: () => {},
};

export const OnboardingContext = createContext<OnboardingContextType>(defaultContext);

export function OnboardingContextProvider({ children }: { children?: React.ReactNode }) {
    const [currentStep, setCurrentStep] = useState(OnboardingStep.BusinessNeeds);

    const { trackUserFlowChoice } = useUserFlowManager('BusinessOnboarding');

    function submitAction(
        action: () => void,
        optionName: string,
        buttonText?: string,
        otherInfo?: { [key: string]: string } | string,
    ) {
        recordOptionSelected(currentStep, optionName, buttonText, otherInfo);
        action();
    }

    function goToStep(nextStep: OnboardingStep, buttonText?: string) {
        recordOptionSelected(currentStep, nextStep, buttonText);
        setCurrentStep(nextStep);
    }

    function recordOptionSelected(
        currentStep: OnboardingStep,
        optionName: string,
        buttonText?: string,
        otherInfo?: { [key: string]: string } | string,
    ) {
        track('Onboarding option selected', { currentStep, optionName });
        trackUserFlowChoice(currentStep, optionName, buttonText, otherInfo);
    }

    const contextValue = {
        currentStep,
        goToStep,
        submitAction,
    };

    return <OnboardingContext.Provider value={contextValue}>{children}</OnboardingContext.Provider>;
}

export function useOnboardingContext() {
    return useContext(OnboardingContext);
}
