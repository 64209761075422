import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import React from 'react';
import JobFormPage from '../JobFormPage';
import RequestedWorkersOnJob from './RequestedWorkersOnJob';

interface ProviderPreferencesPageProps {
    title?: string;
    subtitle?: string;
    nextText?: string;
    submit?: () => void;
}

export default function ProviderPreferencesJobFormPage({
    title,
    subtitle,
    nextText = 'Next',
}: ProviderPreferencesPageProps) {
    const { goToNext } = useJobFormContext();

    function onNextClicked() {
        goToNext();
    }

    return (
        <JobFormPage
            onNextClicked={onNextClicked}
            title={title ?? 'Request Specific Workers'}
            nextText={nextText}
            subtitle={subtitle}
            nextDisabled={false}
            id="provider-preferences-page"
        >
            <RequestedWorkersOnJob />
        </JobFormPage>
    );
}
