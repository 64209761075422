import React from 'react';
import { Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import OptionMenuItem from './OptionMenuItem';
import { useNotificationContext } from 'parent-portal/notifications/NotificationContext/NotificationContext';
import { track } from 'shared';

export default function NotificationOptionMenu({ onClose }: { onClose: () => void }) {
    const { markAsReadNotifications } = useNotificationContext();

    function handleMarkAsReadNotifications() {
        track('Notification: Clicked Mark all as Read');
        markAsReadNotifications();
    }

    return (
        <Grid container direction="column">
            <Grid container item>
                <OptionMenuItem
                    icon={<CheckIcon color={'primary'} style={{ paddingRight: 10, fontSize: 30 }} />}
                    text={'Mark all as read'}
                    action={handleMarkAsReadNotifications}
                    onClose={onClose}
                />
            </Grid>
        </Grid>
    );
}
