import React from 'react';
import HorizontalOptionButton from './components/HorizontalOptionButton';
import JobFormPage from '../JobFormPage';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { BusinessJobTypes, JobFormFields } from 'parent-portal/forms/JobRequestTypes';
import { FieldStates } from '../../FormTypes';
import FormField from '../../FormField';
import { Grid } from '@material-ui/core';
import { OptionType, usePaymentOptions } from 'parent-portal/payments/PaymentOptions';
import TandemHiringPolicy from './components/TandemHiringPolicy';
import { Colors } from 'library';

export default function BusinessPaymentModelPage({ fieldStates }: { fieldStates: FieldStates<JobFormFields> }) {
    const { goToNext } = useJobFormContext();
    const { businessJobType, multipleProviders, endDate } = fieldStates;
    const paymentOptions = usePaymentOptions();

    if (businessJobType.value) businessJobType.setValid(true);

    const onChange = (value: BusinessJobTypes) => {
        if (value === 'SUBSTITUTE') {
            businessJobType.setValue(value);
            multipleProviders.setValue(true);
        } else {
            businessJobType.setValue(value);
            multipleProviders.setValue(false);
            endDate.setValue(null);
        }
        businessJobType.setValid(true);
    };

    function applyDynamicStyles(type: string) {
        return type === businessJobType.value ? styles.selected : {};
    }

    return (
        <JobFormPage
            title="Let's create your job"
            onNextClicked={goToNext}
            backText="Cancel"
            nextDisabled={!businessJobType.isValid}
        >
            <FormField style={{ marginTop: 40 }}>
                <Grid
                    container
                    item
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    style={{ marginTop: 20, gap: 20 }}
                >
                    {paymentOptions.map((o: OptionType) => (
                        <Grid item key={o.option} style={{ width: '100%' }}>
                            <HorizontalOptionButton
                                title={o.title}
                                subtitle={o.subtitle}
                                highlight={o.highlight}
                                listItems={o.listItems}
                                action={() => onChange(o.option as BusinessJobTypes)}
                                cardItems={o.cardItems}
                                icon={o.icon}
                                style={applyDynamicStyles(o.option)}
                                selected={o.option === businessJobType.value}
                            />
                        </Grid>
                    ))}

                    <Grid item style={{ width: '100%' }}>
                        <TandemHiringPolicy initialExpandedState={!businessJobType.value} />
                    </Grid>
                </Grid>
            </FormField>
        </JobFormPage>
    );
}

const styles = {
    selected: {
        backgroundColor: Colors.lightTurq,
        border: `1px solid ${Colors.turquoise}`,
    },
};
