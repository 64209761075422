import React, { CSSProperties, ElementType } from 'react';
import { Colors } from '../../theme';
import BubbleButton from './BubbleButton';
import { ButtonWrapperProps } from './models';
import { Tooltip, SvgIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const enabledStyle = {
    button: {
        outline: 'none',
    },
};

const disabledStyle = {
    button: {
        backgroundColor: Colors.disabledGrey,
    },
};

type ReducedButtonWrapperProps = Omit<ButtonWrapperProps, 'enabledStyle' | 'disabledStyle' | 'color' | 'size'>;

export interface IconButtonProps extends ReducedButtonWrapperProps {
    icon?: ElementType<any>;
    color?: Colors;
    size?: CSSProperties['width'];
    backgroundColor?: CSSProperties['backgroundColor'];
    iconStyle?: CSSProperties;
    tooltip?: string;
    FontAwesomeImg?: IconDefinition;
    className?: string;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(function _IconButton(
    {
        icon,
        color,
        onClick,
        size,
        disabled,
        style,
        backgroundColor,
        iconStyle,
        tooltip,
        FontAwesomeImg,
        className,
    }: IconButtonProps,
    ref,
) {
    const Button = React.forwardRef<HTMLButtonElement, ReducedButtonWrapperProps>(function _Button(props, ref) {
        return (
            <BubbleButton
                ref={ref}
                className={className}
                enabledStyle={enabledStyle}
                disabledStyle={disabledStyle}
                defaultStyle={{
                    button: {
                        padding: 0,
                        minWidth: 'unset',
                        borderRadius: '50%',
                        boxShadow: '0px 3px 8px #00000029',
                        height: size || 30,
                        width: size || 30,
                        outline: 'none',
                        ...style,
                        backgroundColor: backgroundColor || Colors.white,
                    },
                }}
                onClick={onClick}
                disabled={disabled}
                {...props}
            >
                {FontAwesomeImg ? (
                    <FontAwesomeIcon
                        icon={FontAwesomeImg}
                        color={color || Colors.darkNavy}
                        style={{
                            width: size,
                            height: size,
                            padding: 8,
                            borderRadius: size,
                            color: color || Colors.darkNavy,
                            ...iconStyle,
                        }}
                    />
                ) : icon ? (
                    <SvgIcon
                        style={{
                            color: color || Colors.darkNavy,
                            width: size || 30,
                            height: size || 30,
                            padding: 6,
                            ...iconStyle,
                        }}
                        component={icon}
                    />
                ) : null}
            </BubbleButton>
        );
    });

    return tooltip ? (
        <Tooltip title={tooltip} placement="top">
            <Button ref={ref} />
        </Tooltip>
    ) : (
        <Button ref={ref} />
    );
});
export default IconButton;
