import { Grid, TableCell } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { client, consoleLogInDev } from 'shared';
import { PointHistory } from '../types';
import { PagingResult } from 'models';
import { Colors, Text } from 'library';
import PaginatedTable from '../../tables/PaginatedAdminTable';
import Row from '../../tables/Row';
import { format } from 'date-fns';

type PointHistoryProps = {
    userId: number;
    totalPoints: number;
    suspendedBy: string | null;
    ninetyDayProbationCount: number;
};

export default function PointHistoryList({
    userId,
    totalPoints,
    suspendedBy,
    ninetyDayProbationCount,
}: PointHistoryProps) {
    const [page, setPage] = useState(1);
    const [history, setHistory] = useState<PointHistory[]>([]);
    const [data, setData] = useState<PagingResult<PointHistory>>();
    const [loading, setLoading] = useState(false);

    function getPointHistory() {
        setLoading(true);
        client(`api/point-history/${userId}/?page=${page}`)
            .then((res) => {
                setData(res);
                setHistory(res.results ?? []);
            })
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }

    useEffect(getPointHistory, [userId, page]);

    if (!data) return <Text>No History Found</Text>;

    const columns = ['Date', 'Name', 'Description', 'Value'];

    type HeaderTextArguments = Pick<PointHistoryProps, 'totalPoints' | 'suspendedBy' | 'ninetyDayProbationCount'>;
    function getHeaderText({ totalPoints, suspendedBy, ninetyDayProbationCount }: HeaderTextArguments) {
        const suspendedByText = suspendedBy ? `Suspended by: ${suspendedBy}` : '';
        const probationText =
            ninetyDayProbationCount > 0 ? `Ninety Day Probation Count: ${ninetyDayProbationCount}` : '';
        const totalPointsText = totalPoints ? `Current Points: ${totalPoints}` : 'PROBATION';

        return [totalPointsText, probationText, suspendedByText].filter(Boolean).join(' | ');
    }

    return (
        <Grid container item style={{ marginTop: 20, gap: 20 }}>
            <PaginatedTable
                data={data}
                page={page}
                updatePage={setPage}
                columns={columns}
                loading={loading}
                header={getHeaderText({ totalPoints, suspendedBy, ninetyDayProbationCount })}
            >
                {history.map((h) => (
                    <Row key={h.id} oddRowColor={Colors.lightTurq}>
                        <TableCell>{format(new Date(h.added_at), 'MM/dd/yyyy p')}</TableCell>
                        <TableCell>{h.point_value.name}</TableCell>
                        <TableCell>{h.point_value.description}</TableCell>
                        <TableCell>{h.point_value.value}</TableCell>
                    </Row>
                ))}
            </PaginatedTable>
        </Grid>
    );
}
