import { AppliedProvider } from 'models';
import { client, track } from 'shared';

export const getProvider = async (providerId: number | string, jobParam?: string) => {
    return await client(`api/providers/${providerId}/${jobParam}`);
};

export const getJobs = async () => {
    return await client('api/ongoing/unfilled_user_requests/');
};

export const getExperience = async (providerId: number) => {
    return await client(`api/past-experience/?provider=${providerId}`);
};

export const getChildcareCenterInformation = async (providerId: number) => {
    return await client(`api/childcare-center-qualification/?provider=${providerId}`);
};

export const getApplicants = async (
    jobId: number | string,
    relevanceFilter: boolean,
    orderByExperience: boolean,
    orderByQualifications: boolean,
) => {
    return await client(
        `api/sitter-recommendations/applicants/?jobRequest=${jobId}&relevantOnly=${+relevanceFilter}&orderByExperience=${+orderByExperience}&orderByQualifications=${+orderByQualifications}`,
    );
};

export const getJob = async (jobId: number | string) => {
    return await client(`api/ongoing/${jobId}`);
};

export const toggleHideApplicant = async (
    applicants: AppliedProvider[],
    provider: AppliedProvider,
    jobId: number | string,
) => {
    await client(`api/applicants/toggle-hide-applicant/${jobId}`, {
        method: 'PATCH',
        body: {
            sitter_user_id: provider.user.id,
        },
    });
    track('Applicants Page: sitter hide toggled', { provider });
    return [...applicants.filter((a) => a.id !== provider.id), { ...provider, hidden: !provider.hidden }];
};

export const toggleLikeApplicant = async (
    applicants: AppliedProvider[],
    provider: AppliedProvider,
    jobId: number | string,
) => {
    await client(`api/applicants/toggle-like-applicant/${jobId}`, {
        method: 'PATCH',
        body: {
            sitter_user_id: provider.user.id,
        },
    });
    track('Applicants Page: sitter like toggled', { provider });
    return [...applicants.filter((a) => a.id !== provider.id), { ...provider, liked: !provider.liked }];
};

export const getProviderQualifications = async (providerUserId: number | string) => {
    return await client(`api/providers/${providerUserId}/qualifications-preview`);
};
