import React, { useState } from 'react';
import { Grid, Typography, Dialog, DialogContent, Radio, RadioGroup } from '@material-ui/core';
import Button from '../../../reusableComponents/button';
import LoadingButton from '../../../reusableComponents/loadingButton';
import Input from '../../../reusableComponents/input';
import StyledIconButton from '../../../reusableComponents/StyledIconButton';
import CloseIcon from '@material-ui/icons/Close';
import Label from '../../../reusableComponents/checkboxLabel';
import client from '../../../shared/ApiClient';
import { Environment, MixPanelKey } from 'environmentVariables';

var mixpanel = require('mixpanel-browser');
mixpanel.init(MixPanelKey);

interface IRequestNewSitterDialogProps {
    job: any;
    provider?: any;
    open: boolean;
    onClose: () => void;
    type: string;
}

const RequestNewSitterDialog = ({ job, provider, open, onClose, type }: IRequestNewSitterDialogProps) => {
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const [reason, setReason] = useState<string>('Unresponsive');

    const requestNew = async () => {
        if (reason === 'Other' && (!comment || comment === '')) {
            setComment('');
            return;
        }
        setLoading(true);
        let providerId = provider;
        if (!providerId) {
            providerId = job.pairings ? job.pairings[0].babysitter.id : null;
        }
        let jobUrlParm = `?request=${job.ongoing_request.id}`;
        if (type === 'appointment') {
            jobUrlParm = `?appointment=${job.id}`;
        }

        if (providerId) {
            let body = {
                comments: {
                    action: 'family_request_new',
                    reason: reason,
                    other: reason === 'Other' ? comment : '',
                },
                provider: providerId,
            };
            try {
                await client(`api/request-new-sitter/${jobUrlParm}`, {
                    method: 'PATCH',
                    body,
                });
                setConfirmation(true);
                mixpanel.track('Request a New Sitter', { job: jobUrlParm, provider: providerId });
                // refreshes the page - easiest way to do this right now for all the different use cases
                window.location.reload();
            } catch (error) {
                if (Environment !== 'production') {
                    console.log(error);
                }
            }
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open}
                onClose={() => {
                    onClose();
                    setConfirmation(false);
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent style={{ paddingBottom: '5%' }}>
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={11}>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                Request a New Sitter
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <StyledIconButton
                                onClick={() => {
                                    onClose();
                                    setConfirmation(false);
                                }}
                            >
                                <CloseIcon color="error" />
                            </StyledIconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                Tandem will inform your previous sitter that you no longer need their services. You will
                                receive a text message when we find you a new sitter.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup
                                name="reasons"
                                value={reason}
                                onChange={(e) => {
                                    setReason(e.target.value);
                                }}
                            >
                                <Label
                                    value="Unresponsive"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={<Typography variant="subtitle1">My sitter was unresponsive</Typography>}
                                />
                                <Label
                                    value="Logistics"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={
                                        <Typography variant="subtitle1">
                                            I spoke to my sitter and it didn't work out
                                        </Typography>
                                    }
                                />
                                <Label
                                    value="Noshow"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={<Typography variant="subtitle1">Sitter has not shown up</Typography>}
                                />
                                <Label
                                    value="Inappropriate Communication"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={
                                        <Typography variant="subtitle1">
                                            My sitter was rude or inappropriate in our conversations.
                                        </Typography>
                                    }
                                />
                                <Label
                                    value="Provider Verbal Cancellation"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={
                                        <Typography variant="subtitle1">
                                            My sitter verbally cancelled but did not cancel on Tandem.
                                        </Typography>
                                    }
                                />
                                <Label
                                    value="Other"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={<Typography variant="subtitle1">Other</Typography>}
                                />
                            </RadioGroup>
                            {reason === 'Other' && (
                                <Input
                                    error={comment === ''}
                                    helperText={comment === '' ? 'Please explain' : ''}
                                    multiline
                                    rows={3}
                                    variant="outlined"
                                    fullWidth
                                    value={comment}
                                    onChange={(event) => setComment(event.target.value)}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {!confirmation ? (
                        <LoadingButton
                            loading={loading}
                            fullWidth
                            style={{ marginTop: 10 }}
                            onClick={() => requestNew()}
                            disableFocusRipple
                            disableRipple
                        >
                            Request a New Sitter
                        </LoadingButton>
                    ) : (
                        <Button disabled fullWidth style={{ marginTop: 10 }} disableFocusRipple disableRipple>
                            New Sitter Requested
                        </Button>
                    )}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default RequestNewSitterDialog;
