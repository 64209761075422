import React, { useState } from 'react';
import { Colors, Text } from 'library';
import { AddRounded, Pets, Favorite } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { Popover } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useWidth } from 'reusableComponents/useWidth';
import { useModalsContext } from 'ModalsContext';
import { JobTypeIds } from 'models';
import { JobFormPresets } from 'parent-portal/forms/JobFormPresets';
import { BusinessJobTypes, JobPaymentTypes, RequestType } from 'parent-portal/forms/JobRequestTypes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { FaBroom } from 'react-icons/fa';
import usePublicSessionTracking, { PublicSessionEventType } from './PublicSessionTracking';
import { track } from 'shared';
import { useUserContext } from 'UserContext';
import { AllInclusive, RestartAlt } from '@mui/icons-material';

const excludedLocations = ['messages', 'request', 'get-started'];

interface IOption {
    text: string;
    icon: any;
    presets: JobFormPresets;
}

const AlwaysPresentPostAJob = () => {
    const breakpoints = useWidth();
    const location = useLocation();
    const [popOverOpen, setPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const { openJobForm } = useModalsContext();
    const { role, getDisplayText } = useUserContext();
    const smallScreen = ['xs', 'sm'].includes(breakpoints);
    const { trackSession } = usePublicSessionTracking('Always Present - Post a Job');

    const styles = {
        smallIcon: {
            width: 33,
            height: 33,
            backgroundColor: Colors.white,
            borderRadius: 50,
            color: Colors.turquoise,
            padding: 5,
        },
        mainIcon: {
            width: 55,
            height: 55,
            backgroundColor: Colors.turquoise,
            borderRadius: '50%',
            margin: 0,
        },
        mainIconContainer: {
            width: 66,
            height: 66,
            borderRadius: '50%',
            fontSize: 60,
            cursor: 'pointer',
            margin: 0,
            marginTop: 10,
            marginRight: -7,
            backgroundColor: Colors.white,
            boxShadow:
                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        },
    };

    const onJobTypeClick = (option: IOption) => {
        track(`Always Present Post a Job: ${option.text} button clicked`);
        trackSession('CLICK', `Always Present Post a Job: ${option.text} Button`, PublicSessionEventType.User);
        openJobForm('Always present post a job', option.presets);
        setPopoverOpen(false);
    };

    let subText = getDisplayText('substitute').capitalize();
    subText = subText.length > 4 ? subText.substring(0, 3) : subText;

    const options =
        role === 'business_active'
            ? [
                  {
                      text: `${subText} Shifts`,
                      icon: <RestartAlt style={styles.smallIcon} />,
                      presets: {
                          jobType: JobTypeIds.ChildCare,
                          businessJobType: 'SUBSTITUTE' as BusinessJobTypes,
                          payType: 'PERCENTAGE' as JobPaymentTypes,
                      },
                  },
                  {
                      text: 'Temp to Hire',
                      icon: <AllInclusive style={styles.smallIcon} />,
                      presets: {
                          jobType: JobTypeIds.ChildCare,
                          businessJobType: 'TRIAL_RUN' as BusinessJobTypes,
                          payType: 'PERCENTAGE' as JobPaymentTypes,
                      },
                  },
              ]
            : [
                  {
                      text: 'Child Care',
                      icon: <Favorite style={styles.smallIcon} />,
                      presets: { jobType: JobTypeIds.ChildCare, frequency: 'onetime' as RequestType },
                  },
                  {
                      text: 'Pet Care',
                      icon: <Pets style={styles.smallIcon} />,
                      presets: { jobType: JobTypeIds.PetCare, frequency: 'onetime' as RequestType },
                  },
                  {
                      text: 'House Cleaning',
                      icon: <FaBroom style={styles.smallIcon} />,
                      presets: { jobType: JobTypeIds.Housecleaning, frequency: 'onetime' as RequestType },
                  },
                  {
                      text: 'Other',
                      icon: <AddRounded style={styles.smallIcon} />,
                      presets: { jobType: JobTypeIds.Tutoring, frequency: 'onetime' as RequestType },
                  },
              ];

    const getItems = () => {
        return (
            <>
                <Grid
                    container
                    item
                    direction="column"
                    spacing={1}
                    style={{ alignItems: 'flex-end', justifyItems: 'flex-end', width: 175 }}
                >
                    {options.map((option: IOption) => (
                        <Grid
                            key={option.text}
                            container
                            item
                            direction="row"
                            style={{
                                alignItems: 'center',
                                cursor: 'pointer',
                                margin: 0,
                            }}
                            spacing={1}
                            onClick={() => onJobTypeClick(option)}
                        >
                            <Grid item xs={8}>
                                <Text
                                    variant={'body2'}
                                    bold
                                    textStyle={{ textAlign: 'right', color: Colors.turquoise }}
                                >
                                    {option.text}
                                </Text>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container alignItems="center" justify="center">
                                    {option.icon}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    };

    function onMainIconClick(event: any) {
        track(`Always Present Post a Job: expanded`);
        trackSession('CLICK', `Always Present Post a Job Button`, PublicSessionEventType.User);
        setAnchorEl(event.currentTarget);
        setPopoverOpen(true);
    }

    if (
        excludedLocations.some((excluded) => location.pathname.includes(excluded)) ||
        (role !== 'business' && role !== 'family' && role !== 'business_active')
    ) {
        return <></>;
    }

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    bottom: smallScreen ? 76 : 30,
                    right: smallScreen ? 10 : 30,
                    marginRight: 7,
                    height: 'fit-content',
                    display: 'flex',
                    placeItems: 'flex-end',
                    flexDirection: 'column',
                }}
                id="always-present-post-a-job"
            >
                <Grid
                    container
                    item
                    alignItems="center"
                    justify="center"
                    onClick={(event) => onMainIconClick(event)}
                    style={styles.mainIconContainer}
                >
                    <Grid container item alignItems="center" justify="center" style={styles.mainIcon}>
                        <FontAwesomeIcon icon={faCalendarPlus} style={{ width: 36, height: 36 }} color={Colors.white} />
                    </Grid>
                </Grid>
            </div>
            <Popover
                open={popOverOpen}
                onClose={() => setPopoverOpen(false)}
                anchorEl={anchorEl}
                transitionDuration={500}
                transformOrigin={{ horizontal: 102, vertical: options.length * 50 }}
                PaperProps={{
                    sx: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        overflow: 'hidden',
                    },
                }}
                sx={{
                    '& .MuiBackdrop-root': { backgroundColor: '#002F4Db3' },
                }}
            >
                {getItems()}
            </Popover>
        </>
    );
};

export default AlwaysPresentPostAJob;
