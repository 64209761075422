import React, { useEffect, useState } from 'react';
import { makeStyles, Tab } from '@material-ui/core';
import { Colors } from 'library';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ProviderProfileAboutTab from './about/ProviderProfileAboutTab';
import ProviderProfileExperienceTab from './experience/ProviderProfileExperienceTab';
import ProviderProfileSafetyTab from './safety/ProviderProfileSafetyTab';
import ProviderProfileReviewsTab from './reviews/ProviderProfileReviewsTab';
import SwipeableViews from 'react-swipeable-views';
import { useProviderProfileContext } from '../ProviderProfileContext';
import { track } from 'shared';
import { isLoggedIn } from 'api/UserApi';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';
import { useUserContext } from 'UserContext';
import ProviderProfileBusinessSummaryTab from './businessSummary/ProviderProfileBusinessSummaryTab';
import ProviderProfileBusinessCredentialsTab from './businessCredentials/ProviderProfileBusinessCredentialsTab';
import ProviderProfileBusinessExperienceTab from './businessExperience/ProviderProfileBusinessExperienceTab';
import BusinessDocumentsTab from './documents/BusinessDocumentsTab';

const useStyles = makeStyles(() => ({
    tabs: {
        '&.MuiButtonBase-root.MuiTab-root': {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'none',
            outline: 'none',
        },
        '&.MuiTab-textColorInherit': {
            color: Colors.darkNavy,
        },
        '&.Mui-selected': {
            color: Colors.turquoise,
        },
    },
    panels: {
        '&.MuiTabPanel-root': {
            padding: '20px 0px 20px 0px',
        },
    },
}));

export default function ProviderProfileTabs() {
    const [selectedTab, setSelectedTab] = useState('0');
    const classes = useStyles();
    const {
        pastExperience,
        provider,
        visibleProfilePrompts,
        childcareCenterQualifications,
    } = useProviderProfileContext();

    const { user, role } = useUserContext();

    const { trackSession } = usePublicSessionTracking('Provider Profile Tabs');

    const setTab = (_e: React.ChangeEvent<{}>, value: string) => {
        setSelectedTab(value);
        user
            ? track('Provider Profile Tab Selected', { tab: tabs[parseInt(value)].label })
            : trackSession('CLICK', 'Selected Provider Profile Tab', PublicSessionEventType.User, {
                  tabs: { previous: tabs[parseInt(selectedTab)].label, new: tabs[parseInt(value)].label },
              });
    };

    const [tabs, setTabs] = useState<{ label: string; component: React.ReactNode; show?: boolean }[]>([]);

    const getTabs = () => {
        const filteredTabs = [
            {
                label: 'About',
                component: <ProviderProfileAboutTab />,
                show: role !== 'business_active',
            },
            {
                label: 'Summary',
                component: <ProviderProfileBusinessSummaryTab />,
                show: role === 'business_active',
            },
            {
                label: 'Experience',
                component: <ProviderProfileExperienceTab />,
                show:
                    role !== 'business_active' &&
                    (pastExperience.length > 0 ||
                        (isLoggedIn() && !!provider.user.resume?.document) ||
                        childcareCenterQualifications.length > 0),
            },
            {
                label: 'Experience',
                component: <ProviderProfileBusinessExperienceTab />,
                show: role === 'business_active',
            },
            { label: 'Safety', component: <ProviderProfileSafetyTab />, show: role !== 'business_active' },
            {
                label: 'Credentials',
                component: <ProviderProfileBusinessCredentialsTab />,
                show: role === 'business_active',
            },
            {
                label: 'Reviews',
                component: <ProviderProfileReviewsTab />,
                show: (provider.user.reviews?.length || 0) > 0,
            },
            {
                label: 'Documents',
                component: <BusinessDocumentsTab />,
                show: role === 'business_active',
            },
        ].filter((tab) => tab.show);
        setTabs(filteredTabs);
    };

    useEffect(getTabs, [childcareCenterQualifications, pastExperience, visibleProfilePrompts, role]);
    useEffect(() => {
        if (parseInt(selectedTab) >= tabs.length) setSelectedTab('0');
    }, [tabs]);

    return (
        <TabContext value={selectedTab}>
            <TabList
                onChange={setTab}
                variant="scrollable"
                allowScrollButtonsMobile
                textColor="inherit"
                TabIndicatorProps={{ style: { backgroundColor: Colors.turquoise, height: 3 } }}
            >
                {tabs.map((tab, index) => (
                    <Tab key={tab.label} label={tab.label} value={index.toString()} className={classes.tabs} />
                ))}
            </TabList>
            <SwipeableViews
                index={parseInt(selectedTab)}
                onChangeIndex={(index: number) => setSelectedTab(index.toString())}
            >
                {tabs.map((tab, index) => (
                    <TabPanel key={tab.label} value={index.toString()} className={classes.panels}>
                        {tab.component}
                    </TabPanel>
                ))}
            </SwipeableViews>
        </TabContext>
    );
}
