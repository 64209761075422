import { addDays } from 'date-fns';
import { RadioGroup } from 'library';
import React, { useState } from 'react';
const distanceValues = { '5': '5 miles', '10': '10 miles', '15': '15 miles', '25': '25 miles' };
const dateValues = { today: 'today', tomorrow: 'tomorrow' };

export default function StandbyHeader({
    distance,
    setDistance,
    setDate,
}: {
    distance?: number;
    setDistance?: (d: number) => void;
    setDate: (s: string) => void;
}) {
    const includeDistance = !!setDistance && !!distance;
    const [dateString, setDateString] = useState<string>('today');
    function updateDate(val: string) {
        setDate((val === 'today' ? new Date() : addDays(new Date(), 1)).toISOString());
        setDateString(val);
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10 }}>
            {includeDistance ? (
                <RadioGroup
                    style={{ padding: 10, paddingBottom: 0 }}
                    horizontal
                    value={distance.toString()}
                    setValue={(val) => {
                        setDistance(parseInt(val));
                    }}
                    values={distanceValues}
                />
            ) : null}
            <RadioGroup
                style={{ padding: 10, paddingTop: 0 }}
                horizontal
                value={dateString}
                setValue={updateDate}
                values={dateValues}
            />
        </div>
    );
}
