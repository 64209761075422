import { PagingResult } from 'models';
import { AdminBusinessLocation, AdminBusinessLocationOption } from 'models/AdminBusinessLocation';
import { client } from 'shared';

export function getBusinessLocations(querystring?: string): Promise<PagingResult<AdminBusinessLocation>> {
    return client(querystring ? `internal/business-location/?${querystring}` : 'internal/business-location/');
}

export function refreshStatus(businessLocationId: number): Promise<AdminBusinessLocation> {
    return client(`internal/business-location/${businessLocationId}/refresh-status/`, { method: 'POST' });
}

export function getRelatedLocations(businessLocationId: number): Promise<AdminBusinessLocationOption[]> {
    return client(`internal/business-location/${businessLocationId}/related-locations/`);
}

export function saveSnooze(
    businessLocationId: number,
    snooze_until: string | null,
    snooze_reason: number | null,
    snooze_note_text?: string | null,
): Promise<AdminBusinessLocation> {
    return client(`internal/business-location/${businessLocationId}/`, {
        method: 'PATCH',
        body: { snooze_until, snooze_reason, snooze_note_text },
    });
}
