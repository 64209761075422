import React from 'react';
import { faMask } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from 'library';
import { AdminBusinessUser } from 'models';
import { client, consoleLogInDev } from 'shared';

export default function ImpersonateButton({
    user,
    email,
    size,
}: {
    user?: AdminBusinessUser;
    email?: string;
    size?: number;
}) {
    function impersonateUser() {
        const user_email = email || user?.email;
        client('api/auth/impersonate/', { body: { email: user_email } })
            .then((response) => {
                const oldToken = localStorage.getItem('token') || '';
                localStorage.setItem('old', oldToken);
                localStorage.setItem('token', response.token);
                window.open('/jobs', '_blank');
            })
            .catch(consoleLogInDev);
    }

    const iconSize = size
        ? {
              size,
              iconStyle: {
                  width: size - 6,
                  height: size - 6,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
              },
          }
        : {};

    return (
        <span style={{ marginLeft: 10 }}>
            <IconButton
                tooltip="Impersonate this business user"
                FontAwesomeImg={faMask}
                onClick={impersonateUser}
                {...iconSize}
            />
        </span>
    );
}
