import React, { useEffect, useState } from 'react';
import { Colors, Paper, Text } from 'library';
import { Divider, Grid } from '@material-ui/core';
import { client, consoleLogInDev, track } from 'shared';
import { Link } from 'react-router-dom';

const fixedFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

var percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
});

const DiscountBalance = () => {
    const [fixedDiscount, setFixedDiscount] = useState(0);
    const [percentDiscount, setPercentDiscount] = useState(0);

    const getDiscount = () => {
        client('payment/api/discount/unused')
            .then((discounts) => {
                setFixedDiscount(
                    discounts.fixed?.reduce((previous: number, current: number) => current + previous, 0) || 0,
                );
                setPercentDiscount(
                    discounts.percent?.reduce((previous: number, current: number) => Math.max(current, previous), 0) ||
                        0,
                );
            })
            .catch(consoleLogInDev);
    };

    useEffect(getDiscount, []);

    return (
        <Grid style={{ marginBottom: 10 }}>
            <Text bold variant="h1">
                Your discount balance
            </Text>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 16 }}>
                <div style={{ width: 'fit-content' }}>
                    <Text variant="display">{fixedFormatter.format(fixedDiscount)}</Text>
                    <Divider style={{ backgroundColor: Colors.darkNavy, height: 1, marginTop: 12, marginBottom: 8 }} />
                </div>
                {percentDiscount > 0 ? (
                    <>
                        <Text variant="body2" bold textStyle={{ marginLeft: 25 }}>
                            or
                        </Text>
                        <div style={{ width: 'fit-content', marginLeft: 25 }}>
                            <Text variant="display">{percentFormatter.format(percentDiscount)}</Text>
                            <Divider
                                style={{ backgroundColor: Colors.darkNavy, height: 1, marginTop: 12, marginBottom: 8 }}
                            />
                        </div>
                    </>
                ) : null}
            </div>
            <Text variant="body2" textStyle={{ fontSize: 12 }}>
                Your discount is automatically applied when you confirm payments. Don't worry, your provider will still
                receive the full amount - Tandem covers the difference!{' '}
                <Link
                    onClick={() => track('Payment Page: Get Referral')}
                    to="/referrals"
                    style={{ color: Colors.darkNavy, textDecoration: 'underline', fontWeight: 'bold' }}
                >
                    How do I get a discount?
                </Link>
            </Text>
        </Grid>
    );
};

export default DiscountBalance;
