import React, { useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import {
    AdminBusiness,
    Applicant,
    BusinessJob,
    Staff,
    Label,
    ObjectLabel,
    IInterview,
    AdminProvider,
    BusinessJobWithUser,
    ThreadProvider,
    AdminListProvider,
} from 'models';
import { Chip, IconButton, Text, UnformattedMenu } from 'library';
import { client, consoleLogInDev } from 'shared';
import { MenuItem } from '@mui/material';
import { AllLabelsKeys, useAdminAppContext } from '../AdminAppContext';
import AddLabelDialog from './AddLabelDialog';
import { ThreadData, IMessageThread } from '../../texting/outreach/OutreachModels';

const apiToContentTypeMap = {
    business: 'business',
    ongoing: 'ongoingrequest',
    applicants: 'applicant',
    interview: 'interview',
    'admin-providers': 'babysitter',
    'external-message': 'externalmessage',
    'external-message-thread': 'externalmessagethread',
};

export default function Labels({
    type,
    object,
}: {
    type: AllLabelsKeys;
    object:
        | AdminBusiness
        | BusinessJob
        | BusinessJobWithUser
        | Applicant
        | Staff
        | IInterview
        | AdminProvider
        | AdminListProvider
        | ThreadData
        | ThreadProvider
        | IMessageThread
        | undefined
        | null;
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const [labels, setLabels] = useState<ObjectLabel[]>([]);
    const { allLabels } = useAdminAppContext();

    const tracking = ['external-message', 'external-message-thread'].includes(type);
    let url = `api/${type}/${object?.id}/labels/`;
    if (tracking) {
        url = `tracking/api/${type}/${object?.id}/labels`;
    }

    const getLabels = () => {
        client(url).then(setLabels).catch(consoleLogInDev);
    };

    const addLabel = (label: Label) => {
        client(url, { body: { label: label.id } })
            .then(() => {
                setOpen(false);
                getLabels();
            })
            .catch(consoleLogInDev);
    };

    const deleteLabel = (objectLabel: ObjectLabel) => {
        client(url, { body: { label: objectLabel.label.id }, method: 'DELETE' })
            .then(() => {
                getLabels();
            })
            .catch(consoleLogInDev);
    };

    useEffect(getLabels, [object]);

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 300 }}>
                {labels.map((label) => (
                    <Chip
                        key={label.id}
                        style={{ marginRight: 4, minHeight: 24, marginBottom: 4 }}
                        label={label.label.text}
                        backgroundColor={label.label.color}
                        onDelete={() => deleteLabel(label)}
                    />
                ))}
                <div onClick={(event) => setAnchorEl(event.currentTarget)}>
                    <IconButton icon={Add} onClick={() => setOpen(true)} />
                </div>
            </div>
            <UnformattedMenu open={open} onClose={() => setOpen(false)} anchorEl={anchorEl}>
                {allLabels[type]?.map((label) => (
                    <MenuItem onClick={() => addLabel(label)} key={label.id} style={{ marginTop: 8 }}>
                        <Chip
                            style={{ marginRight: 4, minHeight: 24 }}
                            label={label.text}
                            backgroundColor={label.color}
                        />
                    </MenuItem>
                ))}
                <MenuItem onClick={() => setAddOpen(true)} style={{ marginTop: 8 }}>
                    <Text variant="body2">Add New...</Text>
                </MenuItem>
            </UnformattedMenu>
            <AddLabelDialog
                anchorEl={anchorEl}
                type={apiToContentTypeMap[type]}
                open={addOpen}
                onClose={() => setAddOpen(false)}
            />
        </>
    );
}
