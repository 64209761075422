import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import CenterCertified from 'assets/icons/center-certified.svg';
import { Colors, Text, PrimaryButton } from 'library';
import { Job } from 'models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleArrows, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import theme from 'theme';

interface BusinessJobSuccessPageProps {
    onClose: () => void;
    job?: Job;
}

export default function BusinessJobSuccessPage({ onClose, job }: BusinessJobSuccessPageProps) {
    return (
        <>
            <Text variant="display">Your request has been submitted!</Text>
            <Grid container direction="column">
                <Grid container>
                    <StepCard
                        image={
                            <FontAwesomeIcon
                                icon={faPeopleArrows}
                                style={{ width: '100%', height: '100%', color: Colors.darkNavy }}
                            />
                        }
                        imagePadding={10}
                        cardColor={Colors.lightBlue}
                        title="Matching"
                        body="Tandem has your job details and is working on finding workers to fill your needs."
                        active={true}
                    />
                    <StepCard
                        image={
                            <FontAwesomeIcon
                                icon={faCalendarDays}
                                style={{ width: '100%', height: '100%', color: Colors.darkNavy }}
                            />
                        }
                        imagePadding={10}
                        cardColor={Colors.lightTerraCotta}
                        title="Scheduling"
                        body="Keep an eye on your email for updates to your scheduled shifts. We will also send you all state-required documents for your booked workers."
                        caption={
                            <Text variant="caption">
                                You can check the schedule on the{' '}
                                <a
                                    href="/jobs"
                                    target="_blank"
                                    style={{ color: Colors.darkNavy, textDecoration: 'underline' }}
                                >
                                    calendar
                                </a>{' '}
                                at any time for the most up to date information.
                            </Text>
                        }
                    />
                    <StepCard
                        image={<CenterCertified style={{ width: '100%', height: '100%', color: Colors.darkNavy }} />}
                        cardColor={Colors.lightTurq}
                        title="Your Needs Filled"
                        body="Your Care Certified provider will arrive on the day of the job."
                        caption={
                            <Text variant="caption">
                                Print this{' '}
                                <a
                                    href="/clock-in"
                                    target="_blank"
                                    style={{ color: Colors.darkNavy, textDecoration: 'underline' }}
                                >
                                    QR code
                                </a>{' '}
                                to make it easier for them to track their hours.
                            </Text>
                        }
                    />
                </Grid>
                <Divider />
                <Grid container direction="row" justify="flex-end">
                    <PrimaryButton
                        buttonStyle={{ maxWidth: 250, margin: 5, marginBottom: 15 }}
                        onClick={onClose}
                        id="job-success-page-next-button"
                    >
                        Close
                    </PrimaryButton>
                </Grid>
            </Grid>
        </>
    );
}

function Divider() {
    return (
        <div style={{ marginTop: 25, marginBottom: 25, backgroundColor: Colors.darkNavy, height: 2, width: '100%' }} />
    );
}

interface IStepCardProps {
    title: string;
    body: string;
    caption?: React.ReactElement;
    cardColor: string;
    image: React.ReactElement;
    imagePadding?: number;
    active?: boolean;
}

function StepCard({ title, body, cardColor, image, imagePadding, caption, active }: IStepCardProps) {
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid item sm={4} style={{ padding: '30px 10px 10px 10px' }}>
            <div
                style={{
                    borderRadius: '0 0 10px 10px',
                    border: active ? `3px solid ${Colors.darkNavy}` : 'none',
                    backgroundColor: cardColor,
                    textAlign: 'center',
                    padding: 5,
                    height: isXs ? 240 : 300,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div>
                    <span
                        style={{
                            width: 50,
                            height: 50,
                            position: 'relative',
                            top: -25,
                            backgroundColor: Colors.white,
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: `3px solid ${Colors.darkNavy}`,
                            borderRadius: 50,
                            padding: imagePadding || 0,
                        }}
                    >
                        {image}
                    </span>
                    <Text variant="h2" bold textStyle={{ marginBottom: 5 }}>
                        {title}
                    </Text>
                </div>
                <Grid container direction="column" justify="space-between" style={{ height: 'inherit' }}>
                    <Text variant="body2">{body}</Text>
                    {caption}
                </Grid>
            </div>
        </Grid>
    );
}
