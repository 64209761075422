import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Colors, PrimaryButton, Text } from 'library';
import { track } from 'shared';
import ConnectPaymentModal from '../Components/ConnectPaymentModal';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';

export default function ConnectPayCard() {
    const [openPaySetupModal, setOpenPaySetupModal] = useState(false);
    const { trackSession } = usePublicSessionTracking('Connect Pay Card');

    const connectPayment = () => {
        track('Family opened payment setup modal', { fromRoute: 'TandemPayInfoScreen' });
        trackSession('CLICK', 'Family opened payment setup modal', PublicSessionEventType.User, {
            fromRoute: 'TandemPayInfoScreen',
        });
        setOpenPaySetupModal(true);
    };

    return (
        <Grid container item xs={12} direction="column" style={styles.card}>
            <Grid item style={{ paddingBottom: 12 }}>
                <Text variant="h1" textStyle={{ fontWeight: 'bolder', fontSize: 22 }}>
                    Connect the payment method that works best for you.
                </Text>
            </Grid>
            <Grid item>
                <Text variant="body2">Choose between connecting your credit card or bank account.</Text>
            </Grid>
            <Grid item md={6} xs={11} style={{ paddingTop: 12 }}>
                <PrimaryButton onClick={connectPayment}>Connect Payment</PrimaryButton>
            </Grid>
            <ConnectPaymentModal
                isOpen={openPaySetupModal}
                onClose={() => setOpenPaySetupModal(false)}
                fromRoute={'TandemPayInfoScreen'}
            />
        </Grid>
    );
}

const styles = {
    card: {
        backgroundColor: Colors.mediumGreen,
        padding: 32,
        borderRadius: 20,
        alignContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 3px 6px #00000029',
    },
};
