import { faPersonSkiing } from '@fortawesome/free-solid-svg-icons';
import { Grid, styled, useMediaQuery } from '@mui/material';
import AdminButton from 'internal/shared/AdminButton';
import { Colors, SizeableRoundedDialog, Tabs, Text } from 'library';
import { BusinessJobWithAppointments } from 'models';
import React, { useState } from 'react';
import BusinessApplicantMenu from './BusinessApplicantMenu';
import RebookingList from './RebookingList';
import MatchingList from './MatchingList';
import StandbyList from './StandbyList';
import theme from 'theme';
import WithBadge from './WithBadge';
import { addHours, formatISO } from 'date-fns';
import { formatMonthDayTime } from 'shared/Dates';

const classes = {
    tabs: {
        '&.MuiButtonBase-root.MuiTab-root': {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'none',
            outline: 'none',
        },
        '&.MuiTab-textColorInherit': {
            color: Colors.darkNavy,
        },
        '&.Mui-selected': {
            color: Colors.turquoise,
        },
    },
    panels: {
        '&.MuiTabPanel-root': {
            paddingTop: '20px',
            paddingBottom: '20px',
        },
    },
};

interface MatchingProps {
    job: BusinessJobWithAppointments;
    refreshJobs: () => void;
    week?: Date;
    withBadge?: boolean;
}

export default function Matching({ job, refreshJobs, week, withBadge = false }: MatchingProps) {
    const [applicantsOpen, setApplicantsOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState('0');
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const tabs = ['Applicants', 'Rebooking List', 'Matching', 'Standby'];
    const values = [
        <div
            key="Applicants"
            style={{
                visibility: selectedTab === '0' ? 'visible' : 'hidden',
                ...classes.panels,
            }}
        >
            <BusinessApplicantMenu job={job} refreshJobs={refreshJobs} open={selectedTab === '0'} week={week} />
        </div>,
        <div
            key="Rebooking List"
            style={{
                visibility: selectedTab === '1' ? 'visible' : 'hidden',
                ...classes.panels,
            }}
        >
            <RebookingList job={job} refreshJobs={refreshJobs} />
        </div>,
        <div
            key="Matching"
            style={{
                visibility: selectedTab === '2' ? 'visible' : 'hidden',
                ...classes.panels,
            }}
        >
            <MatchingList job={job} open={selectedTab === '2'} refreshJobs={refreshJobs} week={week} />
        </div>,
        <div
            key="Standby"
            style={{
                visibility: selectedTab === '3' ? 'visible' : 'hidden',
                ...classes.panels,
            }}
        >
            <StandbyList job={job} open={selectedTab === '3'} refreshJobs={refreshJobs} />
        </div>,
    ];

    const handleMatchingAdminButtonClick = () => {
        if (withBadge) {
            const oneHourFromNow = formatISO(addHours(new Date(), 1));
            const viewedJobs = JSON.parse(localStorage.getItem('viewedJobs') || '{}');

            viewedJobs[job.id] = { expiresAt: oneHourFromNow };
            localStorage.setItem('viewedJobs', JSON.stringify(viewedJobs));
        }

        setApplicantsOpen(!applicantsOpen);
    };

    const MatchingAdminButton = () => (
        <AdminButton
            onClick={handleMatchingAdminButtonClick}
            FontAwesomeImg={faPersonSkiing}
            color={Colors.lightBlue}
            borderColor={Colors.darkNavy}
            text="Matching"
        />
    );

    return (
        <>
            {withBadge ? (
                <WithBadge job={job}>
                    <MatchingAdminButton />
                </WithBadge>
            ) : (
                <MatchingAdminButton />
            )}
            <SizeableRoundedDialog
                open={applicantsOpen}
                style={{ padding: 10, backgroundColor: Colors.white, width: smDown ? '95%' : '60%' }}
                onClose={() => setApplicantsOpen(false)}
                closeButton
            >
                <div>
                    <AutbookReviewHeader>
                        <Text variant='h2' bold color={Colors.mediumNavy}>
                            {job.autobook_next_review  ? `Next autobook review at ${formatMonthDayTime(job.autobook_next_review)}` : 'No autobook review scheduled.'}
                        </Text>
                    </AutbookReviewHeader>
                    <Text bold>
                        {job.headline} ({job.id}) {job.address.description}
                    </Text>
                    <Text variant="body2">
                        {job.user.first_name} {job.user.last_name} ({job.user.id})
                    </Text>
                    <Tabs tabs={tabs} values={values} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                </div>
            </SizeableRoundedDialog>
        </>
    );
}

const AutbookReviewHeader = styled(Grid)({
    textAlign: "center",
    padding: 5
});
