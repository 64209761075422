import React, { useState, useEffect } from 'react';
import { Colors, UnformattedMenu } from 'library';
import { client, track } from 'shared';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu, { MenuProps } from '@mui/material/Menu';
import { styled } from '@material-ui/core';
import AccountMenuItem from 'parent-portal/navigation/AccountMenu/AccountMenuItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { UpcomingJobModel } from './nestedJobList/jobListModels';
import { useHistory } from 'react-router';
import { DuplicateJob } from './upcoming/DuplicateJob';
import { EditDraft } from './upcoming/EditDraft';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

interface IProps {
    jobId: number;
    past: boolean;
    review: boolean;
    oneTime: boolean;
    job?: UpcomingJobModel;
    provider?: any;
}

enum Options {
    duplicate = 'duplicate',
    refer = 'refer',
    review = 'review',
    deleteDraft = 'deleteDraft',
    editDraft = 'editDraft',
    closeOpen = 'closeOpen',
}
export const MoreOptions = ({ jobId, past, review, job, oneTime, provider }: IProps) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<Options[]>([]);
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
        let optionsToInclude = [
            past && Options.refer,
            review && Options.review,
            job?.status !== 'DRAFT' && Options.duplicate,
            job?.status !== 'DRAFT' && Options.closeOpen,
            job?.status === 'DRAFT' && Options.deleteDraft,
            job?.status === 'DRAFT' && Options.editDraft,
        ].filter((page) => page) as Options[];
        setOptions(optionsToInclude);
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
        track('Job Card more options clicked');
    };

    const Refer = () => {
        return (
            <AccountMenuItem
                icon={<MailOutlineIcon style={{ color: Colors.darkNavy }} />}
                text={`Refer ${provider?.user?.first_name}`}
                action={() => {
                    track('Job card more options: Refer provider');
                    history.push({ pathname: `/referrals/`, state: { sitter: provider } });
                }}
            />
        );
    };

    const DeleteDraft = () => {
        return (
            <AccountMenuItem
                icon={<DeleteOutlineIcon style={{ color: Colors.darkNavy }} id="more-options-delete-draft" />}
                text={`Delete Draft`}
                action={() => {
                    track('Job card more options: Delete draft');
                    client(`api/ongoing/${jobId}/delete-draft/`, { method: 'PATCH' }).then(() => {
                        window.location.reload();
                    });
                }}
            />
        );
    };

    const CloseOpenJob = () => {
        const closed = job?.is_closed;
        const tracking = `Job card more options: ${closed ? 'Job Opened' : 'Job Closed'} ${job?.id}`;
        const icon = closed ? (
            <LockOpenIcon style={{ color: Colors.darkNavy }} />
        ) : (
            <LockIcon style={{ color: Colors.darkNavy }} />
        );
        return (
            <AccountMenuItem
                icon={icon}
                text={`${closed ? 'Open' : 'Close'} Job`}
                action={() => {
                    track(tracking);
                    client(`api/upcoming-jobs/${jobId}/${closed ? 'open-job' : 'close-job'}/`, {
                        method: 'PATCH',
                    }).then(() => {
                        window.location.reload();
                    });
                }}
            />
        );
    };

    return (
        <div>
            <div onClick={handleClick} id="more-job-card-options">
                <MoreVertIcon style={{ cursor: 'pointer' }} />
            </div>
            <UnformattedMenu anchorEl={anchorEl} open={open} onClose={() => setOpen(false)}>
                {options.includes(Options.duplicate) ? (
                    <DuplicateJob jobId={jobId} onClose={() => setOpen(false)} />
                ) : null}
                {/* {options.includes(Options.review) ? <Review /> : null} */}
                {options.includes(Options.refer) ? <Refer /> : null}
                {options.includes(Options.closeOpen) ? <CloseOpenJob /> : null}
                {options.includes(Options.editDraft) ? (
                    <EditDraft jobId={jobId} onClose={() => setOpen(false)} />
                ) : null}
                {options.includes(Options.deleteDraft) ? <DeleteDraft /> : null}
            </UnformattedMenu>
        </div>
    );
};
