import React, { useEffect, useState } from 'react';
import JobFormPage from '../JobFormPage';
import { Text } from 'library';
import { FieldStates } from '../../FormTypes';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { JobFormFields } from 'parent-portal/forms/JobRequestTypes';
import BusinessJobDetails from './BusinessJobDetails';

export default function BusinessJobHeadlinePage({ fieldStates }: { fieldStates: FieldStates<JobFormFields> }) {
    const [isValid, setIsValid] = useState(false);
    const { goToNext } = useJobFormContext();
    const { headline, comments, jobDetails, trialRunBenefits, businessJobType } = fieldStates;

    useEffect(() => {
        setIsValid(headline.value !== '' && comments.value !== '');
    }, [headline, comments]);

    return (
        <JobFormPage
            title="Now, tell us a few details."
            subtitle="We recommend creating one job for each need you have. That will help the best fit worker be matched to the specific job they are fit for."
            onNextClicked={() => goToNext()}
            nextDisabled={!isValid}
        >
            <div style={{ marginTop: 30 }} />
            <BusinessJobDetails
                headline={headline}
                comments={comments}
                jobDetails={jobDetails}
                trialRunBenefits={trialRunBenefits}
                businessJobType={businessJobType}
            />
            <Text variant="caption">
                Only include job responsibilities here. We will ask you the rest of the job’s info on the next few
                screens!
            </Text>
        </JobFormPage>
    );
}
