import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { PrimaryButton, SizeableRoundedDialog, Text, TextButton } from 'library';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';
import useLoading from 'library/hooks/useLoading';
import { markNotWorked } from 'api/HourReportApi';

interface DidNotWorkButtonProps {
    name: string;
    hourReportId: number;
    onComplete: () => void;
}

export default function DidNotWorkButton({ name, hourReportId, onComplete }: DidNotWorkButtonProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <TextButton onClick={() => setIsModalOpen(true)}>{name} didn&apos;t work this shift</TextButton>
            <DidNotWorkModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onComplete={() => {
                    setIsModalOpen(false);
                    onComplete();
                }}
                name={name}
                hourReportId={hourReportId}
            />
        </>
    );
}

export function DidNotWorkModal({
    open,
    onClose,
    onComplete,
    name,
    hourReportId,
}: {
    open: boolean;
    onClose: () => void;
    onComplete: () => void;
    name: string;
    hourReportId: number;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const { loading, setLoading } = useLoading();
    function submit() {
        setLoading(true);
        markNotWorked(hourReportId)
            .then(onComplete)
            .finally(() => {
                setLoading(false);
                onClose();
            });
    }

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">Did {name} not work their shift?</Text>
                <Text>
                    We are so sorry for the inconvenience and take no shows very seriously. Tandem is constantly working
                    to improve the reliability of our workforce and will be following up with the worker on this
                    behavior.
                </Text>
                <PrimaryButton
                    disabled={loading}
                    loading={loading}
                    onClick={submit}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Confirm No Show
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
