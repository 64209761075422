import React, { useState, useEffect } from 'react';
import { BusinessJobWithAppointments } from 'models';
import { Text } from 'library';
import { Grid } from '@mui/material';
import JobAppointments from './JobAppointments';
import useLoading from 'library/hooks/useLoading';
import { getUpcomingShifts } from './UpcomingShiftsApi';

export default function UpcomingShifts({ providerUserId }: { providerUserId: number }) {
    const [upcomingJobs, setUpcomingJobs] = useState<BusinessJobWithAppointments[]>([]);
    const { loading, setLoading, LoadingSpinner } = useLoading();
    function refreshUpcomingShifts() {
        setLoading(true);
        getUpcomingShifts(providerUserId)
            .then(setUpcomingJobs)
            .catch(console.log)
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        refreshUpcomingShifts();
    }, []);

    if (loading) return <LoadingSpinner />;

    return (
        <Grid direction="row" container item gap={2}>
            <Text variant="h2" bold>
                Upcoming Shifts <Text>(next 7 days)</Text>
            </Text>
            <Grid container item direction="column" style={{ gap: 10 }}>
                {upcomingJobs.length > 0 ? (
                    upcomingJobs.map((job) => (
                        <JobAppointments key={job.id} job={job} refreshUpcomingShifts={refreshUpcomingShifts} />
                    ))
                ) : (
                    <Text>No upcoming jobs in the next 7 days</Text>
                )}
            </Grid>
        </Grid>
    );
}
