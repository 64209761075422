import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingButton from '../../../reusableComponents/loadingButton';
import OutlineDropdown from '../../home/components/shared/OutlineDropdown';
import CurrencyInput from '../../../reusableComponents/currencyInput';
import theme from '../../../theme';
import Link from '../../../reusableComponents/link';
import { formatMonthYearWith12HourTime, format12HourTime } from '../../../shared/Dates';
import { track, client } from 'shared';
import { useUserContext } from '../../../UserContext';
import HelpIcon from '@material-ui/icons/Help';
import { SimpleDialog } from '../../../reusableComponents/dialogsSimple';
import PayButton from './PayButton';
import ErrorMessage from './unpaid/ErrorMessage';
import {
    PaySitterProps,
    PaySitterError,
    PaySitterRequestObject,
    UserPaymentInformation,
    ProviderWithRelationship,
    PaySitterResponse,
    GroupPayObject,
    SimplePairingModel,
} from '../Models/PaySitter';
import { Colors } from '../../../constants';
import { Text } from 'library';
import { Environment } from 'environmentVariables';

const CATEGORIES = [
    { key: 'JOB', value: 'Job' },
    { key: 'TIP', value: 'Tip' },
    { key: 'REIMBURSEMENT', value: 'Reimbursement' },
];

const STATUSES = {
    neutral: 'NEUTRAL',
    loading: 'LOADING',
    submitting: 'SUBMITTING',
};

const PAGE_STEPS = {
    Edit: 'Edit',
    Success: 'Success',
    CheckGroupPay: 'CheckGroupPay',
};

const DefaultPaymentError = "Payment failed. Verify you weren't charged before trying again.";

const PaySitter = ({ modal, onPaymentComplete }: PaySitterProps) => {
    const [pageStatus, setPageStatus] = useState(STATUSES.neutral);
    const [pageState, setPageState] = useState(PAGE_STEPS.Edit);
    const [sitters, setSitters] = useState([]);
    const [selectedSitterId, setSelectedSitterId] = useState<number | undefined>();
    const [category, setCategory] = useState<string | undefined>();
    const [amount, setAmount] = useState<number | undefined>();
    const [errors, setErrors] = useState<PaySitterError>({});
    const [errorCount, setErrorCount] = useState(0);
    const [groupPay, setGroupPay] = useState<GroupPayObject | undefined>();
    const [displaySurchargeHelp, setDisplaySurchargeHelp] = useState(false);
    const { user } = useUserContext();

    useEffect(() => {
        getSitters();
    }, []);

    const getSitters = async () => {
        setPageStatus(STATUSES.loading);
        try {
            const apiSitters = await client('api/get-sitters-with-relationship/');

            setSitters(
                apiSitters.map((x: ProviderWithRelationship) => {
                    return { key: x.id.toString(), value: `${x.first_name} ${x.last_name}` };
                }),
            );
        } catch (error) {
            if (Environment !== 'production') {
                console.log(error);
            }
        } finally {
            setPageStatus(STATUSES.neutral);
        }
    };

    const sendPayment = async () => {
        if (pageStatus === STATUSES.submitting) {
            return;
        }

        if (pageState === PAGE_STEPS.Success) {
            setSelectedSitterId(undefined);
            setCategory(undefined);
            setAmount(0);
            setPageState(PAGE_STEPS.Edit);
            setErrorCount(0);
            return;
        }

        const requestObject: PaySitterRequestObject = {
            direct_payment_babysitter: selectedSitterId,
            total_pay: amount,
            direct_type: category,
        };

        setPageStatus(STATUSES.submitting);
        setErrors({});
        try {
            const res: PaySitterResponse = await paySitter(requestObject);

            if (res.isSuccess) {
                setErrorCount(0);
                if (onPaymentComplete) {
                    onPaymentComplete();
                }

                if (res.was_group_pay) {
                    // assume families have only 1 group pay for now
                    if (res.group_pays && res.group_pays.length) {
                        setGroupPay(res.group_pays[0]);
                        setPageState(PAGE_STEPS.CheckGroupPay);

                        track('Direct Pay: Show Complete Group Pay', { id: res.group_pays[0].id });
                    } else {
                        setPageState(PAGE_STEPS.Success);
                    }
                } else {
                    setPageState(PAGE_STEPS.Success);
                }
            } else {
                setErrors({ payment: res.message || DefaultPaymentError });
                setErrorCount((prevValue) => prevValue + 1);
            }
        } catch (error) {
            if (Environment !== 'production') {
                console.log(error);
            }
            setErrors({ payment: DefaultPaymentError });
            setErrorCount((prevValue) => prevValue + 1);
        } finally {
            setPageStatus(STATUSES.neutral);
        }
    };

    const paySitter = async (requestObject: PaySitterRequestObject) => {
        let response: PaySitterResponse = {
            isSuccess: false,
            message: DefaultPaymentError,
            group_pays: null,
            was_group_pay: false,
            status: 'none',
        };
        try {
            response = await client('pay-sitter/', { body: requestObject });
        } catch (error: unknown) {
            if (error instanceof Error) {
                response = {
                    isSuccess: false,
                    message: error.message || DefaultPaymentError,
                    group_pays: null,
                    was_group_pay: false,
                    status: 'error',
                };
            }
        }

        track('Pay Provider: Direct Pay', { status: response.status, requestObject, response });

        return response;
    };

    const onSitterChange = (e: any) => {
        setSelectedSitterId(e.target.value);
    };

    const onCategoryChange = (e: any) => {
        setCategory(e.target.value);
    };

    const sitterDropdown = () => {
        const success = pageState === PAGE_STEPS.Success;
        if (pageStatus === STATUSES.loading) {
            return <CircularProgress />;
        }
        return (
            <OutlineDropdown
                value={selectedSitterId ? selectedSitterId.toString() : ''}
                title={'Select Provider'}
                options={sitters}
                onChange={(e) => onSitterChange(e)}
                transparent={!modal}
                disabled={success}
            />
        );
    };

    const _amount = () => {
        const success = pageState === PAGE_STEPS.Success;
        return (
            <CurrencyInput
                textAlign="left"
                minimumValue="0"
                fullWidth
                value={amount}
                onChange={(e: any, value: number) => setAmount(value)}
                decimalPlaces={2}
                outputFormat="number"
                InputProps={{
                    style: {
                        fontSize: 50,
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                    },
                    inputProps: {
                        style: {
                            textAlign: 'center',
                        },
                    },
                    disableUnderline: true,
                    startAdornment: <Typography style={{ fontSize: 40, fontWeight: 'bold' }}>$</Typography>,
                }}
                placeholder="0.00"
                style={{ width: '80%' }}
                disabled={success}
            />
        );
    };

    const categoryDropdown = () => {
        const success = pageState === PAGE_STEPS.Success;
        return (
            <OutlineDropdown
                value={category || ''}
                title={'Select Category'}
                options={CATEGORIES}
                onChange={(e) => onCategoryChange(e)}
                transparent={!modal}
                disabled={success}
            />
        );
    };

    const button = () => {
        const success = pageState === PAGE_STEPS.Success;
        const button = success ? 'Payment Sent' : 'Pay';
        return (
            <PayButton
                disabled={success || !category || !selectedSitterId || !amount}
                loading={pageStatus === STATUSES.submitting}
                onClick={sendPayment}
            >
                {button}
            </PayButton>
        );
    };

    const pastSitters = () => {
        const success = pageState === PAGE_STEPS.Success;
        return (
            <Grid container direction="column" spacing={2} style={{ marginTop: 5 }}>
                <Grid container item justify="center">
                    <Grid item xs={12}>
                        {sitterDropdown()}
                    </Grid>
                </Grid>
                <Grid container item justify="center">
                    <Grid item xs={12}>
                        {categoryDropdown()}
                    </Grid>
                </Grid>
                {defaultPayIsCreditCard() && (
                    <Grid container item style={{ marginLeft: 30 }}>
                        <Grid item xs={7}>
                            <Typography
                                style={{
                                    fontSize: '.9em',
                                    color: Colors.navy,
                                    textAlign: 'left',
                                }}
                            >
                                *Credit card payments incur a 2.9% + $0.30 fee by our payment processor.
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <HelpIcon onClick={() => setDisplaySurchargeHelp(true)} style={{ paddingLeft: 5 }} />
                        </Grid>
                    </Grid>
                )}
                <Grid container item style={{ marginTop: 10, paddingRight: '2%' }} justify="flex-end">
                    <Grid container item lg={6} md={6} sm={6} xs={6} justify="center">
                        {button()}
                    </Grid>
                    {success && (
                        <Grid item lg={9} md={9} sm={11} xs={9}>
                            <Link onClick={sendPayment} component="button" color="primary" underline="always">
                                Send another payment
                            </Link>
                        </Grid>
                    )}
                </Grid>
                <ErrorMessage
                    errorMessage={errors?.payment}
                    errorCount={errorCount}
                    onModalClose={() => {
                        setErrorCount(0);
                        setErrors({});
                    }}
                />
            </Grid>
        );
    };

    const noPastSitters = () => {
        return (
            <Grid container justify="flex-start" xs={12} style={{ margin: 10 }}>
                <Text variant="body1">You have no past providers to pay.</Text>
            </Grid>
        );
    };

    const paymentComponent = () => {
        return (
            <Grid container justify="center">
                {sitters && sitters.length > 0 ? (
                    <>
                        <Grid
                            container
                            item
                            xs={12}
                            style={{
                                background: Colors.lightTurq,
                                borderRadius: 18,
                                paddingTop: 20,
                                paddingBottom: 20,
                            }}
                            justify="center"
                        >
                            {_amount()}
                        </Grid>
                        {pastSitters()}
                    </>
                ) : (
                    <>{noPastSitters()}</>
                )}
            </Grid>
        );
    };

    const handleGroupPayError = (error: any) => {
        track('Direct Pay: Complete Group Error', error);
        setErrors({
            payment:
                'There was an error completing your request. Try again, but if the problem persists, try to cancel them on the upcoming jobs screen or the payment dialog.',
        });
    };

    const onCompleteGroupPay = () => {
        setPageStatus(STATUSES.submitting);
        setErrors({});

        if (!groupPay) {
            handleGroupPayError('Group Pay object is undefined');
        } else {
            client(`api/ongoing/${groupPay.id}/complete-pending-pairings/`, { method: 'POST' })
                .then(() => {
                    setPageState(PAGE_STEPS.Success);
                    setGroupPay(undefined);
                    track('Direct Pay: Complete Group');

                    if (onCompleteGroupPay) {
                        onCompleteGroupPay();
                    }
                })
                .catch(handleGroupPayError)
                .finally(() => {
                    setPageStatus(STATUSES.neutral);
                });
        }
    };

    const onSkipClick = () => {
        setErrors({});
        setPageState(PAGE_STEPS.Success);
        setGroupPay(undefined);
        track('Direct Pay: Skip Complete Group');
    };

    const possibleGroupPayContent = (groupPayItem?: GroupPayObject) => {
        return (
            <>
                {!!groupPayItem ? (
                    <Grid container justify="center">
                        <Grid container item justify="center" xs={12}>
                            <Grid item xs={12}>
                                <Text variant="body1">
                                    Your payment was successful. We noticed that you have a weekly pay coming up for the
                                    following appointments. If this payment was intended for those appointments press OK
                                    to mark them as paid. You will not be charged again.
                                </Text>
                            </Grid>
                            <Grid container item style={{ padding: '1em' }}>
                                {groupPayItem.pairings.map((x: SimplePairingModel) => (
                                    <Grid key={x.id} container item direction="column" xs={6} alignItems="center">
                                        <Text variant="body2">
                                            {formatMonthYearWith12HourTime(x.appointment.start_date)} -{' '}
                                            {format12HourTime(x.appointment.end_date)}
                                        </Text>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container direction="row" justify="space-evenly">
                                <Grid container item xs={5} direction="column" alignItems="center">
                                    <LoadingButton
                                        disabled={pageStatus === STATUSES.submitting}
                                        loading={pageStatus === STATUSES.submitting}
                                        disableRipple
                                        disableFocusRipple
                                        noMinWidth
                                        onClick={onSkipClick}
                                        style={{ marginBottom: 10, backgroundColor: Colors.coral }}
                                        asSecondary={true}
                                    >
                                        Do Nothing
                                    </LoadingButton>
                                </Grid>
                                <Grid container item xs={5} direction="column" alignItems="center">
                                    <LoadingButton
                                        disabled={pageStatus === STATUSES.submitting}
                                        loading={pageStatus === STATUSES.submitting}
                                        disableRipple
                                        disableFocusRipple
                                        noMinWidth
                                        onClick={onCompleteGroupPay}
                                        style={{ marginBottom: 10 }}
                                    >
                                        OK
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
            </>
        );
    };

    const renderContent = () => {
        switch (pageState) {
            case PAGE_STEPS.Edit:
                return paymentComponent();
            case PAGE_STEPS.Success:
                return paymentComponent();
            case PAGE_STEPS.CheckGroupPay:
                return possibleGroupPayContent(groupPay);
            default:
                break;
        }
    };

    const defaultPayIsCreditCard = () => {
        const defaultCreditCard = (element: UserPaymentInformation) =>
            element.payment_type === 'CREDIT_CARD' && element.is_default;
        return user?.payment_information.some(defaultCreditCard);
    };

    const hasCheckingPaymentMethod = () => {
        const hasChecking = (element: UserPaymentInformation) => element.payment_type !== 'CREDIT_CARD';
        return user?.payment_information.some(hasChecking);
    };

    const getHowToAvoidSurchargeText = () => {
        let start = 'You can avoid the credit card surcharge by ';
        if (hasCheckingPaymentMethod()) {
            return start + 'changing your default payment method to your bank account.';
        }
        return start + 'connecting a bank account.';
    };

    const getHelpDialogText = () => {
        return (
            <Grid container>
                <Typography style={{ color: theme.palette.primary.main }}>
                    Credit card payments incur a 2.9% + $0.30 fee by our payment processor. Alternatively, bank account
                    payments incur no surcharge.
                </Typography>
                <Typography style={{ color: theme.palette.primary.main }}>{getHowToAvoidSurchargeText()}</Typography>
            </Grid>
        );
    };

    return (
        <>
            <SimpleDialog
                isOpen={displaySurchargeHelp}
                dialogTitle={'Why am I experiencing a surcharge?'}
                children={getHelpDialogText()}
                onClose={() => setDisplaySurchargeHelp(false)}
            />
            <Grid item style={{ marginTop: modal ? 15 : 0, marginBottom: modal ? 0 : 15 }}>
                {renderContent()}
            </Grid>
        </>
    );
};

export default PaySitter;
