import React from 'react';
import { ProviderJobType } from 'models/ProviderJobType';
import { Colors, ExpandablePaper, PrimaryButton, Text } from 'library';
import { Provider } from 'models';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';
import { isLoggedIn } from 'api/UserApi';
import { track } from 'shared';

export default function JobTypeAvailability({
    provider,
    jobTypeInterest,
    goToJobTypeProfile,
    last = false,
}: {
    provider: Provider;
    jobTypeInterest: ProviderJobType;
    goToJobTypeProfile: (profile: ProviderJobType, location?: string) => void;
    last?: boolean;
}) {
    const { trackSession } = usePublicSessionTracking('Job Type Profile Description');

    function trackToggle(expanded: boolean) {
        const trackingData = {
            providerId: provider.id,
            jobTypeId: jobTypeInterest.job_type.id,
            expanded,
        };

        !isLoggedIn()
            ? trackSession('CLICK', 'Toggled job type', PublicSessionEventType.User, trackingData)
            : track('Toggled job type', trackingData);
    }

    return (
        <ExpandablePaper
            style={{
                backgroundColor: Colors.lightTurq,
                marginBottom: last ? 0 : 18,
            }}
            header={jobTypeInterest.job_type.name}
            onToggle={trackToggle}
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text variant="body1" textStyle={{ marginBottom: 8 }}>
                    ${jobTypeInterest.hourly_rate || provider.pay_preference_min}+/hr
                </Text>
                <Text variant="body2">{jobTypeInterest.description}</Text>
                <PrimaryButton
                    onClick={() => goToJobTypeProfile(jobTypeInterest, 'Available For Section')}
                    buttonStyle={{ maxWidth: 160, marginTop: 12, alignSelf: 'flex-end' }}
                >
                    View Profile
                </PrimaryButton>
            </div>
        </ExpandablePaper>
    );
}
