import { client } from 'shared';

export function getHourReports(pageNumber?: number) {
    return client(`api/hour-report/?page=${pageNumber ? pageNumber : 1}`);
}

export function getHourReport(id: number) {
    return client(`api/hour-report/${id}/`);
}

export function markNotWorked(id: number) {
    const requestBody = {
        business_closed_not_worked: new Date().toISOString(),
    };
    return client(`api/hour-report/${id}/`, { body: requestBody, method: 'PATCH' });
}
