import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, Colors, IconButton, OutlinedDropdown, Text } from 'library';
import React, { useEffect, useState } from 'react';
import { TaskListItem, TaskListSearchFilters } from './matchingInboxModels';
import { getTaskList } from './matchingInboxApi';
import { Grid, TablePagination } from '@mui/material';
import { PagingResult } from 'models';
import { GroupedTasks } from './components';
import TaskForm, { TaskFormOptions } from './TaskForm';
import { getTaskFormOptions } from './TaskFormFields';
import { AdminBusinessLocationOption } from 'models/AdminBusinessLocation';
import { groupBy, orderBy } from 'lodash';

export default function TaskList({
    isOpen,
    businessLocation,
    addTask = false,
}: {
    isOpen: boolean;
    businessLocation?: AdminBusinessLocationOption;
    addTask?: boolean;
}) {
    const [tasks, setTasks] = React.useState<PagingResult<TaskListItem>>({
        results: [],
        count: 0,
        per_page: 0,
        num_pages: 0,
    });
    const [orderedLocations, setOrderedLocations] = React.useState<string[]>([]);
    const [searchFilters, setSearchFilters] = React.useState<TaskListSearchFilters>({
        page: 1,
        inbox: '',
        page_size: 10,
        business_location: businessLocation?.id,
        include_resolved: false,
    });
    const [isAdding, setIsAdding] = React.useState(addTask);
    const [options, setOptions] = useState<TaskFormOptions>({
        inboxes: [],
        types: [],
        default_inbox: null,
        businessLocation: businessLocation,
    });
    const [editItem, setEditItem] = React.useState<TaskListItem>();

    useEffect(() => {
        getTaskFormOptions().then((result) => {
            setSearchFilters({
                ...searchFilters,
                inbox: result.default_inbox?.key || '',
            });
            setOptions({ ...result, businessLocation: businessLocation });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isOpen) {
            getTaskList({ ...searchFilters, business_location: businessLocation?.id }).then(
                (results: PagingResult<TaskListItem>) => {
                    setTasks(results);
                    setOrderedLocations(results.results.map((task) => (task.business_location?.id ?? 0).toString()));
                },
            );
            if (businessLocation?.id !== options.businessLocation?.id) {
                setOptions({ ...options, businessLocation: businessLocation });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, searchFilters, businessLocation]);

    function refresh() {
        getTaskList({ ...searchFilters, business_location: businessLocation?.id }).then(setTasks);
    }

    function onEdit(item: TaskListItem) {
        setEditItem(item);
    }

    function onShowCompletedChange(val: boolean) {
        setSearchFilters((prev) => {
            return {
                ...prev,
                include_resolved: val,
            };
        });
    }

    const groupedTasks = orderBy(
        Object.entries(groupBy(tasks?.results, (task) => task.business_location?.id ?? '0')),
        (locationTasks: [string, TaskListItem[]]) => orderedLocations.indexOf(locationTasks[0]),
    );

    return (
        <Grid justifyContent="space-between">
            {isAdding || Boolean(editItem) ? (
                <TaskForm
                    options={options}
                    onClose={() => {
                        setIsAdding(false);
                        setEditItem(undefined);
                        refresh();
                    }}
                    inbox={options.inboxes.find((i) => i.key === searchFilters.inbox)}
                    editItem={editItem}
                />
            ) : (
                <>
                    <Grid>
                        <Grid container justifyContent="space-between">
                            <OutlinedDropdown
                                label="Assigned to"
                                onChange={(e) => setSearchFilters({ ...searchFilters, inbox: e.target.value })}
                                value={searchFilters.inbox}
                                fields={options.inboxes}
                                style={{ width: 200 }}
                            />
                            <Checkbox
                                label="Show Completed"
                                checked={searchFilters.include_resolved || false}
                                onChange={onShowCompletedChange}
                                style={{ width: undefined }}
                            />
                            <IconButton
                                tooltip="Add Task"
                                onClick={() => setIsAdding(!isAdding)}
                                FontAwesomeImg={faCalendarPlus}
                                color={Colors.white}
                                backgroundColor={Colors.mediumNavy}
                                iconStyle={styles.icon}
                                style={styles.iconButton}
                            />
                        </Grid>
                        {tasks.results.length > 0 ? (
                            groupedTasks.map(([key, taskGroup]) => (
                                <GroupedTasks
                                    key={key}
                                    group={key}
                                    tasks={taskGroup}
                                    refresh={refresh}
                                    onEdit={onEdit}
                                />
                            ))
                        ) : (
                            <Text>You have finished everything! 💪</Text>
                        )}
                    </Grid>
                    <TablePagination
                        component="div"
                        count={tasks?.count || 0}
                        rowsPerPage={tasks?.per_page || 0}
                        page={searchFilters.page - 1}
                        onPageChange={(_, page) => setSearchFilters({ ...searchFilters, page: page + 1 })}
                        rowsPerPageOptions={[]}
                    />
                </>
            )}
        </Grid>
    );
}

const styles = {
    iconButton: {
        marginBottom: 20,
    },
    icon: {
        marginTop: 5,
    },
};
