import React, { useState } from 'react';
import { Popper } from '@mui/material';
import { Text, Colors, TextButton } from 'library';
import { IReportHoursSummary, PagingResult, SubJobAppointment, SubJobPairing } from 'models';
import { formatMonthYearWith12HourTime } from 'shared/Dates';
import { client, consoleLogInDev } from 'shared';

export default function ProviderForSubJobDropdown({
    visible,
    appt,
    pairing,
    anchorEl,
}: {
    visible: boolean;
    appt: SubJobAppointment;
    pairing: SubJobPairing;
    anchorEl: HTMLElement | null;
}) {
    const [hoursWorked, setHoursWorked] = useState('');

    const user = pairing.hired_provider;
    const approvedTimes = appt.time_changes_requested.find((chg) => chg.appointment === appt.id);
    const hiredByName = () => {
        if (pairing.hired_by) {
            return `${pairing.hired_by.first_name} ${pairing.hired_by.last_name}`;
        }
        return 'Unknown';
    };
    const getHours = (userId: number, bizId: number) => {
        if (hoursWorked) {
            return;
        }

        client(`api/hour-report/summary/?user=${userId}&business_location=${bizId}`)
            .then((response: PagingResult<IReportHoursSummary>) => {
                let hours = '0';
                if (response.results.length) {
                    hours = (response.results[0]?.duration_in_seconds / 3600).toFixed(2);
                }
                setHoursWorked(hours);
            })
            .catch(consoleLogInDev);
    };

    return (
        <Popper
            open={visible}
            anchorEl={anchorEl}
            style={{ backgroundColor: Colors.white, padding: 10, border: 'solid black 1px' }}
        >
            <Text variant="body2" bold>
                {user.first_name} {user.last_name}
            </Text>
            <Text variant="body2">
                Hired by: {hiredByName()}
            </Text>
            <Text variant="body2">Pairing status: {pairing.status}</Text>
            {pairing.provider_confirmed_at && (
                <Text variant="body2">
                    Confirmed at: {formatMonthYearWith12HourTime(pairing.provider_confirmed_at)}
                </Text>
            )}
            {pairing.hour_report?.start && (
                <Text variant="body2">Clocked in at: {formatMonthYearWith12HourTime(pairing.hour_report.start)}</Text>
            )}
            {pairing.hour_report?.end && (
                <Text variant="body2">Clocked out at: {formatMonthYearWith12HourTime(pairing.hour_report.end)}</Text>
            )}
            <Text variant="body2">Approved pay: ${pairing.pay_rate || approvedTimes?.change_request?.pay}</Text>
            {approvedTimes?.change_request?.bring_child && <Text variant="body2">Bringing child</Text>}
            {approvedTimes?.change_request?.comment && (
                <Text variant="body2">Business/admin comment: {approvedTimes.change_request?.comment}</Text>
            )}
            {Boolean(pairing.last_notified) && (
                <Text variant="body2">Emailed: {formatMonthYearWith12HourTime(pairing.last_notified)}</Text>
            )}
            {hoursWorked && <Text variant="body2">Hours: {hoursWorked}</Text>}
            {!hoursWorked && (
                <TextButton onClick={() => getHours(pairing.hired_provider.id, pairing.staff.business_location)}>
                    Load Hours
                </TextButton>
            )}
        </Popper>
    );
}
