import React from 'react';
import { Grid, Tooltip, IconButton } from '@material-ui/core';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Text } from 'library';
import { PagingResult, AdminListProvider } from 'models';

export default function MatchingListSummary({
    matchingList,
    includedLabels,
    excludedLabels,
}: {
    matchingList: AdminListProvider[] | undefined;
    includedLabels: string[];
    excludedLabels: string[];
}) {
    return (
        <Grid container style={{ alignItems: 'center', padding: '0 15px 0 15px' }}>
            <Text variant="h1">
                {matchingList?.length} <Text inline>matches found</Text>
            </Text>
            <Tooltip
                title={
                    <Text color="white">
                        Providers with labels: {includedLabels.join(', ')} and without labels:
                        {excludedLabels.join(', ')}`
                    </Text>
                }
            >
                <IconButton>
                    <QuestionMarkIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Grid>
    );
}
