import React, { useEffect, useState } from 'react';
import { Text, PrimaryButton, BasicDialog } from 'library';
import { Grid, MenuItem, Select } from '@material-ui/core';
import { consoleLogInDev, track } from 'shared';
import { UpcomingJobModel } from 'parent-portal/jobs/nestedJobList/jobListModels';
import KeepJobOpen from 'parent-portal/sitters/ProviderPreview/Components/HireProviderDialog/components/KeepJobOpen';
import { providerOnStaff, toggleProviderStaffStatus } from '../../../../shared/ManageStaffApi';
import { hireProviderForJob, upcomingJobs } from 'shared/JobApi';
import PaySlider from 'parent-portal/sitters/ProviderPreview/Components/HireProviderDialog/components/PaySlider';

interface DropDownField {
    name?: string;
    value: number;
}

export default function ManageStaffDialog({ open, setOpen, providerName, fullScreen, providerIdObject }: any) {
    const generalStaff = { name: 'General Staff', value: -1 };
    const [selectedJob, setSelectedJob] = useState<number>();
    const [keepJobOpen, setKeepJobOpen] = useState<'Yes' | 'No'>('Yes');
    const [dropDownJobs, setDropDownJobs] = useState<DropDownField[]>([]);
    const [jobs, setJobs] = useState<UpcomingJobModel[]>([]);
    const [onStaff, setOnStaff] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mounted, setMounted] = useState(true);
    const [payValue, setPayValue] = useState<number>(0);

    useEffect(() => {
        checkIfOnStaff();
        updateJobs();
        return () => {
            setMounted(false);
        };
    }, []);

    function closeModalWithNoAction() {
        track('Manage Staff modal closed with no action');
        setOpen(false);
        setSelectedJob(undefined);
    }

    function updateJobs() {
        upcomingJobs()
            .then((jobsResponse) => {
                if (mounted) {
                    setJobs(jobsResponse);
                    setDropDownJobs(
                        jobsResponse
                            .filter((j: UpcomingJobModel) => j.is_job_available)
                            .map((j: UpcomingJobModel) => {
                                return { name: j.headline, value: j.id };
                            }),
                    );
                }
            })
            .catch(consoleLogInDev);
    }

    function hireStaff() {
        let body = {
            user_id: providerIdObject.user.id,
            keep_job_open: keepJobOpen === 'Yes',
            pay_rate: payValue,
        };
        if (selectedJob) {
            hireProviderForJob(body, selectedJob).catch(consoleLogInDev).finally(actionCleanUp);
        }
    }

    function toggleStaff() {
        toggleProviderStaffStatus(providerIdObject.user.id)
            .then((res) => {
                if (mounted) setOnStaff(res.on_staff);
            })
            .catch(consoleLogInDev)
            .finally(actionCleanUp);
    }

    function actionCleanUp() {
        if (mounted) {
            setLoading(false);
            setOpen(false);
            setSelectedJob(undefined);
            updateJobs();
        }
    }

    function checkIfOnStaff() {
        setLoading(true);
        providerOnStaff(providerIdObject.user.id).then((res) => {
            if (mounted) {
                setOnStaff(res.on_staff);
                if (!res.on_staff) setSelectedJob(generalStaff.value);
                setLoading(false);
            }
        });
    }

    function confirmOnStaff() {
        if (selectedJob !== generalStaff.value) {
            hireStaff();
        } else if (!onStaff) {
            toggleStaff();
        } else {
            actionCleanUp();
        }
    }

    function modalHeadline() {
        return onStaff
            ? `${providerName} is on your general staff. Hire for an existing job or remove from staff using the options below.`
            : `Hire for an existing job or add ${providerName} to your general staff.`;
    }

    const staffOptions = onStaff ? dropDownJobs : [...dropDownJobs, generalStaff];
    const selectedJobObject = jobs.find((j: any) => j.id === selectedJob);

    return (
        <BasicDialog isOpen={open} onClose={closeModalWithNoAction} dialogTitle="Manage Staff" closeButton>
            <Grid container item style={{ minHeight: '30vh' }}>
                <Text variant="body1">{modalHeadline()}</Text>
                <Grid container item direction="column">
                    <Select
                        value={selectedJob}
                        onChange={(e: any) => setSelectedJob(parseInt(e.target.value))}
                        label={'Selected Staff List'}
                        style={{ marginBottom: 20 }}
                    >
                        {staffOptions.map((j) => (
                            <MenuItem key={j.name} value={j.value}>
                                {j.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {!!selectedJob && selectedJob !== generalStaff.value && !!selectedJobObject ? (
                        <Grid container item>
                            <PaySlider job={selectedJobObject} onPayChange={setPayValue} payValue={payValue} />
                            <KeepJobOpen
                                style={{ padding: 0 }}
                                keepJobOpen={keepJobOpen}
                                setKeepJobOpen={setKeepJobOpen}
                            />
                        </Grid>
                    ) : null}
                </Grid>
                <Grid container justify="flex-end" style={{ maxHeight: 40 }}>
                    {!onStaff || (onStaff && selectedJob !== generalStaff.value) ? (
                        <PrimaryButton
                            loading={loading}
                            disabled={!selectedJob}
                            onClick={confirmOnStaff}
                            buttonStyle={{ width: 150 }}
                        >
                            Confirm
                        </PrimaryButton>
                    ) : null}
                </Grid>
                {onStaff ? (
                    <Grid
                        xs={12}
                        container
                        item
                        direction="row"
                        style={{ gap: 10, alignSelf: 'flex-end', marginTop: 20 }}
                    >
                        <Text variant="body2">
                            {providerName} is currently on your general staff, you can remove them here.
                        </Text>
                        <Text
                            variant="body2"
                            onClick={toggleStaff}
                            textStyle={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                        >
                            Remove
                        </Text>
                    </Grid>
                ) : null}
            </Grid>
        </BasicDialog>
    );
}
