import React, { useState, useEffect } from 'react';
import { IProviderReport } from '../models';
import { PrimaryButton, SizeableRoundedDialog, Text } from 'library';
import { Grid } from '@material-ui/core';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { approveReport } from '../HourReportMethods';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';

// eslint-disable-next-line import/named
import moment, { Moment } from 'moment';

export default function ChangeModal({
    changeReport,
    setChangeReport,
    updateHoursReported,
    fullScreen,
}: {
    changeReport: IProviderReport;
    setChangeReport: (r: IProviderReport | undefined) => void;
    updateHoursReported: () => void;
    fullScreen: boolean;
}) {
    const [loading, setLoading] = useState(false);

    const [breakStartErr, setBreakStartErr] = useState('');
    const [breakEndErr, setBreakEndErr] = useState('');
    const [shiftStartErr, setShiftStartErr] = useState('');
    const [shiftEndErr, setShiftEndErr] = useState('');

    const initialBreakStart = changeReport.business_break_start ?? changeReport.provider_break_start;
    const initialBreakEnd = changeReport.business_break_end ?? changeReport.provider_break_end;

    const [breakStart, setBreakStart] = useState<Moment | null>(initialBreakStart ? moment(initialBreakStart) : null);
    const [breakEnd, setBreakEnd] = useState<Moment | null>(initialBreakEnd ? moment(initialBreakEnd) : null);

    const initialShiftStart = changeReport.business_clock_in ?? changeReport.start;
    const initialShiftEnd = changeReport.business_clock_out ?? changeReport.end;

    const [shiftEnd, setShiftEnd] = useState<Moment | null>(initialShiftEnd ? moment(initialShiftEnd) : null);
    const [shiftStart, setShiftStart] = useState<Moment | null>(initialShiftStart ? moment(initialShiftStart) : null);

    useEffect(() => {
        validateShiftStart();
        validateShiftEnd();
        validateBreakStart();
        validateBreakEnd();
    }, [shiftStart, shiftEnd, breakStart, breakEnd]);

    const validateBreakStart = () => {
        if (!breakStart) return;
        const start = moment(breakStart);
        setBreakStartErr('');

        if (shiftEnd && start.isAfter(shiftEnd)) {
            setBreakStartErr("can't be after the end time");
            return;
        }
        if (start.isBefore(shiftStart)) {
            setBreakStartErr("can't be before the start time");
            return;
        }
        if (start.isAfter(breakEnd)) {
            setBreakStartErr("can't be after the break end time");
            return;
        }
        if (start && !breakEnd) {
            setBreakEndErr('is required if break start is set');
            return;
        }
        setBreakStartErr('');
    };

    const validateBreakEnd = () => {
        if (!breakEnd) return;

        const end = moment(breakEnd);

        setBreakEndErr('');

        if (shiftStart && end.isBefore(shiftStart)) {
            setBreakEndErr("can't be before the start time");
            return;
        }
        if (end.isAfter(shiftEnd)) {
            setBreakEndErr("can't be after the end time");
            return;
        }
        if (end.isBefore(breakStart)) {
            setBreakEndErr("can't be before the break start time");
            return;
        }
        setBreakEndErr('');
    };

    const validateShiftEnd = () => {
        if (!shiftEnd) return;

        const end = moment(shiftEnd);
        setShiftEndErr('');

        if (end.isSame(shiftStart)) {
            setShiftEndErr("can't be the same as the start time");
            return;
        }
        if (end.isBefore(shiftStart)) {
            setShiftEndErr("can't be before the shift start time");
            return;
        }
        if (breakStart && end.isBefore(breakStart)) {
            setShiftEndErr("can't be before the break start time");
            return;
        }
        if (breakEnd && end.isBefore(breakEnd)) {
            setShiftEndErr("can't be before the break end time");
            return;
        }
        setShiftEndErr('');
    };

    const validateShiftStart = () => {
        if (!shiftStart) return;

        const start = moment(shiftStart);
        setShiftStartErr('');

        if (start.isSame(shiftEnd)) {
            setShiftStartErr("can't be the same as the end time");
            return;
        }
        if (shiftEnd && start.isAfter(shiftEnd)) {
            setShiftStartErr("can't be after the end time");
            return;
        }
        if (breakStart && start.isAfter(breakStart)) {
            setShiftStartErr("can't be after the break start time");
            return;
        }
        if (breakEnd && start.isAfter(breakEnd)) {
            setShiftStartErr("can't be after the break end time");
            return;
        }
        setShiftStartErr('');
    };

    const hasTimestamps = changeReport.provider_break_end || changeReport.provider_break_start;

    const breakText =
        changeReport.break > 0
            ? `Provider reported a ${changeReport.break} minute break, if this is incorrect use the
    selection below to update the times.`
            : 'Provider did not report a break, if they took a break use the selection below to update the times.';

    async function submitChangeRequest() {
        if (changeReport) {
            setLoading(true);
            const body = {
                business_clock_in: shiftStart ? shiftStart.toDate() : undefined,
                business_clock_out: shiftEnd ? shiftEnd.toDate() : undefined,
                business_break_start: breakStart ? breakStart.toDate() : undefined,
                business_break_end: breakEnd ? breakEnd.toDate() : undefined,
            };
            approveReport(changeReport.id, body)
                .then(() => {
                    setChangeReport(undefined);
                })
                .finally(updateHoursReported);
        }
    }

    return (
        <SizeableRoundedDialog
            open={!!changeReport}
            onClose={() => setChangeReport(undefined)}
            fullScreen={fullScreen}
            closeButton
            maxWidth="lg"
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 20 }}>
                <Text variant="h1">
                    Change {changeReport?.providerName}&rsquo;s hours for{' '}
                    {formatShortWeekdayMonthDay(changeReport.start ?? new Date())}
                </Text>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                        value={shiftStart}
                        style={{ maxWidth: 200 }}
                        onChange={(d: MaterialUiPickersDate) => {
                            if (d) setShiftStart(moment(d));
                        }}
                        helperText={`Start Time ${shiftStartErr}`}
                        error={!!shiftStartErr}
                    />
                    <TimePicker
                        value={shiftEnd}
                        style={{ maxWidth: 200 }}
                        onChange={(d: MaterialUiPickersDate) => {
                            if (d) setShiftEnd(moment(d));
                        }}
                        helperText={`End Time ${shiftEndErr}`}
                        error={!!shiftEndErr}
                    />
                    <Text>{breakText}</Text>
                    {!hasTimestamps && changeReport.break > 0 ? (
                        <Text variant="caption">
                            Provider reported this break as we transitioned to the new process for clocking in and out
                            for breaks so please excuse if the timestamps are not reflective of the exact times the
                            provider may have taken a break.
                        </Text>
                    ) : null}
                    <TimePicker
                        value={breakStart}
                        style={{ maxWidth: 200 }}
                        onChange={(d: MaterialUiPickersDate) => {
                            if (d) setBreakStart(moment(d));
                        }}
                        helperText={`Break Start ${breakStartErr}`}
                        error={!!breakStartErr}
                    />
                    <TimePicker
                        value={breakEnd}
                        style={{ maxWidth: 200 }}
                        onChange={(d: MaterialUiPickersDate) => {
                            if (d) setBreakEnd(moment(d));
                        }}
                        helperText={`Break End ${breakEndErr}`}
                        error={!!breakEndErr}
                    />
                </MuiPickersUtilsProvider>
                <PrimaryButton
                    disabled={loading || !!breakStartErr || !!breakEndErr || !!shiftEndErr || !!shiftStartErr}
                    onClick={submitChangeRequest}
                >
                    Confirm
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
