import React, { useState } from 'react';
import { Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { Colors, Text } from 'library';
import { AdminListProvider, LeanAdminProvider } from 'models';
import SwipeableViews from 'react-swipeable-views';
import AppliedJobsMenu from 'internal/providers/AppliedJobsMenu';
import ReverseMatchingList from '../../ReverseMatchingList';
import { StandByProvider } from '../../StandbyList';

export default function ReverseMatchingTabs({
    provider,
    updateProvider,
}: {
    provider: AdminListProvider | StandByProvider | LeanAdminProvider;
    updateProvider?: (providerId: number, fields: Partial<AdminListProvider>) => void;
}) {
    const [selectedTab, setSelectedTab] = useState('0');
    return (
        <div>
            {provider.user ? (
                <Text bold>
                    {provider.user.first_name} {provider.user.last_name} ({provider.user.id})
                </Text>
            ) : (
                <></>
            )}
            <TabContext value={selectedTab}>
                <TabList
                    onChange={(e, value: string) => setSelectedTab(value)}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    textColor="inherit"
                    TabIndicatorProps={{ style: { backgroundColor: Colors.turquoise, height: 3 } }}
                >
                    <Tab label="Applied Jobs" value="0" sx={classes.tabs} />
                    <Tab label="Matching" value="1" sx={classes.tabs} />
                </TabList>
                <SwipeableViews
                    index={parseInt(selectedTab)}
                    onChangeIndex={(index: number) => setSelectedTab(index.toString())}
                >
                    <div
                        style={{
                            visibility: selectedTab === '0' ? 'visible' : 'hidden',
                            ...classes.panels,
                        }}
                    >
                        <AppliedJobsMenu open={selectedTab === '0'} user={provider.user} />
                    </div>
                    <div
                        style={{
                            visibility: selectedTab === '1' ? 'visible' : 'hidden',
                            ...classes.panels,
                        }}
                    >
                        <ReverseMatchingList
                            provider={provider}
                            open={selectedTab === '1'}
                            updateProvider={updateProvider}
                        />
                    </div>
                </SwipeableViews>
            </TabContext>
        </div>
    );
}

const classes = {
    tabs: {
        '&.MuiButtonBase-root.MuiTab-root': {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'none',
            outline: 'none',
        },
        '&.MuiTab-textColorInherit': {
            color: Colors.darkNavy,
        },
        '&.Mui-selected': {
            color: Colors.turquoise,
        },
    },
    panels: {
        '&.MuiTabPanel-root': {
            paddingTop: '20px',
            paddingBottom: '20px',
        },
    },
};
