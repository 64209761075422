import React, { useState, useEffect } from 'react';
import { Grid, Alert } from '@mui/material';
import { useCookies } from 'react-cookie';
import PhoneNumberInput from './PhoneNumberInput';
import EmailInput from 'parent-portal/public/public-application/InputFields/EmailInput';
import PasswordInput from 'parent-portal/public/public-application/InputFields/PasswordInput';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { IfRender, PrimaryButton, UnderlinedTextInput, ModalHeader, Text } from 'library';
import ConsentCheckbox from './Consent';
import { checkPhoneNumber } from 'parent-portal/public/PhoneNumberExists';
import { getUser, IAddressBody, isLoggedIn, patchUser, businessSignUp } from 'api/UserApi';
import useFamilyApi from 'api/FamilyApi';
import { consoleLogInDev, formatE164USPhoneNumber, track } from 'shared';
import ReferralCodeInput from './ReferralCodeInput';
import { useHistory } from 'react-router-dom';
import { useUserContext } from 'UserContext';
import { SignUpProps } from 'models';
import BusinessSignup from './business/BusinessSignup';
import { BusinessData } from './business/BusinessModels';
import PhoneNumberVerification from './PhoneNumberVerification';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';
import AddressInputs from './AddressInputs';

export default function SignupForm({
    location,
    onComplete,
    onClose,
    existingEmail,
    existingPhoneNumber,
    message = 'Create Account',
    redirect = true,
    businessSignup = false,
    actionMessage = 'Sign Up',
    subtitle,
    skipToPhone = false,
    businessType = 'CHILD_CARE',
}: SignUpProps) {
    const [password, setPassword] = useState<string>('');
    const [isPassword, setIsPassword] = useState<boolean>(true);
    const [email, setEmail] = useState<string>(existingEmail ?? '');
    const [isEmail, setIsEmail] = useState<boolean>(true);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [display, setDisplay] = useState<string>('');
    const [isValidPhone, setIsValidPhone] = useState<boolean>(false);
    const [phoneError, setPhoneError] = useState<boolean>(false);
    const [isDuplicatePhone, setIsDuplicatePhone] = useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [phoneErrorText, setPhoneErrorText] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [address, setAddress] = useState<IAddressBody>();
    const [isAddressValid, setIsAddressValid] = useState(false);
    const [isValidProgramId, setIsValidProgramId] = useState(false);
    const [valid, setValid] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [consent, setConsent] = useState<boolean>(false);
    const { getTrackingValues } = useFamilyApi();
    const [referralCode, setReferralCode] = useState('');
    const [referralCodeValid, setReferralCodeValid] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [businessData, setBusinessData] = useState<BusinessData>();
    const [businessError, setBusinessError] = useState<boolean>(true);
    const history = useHistory();
    const { refreshUser, user } = useUserContext();
    const [{ puuid, isBusiness, referral }] = useCookies(['puuid', 'isBusiness', 'referral']);
    const { trackSession } = usePublicSessionTracking('Family Signup Modal');

    const disabledParams = [
        consent,
        firstName,
        lastName,
        address,
        email,
        referralCodeValid,
        isValidPhone,
        isDuplicatePhone,
        isEmail,
        password,
        isPassword,
        valid,
        businessError,
    ];

    useEffect(() => {
        if (existingEmail) setEmail(existingEmail);
    }, [existingEmail]);

    useEffect(() => {
        if (existingPhoneNumber) setPhoneNumber(existingPhoneNumber);
        else if (user?.phone_number) setPhoneNumber(user.phone_number);
    }, [existingPhoneNumber, user?.phone_number]);

    useEffect(() => {
        setIsValidProgramId(
            address?.state !== 'Ohio'
                ? true
                : !!businessData?.programId &&
                      !!businessData?.programId?.length &&
                      businessData?.programId?.length > 5 &&
                      businessData?.programId?.length < 21,
        );
    }, [businessData?.programId, address?.state]);

    useEffect(() => {
        const genericSignupDisabled =
            !valid ||
            !consent ||
            !firstName ||
            !lastName ||
            !isAddressValid ||
            !isValidPhone ||
            !isEmail ||
            !password ||
            !isPassword ||
            !isValidProgramId;

        if (businessSignup) {
            const { referral_code } = getTrackingValues();
            setReferralCode(referral_code);
            setButtonDisabled(genericSignupDisabled || businessError);
        } else {
            setButtonDisabled(genericSignupDisabled);
        }
    }, [disabledParams]);

    async function checkIsDuplicatePhone() {
        const result = await checkPhoneNumber(phoneNumber);
        setIsDuplicatePhone(result.duplicate);
        if (result.duplicate) {
            setPhoneError(true);
            setPhoneErrorText(
                'Please confirm that your phone number or email is not already linked to a Tandem account.',
            );
        }
        return result;
    }

    useEffect(() => {
        setIsValidPhone(isValidPhoneNumber(phoneNumber, 'US'));
        const error = !isValidPhone && !!phoneNumber;
        setPhoneError(error);
        const errorText = error ? 'Please enter a valid phone number' : '';
        setPhoneErrorText(errorText);
    }, [phoneNumber, isValidPhone]);

    useEffect(() => {
        async function loadUser() {
            const res = await getUser();
            const newUser = res;

            if (newUser.signup_page > 1 && redirect === true) {
                history.push('/home');
            }
        }
        if (isLoggedIn()) loadUser();
    }, []);

    function isValid() {
        return (
            firstName &&
            lastName &&
            email &&
            password &&
            phoneNumber &&
            address?.street &&
            address?.city &&
            address.state &&
            address.zip_code &&
            isValidProgramId
        );
    }

    async function handlePostUser() {
        setIsSubmitting(true);
        const result = await checkIsDuplicatePhone();
        if (address && isValid() && businessData?.name && !result.duplicate) {
            setError(undefined);
            const { source, ciid, esid, reid } = getTrackingValues();

            const postBody = {
                username: email,
                email,
                password1: password,
                password2: password,
                first_name: firstName,
                last_name: lastName,
                phone_number: formatE164USPhoneNumber(phoneNumber) as string,
                street: address.street!,
                city: address.city!,
                state: address.state!,
                zip_code: address.zip_code!,
                business_name: businessData?.name,
                business_type: businessType,
                program_id: businessData?.programId,
                public_user_token: puuid,
                referral_code: referralCode,
                source,
                ciid,
                esid,
                reid,
            };

            businessSignUp(postBody)
                .then((response) => {
                    if (response) localStorage.setItem('token', response.key);
                    return refreshUser();
                })
                .then((user) => {
                    onClose && onClose();
                    onComplete && onComplete(user);
                    setIsSubmitting(false);
                })
                .catch((error) => {
                    const errorFields = [
                        'username',
                        'email',
                        'password1',
                        'password2',
                        'first_name',
                        'last_name',
                        'phone_number',
                        'street',
                        'city',
                        'state',
                        'zip_code',
                        'business_name',
                        'business_type',
                        'program_id',
                        'non_field_errors',
                    ];

                    const allErrors = errorFields.reduce((errorsAccumulator: string[], field) => {
                        // eslint-disable-next-line security/detect-object-injection
                        return error[field] ? [...errorsAccumulator, error[field]] : errorsAccumulator;
                    }, []);

                    if (allErrors.length) {
                        setError(`The following errors occurred: ${allErrors.join(' ')}`);
                    } else {
                        setError('An unexpected error occurred. Please try again.');
                    }

                    consoleLogInDev(error);
                    setIsSubmitting(false);
                });
        } else {
            setIsSubmitting(false);
        }
        trackSession('CLICK', `Family signup modal submitted`, PublicSessionEventType.User, {
            firstName,
            lastName,
            email,
            phoneNumber,
            phoneErrorText,
        });
    }

    async function finallyComplete(token: string) {
        if (token) localStorage.setItem('token', token);
        let user = await getUser(token);
        user = await patchUser(user.id, { signup_page: 4 }, location || '', token);
        user = await refreshUser();
        onClose && onClose();
        onComplete && onComplete(user);
        setIsSubmitting(false);
    }

    const passwordErrorText =
        email === password
            ? 'Email and password cannot match'
            : 'Password must be minimum 8 characters with at least one uppercase letter, one lowercase letter, one digit, and one special character.';

    return (
        <Grid container item direction="column" spacing={1}>
            <ModalHeader onClose={onClose} title={skipToPhone ? 'Verify Phone Number' : message} subtitle={subtitle} />
            {skipToPhone ? (
                <PhoneNumberVerification
                    phoneNumber={user?.phone_number ?? phoneNumber}
                    handleSuccess={finallyComplete}
                />
            ) : (
                <>
                    <Grid container item spacing={3} style={{ paddingTop: 20, paddingBottom: 20 }}>
                        <Grid item xs={6}>
                            <UnderlinedTextInput
                                id="signup-first-name"
                                label="First Name*"
                                value={firstName}
                                onChange={(value: string) => {
                                    setFirstName(value);
                                }}
                                error={false}
                                errorText=""
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <UnderlinedTextInput
                                id="signup-last-name"
                                label="Last Name*"
                                value={lastName}
                                onChange={(value: string) => {
                                    setLastName(value);
                                }}
                                error={false}
                                errorText=""
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PhoneNumberInput
                                id="signup-phone"
                                label="Phone*"
                                onChange={(value: string, displayPhone: string) => {
                                    setPhoneNumber(value);
                                    setDisplay(displayPhone);
                                }}
                                value={display}
                                error={phoneError}
                                errorText={phoneErrorText}
                            />
                            <Text variant="body2">Must be a mobile number or other SMS-enabled number</Text>
                        </Grid>
                        <AddressInputs setValid={setIsAddressValid} setAddressPatchBody={setAddress} />
                        <Grid item xs={6}>
                            <EmailInput
                                id="signup-email"
                                value={email}
                                error={!isEmail}
                                errorText={'Please enter your email'}
                                label="Email*"
                                onChange={(value: string, isValid: boolean) => {
                                    setIsEmail(isValid);
                                    setEmail(value);
                                    setValid(isValid);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PasswordInput
                                id="signup-password"
                                error={!isPassword}
                                errorText={passwordErrorText}
                                value={password}
                                label="Password*"
                                onChange={(value: string, isValid: boolean) => {
                                    setPassword(value);
                                    setIsPassword(isValid);
                                }}
                            />
                        </Grid>
                        <IfRender condition={businessSignup || isBusiness === 'true'}>
                            <BusinessSignup
                                updateError={setBusinessError}
                                updateBusinessData={setBusinessData}
                                businessType={businessType}
                                state={address?.state}
                                isValidProgramId={isValidProgramId}
                            />
                        </IfRender>
                        <Grid item xs={12}>
                            <ReferralCodeInput
                                referralCode={referral}
                                setReferralCode={setReferralCode}
                                valid={referralCodeValid}
                                setValid={setReferralCodeValid}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item style={{ paddingBottom: 24 }}>
                        <ConsentCheckbox consent={consent} setConsent={setConsent} />
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <PrimaryButton
                                id="signup-submit-button"
                                disabled={buttonDisabled}
                                loading={isSubmitting}
                                onClick={() => {
                                    track('AuthModal: User');
                                    handlePostUser();
                                }}
                            >
                                {actionMessage}
                            </PrimaryButton>
                        </Grid>
                        {error ? <Alert severity="error">{error}</Alert> : null}
                    </Grid>
                </>
            )}
            <Text variant="caption" textStyle={{ marginTop: 12 }}>
                * Indicates required field
            </Text>
            <Text variant="caption" textStyle={{ marginTop: 12 }}>
                ** Required in the state of Ohio
            </Text>
            <Text variant="body2" textStyle={{ marginTop: 12 }}>
                Need help signing up? Email us at <a href="mailto:support@join-tandem.com">support@join-tandem.com</a>
            </Text>
        </Grid>
    );
}
