import React, { useState, KeyboardEvent } from 'react';
import { consoleLogInDev } from 'shared';
import { useCookies } from 'react-cookie';
import { Text, BasicDialog, OutlinedTextInput, PrimaryButton } from 'library';
import DateFnsAdapter from '@date-io/date-fns';
import { verifyMfaCode } from 'api/SsnLast4Api';
import { useUserContext } from 'UserContext';

export default function SsnMfaDialog({
    showMfaDialog,
    setShowMfaDialog,
    setShowDialog,
}: {
    showMfaDialog: boolean;
    setShowMfaDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { refreshUser } = useUserContext();
    const dateFns = new DateFnsAdapter();
    const [ , setCookie] = useCookies();

    const [mfaCode, setMfaCode] = useState<string>('');

    const handleCloseMfaDialog = () => {
        setMfaCode('');
        setShowMfaDialog(false);
    };
    const onSubmitMfaCode = () => {
        if (mfaCode) {
            verifyMfaCode(mfaCode)
                .then((res) => {
                    const oneDayInFuture = dateFns.addDays(new Date(), 1);
                    setCookie('_view_tok', res.one_day_token, {
                        path: '/adminperson',
                        expires: oneDayInFuture,
                        secure: true,
                        sameSite: 'strict',
                    });
                    handleCloseMfaDialog();
                    setShowDialog(true);
                    refreshUser();
                })
                .catch((e) => consoleLogInDev(e));
        }
    };

    function onKeyDownInput(event: KeyboardEvent) {
        if (event.key === 'Enter' && mfaCode) {
            onSubmitMfaCode();
        }
    }

    return (
        <BasicDialog
            closeButton
            isOpen={showMfaDialog}
            dialogTitle={'ssn multi factor authentication'}
            onClose={handleCloseMfaDialog}
        >
            <Text>You should have received a text with a verification code. Enter it in the box to continue.</Text>
            <OutlinedTextInput
                fullWidth
                value={mfaCode}
                onChange={(e) => setMfaCode(e.target.value.toLocaleUpperCase())}
                InputLabelProps={{ shrink: true }}
                style={styles.mfaTextInput}
                onKeyDown={(event: KeyboardEvent) => onKeyDownInput(event)}
            />
            <PrimaryButton
                disabled={!mfaCode}
                onClick={onSubmitMfaCode}
                buttonStyle={styles.mfaSubmitButton}
                type="submit"
            >
                Submit
            </PrimaryButton>
        </BasicDialog>
    );
}

const styles = {
    mfaTextInput: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    mfaSubmitButton: {
        margin: 5,
    },
};
