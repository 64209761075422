import React, { useState, useEffect } from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import { Colors } from '../../constants';
import client from '../../shared/ApiClient';
import { track } from '../../shared/Tracking';
import { RouteComponentProps } from 'react-router';
import { Environment } from 'environmentVariables';

const EmailVerificationPage = ({ match }: RouteComponentProps<{ id: string }>) => {
    const id = match;
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        client(`api/rest-auth/email/confirm/${id.params.id}/`).catch((error) => {
            setIsVerified(false);
            if (Environment !== 'production') {
                console.log(error);
            }
        });
        setIsVerified(true);
        track('User verified email', { success: true });
    }, []);

    return (
        <Grid container justify="center" alignContent="center" style={{ padding: 50 }}>
            <Card style={{ padding: 30, backgroundColor: Colors.cloud, maxWidth: 800 }}>
                <Typography style={{ color: Colors.navy, fontWeight: 'bold' }}>
                    {isVerified
                        ? 'Email Verified Successfully!'
                        : 'This link has expired or is no longer valid. Contact support@join-tandem.com if this is incorrect.'}
                </Typography>
            </Card>
        </Grid>
    );
};

export default EmailVerificationPage;
