import React from 'react';
import { Text, Colors } from 'library';
import { Grid } from '@material-ui/core';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router-dom';
import { track } from 'shared';
export default function CardItem({
    text,
    link,
    icon,
    ext,
    id,
}: {
    text: string;
    link: string;
    icon: any;
    ext?: string;
    id?: string;
}) {
    const history = useHistory();
    return (
        <Grid
            container
            style={{
                backgroundColor: Colors.lightTurq,
                borderRadius: '18px',
                padding: 10,
                marginTop: 7,
                marginBottom: 7,
                cursor: link ? 'pointer' : 'none',
                height: 60,
            }}
            justify="space-between"
            alignItems="center"
            onClick={() => {
                track(`Job Card Click: ${ext ? ext : text}`);
                history.push(link);
            }}
            id={id}
        >
            <Grid xs={11} container direction="row" alignItems="center">
                {icon ? (
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        style={{ backgroundColor: Colors.darkNavy, borderRadius: '50%', width: 30, height: 30 }}
                    >
                        {icon}
                    </Grid>
                ) : null}

                <Text
                    variant="body1"
                    textStyle={{
                        marginLeft: 5,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    {text}
                </Text>
            </Grid>
            {link ? (
                <Grid container xs={1} justify="flex-start">
                    <ChevronRightIcon htmlColor={Colors.darkNavy} fontSize="large" />
                </Grid>
            ) : null}
        </Grid>
    );
}
