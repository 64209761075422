import React, { useEffect, useState } from 'react';
import { CheckboxSelect, Link, LoadingIndicator, Text } from 'library';
import { styled } from '@mui/material';
import { ProviderSelection } from 'parent-portal/forms/JobRequestTypes';
import { PreferredWorkerDTO, getPreferredWorkers } from './preferredWorkersApi';

interface Props {
    selected: ProviderSelection[];
    onChange: (selectedProviderUserIds: ProviderSelection[]) => void;
    addresses?: number[];
    existingMatches?: ProviderSelection[];
    multiple?: boolean;
    clearOtherInput?: () => void;
}

export default function PreferredWorkersInput({
    selected,
    onChange,
    addresses,
    existingMatches,
    multiple = true,
    clearOtherInput,
}: Props) {
    const [preferredWorkers, setPreferredWorkers] = useState<PreferredWorkerDTO[]>([]);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [loading, setLoading] = useState(false);

    const additionalSelectedOptions = selected.filter(
        (selected) => !preferredWorkers.some((preferred) => preferred.user.id === selected.id),
    );
    const options = [
        ...additionalSelectedOptions,
        ...preferredWorkers.map((worker) => ({
            id: worker.user.id,
            name: `${worker.user.first_name} ${worker.user.last_name}`,
        })),
    ];

    useEffect(() => {
        setLoading(true);
        getPreferredWorkers(page, addresses)
            .then((response) => {
                setHasNextPage(page < response.num_pages);
                if (page === 1) {
                    setPreferredWorkers(response.results);
                } else {
                    setPreferredWorkers((preferredWorkers) => [...preferredWorkers, ...response.results]);
                }
            })
            .finally(() => setLoading(false));
    }, [page]);

    function onCheckboxChange(checkedList: string[]) {
        if (!multiple && checkedList.length > 0) clearOtherInput?.();

        if (checkedList.length === 0) {
            onChange([]);
        } else {
            if (multiple) {
                const values = checkedList.map((it) => it);
                onChange(options.filter((it) => values.includes(it.name)));
            } else {
                if (checkedList.length > 0) {
                    const choice = options.find((it) => it.name === checkedList.at(-1));
                    onChange(choice ? [choice] : []);
                } else {
                    onChange([]);
                }
            }
        }
    }

    const renderContent = () => {
        if (loading && preferredWorkers.length === 0) {
            return <LoadingIndicator />;
        }
        if (preferredWorkers.length === 0) {
            return <Text>It looks like you have not set any preferred workers.</Text>;
        }
        return (
            <CheckboxSelect
                options={options.map((it) => it.name)}
                value={[...selected.map((it) => it.name), ...(existingMatches?.map((it) => it.name) ?? [])]}
                isDisabled={(option: string) => !!existingMatches?.some((match) => match.name === option)}
                onChange={onCheckboxChange}
            />
        );
    };

    return (
        <>
            <Spacer />
            <Text variant="body1" bold>
                Preferred Workers
            </Text>
            {renderContent()}
            {!loading && hasNextPage && <Link onClick={() => setPage((page) => page + 1)}>load more</Link>}
        </>
    );
}

const Spacer = styled('div')({
    marginTop: 15,
});
