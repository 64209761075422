import React, { useState } from 'react';
import { Text, Colors, TextButton } from 'library';
import { Grid } from '@material-ui/core';
import { track } from 'shared';
import { isMobile } from 'react-device-detect';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';

export default function WhatToDo() {
    const [expanded, setExpanded] = useState(false);
    function expand() {
        setExpanded(!expanded);
        track('Hire Modal: What do I do if my provider asks to be paid through cash or Venmo? clicked ');
    }

    return (
        <Grid item>
            <Grid container item>
                {!isMobile ? (
                    <Grid item style={{ marginTop: 15, marginLeft: 30 }}>
                        <HelpOutlinedIcon htmlColor={Colors.darkNavy} />
                    </Grid>
                ) : null}
                <Grid item style={{ marginLeft: 10 }}>
                    <TextButton onClick={expand} textStyle={{ marginTop: 15 }}>
                        What do I do if my provider asks to be paid through cash or Venmo?
                    </TextButton>
                </Grid>
            </Grid>
            {expanded ? (
                <Text variant="deprecatedCaption" textStyle={{ marginTop: 15, marginLeft: isMobile ? 20 : 50 }}>
                    If your provider asks you to pay them through another platform, please let them know that this is a
                    requirement and direct them to contact Support at support@join-tandem.com with any questions or
                    concerns. If payment happens outside of the platform, we will have to deactivate your account and
                    you will not be able to find care providers through Tandem in the future.
                </Text>
            ) : null}
        </Grid>
    );
}
