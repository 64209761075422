import React from 'react';
import Checkmarks from 'assets/icons/tandempay/checkmarks.svg';
import { Provider } from 'models';
import ModalPage from 'parent-portal/payments/Components/ConnectPaymentModal/ModalPage';
import PageHeader from 'parent-portal/payments/Components/ConnectPaymentModal/PageHeader';
import { ExpandableHeader, Text, Colors, SecondaryButton, PrimaryButton } from 'library';
import { Grid, Link, SvgIcon, useMediaQuery } from '@material-ui/core';
import { track } from 'shared';
import { ArrowForward } from '@mui/icons-material';
import theme from 'theme';

interface Props {
    provider: Provider;
    isOneTimeJob: boolean;
    isPaymentSetup: boolean;
    onContinue: () => void;
}

export default function FoundYourMatch({ provider, isOneTimeJob, isPaymentSetup, onContinue }: Props) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const url = window.location.origin;
    const subtext = isPaymentSetup
        ? 'Remember TandemPay? All Tandem care providers are paid through our built-in payment system.'
        : `There's one last step. All Tandem care providers are paid through TandemPay.`;

    function pressSecondaryButton() {
        track('Family navigated to payment information hub', {
            fromRoute: 'HireModal',
            isPaymentSetup: isPaymentSetup,
        });
    }
    function whyIsThisGreatContent() {
        return (
            <>
                <Grid container item>
                    <Text variant="body2">
                        {isOneTimeJob
                            ? `• It's just like Venmo, click of a button.`
                            : `• We handle taxes for your provider and give you easy access to your payment history.`}
                    </Text>
                </Grid>
                <Grid container item>
                    <Text variant="body2">
                        • You don't pay fees and {provider.user.first_name} gets 100% of their pay.
                    </Text>
                </Grid>
                <Grid container item>
                    <Text variant="body2">• Pay your care provider for future jobs on their profile.</Text>
                </Grid>
            </>
        );
    }
    function whyDoIHaveToContent() {
        return (
            <Grid container item xs={10} justify="flex-start">
                <Text variant="body2">
                    We can only remain free for you and your care provider if payment stays within the site. We know
                    this is a little out of the ordinary. You may be used to paying your care providers through cash,
                    Venmo, or a payroll service. But, in order to continue to make free connections, payment has to go
                    through Tandem.{' '}
                    <Link
                        href={`${url}/tandempay`}
                        onClick={pressSecondaryButton}
                        underline="always"
                        target="_blank"
                        rel="noopener"
                        style={{ margin: 10 }}
                    >
                        Learn more.
                    </Link>
                </Text>
            </Grid>
        );
    }

    return (
        <ModalPage>
            <PageHeader iconSource={<Checkmarks />} headerText="You're almost there!" subtitleText={subtext} />
            <Grid
                item
                style={{
                    paddingLeft: smDown ? 15 : 50,
                    paddingRight: smDown ? 15 : 50,
                }}
            >
                <Text variant="body1" bold color={Colors.turquoise}>
                    We're so glad you found your match.
                    {isPaymentSetup
                        ? ` You'll pay ${provider.user.first_name} through Tandem. `
                        : ` You'll need to add payment information to your profile so that you can pay ${provider.user.first_name} through Tandem. `}
                </Text>
                <Text variant="body2">
                    {isPaymentSetup ? 'All Tandem care providers are required to be paid through TandemPay.' : ''}
                    If payments happen off of the site, your profile will be deactivated and you will not be able to use
                    Tandem to find providers in the future.
                </Text>
            </Grid>
            <Grid item style={{ padding: smDown ? 4 : 20 }}>
                <ExpandableHeader
                    smallHeader
                    trackingData={'Hire Dialog: Why is this great for me clicked'}
                    header={'Why is this great for me?'}
                    expandedContent={whyIsThisGreatContent()}
                />
            </Grid>
            <Grid item style={{ padding: smDown ? 4 : 20 }}>
                <ExpandableHeader
                    smallHeader
                    trackingData={'Hire Dialog: Why do I have to pay through Tandem clicked'}
                    header={'Why do I have to pay through Tandem?'}
                    expandedContent={whyDoIHaveToContent()}
                />
            </Grid>
            <Grid
                container
                item
                direction="row"
                justify="space-between"
                alignItems="flex-end"
                style={{ flexGrow: 1, marginBottom: -30 }}
            >
                <Link
                    href={`${url}/tandempay`}
                    onClick={pressSecondaryButton}
                    underline="always"
                    target="_blank"
                    rel="noopener"
                    style={{ margin: 10 }}
                >
                    Tell me more
                </Link>
                <Grid item style={{ padding: 10 }}>
                    {isPaymentSetup ? (
                        <SecondaryButton onClick={onContinue} buttonStyle={{ maxHeight: 40 }}>
                            I Agree
                            <SvgIcon
                                style={{
                                    color: Colors.darkNavy,
                                    width: 30,
                                    height: 30,
                                }}
                                component={ArrowForward}
                            />
                        </SecondaryButton>
                    ) : (
                        <PrimaryButton onClick={onContinue}>Setup TandemPay</PrimaryButton>
                    )}
                </Grid>
            </Grid>
        </ModalPage>
    );
}
