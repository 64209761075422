import React, { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton, Text } from 'library';
import TextInput from 'parent-portal/BusinessLocation/TextInput';
import { Grid } from '@material-ui/core';
import { DocumentTypeWithCount, DropDownField, PendingDocumentFilters } from '../models';
import { client, consoleLogInDev } from 'shared';
import DocumentTypeSelection from './DocumentTypeSelection';

const allDocTypes: DropDownField = {
    name: 'All Types',
    value: '-1',
};

export default function HeaderActions({
    search,
    filters,
    setFilters,
    getData,
}: {
    search: () => void;
    filters: PendingDocumentFilters;
    setFilters: (filters: PendingDocumentFilters) => void;
    getData: (filters: PendingDocumentFilters) => void;
}) {
    const [documentTypes, setDocumentTypes] = useState<DropDownField[]>([allDocTypes]);

    async function getDocumentTypes() {
        try {
            const docTypes: DocumentTypeWithCount[] = await client('api/document-type/?include_count=true');
            const convertedToDropDown = docTypes.map((docType) => ({
                name: docType.name + ` (${docType.unverified_count})`,
                value: docType.id.toString(),
            }));
            setDocumentTypes([allDocTypes, ...convertedToDropDown]);
        } catch (e) {
            consoleLogInDev(e);
            return [];
        }
    }

    useEffect(() => {
        getDocumentTypes();
    }, []);

    function updateUserIdFilter(e: string) {
        const newVal = parseInt(e);
        const newUserId = Number.isInteger(newVal) ? newVal : undefined;
        setFilters({ ...filters, user_id: newUserId, page: 1 });
    }

    function updateDocTypeFilter(e: number) {
        setFilters({ ...filters, document_type_id: e });
    }

    const selectedUserId = filters.user_id?.toString() ?? '';
    const selectedDocType = filters.document_type_id?.toString() ?? '-1';

    return (
        <>
            <Text variant="display">Verify Documents</Text>
            <Grid item container direction="row" style={{ gap: 20, marginTop: 20 }} xs={12} alignItems="center">
                <Grid item container alignItems="baseline" style={{ width: 200 }}>
                    <TextInput value={selectedUserId} label="User ID" onChange={updateUserIdFilter} />
                </Grid>
                <Grid item container direction="column" justify="center" style={{ width: 400 }}>
                    <Text bold textStyle={{ width: 200 }}>
                        Document Type:{' '}
                    </Text>
                    <DocumentTypeSelection
                        documentTypes={documentTypes}
                        selectedType={selectedDocType}
                        updateSelected={updateDocTypeFilter}
                    />
                </Grid>
                <PrimaryButton buttonStyle={{ width: 200, maxHeight: 50 }} onClick={search}>
                    Search
                </PrimaryButton>
                <SecondaryButton
                    buttonStyle={{ width: 200, maxHeight: 50 }}
                    onClick={() => {
                        setFilters({});
                        getData({});
                    }}
                >
                    Clear
                </SecondaryButton>
            </Grid>
        </>
    );
}
