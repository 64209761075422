import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { withRouter } from 'react-router-dom';
import ConnectPaymentModal from 'parent-portal/payments/Components/ConnectPaymentModal';
import { track, client } from 'shared';
import { ModalsContext } from 'ModalsContext';
import { Environment } from 'environmentVariables';

//set to 99 so that all of the steps are gray when initially rendered
const activeStep = 99;

const StyledStepper = styled(Stepper)`
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: start;
    }
`;

const StyledStep = styled(Step)`
    @media screen and (max-width: 600px) {
        width: 50%;
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;

const StyledStepButton = styled(StepButton)`
    @media screen and (max-width: 600px) {
        display: flex;
        justify-content: start;
    }
`;

class CompletionBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            completionStatus: [],
            allComplete: true,
            openPaymentModal: false,
        };
        this.closeJobForm = this.closeJobForm.bind(this);
        this.checkIfAllComplete = this.checkIfAllComplete.bind(this);
    }

    componentDidMount = async () => {
        // gets the status
        const status = await this.getCompletionStatus();
        // checks to see if they are complete
        // if it is then the element will not render
        this.checkIfAllComplete(status);
    };

    async closeJobForm() {
        const status = await this.getCompletionStatus();
        this.checkIfAllComplete(status);
    }

    getCompletionStatus = async () => {
        try {
            return await client('api/get-completion-bar-status/');
        } catch (error) {
            if (Environment !== 'production') {
                console.log(error);
            }
        }
    };

    checkIfAllComplete(status) {
        const checkIfComplete = (status) => status.completed;

        this.setState({
            allComplete: status.every(checkIfComplete),
            completionStatus: status,
        });
    }

    handleStep(index, context) {
        const { completionStatus } = this.state;
        const { pageHandler } = this.props;

        let step = completionStatus[index];

        // checks to see if the step isn't completed. Skips otherwise
        if (!step.completed) {
            switch (step.label) {
                case 'Add a Profile Picture':
                    // goes to account tab
                    pageHandler(3);
                    break;
                case 'Setup Payment Information':
                    // opens setup modal
                    track('Family opened payment setup modal', { fromRoute: 'ProfileCompletionBar' });
                    this.setState({ openPaymentModal: true });

                    break;
                case 'Make a Request':
                    context.openJobForm('ProfileCompletionBar', undefined, this.closeJobForm);
                    break;
                case 'Refer a Friend':
                    //goes to referral page
                    this.props.history.push('/referrals');
                    break;

                default:
                // code block
            }
        }
    }

    render() {
        const { completionStatus, allComplete, openPaymentModal } = this.state;

        return (
            //only shoes if there is one that isn't completed. Otherwise hide
            !allComplete && (
                <ModalsContext.Consumer>
                    {(context) => (
                        <>
                            <Paper style={{ borderRadius: 10 }}>
                                <StyledStepper nonLinear activeStep={activeStep} style={{ borderRadius: 10 }}>
                                    {completionStatus.map((item, index) => (
                                        <StyledStep key={item.label}>
                                            <StyledStepButton
                                                completed={item.completed}
                                                onClick={() => this.handleStep(index, context)}
                                            >
                                                {item.label}
                                            </StyledStepButton>
                                        </StyledStep>
                                    ))}
                                </StyledStepper>
                            </Paper>
                            <ConnectPaymentModal
                                fromRoute={'ProfileCompletionBar'}
                                isOpen={openPaymentModal}
                                onClose={() => this.setState({ openPaymentModal: false })}
                            />
                        </>
                    )}
                </ModalsContext.Consumer>
            )
        );
    }
}

export default withRouter(CompletionBar);
