import React, { useEffect, useState } from 'react';
import { LoadingIndicator, OutlinedTextInput, Text } from 'library';
import { styled } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import { ProviderSelection } from 'parent-portal/forms/JobRequestTypes';
import { PastWorkerDTO, getPastWorkers } from './preferredWorkersApi';

interface Props {
    selected: ProviderSelection[];
    onChange: (selectedProviderUserIds: ProviderSelection[]) => void;
    multiple?: boolean;
    clearOtherInput?: () => void;
}

export default function PastWorkersInput({ selected, onChange, multiple = true, clearOtherInput }: Props) {
    const [pastWorkers, setPastWorkers] = useState<PastWorkerDTO[]>([]);
    const [loading, setLoading] = useState(false);

    const pastWorkerOptions = pastWorkers.map((worker) => ({
        id: worker.id,
        name: `${worker.first_name} ${worker.last_name}`,
    }));

    useEffect(() => {
        setLoading(true);
        getPastWorkers()
            .then(setPastWorkers)
            .finally(() => setLoading(false));
    }, []);

    const onDropdownSelect = (value: ProviderSelection | ProviderSelection[] | null) => {
        if (!multiple && !!value) clearOtherInput?.();

        if (!value) {
            onChange([]);
        } else if (Array.isArray(value)) {
            const values = value.map((it: { id: number }) => it.id);
            onChange(pastWorkerOptions.filter((it: { id: number }) => values.includes(it.id)));
        } else {
            const choice = pastWorkerOptions.find((it: { id: number }) => it.id === value.id);
            onChange(choice ? [choice] : []);
        }
    };

    const renderContent = () => {
        if (loading) {
            return <LoadingIndicator />;
        }
        if (pastWorkers.length === 0) {
            return <Text>It looks like you have not set any preferred workers.</Text>;
        }
        return (
            <Autocomplete
                multiple={multiple}
                value={multiple ? selected.map((it) => it) : selected?.[0] ?? null}
                options={pastWorkerOptions}
                onChange={(_, value) => onDropdownSelect(value)}
                getOptionLabel={(option) => `${option.name}`}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                    <OutlinedTextInput
                        {...params}
                        style={{ marginTop: 10 }}
                        variant="outlined"
                        helperText="Select workers to recommend for this job."
                        placeholder="Click here to search for past workers"
                    />
                )}
            />
        );
    };

    return (
        <>
            <Spacer />
            <Text variant="body1" bold>
                Past Workers
            </Text>
            {renderContent()}
        </>
    );
}

const Spacer = styled('div')({
    marginTop: 15,
});
