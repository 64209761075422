import { IBusinessLocation, PagingResult } from 'models';
import { client } from 'shared';

export function getBusinessLocations(): Promise<PagingResult<IBusinessLocation>> {
    return client('api/business-location/');
}

export function patch(businessLocationId: number, body: Partial<IBusinessLocation>) {
    return client(`api/business-location/${businessLocationId}/`, {
        body,
        method: 'PATCH',
    });
}
