import React from 'react';
import { IProviderReports } from './models';
import { Colors, Text } from 'library';
import { Grid } from '@material-ui/core';
import ProviderReports from './ConfirmSubHours/ProviderReports';

export function ConfirmHoursSection({
    title,
    subtitle,
    reports,
    setChangeReport,
    updateHoursReported,
    includeTitles,
}: {
    title: string;
    subtitle: string;
    reports: IProviderReports;
    setChangeReport: (reportId: number) => void;
    updateHoursReported: () => void;
    includeTitles?: boolean;
}) {
    function ProviderContent() {
        return (
            <>
                {Object.keys(reports).map((providerName: string) => (
                    <Grid
                        key={providerName}
                        container
                        item
                        xs={12}
                        style={{
                            borderRadius: 18,
                            backgroundColor: Colors.lightTurq,
                            padding: 20,
                            margin: 10,
                            boxShadow: '0px 3px 6px 0px #00000029',
                        }}
                    >
                        <ProviderReports
                            providerName={providerName}
                            reports={reports[providerName]}
                            setChangeReport={setChangeReport}
                            updateHoursReported={updateHoursReported}
                        />
                    </Grid>
                ))}
            </>
        );
    }
    return includeTitles ? (
        <Grid
            container
            item
            style={{
                padding: 20,
                boxShadow: '0px 3px 6px 0px #00000029',
                borderRadius: 18,
            }}
            direction="column"
        >
            <Text variant="h2" bold>
                {title}
            </Text>
            <Text variant="body1">{subtitle}</Text>
            <ProviderContent />
        </Grid>
    ) : (
        <Grid container item style={{ gap: 20, flexWrap: 'wrap' }}>
            <ProviderContent />
        </Grid>
    );
}
