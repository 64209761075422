import React, { Component } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import theme from '../../../theme';
import Grid from '@material-ui/core/Grid';
import { Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MixPanelKey } from 'environmentVariables';

var mixpanel = require('mixpanel-browser');
mixpanel.init(MixPanelKey);

const InfoSection = styled.div`
    border-radius: 6px;
    background-color: #f7f7f7;
    margin: 10px;
    padding: 10px;
    color: ${theme.palette.primary.main};
`;

const Category = styled.div`
    margin: 0px;
`;

const CategoryTitle = styled.p`
    color: ${theme.palette.primary.main};
    font-size: 12;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 15px;
`;

const CategoryInfo = styled.div`
    background-color: #ffffff;
    border-radius: 6px;
    font-size: 12px;
    font-weight: regular;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 18px;
    margin-right: 18px;
    padding: 15;
`;

const CategorySeparator = styled.hr`
    width: 95%;
    size: 4;
    color: #f7f7f7;
    margin-top: 25px;
    margin-bottom: 10px;
`;

export default class PreferenceDisplay extends Component {
    CertsHandler(e) {
        const { certificationPreferences, setCerts } = this.props;

        //in order to splice and not change the original array without using setState you need to temporary set the array to a new variable
        let tempArray = certificationPreferences;

        // if the target is in the cert array, remove it
        if (certificationPreferences.includes(e.target.value)) {
            //splice the tempArray, leaving the original array that is in state unharmed
            tempArray.splice(certificationPreferences.indexOf(e.target.value), 1);
        }

        //otherwise, add it
        else {
            //add it to the tempArray, leaving the original array in the state unharmed
            tempArray.push(e.target.value);
        }

        //then set the in-state array to the new clean array
        setCerts({
            certificationPreferences: tempArray,
        });
    }

    ExpsHandler(e) {
        const { experiencePreferences, setExps } = this.props;

        //in order to splice and not change the original array without using setState you need to temporary set the array to a new variable
        let tempArray = experiencePreferences;

        // if the target is in the cert array, remove it
        if (experiencePreferences.includes(e.target.value)) {
            //splice the tempArray, leaving the original array that is in state unharmed
            tempArray.splice(experiencePreferences.indexOf(e.target.value), 1);
        }

        //otherwise, add it
        else {
            //add it to the tempArray, leaving the original array in the state unharmed
            tempArray.push(e.target.value);
        }

        //then set the in-state array to the new clean array
        setExps({
            experiencePreferences: tempArray,
        });
    }

    render() {
        const {
            certificationPreferences,
            experiences,
            certificates,
            experiencePreferences,
            patchPreference,
        } = this.props;

        return (
            <div>
                <Grid container justify="center" alignItems="center">
                    <Grid item justify="center" sm={6} xs={12}>
                        <InfoSection id="family-info" style={{ marginBottom: 100, minWidth: '90%' }}>
                            <Category>
                                <Grid container item>
                                    <CategoryTitle>I want a sitter with these certifications:</CategoryTitle>
                                    <CategoryInfo style={{ padding: 15, minWidth: '90%' }}>
                                        <Grid item>
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    {Object.entries(certificates).map((cert) => (
                                                        <FormControlLabel
                                                            key={cert[0]}
                                                            control={
                                                                <Checkbox
                                                                    disableRipple
                                                                    color="primary"
                                                                    value={cert[0]}
                                                                    onClick={(e) => this.CertsHandler(e)}
                                                                    checked={certificationPreferences.includes(cert[0])}
                                                                />
                                                            }
                                                            label={cert[1]}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </CategoryInfo>
                                    <CategorySeparator />
                                    <CategoryTitle>I want a sitter with these experiences:</CategoryTitle>
                                    <CategoryInfo style={{ padding: 15, minWidth: '90%' }}>
                                        <Grid item>
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    {Object.entries(experiences).map((cert) => (
                                                        <FormControlLabel
                                                            key={cert[0]}
                                                            control={
                                                                <Checkbox
                                                                    disableRipple
                                                                    color="primary"
                                                                    value={cert[0]}
                                                                    onClick={(e) => this.ExpsHandler(e)}
                                                                    checked={experiencePreferences.includes(cert[0])}
                                                                />
                                                            }
                                                            label={cert[1]}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </CategoryInfo>
                                    <Grid item xs={11} sm={11}>
                                        <Button
                                            onClick={() => {
                                                patchPreference();
                                                mixpanel.track('Profile: Edit Preference save button pressed.', {
                                                    experiences: experiences,
                                                    certificates: certificates,
                                                });
                                            }}
                                            style={{
                                                marginLeft: 17,
                                                color: '#ffffff',
                                                backgroundColor: theme.palette.primary.main,
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Category>
                        </InfoSection>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
