import React, { useEffect, useState } from 'react';
import JobFormPage from '../JobFormPage';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { client } from 'shared';
import JobTemplateOption from './JobTemplateOption';
import { BusinessJobTemplateDetailsDTO, BusinessJobTemplateListDTO } from 'parent-portal/forms/JobTemplateModels';
import { styled } from '@mui/material';
import { toJobFormPresets } from 'parent-portal/forms/JobFormPresets';
import { TemplateOption } from 'parent-portal/forms/JobRequestTypes';
import { OutlinedDropdown, Text } from 'library';
import { useUserContext } from 'UserContext';

export default function SelectTemplatePage() {
    const { user } = useUserContext();
    const { goToNext, form, setPresets, reset } = useJobFormContext();
    const { templateJobId, address, businessJobType, endDate } = form.fieldStates;
    const [jobTemplates, setJobTemplates] = useState<BusinessJobTemplateListDTO[]>([]);
    const selectedAddress = address.value?.length > 0 ? address.value[0].id : null;
    const trialRun = businessJobType.value === 'TRIAL_RUN';

    useEffect(() => {
        if (!address.value || address.value.length === 0) return;
        client(`api/job-template/?address=${address.value[0].id}`).then(setJobTemplates);
    }, [address.value]);

    function handleAddressChange(value: string) {
        address.setValue([{ id: parseInt(value) }]);
    }

    async function duplicateAndGoToNext() {
        if (!templateJobId) return;
        reset({ useTemplate: TemplateOption.TEMPLATE, businessJobType: businessJobType.value });
        client(`api/job-template/${templateJobId.value}/`).then((resp: BusinessJobTemplateDetailsDTO) => {
            setPresets({ ...toJobFormPresets(resp), multipleProviders: !trialRun });
            if (trialRun) {
                endDate.setValue(null);
            }
            goToNext();
        });
    }

    return (
        <JobFormPage
            title="Choose previous job post for template"
            onNextClicked={duplicateAndGoToNext}
            nextDisabled={!templateJobId}
        >
            {user && user.address.length > 0 ? (
                <LocationContainer>
                    <Text variant="body1" bold>
                        Location:
                    </Text>
                    <OutlinedDropdown
                        value={selectedAddress || ''}
                        fields={user.address.map((addr) => {
                            return { key: addr.id.toString(), value: addr.description || '' };
                        })}
                        onChange={(e) => handleAddressChange(e.target.value)}
                    />
                </LocationContainer>
            ) : null}
            <ListContainer>
                {jobTemplates.length > 0 ? (
                    jobTemplates.map((jobTemplate) => (
                        <JobTemplateOption key={jobTemplate.id} jobTemplate={jobTemplate} />
                    ))
                ) : (
                    <Text variant="body1">
                        Sorry, we couldn&lsquo;t find any recent jobs to duplicate for this location.
                    </Text>
                )}
            </ListContainer>
        </JobFormPage>
    );
}

const LocationContainer = styled('div')({
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
});

const ListContainer = styled('div')({
    marginTop: 20,
});
