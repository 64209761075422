import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseButton from '../../../reusableComponents/CloseButton';
import { Provider, Job } from 'models';
import { track } from 'shared';
import InviteForm from '../../sitters/ProviderPreview/Components/InviteProviderDialog/InviteForm';
interface IProps {
    open: boolean;
    onClose: () => void;
    provider: Provider;
    next: boolean;
    nextAction: () => void;
    page?: string;
    jobRequest: Job;
    location?: string;
}

export default function ProviderRequestSuccessDialog({
    open,
    onClose,
    provider,
    next = false,
    nextAction,
    page = '',
    jobRequest,
    location,
}: IProps) {
    useEffect(() => {
        track(`RT/P@P: Provider Request Success Dialog Opened from ${page} Page`);
        track(`RT/P@P: ${page} job request made with a requested provider`, { provider: provider });
    }, []);

    return (
        <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
            <DialogTitle>
                <CloseButton onClose={onClose} />
            </DialogTitle>
            <DialogContent>
                <InviteForm
                    providers={[provider]}
                    onClose={nextAction}
                    job={jobRequest}
                    from={'Provider Request Success Dialog'}
                    requested={false}
                    sentButtonText={next ? 'Next' : 'Close'}
                    location={location}
                />
            </DialogContent>
        </Dialog>
    );
}
