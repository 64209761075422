import React from 'react';
import { MenuItem, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Checkbox, Text, OnProbationChip } from 'library';
import { AdminListProvider, BusinessJobWithAppointments, LeanUser } from 'models';
import AddProvider from '../AddProvider';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import SendMatch from './SendMatch';
import { StandByProvider } from './StandbyList';
import SubAvailability from './SubAvailability';
import ProviderDetailTextButton from 'internal/shared/ProviderDetailTextButton';
import Colors from 'constants/Colors';
import ProviderBookings from './ProviderBookings';
import moment from 'moment';

export default function MatchingMenuItem({
    provider,
    job,
    refreshJobs,
    userSelected,
    setSelectedUser,
    newMatches,
    week,
}: {
    provider: AdminListProvider | StandByProvider;
    job: BusinessJobWithAppointments;
    refreshJobs: () => void;
    userSelected: boolean;
    setSelectedUser: (user: LeanUser) => void;
    newMatches: number[];
    week?: Date | undefined;
}) {
    const user = provider.user;

    function content() {
        if (Object.keys(provider).includes('sub_availability')) {
            const adminProvider = provider as AdminListProvider;
            return adminProvider.sub_availability ? (
                <Grid container>
                    <SubAvailability sub_availability={adminProvider.sub_availability} />
                    <ProviderBookings userId={provider.user.id} week={week} job={job} />
                </Grid>
            ) : null;
        } else {
            const standbyProvider = provider as StandByProvider;
            return standbyProvider.standby ? (
                <Grid container>
                    <Text variant="body2">
                        {formatShortWeekdayMonthDay(new Date(standbyProvider.standby.date + 'T00:00:00'))}{' '}
                        {moment(standbyProvider.standby.start_time).format('h:mm a')} -{' '}
                        {moment(standbyProvider.standby.end_time).format('h:mm a')}
                    </Text>
                    <ProviderBookings userId={provider.user.id} week={week} job={job} />
                </Grid>
            ) : null;
        }
    }

    return (
        <MenuItem
            disableRipple
            style={{
                marginTop: 8,
                justifyContent: 'flex-start',
                background: `${newMatches.includes(provider.id) ? Colors.mediumGreen : ''}`,
            }}
        >
            <div style={{ padding: '1em' }}>
                <div
                    style={{
                        marginBottom: 8,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        label=""
                        checked={userSelected}
                        onChange={(_) => setSelectedUser(user)}
                        style={{ display: 'inline', width: 'auto' }}
                    />
                    <ProviderDetailTextButton user={user} />
                    {!!job?.user_on_probation && (
                        <ProbationWrapper>
                            <OnProbationChip />
                        </ProbationWrapper>
                    )}
                    <AddProvider job={job} refreshJobs={refreshJobs} initialProviderUserId={user.id} />
                    <SendMatch job={job} initialProviders={[provider.user]} refreshJobs={refreshJobs} />
                </div>
                {content()}
            </div>
        </MenuItem>
    );
}

const ProbationWrapper = styled('div')({
    marginRight: 8,
});
