import React from 'react';
import { AppliedProvider } from 'models';
import { Cake, ThumbUpOutlined } from '@material-ui/icons';
import { Colors, Paper, Text } from 'library';
import ProviderProfileImage from 'parent-portal/provider-profile/ProviderProfileImage';
import ProviderSummaryDetailRow from 'parent-portal/provider-profile/ProviderSummaryDetailRow';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { track } from 'shared';
import HelpIcon from '@material-ui/icons/Help';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';

const AppliedProviderCard = ({
    provider,
    selected,
    setSelected,
    hideApplicant,
    likeApplicant,
}: {
    provider: AppliedProvider;
    setSelected: (id: number) => void;
    selected?: boolean;
    hideApplicant: () => void;
    likeApplicant: () => void;
}) => {
    function distanceText() {
        let distance = provider.distance?.miles ?? -1;
        return distance >= 0 && distance <= 1 ? `Less than a mile away` : distance > 1 ? `${distance} miles away` : '';
    }
    const details = [
        { detail: `${provider.age} years old`, icon: <Cake style={{ fontSize: 14 }} /> },
        {
            detail: distanceText(),
            icon: <FontAwesomeIcon icon={faMapMarker} style={{ fontSize: 10 }} />,
        },
    ];

    const selectProvider = () => {
        track('Applicant List select provider', { provider });
        setSelected(provider.user.id);
    };

    return (
        <button
            onClick={selectProvider}
            style={{ border: 'none', backgroundColor: 'transparent', outline: 'none', width: '100%' }}
        >
            <Paper
                style={{
                    height: 150,
                    maxWidth: 340,
                    padding: 10,
                    marginBottom: 10,
                    backgroundColor: selected ? Colors.mediumTurq : Colors.lightTurq,
                    cursor: 'pointer',
                    position: 'relative',
                }}
            >
                {provider.liked ? (
                    <ThumbUpIcon
                        style={{
                            color: Colors.darkNavy,
                            position: 'absolute',
                            borderRadius: '100%',
                            right: '5px',
                            top: '5px',
                            backgroundColor: 'white',
                            boxShadow: '0px 3px 6px #00000029',
                            border: '3px solid white',
                            width: 25,
                            height: 25,
                        }}
                        onClick={likeApplicant}
                    />
                ) : (
                    <ThumbUpOutlined
                        style={{
                            color: Colors.darkNavy,
                            position: 'absolute',
                            borderRadius: '100%',
                            right: 5,
                            top: 5,
                            backgroundColor: 'white',
                            boxShadow: '0px 3px 6px #00000029',
                            border: '3px solid white',
                            width: 25,
                            height: 25,
                        }}
                        onClick={likeApplicant}
                    />
                )}

                <Text
                    variant="caption"
                    textStyle={{
                        textDecoration: 'underline',
                        lineHeight: 1,
                        color: Colors.darkNavy,
                        position: 'absolute',
                        right: '5px',
                        bottom: '5px',
                        fontSize: '10px',
                        fontWeight: 700,
                    }}
                    onClick={hideApplicant}
                >
                    {provider.hidden ? <a>Unhide applicant</a> : <a>Hide applicant</a>}
                    <HelpIcon style={{ fontSize: '10px', marginLeft: '7px' }} />
                </Text>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 10,
                        maxHeight: 130,
                    }}
                >
                    <ProviderProfileImage provider={provider} mobile />
                    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Text
                            variant="display"
                            textStyle={{
                                fontSize: 26,
                                maxWidth: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                lineHeight: '36px',
                            }}
                        >
                            {provider.user.first_name} {provider.user.last_name}
                        </Text>
                        {details.map((detail) => (detail.detail ? <ProviderSummaryDetailRow {...detail} /> : null))}
                    </div>
                </div>
            </Paper>
        </button>
    );
};

export default AppliedProviderCard;
