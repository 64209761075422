import React from 'react';
import Text from '../text/Text';
import { Grid } from '@material-ui/core';
import Checkbox from './Checkbox';

interface CheckboxSelectProps {
    options: string[];
    additionalOptionText?: string;
    value: string[];
    onChange: (selectedValues: string[]) => void;
    isDisabled?: (option: string) => boolean;
}

export default function CheckboxSelect({
    options,
    additionalOptionText,
    value: values,
    onChange,
    isDisabled = () => false,
}: CheckboxSelectProps) {
    return (
        <Grid container direction="column" justify="flex-start" style={{ width: '100%' }}>
            {options.map((option: string) => (
                <Grid
                    key={option}
                    container
                    item
                    direction="row"
                    justify="flex-start"
                    style={{ alignItems: 'center', width: '100%' }}
                >
                    <Checkbox
                        checked={values.includes(option)}
                        disabled={isDisabled(option)}
                        onChange={(checked) => {
                            const updatedValues = checked
                                ? [...values, option]
                                : values.filter((value) => value !== option);
                            onChange(updatedValues);
                        }}
                        label={option}
                    />

                    {additionalOptionText && (
                        <Text
                            variant="body2"
                            textStyle={{
                                paddingTop: 5,
                            }}
                        >
                            {`${additionalOptionText}`}
                        </Text>
                    )}
                </Grid>
            ))}
        </Grid>
    );
}
