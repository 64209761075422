import React, { useState } from 'react';
import JobFormPage from '../JobFormPage';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import BusinessDatesAndTimes from './BusinessDatesAndTimes';

export default function BusinessDateAndTimePage() {
    const [isValid, setIsValid] = useState(false);
    const { goToNext } = useJobFormContext();

    return (
        <JobFormPage title="When is your job?" onNextClicked={goToNext} backText="Back" nextDisabled={!isValid}>
            <BusinessDatesAndTimes setIsValid={setIsValid} />
        </JobFormPage>
    );
}
