import React, { useState } from 'react';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { FieldStates } from '../../FormTypes';
import JobFormPage from '../JobFormPage';
import { BasicDialog, Colors, Text, TextButton } from 'library';
import { JobFormFields } from 'parent-portal/forms/JobRequestTypes';
import TooltipIconButton from 'library/components/buttons/TooltipIconButton';
import { track } from 'shared/Tracking';
import WeeklyJobCopy from './WeeklyJobCopy';
import OnetimeJobCopy from './OnetimeJobCopy';
import Link from '../../../../../reusableComponents/link';
import { Grid } from '@material-ui/core';

interface TandemPayPageFields {
    acceptedTandemPayAgreement: JobFormFields['acceptedTandemPayAgreement'];
    requestType: JobFormFields['requestType'];
}

interface TandemPayPageProps {
    fieldStates: FieldStates<TandemPayPageFields>;
}

export default function TandemPayPage({
    fieldStates: { acceptedTandemPayAgreement, requestType },
}: TandemPayPageProps) {
    const { goToNext } = useJobFormContext();

    const [showHowTandemWorks, setShowHowTandemWorks] = useState(false);
    const [showProviderExplanation, setShowProviderExplanation] = useState(false);

    function onNextClicked() {
        acceptedTandemPayAgreement.setValue(true);
        goToNext();
    }

    function openHowTandemWorksDialog() {
        setShowHowTandemWorks(true);
        track('Tandem Pay Agreement: opened how tandem is free');
    }

    function closeHowTandemWorksDialog() {
        setShowHowTandemWorks(false);
        track('Tandem Pay Agreement: closed how tandem is free');
    }

    function openProviderExplanationDialog() {
        setShowProviderExplanation(true);
        track('Tandem Pay Agreement: opened how does this work for providers');
    }

    function closeProviderExplanationDialog() {
        setShowProviderExplanation(false);
        track('Tandem Pay Agreement: closed how does this work for providers');
    }

    return (
        <>
            <JobFormPage
                nextText="I agree"
                onNextClicked={onNextClicked}
                title=""
                nextDisabled={false}
                id="tandem-pay-page"
            >
                <Text variant="h1" bold>
                    Meet TandemPay
                </Text>

                {requestType.value === 'recurring' ? <WeeklyJobCopy /> : <OnetimeJobCopy />}
                <Grid container wrap="nowrap">
                    <TooltipIconButton onClick={openProviderExplanationDialog} />
                    <div style={{ flexShrink: 1 }}>
                        <TextButton onClick={openProviderExplanationDialog}>
                            <Text textStyle={{ display: 'inline' }} variant="deprecatedCaption" bold>
                                How does this work for my provider?
                            </Text>
                        </TextButton>
                    </div>
                </Grid>
                <Grid container wrap="nowrap">
                    <TooltipIconButton onClick={openHowTandemWorksDialog} />
                    <div style={{ flexShrink: 1 }}>
                        <TextButton onClick={openHowTandemWorksDialog}>
                            <Text textStyle={{ display: 'inline' }} variant="deprecatedCaption" bold>
                                How is Tandem free?
                            </Text>
                        </TextButton>
                    </div>
                </Grid>
                <div style={{ marginTop: 57 }}>
                    <Text variant="h2" color={Colors.mediumRed} textStyle={{ fontWeight: 'bolder' }}>
                        You'll pay all Tandem providers through the site.
                    </Text>
                </div>
                <div
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        zIndex: 1,
                        marginBottom: -15,
                    }}
                >
                    <Link target="_blank" href="/tandempay">
                        I need more information
                    </Link>
                </div>
            </JobFormPage>
            <BasicDialog
                isOpen={showHowTandemWorks}
                onClose={closeHowTandemWorksDialog}
                closeButton
                dialogTitle="How is Tandem free?"
            >
                <Text variant="body1">
                    This is free for you and Tandem providers. Tandem acts as a bank for our care providers' earnings
                    allowing us to remain completely fee free. Without TandemPay (you paying your providers through
                    Tandem), we cannot continue to exist as a free connection platform.
                </Text>
            </BasicDialog>
            <BasicDialog
                isOpen={showProviderExplanation}
                onClose={closeProviderExplanationDialog}
                closeButton
                dialogTitle="How does this work for my provider?"
            >
                <Text variant="body1" textStyle={{ whiteSpace: 'pre-wrap' }}>
                    Tandem providers receive 100% of what you pay them. We provide a free banking experience that allows
                    providers to spend earnings on a free Visa debit card that we issue to them, transfer to their
                    personal bank account, or save within the app.{'\n\n'}By acting as a bank for care providers'
                    earnings, Tandem can exist without charging fees to either side. It's a win-win!
                </Text>
            </BasicDialog>
        </>
    );
}
