import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import React from 'react';
import { FieldStates } from '../../FormTypes';
import JobFormPage from '../JobFormPage';
import { JobFormFields } from 'parent-portal/forms/JobRequestTypes';
import { useFieldValidation } from '../../FormHook';
import BusinessPayInputs, { payRangeInfoValid } from './BusinessPayInputs';
interface PayPageProps {
    fieldStates: FieldStates<JobFormFields>;
}

export default function BusinessPayPage({ fieldStates: { pay, payScales } }: PayPageProps) {
    const {
        loading,
        goToNext,
        defaultPayScales,
        updateDefaultPayScales,
        hasSeenTrainingsModal,
        updateHasSeenTrainingsModal,
    } = useJobFormContext();

    useFieldValidation(pay);

    function pageIsValid() {
        return pay.isValid;
    }

    return (
        <JobFormPage
            title={'Finalizing Details...'}
            onNextClicked={goToNext}
            nextText={'Next'}
            nextDisabled={loading || !pageIsValid() || !payRangeInfoValid(payScales.value)}
        >
            <BusinessPayInputs
                pay={pay}
                payScales={payScales}
                defaultPayScales={defaultPayScales}
                updateDefaultPayScales={updateDefaultPayScales}
                hasSeenTrainingsModal={hasSeenTrainingsModal}
                updateHasSeenTrainingsModal={updateHasSeenTrainingsModal}
            />
        </JobFormPage>
    );
}
