import React, { useState } from 'react';
import { TaskListItem } from '../matchingInboxModels';
import Task from './Task';
import { styled } from '@mui/material';
import { isToday, isTomorrow } from 'date-fns';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { Colors, Text } from 'library';
import { orderBy } from 'lodash';
import { ChevronLeft } from '@material-ui/icons';
import GroupTaskMenu from './GroupTaskMenu';

export default function GroupedTasks({
    group,
    tasks,
    refresh,
    onEdit,
}: {
    group: string;
    tasks: TaskListItem[];
    refresh: () => void;
    onEdit: (task: TaskListItem) => void;
}) {
    const [show, setShow] = useState(true);

    function displayDate(date: string | null) {
        if (!date) return 'Unknown';

        const today = isToday(new Date(date));
        const tomorrow = isTomorrow(new Date(date));
        return today ? 'Today' : tomorrow ? 'Tomorrow' : formatShortWeekdayMonthDay(date);
    }

    const title =
        group === '0' ? 'No Business Location Found' : tasks[0].business_location?.name ?? `Business Location ${group}`;

    const inboxId = group === '0' ? 0 : tasks[0].inbox;

    const orderedTasks = orderBy(tasks, ['added_at'], ['desc']);

    return (
        <div>
            <TitleContainer>
                <SubTitleContainer onClick={() => setShow(!show)}>
                    <Text variant="body1" bold color={Colors.turquoise} textStyle={{ textDecoration: 'underline' }}>
                        {title}
                    </Text>
                    <Text
                        variant="caption"
                        bold
                        textStyle={{
                            alignItems: 'center',
                            backgroundColor: Colors.darkNavy,
                            borderRadius: 50,
                            color: Colors.white,
                            display: 'flex',
                            marginLeft: 8,
                            padding: '0 8px',
                        }}
                    >
                        {orderedTasks.length}
                    </Text>
                </SubTitleContainer>
                <SubTitleContainer onClick={() => setShow(!show)}>
                    <Text variant="caption">Earliest Due Date: {displayDate(orderedTasks[0].expires_at)}</Text>
                    <ChevronLeft style={{ rotate: show ? '-90deg' : '90deg', marginLeft: 8 }} />
                </SubTitleContainer>
                <SubTitleContainer>
                    <GroupTaskMenu inboxId={inboxId} businessLocationId={parseInt(group)} refresh={refresh} />
                </SubTitleContainer>
            </TitleContainer>
            {show &&
                orderedTasks.map((task) => (
                    <Task
                        key={task.id}
                        task={task}
                        refresh={refresh}
                        noDivider={task.id === orderedTasks[orderedTasks.length - 1].id}
                        onEdit={onEdit}
                    />
                ))}
            <FatDivider />
        </div>
    );
}

const TitleContainer = styled('div')({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    width: '100%',
});

const SubTitleContainer = styled('div')({
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
});

const FatDivider = styled('div')({
    width: '100%',
    height: 3,
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: Colors.mediumGrey,
    borderRadius: 5,
});
