import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { Paper } from 'library';
import { Provider } from 'models';
import { client, queryStringifyObject } from 'shared';
import { useUserContext } from 'UserContext';

import PreferWorkerModal from 'parent-portal/shared/UserActions/PreferWorkerModal';
import BanWorkerModal from 'parent-portal/shared/UserActions/BanWorkerModal';
import { BannedButton } from 'parent-portal/shared/UserActions/BanWorkerModal/BannedButton';
import { PreferredButton } from 'parent-portal/shared/UserActions/PreferWorkerModal/PreferredButton';

import ProviderProfileImage from './ProviderProfileImage';
import ProviderProfileActions from './actions/ProviderProfileActions';
import BusinessProviderSummary from './BusinessProviderSummary';
import FamilyProviderSummary from './FamilyProviderSummary';

import type { BusinessLocationPreferredWorker, BusinessLocationBannedWorker } from 'models';

export default function ProviderSummaryCard({ provider, mobile }: { provider: Provider; mobile: boolean }) {
    const { role, user } = useUserContext();

    function distanceText() {
        const distance = provider.distance?.miles ?? -1;
        return distance >= 0 && distance <= 1 ? `Less than a mile away` : distance > 1 ? `${distance} miles away` : '';
    }

    const businessId = user?.businesses?.[0]?.id;

    const [openBanModal, setOpenBanModal] = useState<boolean>(false);
    const [openPreferredModal, setOpenPreferredModal] = useState<boolean>(false);

    const [bannedAll, setBannedAll] = useState<boolean>(false);
    const [bannedLocations, setBannedLocations] = useState<number>(0);

    const [preferredAll, setPreferredAll] = useState<boolean>(false);
    const [preferredLocations, setPreferredLocations] = useState<number>(0);

    const preferParams = queryStringifyObject({ business_id: businessId, preferred_worker_id: provider.user.id });
    const blockParams = queryStringifyObject({ business_id: businessId, business_outlaw_id: provider.user.id });

    useEffect(() => {
        if (businessId) {
            client(`api/preferred-worker/locations-and-statuses?${preferParams}`, { method: 'GET' })
                .then((response) => handlePrefer(response))
                .catch(() => {});

            client(`api/business-outlaws/locations-and-statuses?${blockParams}`, { method: 'GET' })
                .then((response) => handleBan(response))
                .catch(() => {});
        }
    }, [businessId]);

    function handlePrefer(preferredWorkers: BusinessLocationPreferredWorker[]) {
        const preferred: number = preferredWorkers.reduce(
            (total, preferredWorker) => total + (preferredWorker.is_preferred_worker ? 1 : 0),
            0,
        );
        const allPreferred = preferred === preferredWorkers.length;

        setPreferredAll(allPreferred);
        setPreferredLocations(preferred);
    }

    function handleBan(bannedWorkers: BusinessLocationBannedWorker[]) {
        const banned: number = bannedWorkers.reduce(
            (total, bannedWorker) => total + (bannedWorker.is_business_outlaw ? 1 : 0),
            0,
        );
        const allBanned = banned === bannedWorkers.length;

        setBannedAll(allBanned);
        setBannedLocations(banned);
    }

    return (
        <>
            <Paper
                style={{
                    maxWidth: mobile ? undefined : 336,
                    padding: mobile ? 10 : 20,
                }}
            >
                <Container mobile={mobile.toString()}>
                    <ProviderProfileImage
                        provider={provider}
                        mobile={mobile}
                        showBadge={role === 'business_active' && provider.hasBCIFBICheck}
                    />
                    {!mobile && <ProviderProfileActions mobile={mobile} />}
                    {role === 'business_active' ? (
                        <BusinessProviderSummary provider={provider} distanceText={distanceText()} mobile={mobile} />
                    ) : (
                        <FamilyProviderSummary provider={provider} distanceText={distanceText()} />
                    )}
                </Container>
                {mobile && <ProviderProfileActions mobile={mobile} />}
            </Paper>
            {!!businessId && (
                <ButtonContainer>
                    <PreferredButton
                        preferredAll={preferredAll}
                        preferredLocations={preferredLocations}
                        setOpenPreferredModal={setOpenPreferredModal}
                        buttonTextStyle={{ fontSize: 12 }}
                        style={{ justifyContent: 'flex-start' }}
                    />
                    <BannedButton
                        bannedAll={bannedAll}
                        bannedLocations={bannedLocations}
                        setOpenBanModal={setOpenBanModal}
                        buttonTextStyle={{ fontSize: 12 }}
                        style={{ justifyContent: 'flex-start', marginTop: 10 }}
                    />
                    <PreferWorkerModal
                        open={openPreferredModal}
                        provider={{
                            id: provider.user.id,
                            first_name: provider.user.first_name,
                            last_name: provider.user.last_name,
                            profile_picture: { image: provider.profile_image?.image ?? '' },
                        }}
                        onSuccess={handlePrefer}
                        onClose={() => setOpenPreferredModal(false)}
                    />
                    <BanWorkerModal
                        open={openBanModal}
                        provider={{
                            id: provider.user.id,
                            first_name: provider.user.first_name,
                            last_name: provider.user.last_name,
                            profile_picture: { image: provider.profile_image?.image ?? '' },
                        }}
                        onSuccess={handleBan}
                        onClose={() => setOpenBanModal(false)}
                        leaveFeedback
                    />
                </ButtonContainer>
            )}
        </>
    );
}

const Container = styled('div')(({ mobile }: { mobile: string }) => ({
    display: 'flex',
    flexDirection: mobile === 'true' ? 'row' : 'column',
    alignItems: 'center',
    marginTop: 10,
}));

const ButtonContainer = styled('div')({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
});
