import React, { useEffect, useState } from 'react';
import { Text } from 'library';
import { IComplianceProgress, IComplianceProgressProps } from './models';
import { styled, Grid } from '@mui/material';
import useLoading from 'library/hooks/useLoading';
import { client, consoleLogInDev } from 'shared';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import PendingIcon from '@mui/icons-material/Pending';
import Box from '@mui/material/Box';

export default function ComplianceProgress({ providerUserId }: IComplianceProgressProps) {
    const [complianceProgress, setComplianceProgress] = useState<IComplianceProgress>();
    const { loading, setLoading, LoadingSpinner } = useLoading();
    const [errorText, setErrorText] = useState<string>();

    useEffect(() => {
        if (providerUserId && providerUserId > 0) {
            refreshHistory();
        }

        /* eslint-disable no-comments/disallowComments */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerUserId]);
    /* eslint-enable no-comments/disallowComments */

    function refreshHistory() {
        setLoading(true);
        setErrorText(undefined);
        client(`api/providers/vetting-status-admin/?user_id=${providerUserId}`)
            .then(setComplianceProgress)
            .catch((error) => {
                setErrorText(error.message);
                consoleLogInDev(error);
            })
            .finally(() => setLoading(false));
    }

    if (loading) return <LoadingSpinner />;

    if (errorText)
        return (
            <Container>
                <Text>{errorText}</Text>
            </Container>
        );

    const statusToColor = {
        NOT_STARTED: 'gray',
        IN_PROGRESS: '#FFA500',
        COMPLETED: 'green',
    };

    const statusToIcon = {
        NOT_STARTED: <CheckBoxOutlineBlankIcon style={{ color: statusToColor.NOT_STARTED }} />,
        IN_PROGRESS: <PendingIcon style={{ color: statusToColor.IN_PROGRESS }} />,
        COMPLETED: <CheckIcon style={{ color: statusToColor.COMPLETED }} />,
    };

    return (
        <Container container item>
            <Grid container item flexDirection="column">
                <Text variant="h1">Compliance Progress History</Text>
                <Grid container item>
                    <Grid item>
                        <Text color={complianceProgress?.ready_to_work ? 'green' : 'red'} variant="h2">
                            {complianceProgress?.ready_to_work ? 'Ready to work' : 'Not ready to work'}
                        </Text>
                        {complianceProgress?.unmet_conditions && (
                            <Text color={statusToColor.NOT_STARTED}>
                                {complianceProgress.unmet_conditions.join(', ')}
                            </Text>
                        )}

                        {complianceProgress?.to_do_list.map((step, index) => {
                            if (step.status !== null) {
                                return (
                                    <Grid item key={`${step.key}-${index}`}>
                                        <Box display="flex" alignItems="center">
                                            {statusToIcon[step.status]}
                                            <Text>{step.name}</Text>
                                        </Box>
                                    </Grid>
                                );
                            }
                            return null;
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

const Container = styled(Grid)({
    padding: 20,
    gap: 20,
});
