import { AdminListProvider, Image, LeanAdminProvider, LeanUser } from 'models';
import { Dispatch, SetStateAction } from 'react';
export interface CommonFormProps {
    url: string;
    setUrl: (textValue: string) => void;
    code: string;
    setCode: (textValue: string) => void;
    jobType: string;
    setJobType: (textValue: string) => void;
    duration: string;
    setDuration: (textValue: string) => void;
    contactSourceId: number;
    setContactSourceId: (numberValue: number) => void;
    contactSources: ContactSourceKeyValuePairs[];
}

export interface FormProps extends CommonFormProps {
    firstName: string;
    setFirstName: (textValue: string) => void;
    lastName: string;
    setLastName: (textValue: string) => void;
    phoneNumber: string;
    setPhoneNumber: (textValue: string) => void;
    email: string;
    setEmail: (textValue: string) => void;
    profession: string;
    setProfession: (textValue: string) => void;
    jobId: string;
    setJobId: (textValue: string) => void;
    description: string;
    setDescription: (textValue: string) => void;
}

export interface UploadFormProps extends CommonFormProps {
    setFile: (newFile?: File) => void;
    isLegacy: boolean;
}

export interface ThreadData {
    phone_number: string;
    messages: number;
    unread: number;
    most_recent: string;
    first_name?: string;
    last_name?: string;
    user_id?: number;
    id: number;
    user_first_name?: string;
    user_last_name?: string;
    babysitter_id?: number;
}

export interface ThreadItem {
    data: ThreadData;
    selectedNumber: string;
    onCardClick: (phoneNumber: string) => void;
}
export interface ConversationProps {
    externalNumber: string;
    tandemNumber: string;
    displayName?: string;
    provider?: AdminListProvider | LeanAdminProvider | void;
    userId?: number;
    thread_id?: number;
    state?: string;
    medStatus?: string | undefined;
    onMarkUnread?: () => void;
}

export interface ConversationData {
    id: number;
    added_at: string;
    sender_phone_number: string;
    receiver_phone_number: string;
    message?: string;
    status?: string;
    viewed_at?: string;
    error_message?: string;
    image?: Image;
    user_id?: number;
}

export interface ConversationRowProps {
    data: ConversationData;
    externalNumber: string;
}

export interface SearchProps {
    onSearch: (clearSearch?: boolean) => void;
    displayText?: string;
    search: string;
    senderPhone: string;
    refreshThreads?: () => void;
    setSelectedNumber: Dispatch<SetStateAction<string>>;
    setSearch: Dispatch<SetStateAction<string>>;
    selectedNumber?: string;
}

export interface PreviewData {
    days_until_send: number;
    message: string;
}

export interface ContactSourceData {
    id: number;
    added_at: string;
    last_updated: string;
    organization: string;
    description?: string;
    type: string;
}

export interface ContactSourceKeyValuePairs {
    key: number;
    value: string;
}
export interface TandemNumberKeyValuePairs {
    key: string;
    value: string;
}

export enum TANDEM_NUMBERS {
    INDEED_MAIN_FOLLOWUP = '+16147052786',
    PROVIDER_OUTREACH = '+16146183792',
    PROVIDER_SUCCESS_FUNNEL = '+16144123264',
    NEW_PROVIDER_OUTREACH = '+16146420112',
    OLIVIA_GV = '+16143495661',
    MCKENZIE_GV = '+16143448887',
    NATALIE_GV = '+16142854739',
}

export interface IMessageThread {
    id: number;
    tandem_phone_number: string;
    external_phone_number: string;
    unread_count: number;
    last_updated: string;
    user?: LeanUser;
    state?: string;

    // NOTE: not really on there but trying to supress some errors
    first_name?: string;
    last_name?: string;
    user_first_name?: string;
    user_last_name?: string;
    user_id?: number;
    babysitter_id?: number;
}
