import React, { useEffect, useState } from 'react';
import { TablePagination, styled } from '@mui/material';
import { Call, Email, Notes } from '@material-ui/icons';
import { Colors, DropDown, LoadingSpinner, SmallIcon, Text, IconButton } from 'library';
import { listLocationCommunications } from 'api/CommunicationHistoryApi';

import { LocationEmailCommunicationRow } from './LocationEmailCommunicationRow';
import { LocationCallCommunicationRow } from './LocationCallCommunicationRow';
import { LocationNoteCommunicationRow } from './LocationNoteCommunicationRow';

import type { IBusinessLocationWithNotes, Note, PagingResult } from 'models';
import type { CommunicationHistory } from 'api/CommunicationHistoryApi';
import type { EmailHistory } from './LocationEmailHistory';
import type { CallHistory } from 'models/CallHistory';
import { Phone, StickyNote2 } from '@mui/icons-material';
import { useAdminAppContext } from 'internal/shared/AdminAppContext';

export default function LocationAllCommunicationsHistory({
    businessLocationId,
    businessLocation,
    locationLoading,
    locationErrored,
}: {
    businessLocationId: number;
    businessLocation: IBusinessLocationWithNotes | undefined;
    locationLoading: boolean;
    locationErrored: boolean;
}) {
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [history, setHistory] = useState<PagingResult<CommunicationHistory> | null>(null);
    const [selectedEmail, setSelectedEmail] = useState<string>('All');
    const { setGlobalPhoneDialogOpen, setGlobalNotesDialogOpen } = useAdminAppContext();
    const [refreshToken, setRefreshToken] = useState<number>(0);
    const phoneButtonRef = React.useRef<HTMLButtonElement>(null);
    const notesButtonRef = React.useRef<HTMLButtonElement>(null);

    function refresh() {
        setRefreshToken(refreshToken + 1);
    }

    useEffect(() => {
        setLoading(true);
        setError(false);

        listLocationCommunications(businessLocationId, page, selectedEmail)
            .then((response: PagingResult<CommunicationHistory>) => setHistory(response))
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, [businessLocationId, page, selectedEmail, refreshToken]);

    if (loading || locationLoading)
        return (
            <Container>
                <LoadingSpinner />
            </Container>
        );

    if (error || locationErrored || !businessLocation) {
        return (
            <Container>
                <Text textStyle={{ color: Colors.error }}>
                    Something went wrong, contact the devs if the problem persists.
                </Text>
            </Container>
        );
    }

    const icons = {
        call: {
            icon: <Call style={{ height: 20, width: 20 }} />,
            backgroundColor: Colors.darkNavy,
        },
        email: {
            icon: <Email style={{ height: 20, width: 20 }} />,
            backgroundColor: Colors.turquoise,
        },
        note: {
            icon: <Notes style={{ height: 20, width: 20 }} />,
            backgroundColor: Colors.lightTerraCotta,
        },
    };

    const allEmail = {
        key: 'All',
        value: 'All Contacts',
    };

    const emails =
        businessLocation && businessLocation?.business_contacts
            ? [
                  allEmail,
                  {
                      key: businessLocation?.business.user.email,
                      value: `${businessLocation?.business.user.first_name} ${businessLocation?.business.user.last_name}`,
                  },
                  ...businessLocation.business_contacts.map((x) => {
                      return {
                          key: x.user.email,
                          value: `${x.user.first_name} ${x.user.last_name}`,
                      };
                  }),
              ]
            : [allEmail];

    function onSelectEmail(email: string) {
        setSelectedEmail(email);
        setPage(1);
    }

    return (
        <Container>
            <Header>
                <div style={{ flex: 2 }}>
                    <Text bold>Business Contacts:</Text>
                    <DropDownContainer>
                        <DropDown
                            selected={selectedEmail}
                            placeholder="Chose Email"
                            title="Email"
                            fields={emails}
                            onChange={(e) => onSelectEmail(e.target.value)}
                            style={{ maxWidth: 450 }}
                        />
                    </DropDownContainer>
                </div>
                <Buttons>
                    <IconButton
                        tooltip="Add a call"
                        ref={phoneButtonRef}
                        icon={Phone}
                        color={Colors.darkNavy}
                        onClick={() =>
                            setGlobalPhoneDialogOpen({
                                isOpen: true,
                                name: businessLocation.name,
                                businessLocationId: businessLocationId,
                                refresh: refresh,
                                anchorElement: phoneButtonRef.current,
                            })
                        }
                    />
                    <IconButton
                        tooltip="Add a note to this business location"
                        ref={notesButtonRef}
                        icon={StickyNote2}
                        color={Colors.darkNavy}
                        onClick={() =>
                            setGlobalNotesDialogOpen({
                                isOpen: true,
                                name: businessLocation.name,
                                type: 'business-location',
                                objectId: businessLocationId,
                                refresh,
                                anchorElement: notesButtonRef.current,
                            })
                        }
                    />
                </Buttons>
            </Header>
            {history?.results.map((item: CommunicationHistory) => (
                <Row key={`${item.communication_type} ${item.communication.id}`}>
                    <SmallIcon
                        icon={icons[`${item.communication_type}`].icon}
                        color={Colors.white}
                        style={{ background: icons[`${item.communication_type}`].backgroundColor }}
                    />
                    {item.communication_type === 'email' && (
                        <LocationEmailCommunicationRow email={item.communication as EmailHistory} />
                    )}
                    {item.communication_type === 'call' && (
                        <LocationCallCommunicationRow
                            businessLocation={businessLocation}
                            uneditedCall={item.communication as CallHistory}
                        />
                    )}
                    {item.communication_type === 'note' && (
                        <LocationNoteCommunicationRow note={item.communication as Note} />
                    )}
                </Row>
            ))}
            <TablePagination
                count={history?.count || 0}
                onPageChange={(_, newPage: number) => setPage(newPage + 1)}
                page={page - 1}
                rowsPerPage={history?.per_page || 0}
                rowsPerPageOptions={[]}
                showFirstButton
                showLastButton
                sx={{ '& .MuiTablePagination-displayedRows': { marginBottom: 0 } }}
            />
        </Container>
    );
}

const Header = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
});

const Buttons = styled('div')({
    alignItems: 'flex-end',
    display: 'flex',
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 20,
    paddingRight: 10,
    gap: 10,
});

const DropDownContainer = styled('div')({
    paddingLeft: 10,
    paddingTop: 10,
});

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 10,
});

const Row = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
});
