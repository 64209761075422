import { ApiBaseUrl } from 'environmentVariables';

export interface Request {
    body?: { [key: string]: any };
    method?: string;
    headers?: { [key: string]: any };
    token?: string;
}

function makeRequest(endpoint: string, { body, token, ...customConfig }: Request = {}, stringify: boolean) {
    const finalToken = token ?? localStorage.getItem('token');

    const impersonatingObject: { 'Impersonating-As'?: string } = {};
    const oldToken = localStorage.getItem('old');
    if (oldToken) {
        impersonatingObject['Impersonating-As'] = oldToken.slice(0, 8);
    }

    const headers: { [key: string]: any } = !stringify ? {} : { 'content-type': 'application/json' };

    if (finalToken) {
        headers.Authorization = `Token ${finalToken}`;
    }

    const config: { [key: string]: any } = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...impersonatingObject,
            ...customConfig.headers,
        },
    };

    if (body && stringify) {
        config.body = JSON.stringify(body);
    } else {
        config.body = body;
    }

    return fetch(`${ApiBaseUrl}/${endpoint}`, config);
}

export default makeRequest;
