import React, { useEffect, useState } from 'react';
import { JobFormFields } from 'parent-portal/forms/JobRequestTypes';
import { FieldStates } from '../../FormTypes';
import JobFormPage from '../JobFormPage';
import { Qualification } from 'models';
import { client, consoleLogInDev } from 'shared';
import FormField from '../../FormField';
import { Text } from 'library';
import QualificationInput from './QualificationInput';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import AdditionalQualifications from './AdditionalQualifications';

export default function BusinessJobRequirementsPage({ fieldStates }: { fieldStates: FieldStates<JobFormFields> }) {
    const { goToNext } = useJobFormContext();
    const [qualifications, setQualifications] = useState<Qualification[]>([]);

    const getQualifications = () => {
        client('api/qualification/').then(setQualifications).catch(consoleLogInDev);
    };

    useEffect(getQualifications, []);

    function onNextClicked() {
        goToNext();
    }

    return (
        <JobFormPage
            onNextClicked={onNextClicked}
            title="Tell us about the requirements."
            nextDisabled={false}
            id="provider-preferences-page"
        >
            <Text variant="body2" textStyle={{ marginTop: 20, marginBottom: 20 }}>
                Please only include qualifications all applicants must have to be considered, so we can provide you the
                best field of candidates possible.
            </Text>
            {qualifications
                .filter((qual) => !qual.web_view_options.nested)
                .map((qual) => (
                    <FormField title={qual.web_view_options.header} key={qual.id}>
                        {qual.web_view_options.sub_header && (
                            <Text variant="body1" bold textStyle={{ marginTop: 10 }}>
                                {qual.web_view_options.sub_header}
                            </Text>
                        )}
                        <QualificationInput qualification={qual} jobQualifications={fieldStates.jobQualifications} />
                    </FormField>
                ))}
            <AdditionalQualifications
                qualifications={qualifications.filter((qual) => qual.web_view_options.nested)}
                jobQualifications={fieldStates.jobQualifications}
            />
        </JobFormPage>
    );
}
