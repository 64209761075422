import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { fromPairs } from 'lodash';
import { Divider } from '@material-ui/core';
import { Colors, Text } from 'library';
import { differenceInDays, isSameDay } from 'date-fns';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';

import FormField from '../../FormField';
import JobDatePicker from './JobDatePicker';
import JobDayPicker from './JobDayPicker';
import JobTimePicker from './JobTimePicker';
import JobMultiTimePicker from './JobMultiTimePicker';
import JobSameTimePicker from './JobSameTimePicker';
import BreakRequiredField from './BreakRequiredField';
import BusinessJobDateRangePicker from './BusinessJobDateRangePicker';
import { BusinessLastHireTimePicker } from './BusinessLastHireTimePicker';
import { useUserContext } from 'UserContext';

import type { DayOfWeekSelections } from 'parent-portal/forms/JobRequestTypes';

const DAYS_IN_WEEK = 7;
export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default function BusinessDatesAndTimes({
    setIsValid,
    essentialOnly = false,
}: {
    setIsValid: (valid: boolean) => void;
    essentialOnly?: boolean;
}) {
    const { form } = useJobFormContext();
    const { getDisplayText } = useUserContext();
    const [manuallySetDays, setManuallySetDays] = useState(false);

    const {
        breakRequired,
        breakLength,
        businessJobType,
        startDate,
        endDate,
        lastHireOffsetMinutes,
        daysOfTheWeek,
        sameTimes,
        jobTimes,
        requestType,
        slotsAvailable,
    } = form.fieldStates;

    const trialRun = businessJobType.value === 'TRIAL_RUN';
    const subJob = businessJobType.value === 'SUBSTITUTE';
    const isSingleDay =
        !!startDate.value && !!endDate.value && isSameDay(startDate.value.toDate(), endDate.value.toDate());

    function getUniqueDaysInRange(startDate: moment.Moment, endDate: moment.Moment) {
        const dayValues = new Set();
        const currentDay = startDate.clone();
        const endDay = endDate.clone();

        while (currentDay.isSameOrBefore(endDay, 'day')) {
            if (dayValues.size === DAYS_IN_WEEK) break;
            dayValues.add(currentDay.day() === 0 ? DAYS_IN_WEEK : currentDay.day());
            currentDay.add(1, 'day');
        }

        return dayValues;
    }

    function hasWeekdaysInDateRange() {
        const eDate = endDate.value;
        const sDate = startDate.value;

        if (trialRun) return true;
        if (!eDate || !sDate) return false;

        if (differenceInDays(eDate.toDate(), sDate.toDate()) >= DAYS_IN_WEEK) return true;

        const selectedDays = Object.entries(daysOfTheWeek.value).filter((day) => day[1].selected);

        if (!selectedDays.length) return false;

        const dayValuesInRange = getUniqueDaysInRange(sDate, eDate);

        if (selectedDays.length && !('day' in selectedDays[0][1])) {
            const draftDaysSelected = selectedDays.map((day) => DAYS.indexOf(day[0]));
            return draftDaysSelected.every((day) => dayValuesInRange.has(day));
        }
        return selectedDays.every((day) => dayValuesInRange.has(parseInt(day[1].day)));
    }

    useEffect(() => {
        if (!manuallySetDays && (!jobTimes.value?.start || !jobTimes.value?.end) && startDate.value && endDate.value) {
            const dayValuesInRange = getUniqueDaysInRange(startDate.value, endDate.value);
            const updatedDays = Object.entries(daysOfTheWeek.value).map(([day, values]) => [
                day,
                {
                    ...values,
                    selected: dayValuesInRange.has(parseInt(values.day)) && ![6, 7].includes(parseInt(values.day)),
                },
            ]);
            daysOfTheWeek.setValue(fromPairs(updatedDays) as DayOfWeekSelections);
        }
    }, [startDate.value, endDate.value, manuallySetDays]);

    useEffect(() => {
        const validBreak = !breakRequired.value || !!breakLength.value;
        const validTimes = jobTimes.isValid || (!sameTimes.value && daysOfTheWeek.isValid);
        const hasDayInDateRange = hasWeekdaysInDateRange();

        const validTrialRun =
            validTimes &&
            validBreak &&
            !!startDate.value &&
            !!startDate.isValid &&
            startDate.value.isSameOrAfter(moment(), 'date');

        const validSubJob =
            validTimes &&
            validBreak &&
            !!startDate.value &&
            !!endDate.value &&
            startDate.isValid &&
            endDate.isValid &&
            startDate.value.isSameOrAfter(moment(), 'date') &&
            // NOTE: hiding this for now
            // eslint-disable-next-line no-comments/disallowComments
            // lastHireOffsetMinutes.isValid &&
            hasDayInDateRange &&
            (isSingleDay ||
                (startDate.value < endDate.value &&
                    differenceInDays(endDate.value.toDate(), startDate.value.toDate()) <= 90));

        if (trialRun) setIsValid(validTrialRun);

        if (subJob) setIsValid(validSubJob);

        requestType.setValue(startDate.value !== endDate.value ? 'recurring' : 'onetime');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        jobTimes,
        daysOfTheWeek,
        startDate,
        endDate,
        sameTimes.value,
        trialRun,
        subJob,
        requestType,
        setIsValid,
        breakRequired,
        breakLength,
    ]);

    function updateAllSlots(slots: number) {
        slotsAvailable.setValue(slots);
        const updatedSlots = Object.entries(daysOfTheWeek.value).map(([day, values]) => [day, { ...values, slots }]);
        daysOfTheWeek.setValue(fromPairs(updatedSlots) as DayOfWeekSelections);
        jobTimes.setValue({ ...jobTimes.value, slots });
    }

    return (
        <>
            <FormField title={''} style={{ marginTop: 30, marginBottom: 0 }}>
                {trialRun ? (
                    <FormField title={'1. Select the start date.'} style={{ marginTop: 30 }}>
                        <JobDatePicker jobDate={startDate} />
                    </FormField>
                ) : (
                    <FormField title={'1. Pick the dates.'} style={{ marginTop: 30, marginBottom: 0 }}>
                        <Text variant="body2" textStyle={{ marginBottom: 15 }}>
                            {getDisplayText('substitute').capitalize()} shifts can be one single day or multiple days.
                        </Text>
                        <BusinessJobDateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            daysOfTheWeek={daysOfTheWeek}
                        />
                    </FormField>
                )}
            </FormField>
            <Divider />
            <FormField
                style={{ marginTop: 20 }}
                title={isSingleDay ? '2. Day of the week.' : '2. Choose the days of the week.'}
            >
                <JobDayPicker
                    daysOfTheWeek={daysOfTheWeek}
                    disabled={isSingleDay}
                    setManuallySetDays={setManuallySetDays}
                />
                {hasWeekdaysInDateRange() ? null : (
                    <Text variant="caption" color={Colors.mediumRed}>
                        You may only select days of the week that fall within in the date range.
                    </Text>
                )}
            </FormField>
            <Divider />
            <FormField title={'3. Select the times and number of workers per shift.'} style={{ marginTop: 20 }}>
                <JobSameTimePicker isSameTime={sameTimes} daysOfTheWeek={daysOfTheWeek} />
                {sameTimes.value ? (
                    <JobTimePicker
                        jobTimes={jobTimes}
                        startDate={startDate.value}
                        allowPastMidnight={false}
                        slotsAvailable={slotsAvailable}
                        updateAllSlots={updateAllSlots}
                        essentialOnly={essentialOnly}
                    />
                ) : (
                    <JobMultiTimePicker daysOfTheWeek={daysOfTheWeek} />
                )}
                {!essentialOnly && (
                    <>
                        <Divider />
                        <BreakRequiredField breakRequired={breakRequired} breakLength={breakLength} />
                        <Divider />
                        {false && <BusinessLastHireTimePicker lastHireOffsetMinutes={lastHireOffsetMinutes} />}
                    </>
                )}
            </FormField>
        </>
    );
}
