import React from 'react';
import ButtonWrapper from './ButtonWrapper';
import ButtonStyles from './ButtonStyles';

import { ButtonWrapperProps, designStyle } from './models';

const BubbleButton = React.forwardRef<HTMLButtonElement, ButtonWrapperProps>(function _BubbleButton(
    { children, enabledStyle, disabledStyle, defaultStyle, boldText, ...props }: ButtonWrapperProps,
    ref,
) {
    const defaultButtonStyle = ButtonStyles.defaultButtonStyle;
    const defaultTextStyles = ButtonStyles.defaultTextStyles;
    const bubbleEnabledStyle = {
        button: { ...(enabledStyle.button as object) },
        text: enabledStyle.text,
    };

    const bubbleDefaultStyle = {
        button: (defaultStyle as designStyle)
            ? { ...defaultButtonStyle, ...((defaultStyle as designStyle).button as object) }
            : defaultButtonStyle,

        text: (defaultStyle as designStyle)
            ? { ...defaultTextStyles, ...((defaultStyle as designStyle).text as object) }
            : defaultTextStyles,
    };

    return (
        <ButtonWrapper
            ref={ref}
            defaultStyle={bubbleDefaultStyle as designStyle}
            enabledStyle={bubbleEnabledStyle as designStyle}
            disabledStyle={disabledStyle}
            boldText={boldText}
            {...props}
        >
            {children}
        </ButtonWrapper>
    );
});
export default BubbleButton;
