import { HourReportTimestamp, IHourReport, IHourReportView } from 'internal/payments/HourReportModels';
import { client } from 'shared';
import { IProviderReport, IProviderReports } from './models';
const CONTESTED_REPORTS = ['business_clock_in', 'business_clock_out', 'business_break_start', 'business_break_end'];
const ADMIN_RESOLVED_REPORTS = ['admin_resolved'];

export interface UpdateReportBody {
    [key: string]: Date | string | undefined;
}

function extractDateForSubmission(body: UpdateReportBody, field: keyof UpdateReportBody) {
    if (body[field] !== undefined) {
        // eslint-disable-next-line no-comments/disallowComments
        return new Date(body[field] ?? new Date()).toISOString();
    }
}

export function approveReport(
    report_id: number,
    updateBody: UpdateReportBody,
    appt_id?: number,
    provider_user_id?: number,
) {
    const url = report_id > 0 ? `api/hour-report/${report_id}/` : 'api/hour-report/clock-in/';
    const method = report_id > 0 ? 'PATCH' : 'POST';
    const body = Object.keys(updateBody).reduce(
        (acc: { [key: string]: string | undefined }, key) => {
            acc[key] = extractDateForSubmission(updateBody, key);
            return acc;
        },
        { appointment_id: appt_id?.toString(), provider_user_id: provider_user_id?.toString() },
    );
    return client(url, { method: method, body: body });
}

export function payHourReport(report_id: number, rate: string | number, overrideChecks: boolean) {
    return client(`api/hour-report/${report_id}/pay/`, { body: { rate, override_checks: overrideChecks } });
}

export function getTimestampByType(report: IHourReport | undefined, type: string) {
    if (!report) return null;

    const timestamp = report.timestamps.find((t) => t.time_type.type === type);
    return timestamp ? timestamp.reported_at : null;
}

export function getTimestampsByPriority(timestamps: HourReportTimestamp[], types: string[]) {
    const filteredTimestamps = timestamps.filter((t) => types.includes(t.time_type.type));
    if (filteredTimestamps)
        return filteredTimestamps.sort((a, b) => types.indexOf(a.time_type.type) - types.indexOf(b.time_type.type))[0];
    return undefined;
}

export function getHourReportViewStatus(r: IHourReportView) {
    if (r.admin_resolved) return 'Resolved';
    if (r.paid) return 'Paid';
    if (r.business_disputed) return 'Disputed, awaiting resolution.';
    if (r.business_approved) return 'Approved';
    return 'Awaiting Business';
}

export function getHourReportStatus(r: IHourReport) {
    if (r.timestamps.find((t) => ADMIN_RESOLVED_REPORTS.includes(t.time_type.type))) return 'Resolved';
    if (r.paid) return 'Paid';
    if (r.timestamps.find((t) => CONTESTED_REPORTS.includes(t.time_type.type)) && (r.clock_out || r.end))
        return 'Disputed, awaiting resolution.';
    if (r.business_approved || r.timestamps.find((t) => ['business_approved'].includes(t.time_type.type)))
        return 'Approved';
    return '';
}

export function buildNewReports(reports: IHourReportView[]) {
    return reports.reduce((providerReports: IProviderReports, hourReport: IHourReportView) => {
        const { provider, shift_start, shift_end, approved_break, hours_worked, paid, id } = hourReport;
        const { user } = provider;
        const providerName = `${user.first_name} ${user.last_name}`;
        const current = providerReports[providerName] ?? [];
        const newVal = {
            ...hourReport,
            providerUserId: user.id,
            providerName,
            shift_start: new Date(shift_start),
            shift_end: shift_end ? new Date(shift_end) : null,
            approved_break: approved_break,
            hours_worked: hours_worked,
            manual_reason: hourReport.manual_reason,
            status: getHourReportViewStatus(hourReport),
            paid: paid,
            id: id,
        };
        return { ...providerReports, [providerName]: [...current, newVal] } as IProviderReports;
    }, {});
}

export function buildNewReportFromHourReport(hourReport: IHourReport): IProviderReport {
    const { provider, start, end, approved_break, hours_worked, paid, id } = hourReport;
    const { user } = provider;
    const providerName = `${user.first_name} ${user.last_name}`;

    return {
        providerUserId: user.id,
        providerName,
        start: new Date(start),
        end: end ? new Date(end) : null,
        break: approved_break,
        approved_break: approved_break,
        hours: hours_worked,
        provider_break_start:
            getTimestampByType(hourReport, 'provider_altered_break_start') ??
            getTimestampByType(hourReport, 'provider_break_start'),
        provider_break_end:
            getTimestampByType(hourReport, 'provider_altered_break_end') ??
            getTimestampByType(hourReport, 'provider_break_end'),
        business_clock_in: getTimestampByType(hourReport, 'business_clock_in'),
        business_clock_out: getTimestampByType(hourReport, 'business_clock_out'),
        business_break_start: getTimestampByType(hourReport, 'business_break_start'),
        business_break_end: getTimestampByType(hourReport, 'business_break_end'),
        business_approved: getTimestampByType(hourReport, 'business_approved'),
        status: getHourReportStatus(hourReport),
        paid: paid,
        id: id,
    };
}
