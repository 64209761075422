import React, { useState } from 'react';
import { AdminProvider } from 'models';
import { BasicDialog, IconButton, PrimaryButton, Text } from 'library';
import { Add } from '@mui/icons-material';
import { client } from 'shared';
import DateTimePicker from 'internal/shared/DateTimePicker';

export default function MedAppointment({ provider }: { provider: AdminProvider }) {
    const [open, setOpen] = useState<boolean>(false);

    const [date, setDate] = useState<any>();
    const medRecords = provider?.medical_record;

    const medRecord = medRecords?.find(
        (x) =>
            x.indicated_did_not_have_physical_at ||
            x.indicated_finished_making_cvs_appointment_at ||
            x.indicated_had_physical_at ||
            x.manual_marked_appointment_date,
    );

    function save() {
        if (date) {
            client(`api/md-records/appointment-create/`, {
                method: 'PATCH',
                body: {
                    manual_marked_appointment_date: date?.format('YYYY-MM-DD HH:MM'),
                    provider: provider?.user.id,
                },
            })
                .then(() => alert(`Saved Appt Date: ${date?.format('YYYY-MM-DD HH:MM')}`))
                .catch((e) => alert(`Error: ${e}`));
        } else {
            alert(`no appointment date found`);
        }
    }

    return (
        <>
            {/* TODO: MED_RECORD if not used remove */}
            <Text variant="body2" textStyle={{ margin: 10 }}>
                Manual Marked Appointment: {medRecord?.manual_marked_appointment_date}
            </Text>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 300 }}>
                <div>
                    <Text>
                        {' '}
                        <IconButton icon={Add} onClick={() => setOpen(true)} /> Add Appointment
                    </Text>
                </div>
            </div>

            <BasicDialog closeButton isOpen={open} onClose={() => setOpen(false)}>
                <DateTimePicker
                    value={date ? date : new Date()}
                    onSelectDate={(date) => {
                        setDate(date);
                    }}
                />
                <PrimaryButton onClick={save} buttonStyle={{ width: 250, margin: 10 }}>
                    Save
                </PrimaryButton>
            </BasicDialog>
        </>
    );
}
