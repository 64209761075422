import { IComplianceWarnings } from 'models';
import { Grid, Divider } from '@material-ui/core';
import { Colors, Text } from 'library';
import React from 'react';

export default function ComplianceWarnings({ warnings }: { warnings: IComplianceWarnings[] }) {
    const colorsByType: { [key: string]: string } = {
        error: Colors.red,
        description: Colors.darkNavy,
    };

    return (
        <>
            {warnings.map((warning, index) => (
                <Grid key={index}>
                    <Text variant="body1" bold textStyle={{ margin: 10 }}>
                        {warning.title}
                    </Text>
                    {warning.paragraphs.map((p) => (
                        <Text
                            key={p.title || p.message}
                            variant="body2"
                            textStyle={{ margin: 5, marginLeft: 10, color: colorsByType[p.type] || Colors.darkNavy }}
                        >
                            {p.title ? (
                                <Text
                                    variant="body2"
                                    bold
                                    inline
                                    textStyle={{ color: colorsByType[p.type] || Colors.darkNavy }}
                                >
                                    {p.title}{' '}
                                </Text>
                            ) : null}
                            {p.message}
                        </Text>
                    ))}
                    <Divider style={{ marginTop: 10 }} />
                </Grid>
            ))}
        </>
    );
}
