import React, { useEffect, useState } from 'react';
import { CardActionArea, Grid } from '@material-ui/core';
import { useWidth } from 'reusableComponents/useWidth';
import { Text } from '../text';
import { Colors } from '../../../constants';
import { track } from 'shared';

interface IProps {
    cardContent: React.ReactNode;
    expandedContent: React.ReactNode;
    trackingData?: string;
    openExpanded: boolean;
}

export default function ExpandableCard({ cardContent, expandedContent, trackingData, openExpanded = false }: IProps) {
    const [expanded, setExpanded] = useState<boolean>(openExpanded);
    const [isSmallDevice, setIsSmallDevice] = useState<boolean>(false);
    const width = useWidth();

    useEffect(() => {
        setIsSmallDevice(width === 'sm' || width === 'xs');
    }, [width]);

    function click() {
        setExpanded(!expanded);
        if (!!trackingData) {
            track(trackingData);
        }
    }

    return (
        <Grid
            style={{
                ...styles.outerCard,
                width: isSmallDevice ? '80vw' : '25vw',
            }}
        >
            <CardActionArea style={{ outline: 'none' }} onClick={click}>
                <Grid container item style={{ padding: 24 }}>
                    <Grid container item style={styles.innerContainer}>
                        {cardContent}
                    </Grid>
                </Grid>
                {!!expanded ? (
                    <Grid container item style={{ padding: 24 }}>
                        <Grid item style={styles.turqSeparator}></Grid>
                        <Grid container item style={{ paddingTop: 12, paddingBottom: 12 }}>
                            <Text variant="body1">{expandedContent}</Text>
                        </Grid>
                    </Grid>
                ) : null}
            </CardActionArea>
        </Grid>
    );
}

const styles = {
    innerContainer: {
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    outerCard: {
        backgroundColor: Colors.white,
        borderRadius: 20,
        boxShadow: '0px 3px 6px #00000029',
        marginLeft: 24,
        marginRight: 24,
    },
    turqSeparator: {
        backgroundColor: Colors.turquoise,
        height: 3,
        width: '30%',
    },
};
