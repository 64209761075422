import React from 'react';
import Divider from 'parent-portal/forms/components/Divider';
import MedAppointment from '../../MedAppointment';
import DocumentsSection from '../../documents/DocumentsSection';
import MedStatus from '../../MedStatus';
import Form718bStatus from '../../Form718bStatus';
import { Text } from 'library';
import { AdminProvider } from 'models';
import BspDialog from 'internal/providers/BspDialog';
import ILFingerprintCheckDialog from 'internal/providers/ILFingerprintCheckDialog';
import {
    ProviderLabels,
    FingerPrintAppointmentInfo,
    WorkerOccrraStatus,
    WorkerQualifications,
    WorkerPastExperience,
    WorkerNotes,
    WorkerBasicInfo,
    WorkerProfileHeader,
} from './components';
import OutreachTextBtns from '../../OutreachTextBtns';

export default function ProfileInfo({
    provider,
    refreshProvider,
    userRefreshError,
}: {
    provider?: AdminProvider;
    refreshProvider: () => void;
    userRefreshError?: string;
}) {
    if (!provider)
        if (userRefreshError) {
            return (
                <Text
                    textStyle={{ padding: 20 }}
                >{`Could not find details about this provider. ${userRefreshError}`}</Text>
            );
        } else {
            return (
                <Text textStyle={{ padding: 20 }}>
                    {"Couldn't find details about this provider. We aren't sure why."}
                </Text>
            );
        }

    return (
        <div>
            <WorkerProfileHeader provider={provider} refreshProvider={refreshProvider} />
            <Divider />
            <BspDialog provider={provider} />
            {provider.user?.address.state === 'Illinois' && <ILFingerprintCheckDialog provider={provider} />}
            <WorkerBasicInfo provider={provider} />
            <Divider />
            <WorkerNotes provider={provider} refreshProvider={refreshProvider} />
            <Divider />
            <WorkerPastExperience provider={provider} />
            <Divider />
            <WorkerQualifications provider={provider} />
            <Divider />
            <DocumentsSection provider={provider} refreshProvider={refreshProvider} />
            <Divider />
            <WorkerOccrraStatus provider={provider} refreshProvider={refreshProvider} />
            <Divider />
            <MedStatus provider={provider} onRefresh={refreshProvider} />
            {/* TODO: MED_RECORD if not used remove */}
            <MedAppointment provider={provider} />
            {provider.user?.address.state === 'Illinois' && <Divider />}
            {provider.user?.address.state === 'Illinois' && <Form718bStatus provider={provider} />}
            <Divider style={{ marginTop: 10 }} />
            <FingerPrintAppointmentInfo provider={provider} />
            <Divider />
            <ProviderLabels provider={provider} />
            <Divider />
            <OutreachTextBtns provider={provider} getProvider={refreshProvider} />
        </div>
    );
}
