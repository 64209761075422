import React from 'react';
import { Grid } from '@material-ui/core';
import { Text, Colors } from 'library';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import { MedicalRecordVerifyProps } from '../models';

export default function MedicalRecordVerify({ setSignedAt, signedAt }: MedicalRecordVerifyProps) {
    const stepsBeforeVerification = [
        '✔ All vaccination section boxes are checked',
        '✔ Has signature',
        '✔ TB Section has checkmarks for "no" if empty',
        '✔ Signed in the past year',
    ];
    return (
        <Grid container item direction="column">
            <Text bold textStyle={{ margin: 5 }}>
                Before verifying, check the following...
            </Text>
            {stepsBeforeVerification.map((step, index) => (
                <Text key={index} textStyle={{ margin: 5 }}>
                    {step}
                </Text>
            ))}
            <Text bold textStyle={{ margin: 15 }}>
                Select Date Form Was Signed
            </Text>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    InputProps={{
                        inputProps: {
                            style: { textAlign: 'center', color: Colors.darkNavy },
                        },
                    }}
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    value={signedAt}
                    onChange={(d: MaterialUiPickersDate | Date) => {
                        setSignedAt(d as Date);
                    }}
                    maxDate={new Date()}
                />
            </MuiPickersUtilsProvider>
        </Grid>
    );
}
