import React, { useState, useEffect } from 'react';
import { Grid, Typography, Checkbox, Select, Link, Button, Paper } from '@material-ui/core';
import BackgroundPaper from '../../reusableComponents/backgroundPaper';
import LoadingButton from '../../reusableComponents/loadingButton';
import ContentPaper from '../../reusableComponents/contentPaper';
import Input from '../../reusableComponents/input';
import { isMobile } from 'react-device-detect';
import { track } from '../../shared/Tracking';
import { useLocation } from 'react-router-dom';
import ApiClient from '../../shared/ApiClient';
import { Colors } from '../../constants';
import { getAge } from 'shared';
import { Environment } from 'environmentVariables';

const BACKGROUND = require('../../assets/images/payment-setup/background-gradient.png');
const EXCLUSIVE = require('../../../src/assets/images/payment-setup/exclusive-access.png');
const PAYMENT_PARTNERS = require('../../../src/assets/images/payment-setup/payment-partners.png');
const SECURE = require('../../../src/assets/images/payment-setup/quick-secure.png');

const BENEFITS = [
    {
        image: SECURE,
        large_text: 'Secure Platform',
        small_text:
            'We do not have access to personal account information and we do not save sensitive personal information, specifically the SSN.',
    },
    {
        image: EXCLUSIVE,
        large_text: 'Quick Setup',
        small_text:
            "Because the provider is a minor, we need a parent or legal guardian to register on their behalf. But don't worry, this won't take long!",
    },
];

const WHAT_YOULL_NEED = [
    { decorator: '- ', text: 'Full legal name' },
    { decorator: '- ', text: 'Social Security Number (SSN)' },
    { decorator: '- ', text: 'Date of birth' },
    { decorator: '- ', text: 'Verify or edit address submitted by your child' },
    { decorator: '- ', text: 'Phone number and email address' },
];

const WHAT_HAPPENS_NEXT = [
    { decorator: '1. ', text: 'Fill out Parent Verification Form and accept User Agreements' },
    { decorator: '2. ', text: 'Identity verification is completed within seconds' },
    { decorator: '3. ', text: "Your child's free account and virtual TandemPay card are created instantly" },
    {
        decorator: '4. ',
        text: 'Your child can order their free physical TandemPay card to arrive within 5 - 7 business days',
    },
    { decorator: '5. ', text: 'Your child can save, spend, and transfer their Tandem earnings!' },
];

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const STATES = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Oregon', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
];

const ParentVerification = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthday, setBirthday] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [minorName, setMinorName] = useState('Your child');
    const [minorEmail, setMinorEmail] = useState<string>();
    const [minorPhoneNumber, setMinorPhoneNumber] = useState<string>();
    const [addressStreet, setAddressStreet] = useState('');
    const [addressCity, setAddressCity] = useState('');
    const [addressState, setAddressState] = useState('');
    const [addressZip, setAddressZip] = useState('');
    const [email, setEmail] = useState('');
    const [ssn, setSSN] = useState('');
    const [pageStep, setPageStep] = useState(0);
    const [agreements, setAgreements] = useState<IAgreement[]>([]);
    const query = useQuery();
    const encryptedId = query.get('id');
    const today = new Date();
    interface IAgreement {
        id: string;
        url: string;
        type: string;
        checked: boolean;
        text: string;
    }

    useEffect(() => {
        getMinorData();
    }, [encryptedId]);

    useEffect(() => {
        getAgreements();
    }, []);

    const getMinorData = async () => {
        try {
            const response = await ApiClient(`payment/api/customer/parent-verification/?id=${encryptedId}`);
            setMinorName(`${response.first_name}`);
            if (response.agreements_accepted) {
                setPageStep(3);
                return;
            }
            if (response.parent_user_created) {
                setPageStep(2);
                return;
            }
            setAddressStreet(response.street);
            setAddressCity(response.city);
            setAddressState(response.state);
            setAddressZip(response.zip_code);
            setMinorEmail(response.minor_email);
            setMinorPhoneNumber(
                `(${response.minor_phone.substring(2, 5)}) ${response.minor_phone.substring(
                    5,
                    8,
                )}-${response.minor_phone.substring(8)}`,
            );
        } catch (error) {
            console.log('error getting data', error);
        }
    };

    const getAgreements = () => {
        ApiClient('payment/api/agreement/').then((response: IAgreement[]) => {
            const mapped_agreements = response.map((x) => {
                return { ...x, text: x.type.replace(/_/g, ' ') };
            });
            setAgreements(mapped_agreements);
        });
    };

    const onAgreementSelect = (id: string) => {
        setAgreements(
            agreements.map((x) => {
                return {
                    ...x,
                    checked: x.id === id ? !x.checked : x.checked,
                };
            }),
        );
    };

    const onViewAgreement = (agreement: IAgreement) => {
        track('Parent Verification: Open Agreement', { agreement: agreement.id });
    };

    const getFormRequest = () => {
        return {
            parent_user_info: {
                first_name: firstName,
                last_name: lastName,
                birthday: birthday,
                phone_number: phoneNumber,
                street: addressStreet,
                city: addressCity,
                state: addressState,
                zip_code: addressZip,
                email: email,
                ssn: ssn,
            },
            agreements: agreements.map((a) => a.id),
        };
    };

    const onVerificationSubmit = async () => {
        setLoading(true);
        const requestBody = getFormRequest();
        const { parent_user_info, agreements } = requestBody;
        const { ssn, ...restOfInfo } = parent_user_info;
        ApiClient(`payment/api/customer/parent-verification/?id=${encryptedId}`, {
            method: 'PATCH',
            body: requestBody,
        } as any)
            .then(() => {
                setPageStep(3);
                track('Parent Verification: Submit success', { restOfInfo, agreements });
            })
            .catch((error) => {
                if (Environment !== 'production') {
                    console.log(error);
                }

                const requestInfo = {
                    restOfInfo,
                    agreements,
                    error,
                };
                track('Parent Verification: Submit error', requestInfo);
                setPageStep(4);
            })
            .finally(() => setLoading(false));
    };

    const getStateInput = () => {
        return (
            <Grid item>
                <Select
                    native
                    variant="outlined"
                    value={addressState}
                    label="State"
                    onChange={(event) => setAddressState(event.target.value as string)}
                    style={{ marginBottom: 20 }}
                >
                    {STATES.map((item) => (
                        <option value={item.name}>{item.abbreviation}</option>
                    ))}
                </Select>
            </Grid>
        );
    };

    const getFormInput = (
        fieldLabel: string,
        fieldName: string,
        onChange: any,
        type: string,
        placeholder?: string,
        error?: boolean,
    ) => {
        return (
            <Grid item>
                <Input
                    label={fieldLabel}
                    variant="outlined"
                    type={type}
                    placeholder={placeholder}
                    value={fieldName}
                    onChange={(event) => {
                        onChange(event.target.value);
                    }}
                    required={true}
                    style={{ width: isMobile ? '100%' : 200 }}
                    error={error}
                />
            </Grid>
        );
    };

    const isSSNValid = () => {
        const pattern = new RegExp('\\d{9}');
        if (ssn.length > 0) {
            return ssn.match(pattern) && ssn.length === 9;
        }
        return true;
    };

    const isZipValid = () => {
        const pattern = new RegExp('\\d{5}');
        if (addressZip.length > 0) {
            return addressZip.match(pattern) && addressZip.length === 5;
        }
        return true;
    };

    const isPhoneNumberValid = () => {
        if (phoneNumber.length > 0) {
            return (
                phoneNumber[0] === '(' &&
                phoneNumber[4] === ')' &&
                phoneNumber[5] === ' ' &&
                phoneNumber[9] === '-' &&
                phoneNumber.length === 14 &&
                phoneNumber !== minorPhoneNumber
            );
        }
        return true;
    };

    const isEmailValid = () => {
        if (email.length > 0) {
            return email !== minorEmail;
        }
        return true;
    };

    const isBirthdayValid = () => {
        if (birthday) {
            return getAge(birthday) >= 18;
        }

        return false;
    };

    const getContent = () => {
        if (pageStep === 1) {
            return getForm();
        }
        if (pageStep === 2) {
            return getAgreementForm();
        }
        if (pageStep === 4) {
            return (
                <div>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: Colors.error }}>
                                An error has occurred in submitting the Parent Verification Form
                            </Typography>
                            <Typography variant="subtitle1">
                                Please email support@join-tandem.com for assistance.
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    };

    const isFormValid = () => {
        return (
            firstName &&
            lastName &&
            birthday &&
            ssn &&
            addressStreet &&
            addressCity &&
            addressState &&
            addressZip &&
            phoneNumber &&
            email &&
            isEmailValid() &&
            isSSNValid() &&
            isPhoneNumberValid() &&
            isZipValid() &&
            isBirthdayValid()
        );
    };

    const onPhoneNumberChange = async (e: any) => {
        let text = e.target.value;
        let cleaned = ('' + text).replace(/\D/g, '');
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            let number = ['(', match[2], ') ', match[3], '-', match[4]].join('');
            setPhoneNumber(number);
            return;
        }
        setPhoneNumber(text);
        return;
    };

    const getInfo = () => {
        return (
            <Grid container xs={12} alignItems="center">
                <Grid item xs={1} md={2} />
                <Grid
                    item
                    xs={10}
                    md={8}
                    alignItems="center"
                    alignContent="center"
                    justify="center"
                    style={{ marginBottom: 30 }}
                >
                    <Typography
                        style={{
                            textAlign: 'center',
                            fontSize: 33,
                            color: Colors.darkNavy,
                            fontWeight: 'bold',
                            fontFamily: 'PT Serif',
                            marginBottom: 24,
                        }}
                    >
                        TandemPay is a safe payment platform that allows care providers to be paid and manage their
                        earnings comprehensively
                    </Typography>
                    <Typography
                        style={{
                            textAlign: 'center',
                            fontSize: 20,
                            color: Colors.darkNavy,
                            fontFamily: 'PT Serif',
                        }}
                    >
                        Tandem is lucky to give care providers between the ages of 13 and 18 the opportunity to safely
                        and independently find jobs, get paid, and manage their earnings. To maintain the safety of this
                        process, we require a parent or legal guardian to permit their registration.
                    </Typography>
                </Grid>
                {getBenefits()}
                {getConnectPayment()}
                {getPaymentPartners()}
                {getStat()}
                {getQuestions()}
            </Grid>
        );
    };

    const getBenefits = () => {
        return (
            <Grid container xs={12} alignItems="center" justify="space-evenly">
                {BENEFITS.map((item, index) => (
                    <Grid container item xs={12} md={4} direction="column" alignItems="center" key={item.large_text}>
                        <Grid item style={{ marginBottom: 10 }}>
                            <img src={item.image} height="100px" width="100px" />
                        </Grid>
                        <Grid item>
                            <Typography
                                style={{
                                    fontSize: 18,
                                    color: Colors.darkNavy,
                                    fontWeight: 'bold',
                                }}
                            >
                                {item.large_text}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={8} style={{ paddingBottom: 30 }}>
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    fontSize: 12,
                                    color: Colors.darkNavy,
                                }}
                            >
                                {item.small_text}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        );
    };

    const getListItem = (item: { decorator: string; text: string }) => {
        return (
            <Typography
                style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    color: Colors.darkNavy,
                }}
            >
                {item.decorator}
                <Typography
                    display="inline"
                    style={{
                        fontSize: 18,
                        color: Colors.darkNavy,
                    }}
                >
                    {item.text}
                </Typography>
            </Typography>
        );
    };

    const getListTitle = (text: string) => {
        return (
            <Typography
                style={{
                    textAlign: 'center',
                    fontSize: 20,
                    fontWeight: 'bold',
                    color: Colors.darkNavy,
                }}
            >
                {text}
            </Typography>
        );
    };

    const getWhatHappensNext = () => {
        return (
            <Grid item xs={12} md={6} alignContent="center">
                {getListTitle('What happens next:')}
                {WHAT_HAPPENS_NEXT.map((item) => getListItem(item))}
            </Grid>
        );
    };

    const getWhatYoullNeed = () => {
        return (
            <Grid item xs={12} md={6} alignContent="center">
                {getListTitle("What you'll need:")}
                {WHAT_YOULL_NEED.map((item) => getListItem(item))}
            </Grid>
        );
    };

    const getConnectPayment = () => {
        return (
            <Grid container item justify="space-evenly" xs={12}>
                <Paper
                    style={{
                        marginTop: 75,
                        marginBottom: 25,
                        padding: 32,
                        borderRadius: 18,
                        boxShadow: '0px 3px 6px #00000060',
                        width: '90%',
                    }}
                >
                    <Grid container item direction="column" alignItems="center">
                        <Typography
                            style={{
                                textAlign: 'center',
                                fontSize: 22,
                                fontWeight: 'bold',
                                color: Colors.darkNavy,
                            }}
                        >
                            Ready to complete the Parent Verification Form?
                        </Typography>
                        <Grid
                            container
                            item
                            style={{ flexDirection: 'row', marginTop: 24, marginBottom: 36 }}
                            spacing={3}
                        >
                            {getWhatYoullNeed()}
                            {getWhatHappensNext()}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={false}
                                onClick={() => {
                                    setPageStep(1);
                                    track('Parent Verification: Form click');
                                }}
                                style={{
                                    color: 'white',
                                    backgroundColor: Colors.mediumRed,
                                    fontSize: 16,
                                    borderRadius: '70px',
                                    padding: 8,
                                    paddingLeft: 25,
                                    paddingRight: 25,
                                    textTransform: 'none',
                                    width: 230,
                                }}
                            >
                                Go to Form
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    };

    const getPaymentPartners = () => {
        return (
            <>
                <Grid container xs={12} md={3} />
                <Grid container item justify="space-evenly" xs={12} md={6} style={{ marginTop: 48 }}>
                    <img src={PAYMENT_PARTNERS} width="100%" />
                </Grid>
                <Grid xs={12} justify="center">
                    <Typography variant="subtitle2" style={{ color: Colors.grey, textAlign: 'center' }}>
                        Tandem Visa® Prepaid Card USD is issued by MetaBank®, N.A. Member FDIC, pursuant to a license
                        from Visa U.S.A. Inc. This card may be used everywhere Visa debit cards are accepted. Use of
                        this card constitutes acceptance of the terms and conditions stated in the Cardholder Agreement.
                    </Typography>
                </Grid>
            </>
        );
    };

    const getStat = () => {
        return (
            <Grid container md={12} spacing={3} alignItems="center" style={{ marginTop: 50 }}>
                <Grid item xs={12} alignContent="center" justify="center">
                    <Typography
                        style={{
                            textAlign: 'center',
                            fontSize: 26,
                            fontWeight: 'bold',
                            color: Colors.darkNavy,
                        }}
                    >
                        Over 370 local care providers have been paid through Tandem!
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const getQuestions = () => {
        return (
            <>
                <Grid container xs={1} />
                <Grid
                    container
                    direction="column"
                    xs={10}
                    style={{
                        marginTop: 20,
                        marginBottom: 75,
                    }}
                    alignItems="center"
                >
                    <Grid item>
                        <Typography
                            style={{
                                textAlign: 'center',
                                fontSize: 22,
                                color: Colors.darkNavy,
                                fontWeight: 'bold',
                            }}
                        >
                            Have more questions?
                        </Typography>
                    </Grid>
                    <Grid item justify="center" style={{ marginTop: 10 }}>
                        <Link
                            href="https://www.join-tandem.com/tandem-pay"
                            onClick={() => track('Parent Verification: Go to /tandem-pay')}
                            style={{ fontSize: 20 }}
                        >
                            Visit https://www.join-tandem.com/tandem-pay
                        </Link>
                    </Grid>
                </Grid>
            </>
        );
    };

    const onChangeSSN = (input: string) => {
        setSSN(input.replace('-', '').replace(' ', '').replace('.', ''));
    };

    const getForm = () => {
        return (
            <div>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Typography display="inline" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            Step 1 of 2
                            <Typography variant="subtitle1" display="inline">
                                : Please fill out the form below with your information.
                            </Typography>
                        </Typography>
                    </Grid>

                    <Grid container item direction="row" spacing={2}>
                        {getFormInput('First Name', firstName, setFirstName, 'text')}
                        {getFormInput('Last Name', lastName, setLastName, 'text')}
                    </Grid>
                    <Grid item>
                        <Input
                            id="date"
                            label="Date of Birth"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => {
                                setBirthday(event?.target.value);
                                console.log(event?.target.value);
                            }}
                            variant="outlined"
                            style={{ width: isMobile ? '100%' : 200 }}
                            error={!!birthday && !isBirthdayValid()}
                        />
                    </Grid>
                    {!!birthday && !isBirthdayValid() && (
                        <Typography style={{ color: Colors.error, marginBottom: 15 }}>
                            Must be at least 18 years of age
                        </Typography>
                    )}
                    {getFormInput('Social Security Number', ssn, onChangeSSN, 'default', undefined, !isSSNValid())}

                    <Grid container item direction="row" spacing={2}>
                        {getFormInput('Street Address', addressStreet, setAddressStreet, 'text')}
                        {getFormInput('City', addressCity, setAddressCity, 'text')}
                        {getStateInput()}
                        {getFormInput(
                            'Zip Code',
                            addressZip,
                            setAddressZip,
                            'number',
                            undefined,
                            !isZipValid() || false,
                        )}
                    </Grid>
                    <Grid item>
                        <Input
                            required
                            id="primaryPhoneNumber"
                            label="Primary Phone Number"
                            variant="outlined"
                            value={phoneNumber}
                            error={!isPhoneNumberValid() || false}
                            onChange={(e) => onPhoneNumberChange(e)}
                            type="numeric"
                        />
                    </Grid>
                    {phoneNumber === minorPhoneNumber && (
                        <Typography style={{ color: Colors.error, marginBottom: 15 }}>
                            Phone number cannot be the same as {minorName}'s
                        </Typography>
                    )}
                    {getFormInput('Email', email, setEmail, 'email', undefined, !isEmailValid())}
                    {email === minorEmail && (
                        <Typography style={{ color: Colors.error, marginBottom: 15 }}>
                            Email cannot be the same as {minorName}'s
                        </Typography>
                    )}
                </Grid>
                {
                    <LoadingButton
                        style={{ marginTop: 15, marginBottom: 15 }}
                        disabled={!isFormValid()}
                        onClick={() => {
                            setPageStep(2);
                        }}
                    >
                        Continue
                    </LoadingButton>
                }
            </div>
        );
    };

    const agreementsAccepted = () => {
        const accepted = (element: { checked: boolean }) => element.checked;
        return agreements.every(accepted);
    };

    const getAgreementForm = () => {
        return (
            <Grid container>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Typography display="inline" variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            Step 2 of 2
                            <Typography variant="subtitle1" display="inline">
                                : Please accept the following agreements.
                            </Typography>
                        </Typography>
                        {agreements.map((x) => (
                            <Grid container item key={x.id} direction="row" style={{ alignItems: 'center' }}>
                                <Checkbox
                                    checked={x.checked}
                                    onClick={() => onAgreementSelect(x.id)}
                                    color={'primary'}
                                />
                                <Typography variant="body2" style={{ marginLeft: 10 }}>
                                    {x.text}{' '}
                                    <Link href={x.url} target="_blank" onClick={() => onViewAgreement(x)}>
                                        (view)
                                    </Link>
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                {
                    <LoadingButton
                        style={{ marginTop: 15, marginBottom: 15 }}
                        disabled={!agreementsAccepted()}
                        onClick={onVerificationSubmit}
                        loading={loading}
                    >
                        Submit
                    </LoadingButton>
                }
            </Grid>
        );
    };

    const getSubtitle = () => {
        if (pageStep === 3) {
            return (
                <Typography variant="subtitle1">
                    Parent verification for {minorName} has been completed. Thank you!
                </Typography>
            );
        }
        return (
            <Typography variant="subtitle1">
                {minorName} needs permission from their parent or guardian to set up their TandemPay account.
            </Typography>
        );
    };

    return (
        <Grid
            container
            direction="row"
            xs={12}
            style={{
                paddingTop: '3%',
                paddingBottom: '3%',
                backgroundImage: `url(${BACKGROUND})`,
                backgroundSize: '190%',
                backgroundPosition: 'center',
                minHeight: '100vh',
            }}
        >
            {pageStep === 0 && getInfo()}
            {pageStep !== 0 && (
                <>
                    <Grid container xs={1} />
                    <Grid container xs={10}>
                        <Grid container item md={3} />
                        <Grid container item md={6} xs={12}>
                            <BackgroundPaper>
                                <Grid container direction="column">
                                    <Grid container direction="row" style={{ marginBottom: 20 }}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h4"
                                                style={{
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    marginBottom: 18,
                                                }}
                                            >
                                                Parent Verification Form
                                            </Typography>
                                            {getSubtitle()}
                                        </Grid>
                                    </Grid>
                                    <ContentPaper>
                                        {getContent()}
                                        <Typography variant="subtitle2">
                                            Have questions about TandemPay? Visit{' '}
                                            <Link
                                                href="https://www.join-tandem.com/tandem-pay"
                                                onClick={() => track('Parent Verification: Go to /tandem-pay')}
                                            >
                                                https://www.join-tandem.com/tandem-pay
                                            </Link>{' '}
                                            to learn more or contact us at support@join-tandem.com.
                                        </Typography>
                                    </ContentPaper>
                                </Grid>
                            </BackgroundPaper>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default ParentVerification;
