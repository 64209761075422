import React, { useEffect, createContext, useContext, useState, Dispatch, SetStateAction } from 'react';
import useForm from './components/FormHook';
import { FormFields } from './components/FormTypes';
import { JobFormFields, IPayScale } from './JobRequestTypes';
import { initialForm } from './JobForm';
import submitJobRequest, { JobType } from './JobRequestSubmission';
import client from 'shared/ApiClient';
import { Job, Provider } from 'models';
import { JobType as JobTypeModel } from '../../models/JobType';
import { track } from 'shared/Tracking';
import { timeWithDate } from './time-operations';
import { useUserContext } from 'UserContext';
import UserAttributesApi from 'shared/UserAttributesApi';
import { useModalsContext } from 'ModalsContext';
import { JobFormPresets, setJobFormPresets } from './JobFormPresets';
import { isLoggedIn } from 'api/UserApi';
import moment from 'moment';
import { submitRequest, createOngoingRequest as oneTimeCreateOngoingRequest } from './OnetimeJobSubmission';
import { getBusinessPreferences } from 'api/BusinessPreferenceApi';
import { BusinessPreference } from 'parent-portal/profile/BusinessProfile/BusinessPreferences/models';
import { setBreakMinutes } from './businessPreferenceMethods';
import { consoleLogInDev } from 'shared';
import userAttributesApi from '../../shared/UserAttributesApi';
interface JobFormContextType {
    form: FormFields<JobFormFields>;
    currentPage: number;
    pageCount: number;
    goToNext: () => void;
    goToPrevious: () => void;
    jobTypes: JobTypeModel[];
    previouslyAgreedToTandemPay: boolean;
    loading: boolean;
    invitedProvider?: Provider;
    jobSubmissionCompleted: boolean;
    submit: () => Promise<Job | undefined>;
    createdJob: Job | undefined;
    setPageCount: (n: number) => void;
    setFamilyId: (id: number) => void;
    reset: (preset?: JobFormPresets) => void;
    simple: boolean;
    toggleSimpleForm: () => void;
    defaultPayScales: IPayScale[];
    updateDefaultPayScales: (payScales: IPayScale[]) => void;
    businessPreferences: BusinessPreference[];
    formErrors: Array<string>;
    setFormErrors: Dispatch<SetStateAction<never[]>>;
    hasSeenTrainingsModal: boolean;
    updateHasSeenTrainingsModal: () => void;
    setPresets: (presets: JobFormPresets) => void;
    recommendations: string[];
    setRecommendations: (recommendations: string[]) => void;
    ignoreRecommendations: boolean;
    setIgnoreRecommendations: (ignore: boolean) => void;
    datesConfirmed: boolean;
    setDatesConfirmed: Dispatch<SetStateAction<boolean>>;
}
interface JobFormContextProps {
    children?: React.ReactNode;
}

const defaultPageCount = 8;

export const JobFormContext = createContext<JobFormContextType>({} as JobFormContextType);

export function JobFormContextProvider({ children }: JobFormContextProps) {
    const [formErrors, setFormErrors] = useState([]);

    const { user, updateHasPostedJob, role } = useUserContext();
    const [invitedProvider, setInvitedProvider] = useState<Provider>();

    const [currentPage, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(5);
    const [lastPage, setLastPage] = useState(defaultPageCount - 1);
    const [createdJob, setCreatedJob] = useState<Job>();
    const [jobSubmissionCompleted, setJobSubmissionCompleted] = useState(false);
    const [jobTypes, setJobTypes] = useState<JobTypeModel[]>([]);
    const [previouslyAgreedToTandemPay, setPreviouslyAgreedToTandemPay] = useState(false);
    const [businessPreferences, setBusinessPreferences] = useState<BusinessPreference[]>([]);

    const [familyId, setFamilyId] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const [simple, setSimple] = useState(true);
    const [jobId, setJobId] = useState(0);
    const [defaultPayScales, setDefaultPayScales] = useState<IPayScale[]>([]);
    const [hasSeenTrainingsModal, setHasSeenTrainingsModal] = useState(false);
    const [ignoreRecommendations, setIgnoreRecommendations] = useState(false);
    const [recommendations, setRecommendations] = useState<string[]>([]);
    const [datesConfirmed, setDatesConfirmed] = useState(false);

    const form = useForm<JobFormFields>(initialForm(role === 'business_active'));
    const { closeJobForm, jobFormPresets } = useModalsContext();

    useEffect(() => {
        setLastPage(pageCount - 1);
    }, [pageCount]);

    useEffect(() => {
        getValuesForJobForm().then((info) => {
            setJobTypes(info.jobTypes);
            setPreviouslyAgreedToTandemPay(info.acceptedTandemPayAgreement);
            setBusinessPreferences(info.businessPreferences);
            setLoading(false);

            if (info?.businessPreferences?.length) {
                setBreakMinutes(info.businessPreferences, form.fieldStates.breakRequired, form.fieldStates.breakLength);
            }
        });
        getPayScales();
        getUserAttribute();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        jobFormPresets && setPresets(jobFormPresets);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobFormPresets]);

    function getPayScales() {
        client('api/pay-scale/').then(setDefaultPayScales);
    }

    const HAS_SEEN_TRAININGS_MODAL = 'Has Seen Job Form Trainings Modal';
    function getUserAttribute() {
        userAttributesApi
            .exists(HAS_SEEN_TRAININGS_MODAL)
            .then((response: boolean) => {
                setHasSeenTrainingsModal(response);
            })
            .catch(consoleLogInDev);
    }

    function updateHasSeenTrainingsModal() {
        setHasSeenTrainingsModal(true);
        userAttributesApi.create(HAS_SEEN_TRAININGS_MODAL).catch(consoleLogInDev);
    }

    function updateDefaultPayScales(payScales: IPayScale[]) {
        setDefaultPayScales(payScales);
    }

    useEffect(() => {
        if (form.fieldStates.requestType.value === 'recurring') setSimple(false);
    }, [form.fieldStates.requestType.value]);

    function setPresets(presets: JobFormPresets) {
        setJobFormPresets(presets, form.fieldStates, setSimple, setPage, setInvitedProvider, setJobId);
    }

    async function goToNext() {
        setFormErrors([]);
        try {
            if (currentPage > 0) {
                const res = await updateDraft();
                setJobId(res.id);
            }

            goToNextPage();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            consoleLogInDev(e);
            setFormErrors(e.errors);
        }
    }

    function goToNextPage() {
        track('Job Form next button clicked on page', { currentPage });

        if (currentPage === 0) {
            track('Job Form Job Type on Next', { jobTypeId: form.fieldStates.jobTypeId.value });
        }

        if (currentPage < lastPage) {
            setPage(currentPage + 1);
        }
    }

    function reset(presets: JobFormPresets = {}) {
        form.reset(initialForm(role === 'business_active'));
        setPage(0);
        setInvitedProvider(undefined);
        setCreatedJob(undefined);
        setJobSubmissionCompleted(false);
        setSimple(true);
        setJobId(0);
        const addresses = user?.address;
        if (addresses) {
            const mailingAddress = addresses.find((x) => x.address_type === 'PRIMARY');
            const addressId = mailingAddress ? mailingAddress.id : addresses[0].id;
            const busLocationId = mailingAddress?.business_location
                ? mailingAddress.business_location.id
                : addresses[0]?.business_location?.id;

            form.fieldStates.address.setValue([{ id: addressId, businessLocationId: busLocationId }]);
        }
        setJobFormPresets(presets, form.fieldStates, setSimple, setPage, setInvitedProvider, setJobId);
    }

    async function updateDraft() {
        const now = moment().toISOString();
        const family_id = familyId ?? user?.family[0].id ?? 0;
        const formValues = form.toValues();
        let request = null;
        request =
            formValues.requestType === 'onetime'
                ? await oneTimeCreateOngoingRequest(now, formValues, family_id, 'DRAFT')
                : await submitJobRequest({
                      familyId: family_id,
                      payMin: formValues.pay.payMin,
                      payMax: formValues.pay.payMax,
                      jobOptions: {
                          selectedPreferredWorkers: formValues.preferredWorkers,
                          selectedPastWorkers: formValues.pastWorkers,
                          daysOfTheWeek: formValues.daysOfTheWeek,
                          sameTimes: formValues.sameTimes,
                          start: formValues.sameTimes
                              ? formValues.jobTimes.start &&
                                timeWithDate(formValues.jobTimes.start!, formValues.startDate!)
                              : formValues.startDate,
                          end: formValues.endDate,
                          end_time: formValues.jobTimes.end,
                          lastHireOffsetMinutes: formValues.lastHireOffsetMinutes,
                          transportation: formValues.transportation ?? false,
                          multipleProviders: formValues.multipleProviders,
                          comments: formValues.comments,
                          startEstimateMonth: formValues.startEstimateMonth,
                          headline: formValues.headline,
                          paymentSchedule: formValues.paymentSchedule,
                          jobQualifications: formValues.jobQualifications,
                          address: formValues.address?.length ? formValues.address[0].id : undefined,
                          businessLocation: formValues.address?.length
                              ? formValues.address[0].businessLocationId
                              : undefined,
                          pay_type: formValues.payType,
                          business_job_type: formValues.businessJobType,
                          jobDetails: formValues.jobDetails.map((detail) => ({ ...detail, option: detail.option_id })),
                          payScales: formValues.payScales,
                          breakRequired: formValues.breakRequired,
                          breakLength: formValues.breakLength,
                          slotsAvailable: formValues.slotsAvailable,
                          parentRequest: formValues.templateJobId,
                          faqs: formValues.faqs,
                          breakEveryNMinutes: formValues.breakEveryNHours
                              ? formValues.breakEveryNHours * 60
                              : undefined,
                          trialRunBenefits: formValues.trialRunBenefits,
                      },
                      lastName: user?.last_name || formValues.userEmail,
                      jobType: JobType.ongoing,
                      invitedProvider: invitedProvider ? { id: invitedProvider.id } : undefined,
                      jobTypeId: formValues.jobTypeId,
                      otherJobType: formValues.otherJobType,
                      acceptedTandemPayAgreement: formValues.acceptedTandemPayAgreement,
                      code: formValues.code,
                      status: 'DRAFT',
                      jobId: jobId,
                  });
        const res = await client(`api/ongoing/${jobId ? jobId : ''}`, {
            method: jobId ? 'PATCH' : 'POST',
            body: request,
        });
        return res;
    }

    async function submit() {
        track('Job Form submit button clicked on page');
        const formValues = form.toValues();
        if (loading) {
            return;
        }

        setLoading(true);
        const family_id = familyId ?? user!.family[0].id!;

        let newJob;
        try {
            if (formValues.requestType === 'onetime') {
                newJob = await submitRequest(
                    form,
                    family_id,
                    user?.last_name || formValues.userEmail,
                    invitedProvider,
                    jobId,
                );
                setCreatedJob(newJob);
            } else {
                const jobPostDailyPay =
                    (formValues.payType && ['PAY_BY_DAY', 'MONTHLY_BUDGET'].includes(formValues.payType)) ||
                    formValues.businessJobType === 'TRIAL_RUN';
                let isOneTime = !jobPostDailyPay && (formValues.endDate?.diff(formValues.startDate, 'd') || 0) < 6;
                if (formValues.businessJobType) {
                    if ((formValues.endDate?.diff(formValues.startDate, 'd') || 0) > 1) {
                        isOneTime = false;
                    }
                }
                const submissionResult = (await submitJobRequest({
                    familyId: family_id,
                    payMin: formValues.pay.payMin,
                    payMax: formValues.pay.payMax,
                    jobOptions: {
                        selectedPreferredWorkers: formValues.preferredWorkers,
                        selectedPastWorkers: formValues.pastWorkers,
                        daysOfTheWeek: formValues.daysOfTheWeek,
                        sameTimes: formValues.sameTimes,
                        start: formValues.sameTimes
                            ? formValues.jobTimes.start &&
                              timeWithDate(formValues.jobTimes.start!, formValues.startDate!)
                            : formValues.startDate,
                        end: formValues.endDate,
                        end_time: formValues.jobTimes.end,
                        lastHireOffsetMinutes: formValues.lastHireOffsetMinutes,
                        transportation: formValues.transportation ?? false,
                        multipleProviders: formValues.multipleProviders,
                        comments: formValues.comments,
                        startEstimateMonth: formValues.startEstimateMonth,
                        headline: formValues.headline,
                        paymentSchedule: formValues.paymentSchedule,
                        jobQualifications: formValues.jobQualifications,
                        address: formValues.address,
                        pay_type: formValues.payType,
                        business_job_type: formValues.businessJobType,
                        jobDetails: formValues.jobDetails.map((detail) => ({ ...detail, option: detail.option_id })),
                        payScales: formValues.payScales,
                        breakRequired: formValues.breakRequired,
                        breakLength: formValues.breakLength,
                        parentRequest: formValues.templateJobId,
                        trialRunCoverage: formValues.trialRunCoverage,
                        faqs: formValues.faqs,
                        requireMinimumQualifications: formValues.requireMinimumQualifications,
                        breakEveryNMinutes: formValues.breakEveryNHours ? formValues.breakEveryNHours * 60 : undefined,
                        trialRunBenefits: formValues.trialRunBenefits,
                    },
                    lastName: user?.last_name || formValues.userEmail,
                    jobType: isOneTime ? JobType.onetime : JobType.ongoing,
                    invitedProvider: invitedProvider ? { id: invitedProvider.id } : undefined,
                    jobTypeId: formValues.jobTypeId,
                    otherJobType: formValues.otherJobType,
                    acceptedTandemPayAgreement: formValues.acceptedTandemPayAgreement,
                    code: formValues.code,
                    status: 'ACTIVE',
                    jobId: jobId,
                })) as { job: Job };
                setCreatedJob(submissionResult.job);
                newJob = submissionResult.job;
            }
            updateHasPostedJob();
            setJobSubmissionCompleted(true);
        } finally {
            setLoading(false);
        }
        goToNextPage();
        return newJob;
    }

    function goToPrevious() {
        track('Job Form back button clicked on page', { currentPage });

        if (currentPage === 0) {
            reset();
            closeJobForm();
        } else {
            setPage(currentPage - 1);
        }
    }

    async function getValuesForJobForm() {
        const initialValue = { preferredWorkers: [], pastWorkers: [], businessPrefences: [] };
        const values = await Promise.all([getJobTypes()]);
        const userValues = isLoggedIn()
            ? Array.from(await Promise.all([getHasAgreedToTandemPay(), getBusinessPreference()]))
            : [{ transportation: false }];

        return Object.assign(initialValue, ...values, ...userValues);
    }

    function toggleSimpleForm() {
        setSimple(!simple);
    }

    const contextValue = {
        form,
        currentPage,
        setPageCount,
        setFamilyId,
        pageCount,
        goToNext,
        goToPrevious,
        createdJob,
        jobTypes,
        previouslyAgreedToTandemPay,
        invitedProvider,
        loading,
        jobSubmissionCompleted,
        submit,
        reset,
        simple,
        toggleSimpleForm,
        defaultPayScales,
        updateDefaultPayScales,
        businessPreferences,
        formErrors,
        setFormErrors,
        hasSeenTrainingsModal,
        updateHasSeenTrainingsModal,
        setPresets,
        recommendations,
        setRecommendations,
        ignoreRecommendations,
        setIgnoreRecommendations,
        datesConfirmed,
        setDatesConfirmed,
    };

    return <JobFormContext.Provider value={contextValue}>{children}</JobFormContext.Provider>;
}

export function useJobFormContext() {
    return useContext(JobFormContext);
}

async function getJobTypes() {
    const jobTypes = (await client('api/job-types/')) as JobTypeModel[];
    jobTypes.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    return { jobTypes: [...jobTypes, { id: -1, name: 'Other' }] };
}

async function getHasAgreedToTandemPay() {
    const acceptedTandemPayAgreement = await UserAttributesApi.exists('Agreed to use TandemPay');
    return { acceptedTandemPayAgreement };
}

async function getBusinessPreference() {
    const businessPreferences = await getBusinessPreferences();
    return { businessPreferences };
}
