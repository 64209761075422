import DateFnsUtils from '@date-io/moment';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import ErrorText from '../../ErrorText';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FormFieldProps } from '../../FormTypes';
import FormField from '../../FormField';
import { plusOneDay, timeWithDate } from 'parent-portal/forms/time-operations';
import moment from 'moment';
import { useWidth } from 'reusableComponents/useWidth';
import { FaClock } from 'react-icons/fa';
import { Colors } from 'library';
import { JobTimes } from 'parent-portal/forms/JobRequestTypes';
import SlotsPicker from './SlotsPicker';

export default function JobTimePicker({
    jobTimes,
    startDate,
    allowPastMidnight = true,
    title,
    slotsAvailable,
    updateAllSlots,
    essentialOnly = false,
}: {
    jobTimes: FormFieldProps<JobTimes>;
    startDate?: moment.Moment | null;
    allowPastMidnight?: boolean;
    title?: string;
    slotsAvailable?: FormFieldProps<number>;
    updateAllSlots?: (slots: number) => void;
    essentialOnly?: boolean;
}) {
    const { start, end } = jobTimes.value;
    const width = useWidth();

    const [durationIsValid, setDurationValid] = useState(true);
    const [startTimeIsValid, setStartTimeValid] = useState(true);
    const [endTimeIsValid, setEndTimeValid] = useState(true);

    const [startIsNull, setStartIsNull] = useState(false);
    const [endIsNull, setEndIsNull] = useState(false);

    useEffect(() => updateValidStates(jobTimes.value), [jobTimes, startIsNull, endIsNull]);
    useEffect(
        () => updateFieldValidity(durationIsValid, startTimeIsValid, endTimeIsValid),
        [durationIsValid, startTimeIsValid, endTimeIsValid],
    );

    function updateFieldValidity(durationIsValid: boolean, startTimeIsValid: boolean, endTimeIsValid: boolean) {
        jobTimes.setValid(durationIsValid && startTimeIsValid && endTimeIsValid);
    }

    function updateValidStates({ start, end }: JobTimes) {
        const now = moment();

        const startIsDefined = !startIsNull && start !== null;
        const startIsNotInThePast = startIsDefined && timeWithDate(start!, startDate ?? moment()).isAfter(now);
        setStartTimeValid(startIsDefined && startIsNotInThePast);

        setEndTimeValid(!endIsNull && !!end && endTimeAllowPastMidnight(start as moment.Moment, end));

        const durationIsNotDefined = !start || !end;
        const newDurationIsValid = durationIsNotDefined || durationMeetsMinimum(start!, end!);
        setDurationValid(newDurationIsValid);
    }

    function durationMeetsMinimum(start: moment.Moment, end: moment.Moment) {
        const endTimeAndDate = end.isBefore(start) ? plusOneDay(end) : end;

        return endTimeAndDate.diff(start, 'minutes') + 1 > 30;
    }

    function onStartTimeChanged(time: MaterialUiPickersDate) {
        if (!time || !time.isValid()) {
            setStartIsNull(true);
            return;
        }
        setStartIsNull(false);

        const dateContext = startDate || moment();
        const dateTime = dateContext.clone().set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: 0,
            millisecond: 0,
        });

        jobTimes.setValue({
            ...jobTimes.value,
            start: dateTime,
        });
    }

    function onEndTimeChanged(time: MaterialUiPickersDate) {
        if (!time || !time.isValid()) {
            setEndIsNull(true);
            return;
        }
        setEndIsNull(false);

        const dateContext = startDate || moment();
        const dateTime = dateContext.clone().set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: 0,
            millisecond: 0,
        });

        jobTimes.setValue({
            ...jobTimes.value,
            end: dateTime,
        });
    }

    function endTimeAllowPastMidnight(start: moment.Moment, end: moment.Moment) {
        if (!allowPastMidnight) {
            return !end.isBefore(start);
        }
        return true;
    }

    const initialFocusedDateStart = start ?? moment().startOf('day').hour(8);

    const initialFocusedDateEnd = end ?? moment().startOf('day').hour(17);
    const startIsMidnight = start?.hour() === 0 && start.minute() === 0;
    const endIsMidnight = end?.hour() === 0 && end.minute() === 0;
    const startIsNoon = start?.hour() === 12 && start.minute() === 0;
    const endIsNoon = end?.hour() === 12 && end.minute() === 0;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container direction="row" paddingTop={2}>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{ marginRight: width === 'xs' ? 0 : 30, width: width === 'xs' ? '100%' : undefined }}
                >
                    <FormField title={title}>
                        <KeyboardTimePicker
                            id="start"
                            label={'Start'}
                            placeholder="Start: 08:00 AM"
                            format={`hh:mm ${startIsMidnight ? '\\mi\\dnig\\ht' : startIsNoon ? 'noon' : 'a'}`}
                            mask="__:__ _M"
                            value={start}
                            onChange={onStartTimeChanged}
                            initialFocusedDate={initialFocusedDateStart}
                            variant="inline"
                            style={{ width: '100%', marginBottom: 0 }}
                            keyboardIcon={<FaClock color={Colors.darkNavy} />}
                        />
                        {!startTimeIsValid && !!start && <ErrorText>Invalid start time</ErrorText>}
                    </FormField>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{
                        marginRight: width === 'xs' ? 0 : 30,
                        width: width === 'xs' ? '100%' : undefined,
                    }}
                >
                    <FormField>
                        <KeyboardTimePicker
                            id="end"
                            label={'End'}
                            placeholder="End: 5:00 PM"
                            format={`hh:mm ${endIsMidnight ? '\\mi\\dnig\\ht' : endIsNoon ? 'noon' : 'a'}`}
                            mask="__:__ _M"
                            value={end}
                            onChange={onEndTimeChanged}
                            initialFocusedDate={initialFocusedDateEnd}
                            variant="inline"
                            style={{ width: '100%' }}
                            keyboardIcon={<FaClock color={Colors.darkNavy} />}
                        />
                        {!endTimeIsValid && !!end && <ErrorText>Invalid end time</ErrorText>}

                        <Grid container xs={5}>
                            {!durationIsValid && <ErrorText>Duration must be at least 30 minutes</ErrorText>}
                        </Grid>
                    </FormField>
                </Grid>
                {updateAllSlots && (
                    <Grid item xs={12} sm={2} style={{ marginBottom: 15 }}>
                        <SlotsPicker
                            slotsAvailable={essentialOnly ? 1 : slotsAvailable?.value}
                            setSlotsAvailable={updateAllSlots}
                            label="Number of workers"
                            disabled={essentialOnly}
                        />
                    </Grid>
                )}
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
