import React from 'react';
import { SecondaryButton } from 'library';
import { AppliedProvider, Job } from 'models';
import { client, consoleLogInDev, track } from 'shared';
import { useHistory } from 'react-router';

export default function DeclineApplicantAction({
    provider,
    job,
    style,
}: {
    provider: AppliedProvider;
    job: Job;
    style?: React.CSSProperties;
}) {
    const history = useHistory();

    function declineApplicant() {
        client(`api/applicants/decline-applicant/${job.id}`, {
            method: 'PATCH',
            body: {
                sitter_user_id: provider.user.id,
            },
        })
            .then((res) => {
                track('Applicants Page: sitter declined');
                history.push(`/providers/applicants/${job.id}`);
            })
            .catch(consoleLogInDev);
    }

    return (
        <SecondaryButton onClick={declineApplicant} buttonStyle={style}>
            Decline
        </SecondaryButton>
    );
}
