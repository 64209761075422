import React, { useState } from 'react';
import { Grid, TableCell } from '@mui/material';
import ProviderExternalMessaging from 'internal/providers/ProviderExternalMessaging';
import ReverseMatching from 'internal/substitutes/Matching/ReverseMatching';
import SubAvailabilityDialog from 'internal/substitutes/SubAvailabilityDialog';
import ProviderDetailsOutreachModal from 'internal/texting/outreach/ProviderDetailsOutreachModal';
import { Text, Colors } from 'library';
import { AdminListProvider, IUserStats } from 'models';
import { formatDate } from 'shared/Dates';
import Notes from './tables/Notes';
import ProviderStatsCell from './ProviderStatsCell';

export default function ProviderNameCell({
    provider,
    providerStats,
    hiringGroupOpened,
}: {
    provider: AdminListProvider;
    providerStats?: IUserStats;
    hiringGroupOpened?: string;
}) {
    const [isModal, setIsModal] = useState(false);

    return provider ? (
        <>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} style={{ marginBottom: 4 }}>
                        <ProviderStatsCell
                            user={provider.user}
                            providerStats={providerStats}
                            isModal={isModal}
                            setIsModal={setIsModal}
                            hiringGroupOpened={hiringGroupOpened}
                        />
                        <Notes
                            name={`${provider.user.first_name} ${provider.user.last_name}`}
                            type="admin-providers"
                            objectId={provider.id}
                        />
                        {provider.user.phone_number ? <ProviderExternalMessaging user={provider.user} /> : null}
                    </Grid>
                    <Grid item container direction="column" style={{ gap: 10 }}>
                        <Grid item container direction="row">
                            <SubAvailabilityDialog userId={provider.user.id} />
                            <ReverseMatching provider={provider} />
                        </Grid>
                        <Grid>
                            <Text textStyle={{ color: provider.strikes > 0 ? Colors.error : Colors.darkNavy }}>
                                {provider.strikes} late cancellation strike{provider.strikes > 1 ? 's' : ''}{' '}
                                {provider.cancellation_reset ? `since ${formatDate(provider.cancellation_reset)}` : ''}
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>
            <ProviderDetailsOutreachModal
                providerUserId={provider.user.id}
                onClose={() => setIsModal(false)}
                open={isModal}
            />
        </>
    ) : null;
}
