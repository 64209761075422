import useLoading from 'library/hooks/useLoading';
import React, { useEffect, useState } from 'react';
import { PastShift } from '../models';
import { client, consoleLogInDev } from 'shared';
import { Colors, SizeableRoundedDialog } from 'library';
import { styled, Grid, Pagination, PaginationItem } from '@mui/material';
import PastShifts from './PastShifts';

export default function PastShiftsModal({
    open,
    onClose,
    providerUserId,
}: {
    open: boolean;
    onClose: () => void;
    providerUserId?: number;
}) {
    const { loading, setLoading, LoadingSpinner } = useLoading();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [shifts, setShifts] = useState<PastShift[]>([]);

    useEffect(loadShiftHistory, [page, open]);

    function loadShiftHistory() {
        if (totalPages >= page && providerUserId && open) {
            setLoading(true);
            client(`api/hour-report/past-shifts/?page=${page}&user_id=${providerUserId}`)
                .then((result) => {
                    setShifts(result.results);
                    setTotalPages(result.num_pages);
                })
                .catch(consoleLogInDev)
                .finally(() => setLoading(false));
        }
    }

    function closeModal() {
        onClose();
        setPage(1);
    }
    return (
        <SizeableRoundedDialog open={open} onClose={closeModal} closeButton>
            <Container item container direction="column" alignContent="center">
                {loading ? <LoadingSpinner /> : <PastShifts shifts={shifts} />}

                <StyledPagination
                    count={totalPages}
                    defaultValue={page}
                    onChange={(_e: any, p: number) => setPage(p)}
                    showFirstButton
                    showLastButton
                    color="primary"
                    renderItem={(item) => <StyledPaginationItem {...item} />}
                />
            </Container>
        </SizeableRoundedDialog>
    );
}

const Container = styled(Grid)({
    gap: 10,
});

const StyledPagination = styled(Pagination)({
    alignSelf: 'center',
});

const StyledPaginationItem = styled(PaginationItem)({
    color: Colors.darkNavy,
    '&.Mui-selected': {
        backgroundColor: Colors.darkNavy,
        color: Colors.white,
    },
    '&.Mui-hover': {
        backgroundColor: Colors.darkNavy,
    },
});
