import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ApiClient from '../../../../shared/ApiClient';
import DropDown from '../../../../library/components/formInputs/Dropdown';
import StyledDivider from '../../../../reusableComponents/StyledDivider.js';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { SimpleDialog } from '../../../../reusableComponents/dialogsSimple';
import theme from '../../../../theme';
import { track } from '../../../../shared/Tracking';
import { Environment } from 'environmentVariables';

const paySchedule = {
    'Confirm Payment at the end of the job': 'DAILY',
    'Weekly Payment': 'WEEKLY',
    // 'Monthly Payment on the first of the month': 'MONTHLY', // not ready for this quite yet
    'Automatic Payment at the end of every job': 'AUTOMATIC',
};

const payDays = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
};

interface IEditPayScheduleProps {
    confirmation: boolean;
    payWindow?: string;
    payDay?: string;
    showPayDayDropdown: boolean;
    onPayWindowChange: (e: any) => boolean | undefined;
    onPayWindowDayChange: (e: any) => void;
    ongoingRequest?: IOngoingRequest;
}

interface IOngoingRequest {
    id: string | number;
}

interface IUnpaidPairing {
    ongoingRequest: IOngoingRequest;
    status: string;
}

const EditPaySchedule = (props: IEditPayScheduleProps) => {
    const [unpaidPairings, setUnpaidPairings] = useState<IUnpaidPairing[]>();
    const [showUnpaidModal, setShowUnpaidModal] = useState(false);
    useEffect(() => {
        getUnpaidPairings();
    }, []);

    const getUnpaidPairings = async () => {
        try {
            const response = await ApiClient(`api/nested-pairings/?unpaid&request_id=${props.ongoingRequest?.id}`);
            setUnpaidPairings(response);
        } catch (error) {
            if (Environment !== 'production') {
                console.error(error);
            }
            track('Error: nested-pairings/unpaid', { requestID: props.ongoingRequest?.id });
        }
    };

    const onPayScheduleChange = (e: any) => {
        const groupToNon = props.onPayWindowChange(e);
        if (groupToNon && unpaidPairings && unpaidPairings?.length > 0) {
            setShowUnpaidModal(true);
            track('Edit Pay Schedule: Group Pay to Daily/Auto with Unpaid Pairings', {
                unpaidPairingCount: unpaidPairings.length,
            });
        }
    };

    const getHelpDialogText = (
        <Grid container>
            <Typography style={{ color: theme.palette.primary.main }}>
                You have {unpaidPairings?.length} unpaid past appointment
                {unpaidPairings?.length && unpaidPairings?.length > 1 ? 's' : ''} that will be automatically paid within
                the next hour if the payment schedule is changed from 'Weekly Payment'.
            </Typography>
            <Typography style={{ color: theme.palette.primary.main }}>
                If you would like to make any adjustments to the appointment
                {unpaidPairings?.length && unpaidPairings?.length > 1 ? 's before they are ' : ' before it is '}
                automatically paid, you can do so from the home page or payments page after saving these changes.
            </Typography>
        </Grid>
    );

    return (
        <Grid direction="row">
            <Grid container>
                <Grid item>
                    <SimpleDialog
                        isOpen={showUnpaidModal}
                        dialogTitle={'You have unpaid appointments'}
                        children={getHelpDialogText}
                        onClose={() => setShowUnpaidModal(false)}
                    />
                </Grid>
                <Grid item container justify="center" xs={1} alignContent="center">
                    <DateRangeIcon style={{ color: '#28516b' }} />
                </Grid>
                <Grid item xs={10} style={{ marginLeft: 10 }}>
                    <DropDown
                        disabled={props.confirmation}
                        noMarginBottom
                        marginRight
                        selected={props.payWindow}
                        title="Manage Payment Schedule"
                        fields={Object.keys(paySchedule)}
                        onChange={(e: any) => onPayScheduleChange(e)}
                        placeholder="Manage Payment Schedule"
                    />
                    {props.showPayDayDropdown && (
                        <DropDown
                            disabled={!props.showPayDayDropdown}
                            noMarginBottom
                            marginRight
                            selected={props.payDay}
                            title="Day of the Week"
                            fields={Object.keys(payDays)}
                            onChange={(e: any) => props.onPayWindowDayChange(e)}
                        />
                    )}
                </Grid>
            </Grid>
            <StyledDivider />
        </Grid>
    );
};

export default EditPaySchedule;
