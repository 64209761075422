import React, { useState } from 'react';
import { Text, Colors, RadioGroup, OutlinedTextInput } from 'library';
import { Grid } from '@material-ui/core';
import { useUserContext } from 'UserContext';
import { client, consoleLogInDev, track } from 'shared';
import JobTimePicker from '../../forms/components/pages/DateAndTimePage/JobTimePicker';
import JobDatePicker from '../../forms/components/pages/DateAndTimePage/JobDatePicker';
import CurrencyInput from 'reusableComponents/currencyInput';
import moment from 'moment';
import useForm from '../../forms/components/FormHook';
import BookProviderSuccess from './BookProviderSuccess';
import { JOB_TYPES, PAY_TYPES } from './models';
import BookProviderSubmitButton from './BookProviderSubmitButton';
import { JobTimes } from 'parent-portal/forms/JobRequestTypes';

interface IProps {
    providerUserId: number;
    providerName: string;
    onClose: () => void;
    showJobType?: boolean;
    showFixedPay?: boolean;
    title?: string;
}

export const BookProvider = ({
    providerUserId,
    providerName,
    onClose,
    showJobType = true,
    showFixedPay = true,
    title,
}: IProps) => {
    const tomorrow = moment(new Date()).add(1, 'days');
    const form = useForm({
        jobTimes: {
            start: moment(new Date(tomorrow.year(), tomorrow.month(), tomorrow.date(), 8, 0)),
            end: moment(new Date(tomorrow.year(), tomorrow.month(), tomorrow.date(), 17, 0)),
        } as JobTimes,
        jobDate: tomorrow as moment.Moment | null,
        multiDay: true,
    });

    const [payType, setPayType] = useState('hourly');
    const [payAmount, setPayAmount] = useState<number>(20);
    const [description, setDescription] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [jobType, setJobType] = useState(1);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [showSuccess, setShowSuccess] = useState(false);

    const { user } = useUserContext();

    function onBookPress() {
        setLoading(true);
        if (!validateForm()) {
            setLoading(false);
            return;
        }
        client('api/ongoing/request-available-provider/', {
            method: 'POST',
            body: getRequestBody(),
        })
            .then(() => {
                setShowSuccess(true);
                track('Book Provider from Messaging: job created', { providerId: providerUserId });
            })
            .catch((error) => {
                consoleLogInDev(error);
                setErrorMessage('An error has occurred. Please try again.');
            })
            .finally(() => setLoading(false));
    }

    function getStartDate() {
        return moment(
            new Date(
                form.fieldStates.jobDate.value?.year() || tomorrow.year(),
                form.fieldStates.jobDate.value?.month() || tomorrow.month(),
                form.fieldStates.jobDate.value?.date() || tomorrow.date(),
                form.fieldStates.jobTimes.value.start?.hours(),
                form.fieldStates.jobTimes.value.start?.minutes(),
            ),
        );
    }

    function getEndDate() {
        if (
            form.fieldStates.jobTimes.value.start?.hours() &&
            form.fieldStates.jobTimes.value.end?.hours() &&
            form.fieldStates.jobTimes.value.start?.hours() > form.fieldStates.jobTimes.value.end?.hours()
        ) {
            return moment(
                new Date(
                    form.fieldStates.jobDate.value?.year() || tomorrow.year(),
                    form.fieldStates.jobDate.value?.month() || tomorrow.month(),
                    form.fieldStates.jobDate.value?.date() || tomorrow.date(),
                    form.fieldStates.jobTimes.value.end?.hours(),
                    form.fieldStates.jobTimes.value.end?.minutes(),
                ),
            ).add(1, 'days');
        }
        return moment(
            new Date(
                form.fieldStates.jobDate.value?.year() || tomorrow.year(),
                form.fieldStates.jobDate.value?.month() || tomorrow.month(),
                form.fieldStates.jobDate.value?.date() || tomorrow.date(),
                form.fieldStates.jobTimes.value.end?.hours(),
                form.fieldStates.jobTimes.value.end?.minutes(),
            ),
        );
    }

    function getRequestBody() {
        return {
            family: user?.family?.[0]?.id,
            status: 'ACTIVE',
            preferred_sitters: { preferred_sitters: [{ sitter: providerUserId }] },
            job_type: jobType,
            headline: getHeadline(),
            family_comments: description,
            preferred_education_level: 'NO_PREFERENCE',
            times_of_day: '',
            start_date: getStartDate(),
            end_date: getEndDate(),
            pay: payAmount,
            pay_is_fixed: payType === 'fixed',
            long_term: false,
            quick_fill: true,
            ongoing: false,
            automatic: false,
            hard_availability: true,
            transportation: false,
            multiple_providers: false,
            requested: false,
            send_to_others: false,
        };
    }

    function onBookAgainPress() {
        setErrorMessage(undefined);
        setDescription('');
        track('Book Provider from Messaging: Book Again clicked');

        setShowSuccess(false);
    }

    function getHeadline() {
        if (title) return title;
        let jobString = 'Child Care';
        if (jobType === 2) {
            jobString = 'Pet Care';
        }
        if (jobType === 8) {
            jobString = 'House Cleaning';
        }
        let familyName = '';
        if (user && user.last_name) {
            familyName = ` for the ${user.last_name} family`;
        }
        return `${jobString}${familyName}`;
    }

    function validateForm() {
        setErrorMessage(undefined);
        return validateDateTimes() && validatePay() && validateDescription();
    }

    function validateDateTimes() {
        return form.fieldStates.jobDate.isValid && form.fieldStates.jobTimes.isValid;
    }

    function validateDescription() {
        if (!description) {
            setErrorMessage('Please enter a job description');
            return false;
        }

        return true;
    }

    function validatePay() {
        if (!payAmount) {
            setErrorMessage('Please enter pay');
            return false;
        }
        if (payAmount <= 0) {
            setErrorMessage('Pay must be greater than $0');
            return false;
        }

        if (payAmount < 8 && payType === 'hourly') {
            setErrorMessage('Minimum pay rate is $8/hr');
            return false;
        }

        return true;
    }

    function onPayChange(amount: number) {
        setPayAmount(amount);
    }

    function onTextChange(e: { target: { value: string } }) {
        setDescription(e.target.value);
    }

    function getSectionLabel(label: string) {
        return (
            <Grid item xs={12}>
                <Text bold variant={'body1'}>
                    {label}
                </Text>
            </Grid>
        );
    }

    function getJobTypeSelection() {
        return (
            <Grid container xs={12} style={{ marginBottom: 5 }}>
                {getSectionLabel('Job Type*')}
                <Grid item>
                    <RadioGroup
                        setValue={(val: string) => setJobType(parseInt(val))}
                        value={jobType.toString()}
                        horizontal
                        values={JOB_TYPES}
                    />
                </Grid>
            </Grid>
        );
    }

    function getTimes() {
        return (
            <Grid container xs={12} md={6}>
                {getSectionLabel('Time*')}
                <Grid container direction="row" xs={12}>
                    <Grid item xs={12} style={{ padding: 10 }}>
                        <JobTimePicker
                            jobTimes={form.fieldStates.jobTimes}
                            startDate={form.fieldStates.jobDate.value}
                            allowPastMidnight={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function getDates() {
        return (
            <Grid container xs={12} md={6}>
                {getSectionLabel('Date*')}
                <Grid container direction="row" xs={12}>
                    <Grid item xs={12} style={{ padding: 10, paddingTop: 25 }}>
                        <JobDatePicker jobDate={form.fieldStates.jobDate} minDate={moment(new Date())} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function getPay() {
        return (
            <Grid container xs={12} style={{ marginBottom: 5 }}>
                {getSectionLabel('Pay*')}
                <Grid container item direction="row" spacing={2} justify="flex-start" alignItems="center">
                    <Grid item>
                        <CurrencyInput
                            textAlign="left"
                            minimumValue="0"
                            maximumValue="10000"
                            fullWidth
                            value={payAmount}
                            onChange={(e: any, value: number) => onPayChange(value)}
                            decimalPlaces={0}
                            outputFormat="number"
                            InputProps={{
                                style: {
                                    fontSize: 18,
                                    color: Colors.darkNavy,
                                },
                                inputProps: {
                                    style: {
                                        textAlign: 'left',
                                    },
                                },
                                disableUnderline: false,
                                startAdornment: (
                                    <Text variant="body2" textStyle={{ fontSize: 18 }}>
                                        $
                                    </Text>
                                ),
                            }}
                            placeholder="0"
                            style={{ maxWidth: 100 }}
                        />
                    </Grid>
                    <Grid item>
                        {showFixedPay ? (
                            <RadioGroup
                                setValue={(val: string) => setPayType(val)}
                                value={payType}
                                horizontal
                                values={PAY_TYPES}
                            />
                        ) : (
                            <Text>/hr</Text>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function getDescription() {
        return (
            <Grid container item xs={12} style={{ marginBottom: 5 }}>
                {getSectionLabel('Description*')}
                <Grid item xs={12}>
                    <OutlinedTextInput
                        style={{
                            width: '100%',
                            backgroundColor: Colors.white70percent,
                            borderRadius: 18,
                        }}
                        multiline={true}
                        onChange={(e) => onTextChange(e)}
                    />
                </Grid>
            </Grid>
        );
    }

    function getCreateStep() {
        return (
            <>
                <Grid container direction="row" xs={12} lg={6} xl={4} alignItems="flex-start">
                    {showJobType && getJobTypeSelection()}
                    {getDates()}
                    {getTimes()}
                </Grid>
                <Grid container xs={12} direction="column" lg={6} xl={8} alignItems="flex-start">
                    {getPay()}
                    {getDescription()}
                    <BookProviderSubmitButton
                        loading={loading}
                        showSuccess={showSuccess}
                        onBookPress={onBookPress}
                        onBookAgainPress={onBookAgainPress}
                        errorMessage={errorMessage}
                    />
                </Grid>
            </>
        );
    }

    return (
        <Grid
            style={{
                backgroundColor: Colors.mediumTurq,
                padding: 15,
            }}
            container
            direction="row"
            alignItems="flex-start"
            justify="space-between"
        >
            {showSuccess ? <BookProviderSuccess providerName={providerName} onClose={onClose} /> : getCreateStep()}
        </Grid>
    );
};
