import React, { useEffect, useState } from 'react';
import SignIn from './LoginForm';
import { withRouter } from 'react-router-dom';
import { identifyProvider, track } from '../../shared/Tracking';
import apiClient from '../../shared/ApiClient';
import { useUserContext } from '../../UserContext';
import { Environment } from 'environmentVariables';

const Login = (props) => {
    const [username, setUsername] = useState('');
    const [token, setToken] = useState('');
    const [loginFailed, setLoginFailed] = useState(false);
    const { refreshUser } = useUserContext();

    useEffect(() => {
        if (token) {
            doLogin();
        }
    }, [token]);

    const doLogin = () => {
        let { from } = props.location.state || { from: { pathname: '/sitter/payment' } };
        apiClient('api/users/identity/', { headers: { Authorization: `Token ${token}` } })
            .then((emailResponse) => {
                if (emailResponse.hasOwnProperty('sitter') && emailResponse.sitter.length !== 0) {
                    identifyProvider(emailResponse.id);
                    track('Sitter Portal Login', { user: username });
                    localStorage.setItem('token', token);
                    refreshUser();
                    props.history.replace(from);
                }
            })
            .catch((error) => {
                if (Environment !== 'production') {
                    console.error(error);
                }
            });
    };

    const handleLogin = (username, password) => {
        setUsername(username);
        apiClient('api/rest-auth/login/', { body: { username, password } })
            .then((loginResponse) => {
                if (loginResponse.key) {
                    localStorage.clear();
                    setToken(loginResponse.key);
                }
            })
            .catch((error) => setLoginFailed(true));
    };

    return <SignIn handleLogin={handleLogin} loginFailed={loginFailed} />;
};

export default withRouter(Login);
