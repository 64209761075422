import React from 'react';
import MedicalRecordVerify from './VerificationPages/MedicalRecordVerify';
import GeneralDocumentVerify from './VerificationPages/GeneralDocumentVerify';
import { DocumentVerificationContentProps } from './models';

export default function DocumentVerificationContent({
    medicalRecord,
    signedAt,
    setSignedAt,
    expirationDate,
    setExpirationDate,
}: DocumentVerificationContentProps) {
    const contentOptions = {
        medDoc: <MedicalRecordVerify setSignedAt={setSignedAt} signedAt={signedAt} />,
        generalDoc: <GeneralDocumentVerify setExpirationDate={setExpirationDate} expirationDate={expirationDate} />,
    };
    const specializedDoc = medicalRecord ? 'medDoc' : 'generalDoc';

    return contentOptions[specializedDoc as keyof typeof contentOptions];
}
