import React, { useState, useEffect } from 'react';
import { Provider } from 'models';
import { consoleLogInDev, track } from 'shared';
import { likeProvider } from 'api/ProviderLikeApi';
import { Bookmark } from '@material-ui/icons';
import { useUserContext } from 'UserContext';
import AuthModal from 'parent-portal/login/AuthModal';
import { useHistory, useLocation } from 'react-router';
import { isLoggedIn } from 'api/UserApi';
import { Colors, SmallIconButton } from 'library';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';
import useIsMount from 'parent-portal/shared/PageMounted';
import useCookies from 'shared/Cookies';

const signUpMessage = 'Create your free account to view and message your saved providers!';

const SaveProviderButton = ({ provider, shadow = false }: { provider: Provider; shadow?: boolean }) => {
    const { user, updateSavedProviders, savedProviders } = useUserContext();
    const [isLiked, setIsLiked] = useState(provider.is_liked || false);
    const [isSaving, setIsSaving] = useState(false);
    const [open, setOpen] = useState(false);
    const { setCookie } = useCookies(['provider_accessor']);
    const location = useLocation();
    const history = useHistory();
    const isMount = useIsMount();

    const { trackSession } = usePublicSessionTracking('Save Provider Button');

    useEffect(() => {
        if (!location.pathname.includes('providers')) {
            setOpen(!!savedProviders && savedProviders.includes(provider.id));
        }
    }, []);

    useEffect(() => {
        if (!isLoggedIn() && !!location && !!provider && !isMount) {
            trackSession(
                open ? 'DIALOG_OPEN' : 'DIALOG_CLOSE',
                'Family signup modal from Provider Card: Save Provider Button',
                PublicSessionEventType.User,
                { providerId: provider.id },
            );
        }
    }, [open]);

    useEffect(() => {
        setIsLiked(!!provider?.is_liked);
    }, [provider]);

    const onClick = (e: any) => {
        if (user || isLoggedIn()) {
            e.preventDefault();
            if (isSaving) {
                return;
            }

            setIsSaving(true);

            const newValue = !isLiked ? 'Like' : 'Unlike';
            track(`Provider Card: ${newValue} Provider`, { id: provider.id });
            trackSession('CLICK', `Provider Card: ${newValue}`, PublicSessionEventType.User, {
                providerId: provider.id,
            });
            likeProvider(provider.id)
                .then(() => {
                    setIsLiked(!isLiked);
                    track('Provider Card: Like Provider Success');
                })
                .catch((error) => track('Provider Card: Like Provider Error', error))
                .finally(() => setIsSaving(false));
        } else if (!!savedProviders && !savedProviders.includes(provider.id)) {
            updateSavedProviders(provider.id);
            setCookie('provider_accessor', provider.user.id, { path: '/' });
            setOpen(true);
        } else {
            trackSession('CLICK', 'Provider Card: Unlike', PublicSessionEventType.User, {
                providerId: provider.id,
            });
            updateSavedProviders(provider.id);
        }
    };

    function handleClose() {
        if (isLoggedIn()) {
            trackSession('NAVIGATE_FROM', 'To home page after liking provider', PublicSessionEventType.User, {
                providerId: provider.id,
            });
            track('Provider Card: User Created After Liked Provider', { id: provider.id, saved_ids: savedProviders });
            !!savedProviders &&
                savedProviders.map((providerId: number, index: number, providerList: number[]) => {
                    likeProvider(providerId).catch(consoleLogInDev);
                    if (index === providerList.length - 1) {
                        setOpen(false);
                    }
                });
            history.push('/home');
        } else {
            trackSession('DIALOG_CLOSE', 'Aborted sign up after liking provider', PublicSessionEventType.User, {
                providerId: provider.id,
            });
            setOpen(false);
        }
    }

    return (
        <>
            <SmallIconButton
                onClick={onClick}
                icon={Bookmark}
                color={
                    isLiked || (!!savedProviders && savedProviders.includes(provider.id))
                        ? Colors.terraCotta
                        : Colors.disabledGrey
                }
                backgroundColor={Colors.white}
                disabled={isSaving}
                hoverText="Save Provider"
                shadow={shadow}
                style={{ marginRight: 0 }}
            />
            <AuthModal
                location="Save Provider Button"
                signup
                open={open}
                onClose={handleClose}
                message={signUpMessage}
            />
        </>
    );
};

export default SaveProviderButton;
