import ProviderDetailsOutreachModal from 'internal/texting/outreach/ProviderDetailsOutreachModal';
import { TextButton } from 'library';
import { LeanUser } from 'models';
import React, { useState } from 'react';

export default function ProviderDetailTextButton({ user }: { user: LeanUser }) {
    const [detailsOpen, setDetailsOpen] = useState(false);
    return (
        <>
            <TextButton buttonStyle={{ width: undefined, marginRight: 10 }} onClick={() => setDetailsOpen(true)}>
                {user.first_name} {user.last_name} ({user.id})
            </TextButton>
            <ProviderDetailsOutreachModal
                providerUserId={user.id}
                onClose={() => setDetailsOpen(false)}
                open={detailsOpen}
            />
        </>
    );
}
