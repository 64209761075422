import React from 'react';
import ModalPage from 'parent-portal/payments/Components/ConnectPaymentModal/ModalPage';
import PageHeader from 'parent-portal/payments/Components/ConnectPaymentModal/PageHeader';
import { PrimaryButton, Text } from 'library';
import { Grid } from '@material-ui/core';
import TadaIcon from 'assets/icons/tandempay/tada-circle.svg';
import { track } from 'shared';
import { isMobile } from 'react-device-detect';

export default function PostHireReferral() {
    return (
        <ModalPage>
            <PageHeader
                iconSource={<TadaIcon />}
                headerText={`What's next?`}
                subtitleText={`Let us know how we're doing!`}
            />
            <Grid style={{ marginBottom: 20, paddingLeft: isMobile ? 10 : 100, paddingRight: isMobile ? 10 : 100 }}>
                <Text variant="body1">
                    We're excited you found your perfect provider! Leave a Google review and let us know how we're
                    doing!
                </Text>

                <Grid xs={12} justify="flex-end" spacing={1} container style={{ marginTop: isMobile ? 30 : 100 }}>
                    <Grid item>
                        <PrimaryButton
                            buttonStyle={{ width: 300 }}
                            onClick={() => {
                                track('Post hire: leave a google review');
                                window.open(
                                    'http://search.google.com/local/writereview?placeid=ChIJDRvXCtePOIgRf8lCeOHYuCg',
                                    '_blank',
                                );
                            }}
                        >
                            Leave a Google Review
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </ModalPage>
    );
}
