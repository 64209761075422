import React from 'react';
import { Divider, MenuItem } from '@mui/material';
import { Chip, Colors, Text } from 'library';
import { AdminListProvider, BusinessJobWithAppointments, LeanAdminProvider } from 'models';
import AddProvider from '../AddProvider';
import { format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';
import SendMatch from './SendMatch';
import JobDescription from 'internal/business/JobDescription';
import SubAvailabilityDialog from '../SubAvailabilityDialog';
import { client } from 'shared';
import { ProviderMatch } from '../substituteModels';
import { StandByProvider } from './StandbyList';

export default function ReverseMatchingMenuItem({
    provider,
    job,
    refreshJobs,
    updateProvider,
}: {
    provider: AdminListProvider | StandByProvider | LeanAdminProvider;
    job: BusinessJobWithAppointments;
    refreshJobs: () => void;
    updateProvider?: (providerId: number, fields: Partial<AdminListProvider>) => void;
}) {
    const user = provider.user;

    function refreshMatches() {
        if (updateProvider) {
            client(`api/match/?provider_user=${provider.user.id}&outstanding=true`).then((res) => {
                const jobs = new Set(res.map((match: ProviderMatch) => match.appointment.ongoing_request.id));
                if ('id' in provider) updateProvider(provider.id, { outstanding_matches: jobs.size });
            });
        }
    }

    return (
        <>
            <MenuItem disableRipple style={{ justifyContent: 'space-between' }}>
                <div>
                    <Text textStyle={{ whiteSpace: 'break-spaces' }}>
                        {job.business[0].name} {job.headline} ({job.id}){' '}
                        <Chip
                            style={{ minHeight: 20, height: 20 }}
                            textColor={Colors.white}
                            backgroundColor={
                                job.business_job_type === 'TRIAL_RUN' ? Colors.mediumTerraCotta : Colors.turquoise
                            }
                            label={job.business_job_type === 'TRIAL_RUN' ? 'TRIAL' : 'SUB'}
                        />
                        <JobDescription job={job} />
                    </Text>
                    <Text variant="body2" bold>
                        Open appointments
                    </Text>
                    {job.slots.map((slot) => (
                        <Text key={slot.id}>
                            {formatShortWeekdayMonthDay(slot.start_date)} {format12HourTime(slot.start_date)} -{' '}
                            {format12HourTime(slot.end_date)}
                        </Text>
                    ))}
                    <AddProvider job={job} refreshJobs={refreshJobs} initialProviderUserId={user.id} />
                    <SendMatch job={job} initialProviders={[user]} refreshProvider={refreshMatches} />
                    <SubAvailabilityDialog userId={provider.user.id} />
                    <Divider style={{ border: `1px solid ${Colors.lightGrey}`, marginTop: 8, marginBottom: 8 }} />
                </div>
            </MenuItem>
        </>
    );
}
