import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { ButtonWrapperProps } from './models';

const ButtonWrapper = React.forwardRef<HTMLButtonElement, ButtonWrapperProps>(function _ButtonWrapper(
    {
        disabled,
        children,
        onClick,
        enabledStyle,
        defaultStyle,
        disabledStyle,
        buttonStyle,
        textStyle,
        boldText,
        ...props
    }: ButtonWrapperProps,
    ref,
) {
    const getButtonStyle = () => {
        return !disabled ? enabledStyle.button : disabledStyle.button;
    };

    const getTextStyle = () => {
        return disabled ? disabledStyle.text : enabledStyle.text;
    };

    return (
        <Button
            ref={ref}
            style={{ ...defaultStyle?.button, ...getButtonStyle(), ...buttonStyle }}
            onClick={disabled ? () => null : onClick}
            disableElevation
            {...props}
        >
            <Typography
                component="div"
                style={{
                    ...getTextStyle(),
                    ...defaultStyle?.text,
                    ...textStyle,
                    fontWeight: boldText ? 'bold' : 'normal',
                }}
            >
                {children}
            </Typography>
        </Button>
    );
});
export default ButtonWrapper;
