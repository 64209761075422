import { PagingResult } from 'models';
import { client, queryStringifyObject } from 'shared';
import { InboxCount, TaskListItem, TaskListSearchFilters } from './matchingInboxModels';

export async function getTaskList(searchFilters: TaskListSearchFilters): Promise<PagingResult<TaskListItem>> {
    const params = queryStringifyObject(searchFilters);
    return await client(`api/matching-inbox/?${params}`);
}

export async function getInboxCountSummary(regions: number[]): Promise<InboxCount[]> {
    return await client(`api/matching-inbox/count/?regions=${regions.join(',')}`);
}
