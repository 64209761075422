import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    IconButton,
    withStyles,
    Dialog,
    DialogContent,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../reusableComponents/button';
import Input from '../../../../reusableComponents/input';
import CloseIcon from '@material-ui/icons/Close';
import theme from '../../../../theme';
import apiClient from '../../../../shared/ApiClient';
import { track } from '../../../../shared/Tracking';
import NotPayingCancellationModal from '../CancellationModals/NotPayingCancellationModal';
import CancelWithApplicantModal from '../CancellationModals/CancelWithApplicantModal';
import { consoleLogInDev } from 'shared';

const StyledIconButton = withStyles({
    root: {
        padding: 0,
        '&:focus': {
            outline: 'none',
        },
    },
})(IconButton);

const useStyles = makeStyles(() => ({
    root: {
        color: theme.palette.primary.main,
        margin: 5,
    },
}));

export const getCancellationReasonsResp = async (job_id) => {
    return apiClient(`api/reason/${job_id}/questions/`).catch((error) => {
        console.log(error);
    });
};

function WhyCancelDialog(props) {
    const { job, cancel, requestNewSitter, reasons, reasonType, refresh } = props;

    const [comment, setComment] = useState('');
    const [primaryReason, setPrimaryReason] = useState('Unnecessary');
    const [prettyPrimary, setPrettyPrimary] = useState('Unnecessary');
    const [secondaryReason, setSecondaryReason] = useState('Unnecessary');
    const [secondaryReasons, setSecondaryReasons] = useState([]);
    const [step, setStep] = useState(1);
    const [openNextStep, setOpenNextStep] = useState(true);
    const [isOpen, setIsOpen] = useState(!job.deactivated);
    const [checkedInput, setCheckedInput] = useState(false);
    const [showNotPaying, setShowNotPaying] = useState(false);
    const [showApplicantCancellationModal, setShowApplicantCancellationModal] = useState(
        job.status === 'AWAITING_SELECTION',
    );
    const [hasMessaged, setHasMessaged] = useState(false);
    const [appliedProviders, setAppliedProviders] = useState([]);
    const notIndividualDayOfWeeklyJob = props.type !== 'appointment' || (props.type === 'appointment' && !job.ongoing);

    useEffect(() => {
        const id = job.id;
        apiClient(`api/applicants/including-job-modularity/${id}/`).then((resp) => {
            // filter on messaged applicants
            let messageList = [];

            resp.forEach((item) => {
                if (item.has_recieved_message_from_family) {
                    setHasMessaged(true);
                    messageList.push(item);
                }
            });
            setAppliedProviders(messageList);
        });
    }, []);

    const classes = useStyles();

    useEffect(() => {
        if (primaryReason !== 'Unnecessary') {
            setSecondaryReasons(reasons[primaryReason]);
        }
    }, [primaryReason]);

    const submitReason = async () => {
        let form = {
            reasons: [{ primary_reason: primaryReason }],
            reason_type: reasonType,
            overall_reason_type: 'FAMILY_CANCELLED',
        };

        if (secondaryReason !== 'Unnecessary') {
            form.reasons.push({ secondary_reason: secondaryReason });
        }

        if (comment !== '' && primaryReason.indexOf('DISLIKE_TANDEM') > 0) {
            form['comment'] = comment;
        }

        // onetime job -> ongoing request
        // entire recurring -> ongoing request
        // single day of ongoing job -> appointment
        form['appointment'] = job.id;

        if (primaryReason !== 'Unnecessary') {
            try {
                apiClient('api/reason/', {
                    body: form,
                    method: 'POST',
                }).then(() => {
                    if (job.pairings.length > 0) {
                        track('Family Cancelled', { pairing: job.pairings[0].id });
                    } else {
                        track('Family Cancelled', { appointment: job.id });
                    }
                    setCheckedInput(false);
                    setSecondaryReason('Unnecessary');
                    setSecondaryReasons([]);
                    setPrimaryReason('Unnecessary');
                    setPrettyPrimary('Unnecessary');
                    setComment('');
                });
            } catch (error) {
                consoleLogInDev(error);
            }
        }
    };

    const cancelAndSubmit = () => {
        submitReason();
        cancel();
        refresh();
    };

    const canConfirm = () => {
        if (secondaryReason.toLowerCase() !== 'unnecessary' && prettyPrimary.toLowerCase() !== 'unnecessary') {
            // picked a primary and a secondary reason
            setCheckedInput(true);
        } else if (prettyPrimary === "I don't like using Tandem" && comment === '') {
            // picked I don't like tandem and didn't give a comment
            setCheckedInput(false);
        } else if (
            secondaryReasons.length !== 0 &&
            secondaryReason.toLowerCase() === 'unnecessary' &&
            prettyPrimary !== "I don't like using Tandem"
        ) {
            // hasn't picked a secondary reason yet
            setCheckedInput(false);
        } else if (secondaryReasons.length === 0) {
            // no secondary reason to be chosen
            setCheckedInput(true);
        } else {
            setCheckedInput(true);
        }
    };

    const primaryReasonDialog = () => {
        return (
            <Grid container xs={12}>
                {reasons && (
                    <RadioGroup value={primaryReason}>
                        {Object.keys(reasons).map((text) => (
                            <FormControlLabel
                                key={text}
                                onChange={() => {
                                    setPrimaryReason(text);
                                    setPrettyPrimary(text.substring(text.indexOf(' ') + 2, text.length - 2));
                                }}
                                control={<Radio color="primary" disableRipple disableFocusRipple value={text} />}
                                label={
                                    <Typography variant="subtitle1">
                                        {text.substring(text.indexOf(' ') + 2, text.length - 2)}
                                    </Typography>
                                }
                            />
                        ))}
                    </RadioGroup>
                )}
                <Button
                    fullWidth
                    style={{ marginTop: 10 }}
                    disableFocusRipple
                    disableRipple
                    disabled={primaryReason.toLowerCase() === 'unnecessary'}
                    onClick={() => {
                        setSecondaryReasons(reasons[primaryReason]);
                        setStep(2);
                        canConfirm();
                    }}
                >
                    Next
                </Button>
            </Grid>
        );
    };

    const secondaryReasonDialog = () => {
        return (
            <Grid container xs={12}>
                <Typography variant="subtitle1">{prettyPrimary}</Typography>
                {secondaryReasons !== 'Unnecessary' && (
                    <RadioGroup value={secondaryReason}>
                        {prettyPrimary !== "I don't like using Tandem" &&
                            secondaryReasons.map((text) => (
                                <FormControlLabel
                                    key={text}
                                    onChange={() => {
                                        setSecondaryReason(text);
                                        setCheckedInput(true);
                                    }}
                                    control={<Radio color="primary" disableRipple disableFocusRipple value={text} />}
                                    label={
                                        <Typography variant="subtitle1">
                                            {text.substring(text.indexOf(' ') + 2, text.length - 2)}
                                        </Typography>
                                    }
                                />
                            ))}
                    </RadioGroup>
                )}
                {prettyPrimary === "I don't like using Tandem" && (
                    <Grid item xs={12}>
                        <Input
                            required
                            error={comment === null}
                            helperText={'Please explain'}
                            multiline
                            rows={3}
                            variant="outlined"
                            fullWidth
                            value={comment}
                            onChange={(event) => {
                                setComment(event.target.value);
                                canConfirm();
                            }}
                        />
                    </Grid>
                )}
                <Grid container direction="row" justify="space-between">
                    <Button
                        noMinWidth
                        style={{ marginTop: 10, backgroundColor: theme.palette.secondary.coral, width: '48%' }}
                        disableFocusRipple
                        disableRipple
                        onClick={() => {
                            setStep(1);
                            setSecondaryReason('Unnecessary');
                            setSecondaryReasons([]);
                            setCheckedInput(false);
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        style={{ marginTop: 10, width: '48%' }}
                        noMinWidth
                        disableFocusRipple
                        disableRipple
                        disabled={!checkedInput && secondaryReasons?.length !== 0}
                        onClick={() => {
                            if (primaryReason.indexOf('PAYMENT') !== -1) {
                                setShowNotPaying(true);
                            } else {
                                setStep(3);
                            }
                            setIsOpen(false);
                        }}
                    >
                        Confirm
                    </Button>
                </Grid>
            </Grid>
        );
    };

    const nextStepDialog = () => {
        let index = 0;
        if (prettyPrimary === 'I found someone else not on Tandem' && job.ongoing) {
            index = 1;
            // invite your provider to tandem and get $50
        } else if (
            prettyPrimary === 'My schedule changed' &&
            secondaryReason.substring(secondaryReason.indexOf(' ') + 2, secondaryReason.length - 2) ===
                'I would like to try Tandem again'
        ) {
            index = 2;
            // send them to make a new request
        } else if (prettyPrimary === "The provider didn't work out") {
            index = 3;
            // send them to request a new sitter
        } else if (prettyPrimary === "Tandem didn't find me a provider") {
            index = 4;
            // tips for finding a provider
        } else {
            setStep(1);
            cancelAndSubmit();
            return;
        }
        switch (index) {
            case 1:
                return (
                    <Dialog open={openNextStep}>
                        <DialogContent aria-labelledby="max-width-dialog-title">
                            <Grid container direction="row" justify="space-between">
                                <Grid item xs={8}>
                                    <Typography variant="h6">
                                        Invite your current provider to join and get $50.
                                    </Typography>
                                </Grid>
                                <Grid xs={1}>
                                    <StyledIconButton
                                        aria-label="Close"
                                        onClick={() => {
                                            setStep(1);
                                            setOpenNextStep(false);
                                            cancelAndSubmit();
                                        }}
                                    >
                                        <CloseIcon color="error" />
                                    </StyledIconButton>
                                </Grid>
                            </Grid>
                            <Typography variant="subtitle1">
                                If interested, please contact support@join-tandem.com.
                            </Typography>
                        </DialogContent>
                    </Dialog>
                );
            case 2:
                submitReason();
                cancel();
                return props.history.push({ pathname: '/jobs' });
            case 3:
                return (
                    <Dialog open={openNextStep}>
                        <DialogContent aria-labelledby="max-width-dialog-title">
                            <Typography variant="h6" style={{ margin: 10 }}>
                                Instead of cancelling, you could request a new sitter.
                            </Typography>
                            <Grid container direction="row" justify="space-between" xs={12}>
                                <Button
                                    style={{
                                        backgroundColor: theme.palette.secondary.coral,
                                        width: '48%',
                                    }}
                                    onClick={() => requestNewSitter()}
                                    noMinWidth
                                    padding={'0px'}
                                >
                                    Request New
                                </Button>
                                <Button
                                    style={{ width: '48%' }}
                                    noMinWidth
                                    onClick={() => {
                                        cancelAndSubmit();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                );
            case 4:
                return (
                    <Dialog open={openNextStep}>
                        <DialogContent>
                            <Grid container direction="row" justify="space-between">
                                <Typography variant="h6">Tandem Tip: Finding a Provider</Typography>
                                <StyledIconButton
                                    onClick={async () => {
                                        setOpenNextStep(false);
                                        cancelAndSubmit();
                                    }}
                                >
                                    <CloseIcon color="error" />
                                </StyledIconButton>
                            </Grid>
                            <ol className={classes.root}>
                                <li>
                                    <Typography variant="subtitle1">
                                        Send in the request at least 5 hours before the start time of the job.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle1">
                                        Set your pay to $10/hr or more. Most in-home providers expect this minimum.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle1">
                                        Layout your job expectations and preferences in the comments so candidates have
                                        all necessary information.
                                    </Typography>
                                </li>
                            </ol>
                        </DialogContent>
                    </Dialog>
                );

            default:
                return;
        }
    };

    return (
        <React.Fragment>
            <CancelWithApplicantModal
                isOpen={
                    // controls the state of this modal specifically
                    showApplicantCancellationModal &&
                    // only show if messaged a provider
                    hasMessaged &&
                    // 2 existing state controls for cancellation modal overall
                    props.open &&
                    isOpen &&
                    // this condition excludes opening the modal when they are cancelling an individual day of a weekly job
                    notIndividualDayOfWeeklyJob
                }
                onClose={() => setShowApplicantCancellationModal(false)}
                closeAllModals={() => {
                    setShowApplicantCancellationModal(false);
                    setIsOpen(false);
                    refresh();
                }}
                job={job}
                hasMessaged={hasMessaged}
                appliedProviders={appliedProviders}
                refresh={() => refresh()}
            />

            <Dialog
                open={
                    (!showApplicantCancellationModal ||
                        !hasMessaged ||
                        // this condition makes sure we open the why cancel modal when they cancel an individual day of a weekly request
                        (!notIndividualDayOfWeeklyJob && !job.deactivated)) &&
                    props.open &&
                    isOpen
                }
                fullWidth
                maxWidth="xs"
                onClose={() => {
                    props.onClose();
                    setComment(undefined);
                    setPrimaryReason('Unnecessary');
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent style={{ paddingBottom: '3%' }}>
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={11}>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                Why Did You Cancel?
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <StyledIconButton
                                onClick={() => {
                                    props.onClose();
                                    setComment(undefined);
                                    setPrimaryReason('Unnecessary');
                                    setSecondaryReason('Unnecessary');
                                }}
                            >
                                <CloseIcon color="error" />
                            </StyledIconButton>
                        </Grid>
                        <Grid container direction="row">
                            {step == 1 && primaryReasonDialog()}
                            {step == 2 && secondaryReasonDialog()}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {step == 3 && nextStepDialog()}
            <NotPayingCancellationModal
                isOpen={showNotPaying}
                cancelJob={cancelAndSubmit}
                onClose={() => setShowNotPaying(false)}
                job={job}
                refresh={() => refresh()}
                submitReason={submitReason}
            />
        </React.Fragment>
    );
}

export { WhyCancelDialog };
