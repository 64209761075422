import React from 'react';
import OCCRRAStatus from '../../../OccrraStatus';
import { Text, Colors } from 'library';
import { AdminProvider } from 'models';

export function WorkerOccrraStatus({
    provider,
    refreshProvider,
}: {
    provider: AdminProvider;
    refreshProvider: () => void;
}) {
    return (
        <>
            <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                Status
            </Text>
            <Text variant="body2" bold textStyle={{ margin: 10 }}>
                OCCRRA Admin Status:{' '}
                {provider.occrra_info_collect?.admin_status ? provider.occrra_info_collect?.admin_status : 'NONE'}
            </Text>
            <OCCRRAStatus provider={provider} onRefresh={refreshProvider} />
        </>
    );
}
