import { client } from 'shared';
import { VerifyMfaCodeResponse } from '../internal/providers/ILFingerprintCheckTypes';

export function getWorkerSsnLast4(id: number, ssn_view_token: string): Promise<string> {
    const data = {
        one_day_token: ssn_view_token,
    };
    return client(`api/illinois-info-collect/reveal/?provider=${id}`, {
        method: 'POST',
        body: data,
    });
}

export async function triggerMfaCode(): Promise<void> {
    return client('api/admin-access/send-2fa-code/', {
        method: 'POST',
    });
}

export async function verifyMfaCode(code: string): Promise<VerifyMfaCodeResponse> {
    return client('api/admin-access/verify-2fa-code/', {
        method: 'POST',
        body: {
            code: code,
        },
    });
}
