import React, { Component } from 'react';
import LoginLine from '../../reusableComponents/line';
import Header from '../../reusableComponents/header';
import Button from '../../reusableComponents/button';
import { Grid } from '@material-ui/core';
import Input from '../../reusableComponents/input';
import PasswordInput from '../../reusableComponents/passwordInput';
import BackgroundPaper from '../../reusableComponents/backgroundPaper';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../theme';
import { UserContext } from '../../UserContext';
import FamilyLandingBackground from './FamilyLanding/FamilyLandingBackground';
import { client, consoleLogInDev } from 'shared';
import { Environment, MixPanelKey } from 'environmentVariables';

var mixpanel = require('mixpanel-browser');
mixpanel.init(MixPanelKey);

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            isPassword: true,
            passwordChanged: false,
            user: 0,
            email: '',
            loading: false,
        };
    }

    componentDidMount = async () => {
        try {
            const user = await client('api/users/identity/');
            this.setState({ user: user.id, email: user.email });
        } catch (error) {
            if (Environment !== 'production') {
                console.error(error);
            }
        }
    };

    async changePassword(context) {
        this.setState({ loading: true });
        const { password } = this.state;
        if (this.validatePassword(password)) {
            this.setState({ isPassword: this.validatePassword(password) });
            let changePasswordObject = {
                password: password,
            };
            try {
                const changeRes = await client('change-password/', {
                    method: 'PATCH',
                    body: changePasswordObject,
                });
                if (changeRes.changed) {
                    context.logout();
                }
                mixpanel.track('Change Password');
            } catch (error) {
                consoleLogInDev(error);
            }
        } else {
            this.setState({ isPassword: this.validatePassword(password), loading: false });
        }
    }

    onChangeText = async (name, e) => {
        this.setState({ [name]: e.target.value });
    };

    validatePassword(password) {
        var valid = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return valid.test(password);
    }

    render() {
        const { user, email, password, isPassword, loading } = this.state;
        return (
            <UserContext.Consumer>
                {(context) => {
                    return (
                        <div>
                            <ThemeProvider theme={theme}>
                                <FamilyLandingBackground>
                                    <Grid
                                        container
                                        style={{ height: '75vh' }}
                                        justify="center"
                                        alignContent="flex-start"
                                    >
                                        <Grid item lg={4} md={5} sm={9} xs={10}>
                                            <BackgroundPaper style={{ maxHeight: 275, marginBottom: 15 }}>
                                                <Grid item container direction="column" justify="center">
                                                    <Header variant="h5" gutterBottom>
                                                        Set Password
                                                    </Header>
                                                    <Grid>
                                                        <LoginLine>
                                                            <Input
                                                                fullWidth
                                                                name={email}
                                                                label="Email"
                                                                variant="outlined"
                                                                value={email}
                                                                disabled
                                                            />
                                                        </LoginLine>
                                                    </Grid>
                                                    <Grid>
                                                        <LoginLine>
                                                            {!loading ? (
                                                                <PasswordInput
                                                                    error={!isPassword}
                                                                    helperText={
                                                                        'Password must be minimum 8 characters with at least one uppercase letter, one lowercase letter, one digit, and one special character.'
                                                                    }
                                                                    fullWidth
                                                                    name={password}
                                                                    label="Password"
                                                                    variant="outlined"
                                                                    value={password}
                                                                    onChange={(e) => this.onChangeText('password', e)}
                                                                />
                                                            ) : (
                                                                <PasswordInput
                                                                    error={!isPassword}
                                                                    helperText={
                                                                        'Password must be minimum 8 characters with at least one uppercase letter, one lowercase letter, one digit, and one special character.'
                                                                    }
                                                                    fullWidth
                                                                    name={password}
                                                                    label="Password"
                                                                    variant="outlined"
                                                                    value={password}
                                                                    disabled
                                                                />
                                                            )}
                                                        </LoginLine>
                                                    </Grid>
                                                    <Grid>
                                                        <LoginLine>
                                                            {!loading ? (
                                                                <Button
                                                                    fullWidth
                                                                    type="submit"
                                                                    onClick={() => this.changePassword(context)}
                                                                    variant="contained"
                                                                >
                                                                    Set Password
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    disabled
                                                                    fullWidth
                                                                    type="submit"
                                                                    variant="contained"
                                                                >
                                                                    Success
                                                                </Button>
                                                            )}
                                                        </LoginLine>
                                                    </Grid>
                                                </Grid>
                                            </BackgroundPaper>
                                        </Grid>
                                    </Grid>
                                </FamilyLandingBackground>
                            </ThemeProvider>
                        </div>
                    );
                }}
            </UserContext.Consumer>
        );
    }
}

export default withRouter(ChangePassword);
