import React, { MouseEvent } from 'react';
import { Text } from 'library';
import download from 'shared/Download';
import { track } from 'shared';
import { IInfoModalDoc, Provider } from 'models';

export async function downloadFile(path: string) {
    try {
        await download(path);
    } catch (error) {
        track('Document Download Error:', error);
    }
}

export function getBackGroundCheckLink(provider: Provider) {
    const occrraInfo = provider.occrra_info;
    if (occrraInfo?.background_check_link) {
        return occrraInfo.background_check_link;
    }
}

export function getLinks(provider: Provider) {
    const backgroundCheckLink = getBackGroundCheckLink(provider);
    const hasBackgroundPDF = provider.compliance_documents?.some(
        (x) => x.friendly_name === 'Background Check Document',
    );

    const includeBackgroundCheckLink = backgroundCheckLink && !hasBackgroundPDF;

    const baseLinkObjectList: IInfoModalDoc[] = includeBackgroundCheckLink
        ? [{ type: 'link', text: 'FBI/BCI Background Check', url: backgroundCheckLink, id: -1 }]
        : [];

    const complianceDocs = provider.compliance_documents ?? [];

    const complianceLinks = complianceDocs.reduce((links, doc) => {
        const linkObj = {
            type: 'download',
            url: `api/documents/${doc.id}/download/`,
            text: doc.friendly_name,
            id: doc.id,
        };
        return [...links, linkObj];
    }, baseLinkObjectList);

    return complianceLinks;
}

export const getLink = (item: IInfoModalDoc) => {
    if (item.type === 'download') {
        const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
            downloadFile(item.url);
            e.preventDefault();
        };
        return (
            <a href={item.url} onClick={onClick}>
                {item.text}
            </a>
        );
    }

    return (
        <a target="_blank" rel="noreferrer" href={item.url}>
            {item.text}
        </a>
    );
};

export default function DocumentLinks({ provider }: { provider: Provider }) {
    const links = getLinks(provider);

    const displayDocuments = () => {
        if (!links.length) {
            return <Text variant="body2">There are no documents to show for this provider.</Text>;
        }

        return (
            <div>
                {links.map((x) => (
                    <div key={x.url}>• {getLink(x)}</div>
                ))}
            </div>
        );
    };

    return displayDocuments();
}
