import React from 'react';
import { Text, Colors } from 'library';
import { BusinessJobWithAppointments, BusinessJobWithUser } from 'models';

export default function ProviderOutlawedWarning({
    business_locations_outlawed,
    businesses_outlawed,
    job,
}: {
    businesses_outlawed: number[];
    business_locations_outlawed: number[];
    job?: BusinessJobWithAppointments | BusinessJobWithUser;
}) {
    const isOutlawed =
        job &&
        (businesses_outlawed?.some((business_id) => job.business.map((b) => b.id).includes(business_id)) ||
            business_locations_outlawed?.some((id) => job.business_location?.id === id));
    return isOutlawed ? (
        <Text textStyle={{ color: Colors.error }} variant="caption" bold>
            Provider Outlawed from this Location
        </Text>
    ) : (
        <></>
    );
}
