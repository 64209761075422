import { useState, Dispatch, SetStateAction } from 'react';
import { BusinessJobWithAppointments } from 'models';
import { queryStringifyObject } from 'shared';
import useMatchingListLabels from './useMatchingListLabels';

const useMatchingListQuery = (): [
    boolean,
    Dispatch<SetStateAction<boolean>>,
    (
        job: BusinessJobWithAppointments,
        distance: number,
        showMoreMatches: boolean,
        week: Date | undefined,
        excludeStandbyUsers: boolean,
    ) => string,
] => {
    const [showMoreMatches, setShowMoreMatches] = useState<boolean>(false);
    const [
        includedLabels,
        excludedLabels,
        setIncludedLabels,
        defaultIncludedLabels,
        labelQueryString,
    ] = useMatchingListLabels();

    const generateQueryParams = (
        job: BusinessJobWithAppointments,
        distance: number,
        showMoreMatches: boolean,
        week: Date | undefined,
        excludeStandbyUsers: boolean,
    ) => {
        const available_week = week ? week.toISOString() : undefined;

        if (!showMoreMatches) {
            setIncludedLabels(defaultIncludedLabels);
        }

        return queryStringifyObject({
            distance: distance,
            distance_job: job.id,
            labels: labelQueryString(includedLabels),
            exclude_labels: labelQueryString(excludedLabels),
            available_week: !showMoreMatches ? available_week : undefined,
            available_for_job: job.id,
            show_more_matches: showMoreMatches,
            exclude_standby: excludeStandbyUsers,
        });
    };

    return [showMoreMatches, setShowMoreMatches, generateQueryParams];
};

export default useMatchingListQuery;
